import { forgotPwdService } from '../services'

export default (username, captchaResponse) => {
  return new Promise(resolve => {
    forgotPwdService.create(username, captchaResponse)
      .then(response => {
        if (response && response.data === 'Y') {
          resolve({ success: true })
        } else {
          resolve({ error: true })
        }
      })
      .catch(error => {
				if(error && error.response) resolve({ error: true })
			})
  })
}
