import {FileManagerService} from "../services";

export default (folderName) => {
    return new Promise((resolve, reject) => {
        FileManagerService.createFolderPath(folderName)
            .then(response => {
                resolve(response.data)
            })
            .catch(err => reject(err.response.data))
    })

}