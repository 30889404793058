import getCategories from './getCategories'
import Form from './Form'
import getClientsMin from './getClientsMin'
import cdi from './cdi'

export const getClientsMinService = getClientsMin
export const getCategoriesService = getCategories
export const FormService = Form
export const cdiService = cdi

