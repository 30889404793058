import { notification } from 'antd'
import { getBeneficiariesMatches } from '../services/getBeneficiariesMatches'

export const getBeneficiariesMatchesPromise = (idsForMatch) => {
	return new Promise(resolve => {
		getBeneficiariesMatches(idsForMatch)
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "transactional activity: "+error.message})})
	})
}
