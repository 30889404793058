import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, DatePicker, Row, Select, Spin} from "antd";

import './CategoryRiskCard.scss'
import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import { getRiskCLClientesPromise } from "../../../../../Register/promises";
import moment from "moment";

const CategoryRiskCard = ({categories, globalActiveCategory, handleTabAlertas}) => {

    const {t} = useTranslation()

    const defaultRiskInfo = [
        {title: t('messages.aml.critical'), value: null, score: 4},
        {title: t('messages.aml.high'), value: null, score: 3},
        {title: t('messages.aml.medium'), value: null, score: 2},
        {title: t('messages.aml.low'), value: null, score: 1}
    ];

    const colorRisk = (score) => {
        if (score === 0) return 'GREEN'
        if (score === 1) return 'YELLOW'
        if (score === 2) return 'ORANGE'
        if (score === 3) return 'RED'
        if (score === 4) return 'BLACK'
    }

    const [activeCategory, setActiveCategory] = useState(globalActiveCategory);
    const [activePersonType, setActivePersonType] = useState(null);
    const [activeDateRange, setActiveDateRange] = useState(null);
    const [riskValue, setRiskValue] = useState([]);


    useEffect(() => {
        handleSearch()
    }, [activeCategory, activePersonType, activeDateRange]);


    const handleSearch = () => {
        let _filters = { }
        if(activeCategory) _filters['categories'] = [activeCategory]
        if(activePersonType) _filters['types'] = [activePersonType]
        if(activeDateRange && activeDateRange.length === 2) {
            _filters.alerts = { fechaAlerta: activeDateRange }
        }

        const data = []

        getRiskCLClientesPromise(_filters).then(risk => {
            defaultRiskInfo.map(riskInfo => {
                let cant = risk[colorRisk(riskInfo.score)]
                if(cant) {
                    riskInfo.value = cant
                }else {
                    riskInfo.value = 0
                }
                data.push(riskInfo)
            })
            setRiskValue(data)
        })
    }

    const handleSetActiveCategory = (category) => {
        setActiveCategory(category);
    };

    const handleSetActivePersonType = (personType) => {
        setActivePersonType(personType);
    };

    const handleSetActiveDateRange = (dateRange) => {
        if (dateRange !== null) {
            setActiveDateRange([dateRange[0], dateRange[1]])
        } else {
            setActiveDateRange(null)
        }
    }

    const getFiltersAlertas = (score) => {
        let filters = { amlStatus: [ colorRisk(score) ] }
        if(activeCategory) {
            filters['categories'] = [activeCategory]
        }
        if(activePersonType) {
            filters['types'] = [activePersonType]
        }
        if(activeDateRange && activeDateRange.length === 2) {
            filters.alerts = { fechaAlerta: activeDateRange }
        }

        return filters
    }

    const RiskResume = () => {
        return (
            <>
                <div className={"risk-resume-content"}>
                    <Row gutter={[0, 0]}>
                        {riskValue.map((risk, index) =>
                            <>
                                {risk.value !== null ?

                                    (<Col span={6}>
                                        <div className={'wrapper-risk'}>
                                            <div className={'circle-risk risk-' + colorRisk(risk.score)}>
                                                <a onClick={() => handleTabAlertas(getFiltersAlertas(risk.score), 'records')}>
                                                    <div className="risk-value"> {(risk.value)} </div>
                                                </a>
                                            </div>
                                        </div>
                                        <a onClick={() => handleTabAlertas(getFiltersAlertas(risk.score), 'records')}>
                                            <h3 className={'title-risk'}>{risk.title}</h3>
                                        </a>
                                    </Col>)
                                    :
                                    (<Col span={6} key={index}>
                                        <div className="spinner">
                                            <Spin spinning={true} size="large"/>
                                        </div>
                                    </Col>)

                                }
                            </>
                        )}
                    </Row>
                    <br/>
                    <Row>
                        <div className="risk-filter">
                            <Row>
                                <Col span={6}>
                                    <Select
                                        style={{width: "100%"}}
                                        value={activePersonType}
                                        placeholder={t('messages.aml.personType')}
                                        onChange={value => handleSetActivePersonType(value)}
                                    >
                                        <Select.Option value={null}>{t('messages.aml.personType')}</Select.Option>
                                        <Select.Option value="Person">{t('messages.aml.risk.PERSONA')}</Select.Option>
                                        <Select.Option value="Entity">{t('messages.aml.legalPerson')}</Select.Option>
                                    </Select>
                                </Col>

                                <Col span={8}>
                                    <DatePicker.RangePicker

                                        placeholder={[t('messages.aml.from'), t('messages.aml.to')]}
                                        style={{width: "100%"}}
                                        onChange={momentData => {

                                            handleSetActiveDateRange(
                                                momentData !== null && momentData.length > 0 ?
                                                    [
                                                        moment(momentData[0]).valueOf(),
                                                        moment(momentData[1]).valueOf(),
                                                    ]
                                                    : null)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='category-risk'>
                <LargeCardDashboard
                    key={activeCategory}
                    title={t("messages.aml.monitor.risk.of." + (activeCategory ? activeCategory : 'null'))}
                    legend={t("messages.aml.monitor.leyend." + (activeCategory ? activeCategory : 'null'))}
                    content={RiskResume()}
                    handleSetActiveCategory={handleSetActiveCategory}
                    activeCategory={activeCategory}
                    categories={categories}
                />
            </div>
        </>
    )
}

export default CategoryRiskCard;