import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Row, Spin} from "antd";
import './ListCategoryRiskCard.scss'

import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import { getClientsPromise } from "../../../../../Register/promises";
import {camelizerHelper} from "../../../../../../helpers";


const ListCategoryRiskCard = ({categories, globalActiveCategory, handleTabAlertas}) => {

    const {t} = useTranslation()

    const colorRisk = (score) => {
        if (score === 0) return 'GREEN'
        if (score === 1) return 'YELLOW'
        if (score === 2) return 'ORANGE'
        if (score === 3) return 'RED'
        if (score === 4) return 'BLACK'
    }

    const defaultRisk = [{title: t('messages.aml.critical'), value: null, score: 4},
        {title: t('messages.aml.high'), value: null, score: 3},
        {title: t('messages.aml.medium'), value: null, score: 2},
        {title: t('messages.aml.low'), value: null, score: 1}
    ]

    const [activeCategory, setActiveCategory] = useState(globalActiveCategory);
    const [riskData, setRiskData] = useState([])
    const [riskData1, setRiskData1] = useState({})
    const [riskData2, setRiskData2] = useState({})
    const [riskData3, setRiskData3] = useState({})
    const [riskData4, setRiskData4] = useState({})

    useEffect(() => {
        handleSearch()

    }, [activeCategory]);

    useEffect(() => {
        setRiskData([riskData1, riskData2, riskData3, riskData4])
    }, [riskData1, riskData2, riskData3, riskData4]);

    const handleSearch = () => {
        setRiskData(defaultRisk)

        defaultRisk.map((r, iRisk) => {
            let risk = { ...r }
            const color = colorRisk(risk.score)

            let filter = { amlStatus: [ color ] }
            if(activeCategory) filter.categories = [activeCategory]
            getClientsPromise(0, 4, filter).then(records => {
                risk.value = records.records

                if(iRisk === 0) setRiskData1(risk)
                else if(iRisk === 1) setRiskData2(risk)
                else if(iRisk === 2) setRiskData3(risk)
                else if(iRisk === 3) setRiskData4(risk)
            })
        })
    }

    const handleSetActiveCategory = (category) => {
        setActiveCategory(category);
    };

    const getFilters = (score, rut) => {
        let filters = {amlStatus: [colorRisk(score)]}
        if(activeCategory) filters.categories = [activeCategory]
        if(rut) filters.text = rut

        return filters
    }

    const RiskResume = () => {
        return (
            <>
                <div className={"risk-list-resume-content"}>
                    <Row gutter={[50, 0]}>
                        <br/>

                        {Object.values(riskData).map((risk, index) =>
                            <>
                                <Col span={6} key={index}>
                                    <a onClick={() => handleTabAlertas(getFilters(risk.score), 'records')}>
                                        <h3 className={'title-list-risk'}> {risk.title} </h3>
                                    </a>

                                    <div className="list-value-risk">
                                        {risk.value ?
                                            (risk.value.length > 0 ?
                                                risk.value.map(record =>
                                                    <a onClick={() => handleTabAlertas(getFilters(risk.score, record.rut), 'records')}>
                                                        <div className="value-text">{camelizerHelper(record.nombre.toString().trim())}</div>
                                                    </a>
                                                )
                                            :
                                                <p className="no-result">{t('messages.aml.withoutResults')}</p>
                                            )
                                        :
                                            <div className="spinner">
                                                <Spin spinning={true} size="large"/>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </>
                        )
                        }

                    </Row>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='list-category-risk'>
                <LargeCardDashboard
                    key={activeCategory}
                    title={t("messages.aml.monitor.list.risk.of." + activeCategory)}
                    legend={t("messages.aml.monitor.list.leyend." + activeCategory)}
                    content={RiskResume()}
                    handleSetActiveCategory={handleSetActiveCategory}
                    activeCategory={activeCategory}
                    categories={categories}
                />
            </div>
        </>
    )
}

export default ListCategoryRiskCard;