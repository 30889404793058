import { notification } from 'antd'
import {getPendingDeclarationsService} from '../services/getPendingDeclarationsService'

export const getPendingDeclarationsPromise= (dataRange) => {
	return new Promise(resolve => {
		getPendingDeclarationsService(dataRange)
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "Alert Resume : "+error.message})})
	})
}

