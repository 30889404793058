import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const negativeList = {
  read: (params) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/negativeList',
      method: 'post',
      body: params
    })
  },
  changeAllowNegClient: (allowNegAdm) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/changeAllowNegClient',
      method: 'post',
      body: {
        allowNegAdm
      }
    })
  },
  updateNegativeRecord: (recordList) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/updateListasPropias',
      method: 'post',
      body: recordList
    })
  },
}
export default negativeList