import { notification } from 'antd'
import {getCdiDeclarationSumaryService} from '../services/getCdiDeclarationSumaryService'

export const getCdiDeclarationSumaryPromise= (dataRange) => {
	return new Promise(resolve => {
		getCdiDeclarationSumaryService(dataRange)
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "Alert Resume : "+error.message})})
	})
}

