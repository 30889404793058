import React, {useEffect, useState} from 'react';
import {getAlertResumeByClClientPromise} from "./promises/getAlertResumeByClClientPromise";
import {Button, Col, Modal, Row, Spin, Table, Tooltip} from "antd";
import './AlertStackedChart.scss'
import {useTranslation} from "react-i18next";

export const AlertStackedChart = ({dateValue, riskColor, handleTabRedirection}) => {
    const [loading, setLoading] = useState(false);
    const [alertData, setAlertData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [maxRow, setMaxRow] = useState(0);
    const [isModalAllContentVisible, setIsModalAllContentVisible] = useState(false);

    const {t} = useTranslation()

    useEffect(() => {
        initFunc();
    }, [dateValue]);

    const initFunc = () => {
        setLoading(true)
        getAlertResumeByClClientPromise({dateValue}).then((response) => {
            let max = 0;
            let total = 0;

            response.data.forEach((row) => {
                    const totalRow = row.risk1 + row.risk2 + row.risk3 + row.risk4
                    row.total = totalRow;
                    max = (totalRow > max) ? totalRow : max;
                    total = total + totalRow;
                }
            )

            setMaxRow(max)
            setTotalCount(total)
            setAlertData(response.data)
            setLoading(false)
        })
    }

    const getStrokeColorByRisk = (risk) => {
        return riskColor.filter(color => color.risk === risk)[0].color
    };

    const getDataLimited = (data) => {
        const sortedData = data.sort((a, b) => b.total - a.total); //siempre muestra las cantidades mayores
        return sortedData.slice(0, 8);
    }

    const handleModalAllContent = () => {
        setIsModalAllContentVisible(true)
    }

    const handleModalAllContentCancel = () => {
        setIsModalAllContentVisible(false)
    }

    const handleClick = (data, risk) => {
        let filters = {
            group: data.group,
            risks: [risk],
            activeTab: 'tab-alerts',
        }
        if(dateValue && dateValue.length === 2) {
            filters.fechaAlerta = dateValue;
        }
        handleTabRedirection(filters, 'monitoring')
    };


    const dataColumns = [
        {
            title: 'Proceso',
            dataIndex: 'group',
            width: '19%',
        },
        {
            title: 'Cantidad y Riesgo',
            align: 'center',
            width: '81%',
            render: (text, group) => {

                return (

                    <Row className="inside-alert-stacked-row">

                        <div className="total-chart-width" style={{width: '94%'}}>

                            <div className="inside-alert-stacked-row-chart" style={{width: (group.total * 100 / maxRow ) + '%'}}>


                            {[1, 2, 3, 4].map(i => (
                                    <Tooltip title={group['risk' + i]}>

                                        {group['risk' + i] > 0 &&
                                        <div
                                            className={'risk-chart-row'}
                                            style={{width: (group['risk' + i] * 100 / group.total) + '%'}}>

                                            <div className={'risk-chart-cell'}
                                                 style={{backgroundColor: getStrokeColorByRisk(i)}}
                                                 onClick={() => handleClick(group, i)}>


                                                <div className={"section-info"}>
                                                {((group['risk' + i] * 100 / maxRow) >= 10) && group['risk' + i]}{" "}
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </Tooltip>
                                )
                            )}
                            </div>
                        </div>
                        <div className='inside-alert-stacked-row-total'>
                            {group.total}
                        </div>
                    </Row>
                );
            }
        }
    ];

    return (
        <div className='inside-alert-stacked-chart'>
            <Spin spinning={loading}>
                {alertData.length === 0 ? (
                    <div className="text-no-content">{t('messages.aml.inside.no.content')}</div>
                ) : (
                    <div>
                        <Table
                            pagination={false}
                            columns={dataColumns}
                            dataSource={getDataLimited(alertData)}
                            loading={loading}
                            style={{width: '100%'}}
                            size='small'
                        />

                        <div className="total-content">

                            <Row>
                                <Col span={6} className={"show-content-alert"}>
                                    {alertData.length > 8 &&
                                        <Button className="show-all-alert-content" onClick={handleModalAllContent}>
                                            <div className="all-content">{t('messages.aml.inside.all.content')}</div>
                                        </Button>
                                    }
                                </Col>

                                <Col span={6} offset={12}>
                                    <div className="total-content">
                                        <div>{"Total " + totalCount}</div>
                                    </div>
                                </Col>
                            </Row>
                    </div>


                {isModalAllContentVisible && (

                    <Modal
                    visible={true}
                footer={false}
                onCancel={handleModalAllContentCancel}
                width={800}
                                style={{top: 10}}
                            >
                                <div>
                                    <Table
                                        pagination={false}
                                        columns={dataColumns}
                                        dataSource={alertData}
                                        loading={loading}
                                        style={{width: '100%'}}
                                        size='small'
                                    />
                                </div>
                            </Modal>
                        )}
                    </div>
                )}
            </Spin>
        </div>
    );
};