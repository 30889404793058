import { stakeholderService } from '../services'

export default (countryCode, rut, password) => {
	return new Promise((resolve, reject) => {
		stakeholderService.loginPortal(countryCode, rut, password)
			.then(response => {
				if(response) resolve({ success: true, data: response.data })
			})
			.catch(error => {
				if(error && error.response) reject({ success: false, data: error.response.data })
			})
	})
}
