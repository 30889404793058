import React, {useEffect, useState} from 'react';
import {Row, Col, Input, Form, Spin} from 'antd';
import './BasicInfoAlert.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { camelizerHelper } from '../../../../helpers';

const BasicInfoAlert = ({ alert, origin}) => {
    const [risks, setRisks] = useState([])
    const [itemsTotalNum, setItemsTotalNum] = useState(0)
    const [currentPageRisk, setCurrentPageRisk] = useState(1)
    const itemsPerPage = 10
    const { t } = useTranslation()

    useEffect(() => {
        if(alert?.risks?.length > 0) {
            handlePaginationChange(1)
            setItemsTotalNum(alert.risks.length)
        }
    }, []);

    const handlePaginationChange = (page) => {
        const fromNum = ((page - 1) * itemsPerPage)
        setCurrentPageRisk(page)
        setRisks(alert.risks.slice(fromNum, fromNum + itemsPerPage))
    }

    const ifNullText = (data) => {
        if (data) {
            return data
        }else{
            return "N/A"
        }
    }
    return (
        alert ? 
            <div className='basic-info-alert-section'>
                <div className="title-section">
                    <Row>
                        <Col span={19}>
                            <h3>{camelizerHelper(alert?.record?.nombre)}</h3>
                        </Col>
                        <Col span={5}>
                            <div style={{textAlign: 'right'}}>
                                Recepción de la alerta: {moment(alert?.creationDate).format('DD/MM/YYYY')}
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                </div>
                <Form colon={false}>
                    <div className='subtitle-section'>
                        <Row>
                            <Col span={24}>
                                Persona
                            </Col>
                        </Row>
                    </div>
                    <div className='body-section-wrapper'>
                        <Row gutter={[36]}>
                            <Col span={6}>
                                <Form.Item label= "Tipo de Documento">
                                    <Input readOnly value={ifNullText(alert?.record?.tipoDoc)}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label= "Nro. de Documento">
                                    <Input readOnly value={alert?.record?.rut}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label= "Tipo de Persona">
                                    <Input readOnly value={alert?.typePerson === 'Person' ? "Persona Natural" : "Persona Jurídica"}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    {alert?.contratante?.typePEP && alert?.contratante?.typePEP !== 'T' && alert?.contratante?.rutPEPTitular &&
                        <>
                            <div className='subtitle-section'>
                                <Row>
                                    <Col span={24}>
                                        Asociado a PEP
                                    </Col>
                                </Row>
                            </div>
                            <div className='body-section-wrapper'>
                                <Row gutter={[36]}>
                                    <Col span={6}>
                                        <Form.Item label= "Nombre PEP Titular">
                                            <Input readOnly value={ifNullText(camelizerHelper(alert?.contratante?.nombrePEPTitular))}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label= "DNI PEP Titular">
                                            <Input readOnly value={ifNullText(alert?.contratante?.rutPEPTitular)}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                    {alert?.record &&
                        <>
                            <div className='subtitle-section'>
                                <Row>
                                    <Col span={24}>
                                        Información adicional
                                    </Col>
                                </Row>
                            </div>
                            <div className='body-section-wrapper'>
                                <Row gutter={[36]}>
                                    <Col span={6}>
                                        <Form.Item label= "Fecha de nacimiento">
                                            <Input readOnly value={alert?.record?.dateBirth ? moment(alert.record.dateBirth).format('DD/MM/YYYY') : "N/A"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label= "Nacionalidad">
                                            <Input readOnly value={ifNullText(alert?.record?.citizenship)}/>
                                        </Form.Item>
                                    </Col>
                                    {alert?.contratante &&
                                        <>
                                            { alert.contratante.actividadEconomica &&
                                                <Col span={6}>
                                                    <Form.Item label= "Actividad">
                                                        <Input readOnly value={ifNullText(alert?.contratante?.actividadEconomica)}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                            { alert.contratante.comunaParticular &&
                                                <Col span={6}>
                                                    <Form.Item label= "Comuna Dirección Particular">
                                                        <Input readOnly value={ifNullText(alert?.contratante?.comunaParticular)}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                            { alert.contratante.comunaComercial &&
                                                <Col span={6}>
                                                    <Form.Item label= "Comuna Dirección Comercial">
                                                        <Input readOnly value={ifNullText(alert?.contratante?.comunaComercial)}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                            { alert.contratante.perfil &&
                                                <Col span={6}>
                                                    <Form.Item label= "Perfil de Riesgo">
                                                        <Input readOnly value={alert.contratante.perfil}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                            { alert.contratante.primerAporte &&
                                                <Col span={6}>
                                                    <Form.Item label= "Primer aporte">
                                                        <Input readOnly value={ifNullText(alert?.contratante?.primerAporte)}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                            { alert.contratante.patrimonioAdministrar &&
                                                <Col span={6}>
                                                    <Form.Item label= "Patrimonio a Administrar">
                                                        <Input readOnly value={ifNullText(alert?.contratante?.patrimonioAdministrar)}/>
                                                    </Form.Item>
                                                </Col>
                                            }
                                        </>
                                    }
                                </Row>
                            </div>
                        </>
                    }

                        <div className='subtitle-section'>
                            <Row>
                                <Col span={24}>
                                    Alerta
                                </Col>
                            </Row>
                        </div>
                        <div className='body-section-wrapper'>
                            <Row gutter={[36]}>
                                <Col span={5}>
                                    <Form.Item label= "Folio">
                                        <Input readOnly value={alert?.folio}/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item label= "Estado">
                                        <Input readOnly value={t("messages.aml.alert.status."+alert?.status)}/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item label= "Tipo de Riesgo">
                                        <Input readOnly value={t("messages.aml.risk."+alert?.type)}/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label= "Riesgo Alerta">
                                        <Input readOnly value={alert?.score}/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item label= {t("messages.aml.totalTime")}>
                                        <Input readOnly value={alert.days > 1 ? alert.days+ " días" : alert.days+" día"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                </Form>
            </div>
        :
            <div style={{textAlign: 'center', padding: 100}}>
                <Spin size="large"/>
            </div>
    );
};

export default BasicInfoAlert;