import { Button, Col, DatePicker, Icon, Input, notification, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { requestRosPromise, updateRosPromise } from '../../promises';
import './SuspiciousOperation.scss'

const SuspiciousOperation = ({alert, currentUser, refreshHandler}) => {

    const [seeMore, setSeeMore] = useState(0);
    const [isEnabled, setIsEnabled] = useState(false)
    const [changes, setChanges] = useState(0)
    const [ros, setRos] = useState({})
    const [commentsResponse, setCommentsResponse] = useState(null)
    const [date, setDate] = useState(null)
    const [code, setCode] = useState(null)

    const { t } = useTranslation()

    useEffect(() => {
		if(alert !== null && alert.ros !== null) {
			setRos(alert.ros)
		}
	}, [alert])

    const requestRos = () => {
        requestRosPromise(alert.id, ros.comments).then((response) => {
            notification["success"]({
                message: t("messages.aml.delivered"),
            });
            setRos({...ros, comments:''})
            refreshHandler()
        });
    }

    const updateRos = () => {
        updateRosPromise(alert.id, ros).then((response) => {
        notification["success"]({
            message: t("messages.aml.delivered"),
        });
        setRos({...ros,
            status:null,
            comments:null,
            dateReport:null,
            code:null
        })
        refreshHandler()
        });
    }

    const handlerOnChange = (field,value) => {
        setRos({...ros,[field]:value})
        setChanges(changes+1)
    }


    return (
        <div className='suspicious-operation-section'>
            <div className="title-section">
                <Row>
                    <Col span={19}>
                        <h3>Registro de Reporte de Operaciones Sospechosas (ROS)</h3>
                    </Col>
                    <Col span={5}>
                        <div style={{textAlign: 'right'}}>
                            <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                                {seeMore ?
                                    <>Ver menos <Icon type="minus"/></>
                                    :
                                    <>Ver más <Icon type="plus"/></>
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
            <AnimateHeight
                duration={ 600 }
                height={ seeMore }
                animateOpacity={true}
            >
                <section className='suspicious-operation-body'>
                    <Row style={{height: 0}}>
                    { changes > 0 && currentUser.cliente.oficialCto !== null && currentUser.id === currentUser.cliente.oficialCto.id &&
                        <Button className="save-button" onClick = {(e)=>updateRos()} type="primary" icon="save" size="small" style={{float: 'right'}}>Guardar cambios</Button>
                    }
                    </Row>
                    <article className='suspicious-operation-content-wrapper'>
                        <Row gutter={[0,15]}>
                            <Col span={15}>
                                Fecha en que se autorizó el envío del ROS
                            </Col>
                            <Col span={9}>
                                <DatePicker placeholder='Seleccione' style={{width: '100%'}} onChange={ momentObj => handlerOnChange('sendRosDate',moment(momentObj).valueOf())}/>
                            </Col>
                            <Col span={15}>
                                Instancia de autorización
                            </Col>
                            <Col span={9}>
                                {/* <Select placeholder="Seleccione" style={{width: '100%'}} onCWhange={ e => handlerOnChange('status',e.target.value) }>
                                    <Select.Option value="AUTHORIZED">
                                        Autorizado
                                    </Select.Option>
                                    <Select.Option value="DENIED">
                                        Rechazado
                                    </Select.Option>
                                </Select> */}
                                <Input onChange={ e => handlerOnChange('authMoment', e.target.value) }/>
                            </Col>
                            <Col span={15}>
                                Fecha de envío del reporte a las autorizades correspondientes
                            </Col>
                            <Col span={9}>
                                <DatePicker style={{ width: '100%' }} placeholder={ t('messages.aml.selectDate') } onChange={ momentObj => handlerOnChange('dateReport',moment(momentObj).valueOf()) } format="DD/MM/YYYY" />
                            </Col>
                            <Col span={15}>
                                Código generado por la autoridad
                            </Col>
                            <Col span={9}>
                                <Input onChange={ e=> handlerOnChange('code',e.target.value) } />
                            </Col>
                            <Col span={24}>
                                <Input.TextArea placeholder={t('messages.aml.comments')} onChange={ e=>handlerOnChange('comments',e.target.value) }/>
                            </Col>
                        </Row>
                    </article>
                </section>
            </AnimateHeight>
        </div>
    );
};

export default SuspiciousOperation;