import './Reporting.scss'
import React, { useState } from 'react'
import {Tabs } from 'antd';
import {Row, Col} from 'antd'
import {ReportingFormPage} from './components'

const Reporting = ({currentUser}) => {

    const [activeTab, setActiveTab] = useState("declarations");

	  const typeOfReport = [
		  {
            title: "Declaraciones",
		  	key: "declarations"
		  },
		  {
            title: "Registros",
		  	key: "registers"
		  },
	  ]

      const handleTabOnChange = (key) => {
        setActiveTab(key)
      }

    return (
        <div className="reporting-content">
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey="declarations" onChange={handleTabOnChange} type="line" className='reporting-tabs'>
                        {typeOfReport.map(tab =>
                            <Tabs.TabPane tab={tab.title} key={tab.key}/>
                        )

                        }
                    </Tabs>
                </Col>
                <Col span={24}>
                    <div className="reporting-tabs">
                        <ReportingFormPage typeOfReport={activeTab} currentUser={currentUser} key={activeTab}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default Reporting
