import './Stakeholder.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Button, Icon, Form, Row, Col, Input, notification, Spin, message } from 'antd'
import { getLoginPortalDataPromise, forgotPasswordPromise, createNewUserPromise, registerUserPromise } from './promises'
import { Captcha } from '../../layouts/Private/components'
import { validateEntityRutHelper } from "../../helpers";
import Countdown from '../../layouts/Private/components/Countdown/Countdown'

const PublicPortal = ({ form, countryCode, successHandler, handleLoginRut }) => {
	const { t } = useTranslation();
	const { getFieldDecorator, validateFields } = form
	const [isCreate, setIsCreate] = useState(false)
	const [isRestore, setIsRestore] = useState(false)
	const [isValidating, setIsValidating] = useState(false);
	const [rut, setRut] = useState(null)
	const [confirmDirty, setConfirmDirty] = useState(false);
	const [captchaResponse, setCaptchaResponse] = useState(null);
	const [captchaSucess, setCaptchaSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// const [captchaKey, setCaptchaKey] = useState(Math.random());

	const renderFormItem = (formItem) => {
		return (
			<Row className={formItem.styleClass}>
				<Form.Item label={formItem.label} hasFeedback={formItem.hasFeedback}>
					{getFieldDecorator(formItem.name, { rules: formItem.rules, initialValue: formItem.initialValue })(formItem.item)}
				</Form.Item>
			</Row>
		)
	}

	const rutValidate = (rule, value, callback) => {
		if (value && !validateEntityRutHelper(value)) {
			callback("Rut no es válido.")
		}
		callback()
	}

	const handleSubmit = async (e) => {
		handleCatpcha(false)
		e.preventDefault()
		setIsLoading(true)

		validateFields((err, values) => {
			if (!err) {
				if (isRestore) {
					forgotPasswordPromise(countryCode, rut)
						.then(response => {
							if (response.success) {
								notification.success({ message: "Correo enviado", description: "se envió correo con contraseña temporal" })
							} else if (response.data.code === '400-10') {
								notification.error({ message: "Error", description: "Máximo de intentos alcanzado, inténtelo más tarde." })
							} else {
								notification.error({ message: "Error", description: "Ha ocurrido un error" })
							}
						})
						.finally(() => { setIsLoading(false) })



					// const u = await forgotPasswordPromise(countryCode, rut)
					// if (u.success) {
					// 	notification.success({ message: "Correo enviado", description: "se envió correo con contraseña temporal" })
					// } else {
					// 	if (u.data.code === '400-10') {
					// 		notification.error({ message: "Error", description: "Máximo de intentos alcanzado, inténtelo más tarde." })
					// 	} else {
					// 		notification.error({ message: "Error", description: "Ha ocurrido un error" })
					// 	}
					// }


					// if (u.data.status === 'OK') {
					// 	if(u.data.code === '401') {
					// 		handleErrors(u.data.code, "Error", true);
					// 	} else if(u.data.code === '409') {
					// 		handleErrors(u.data.code, "Error", true);
					// 	} else if(u.data.code === '400-10') {
					// 		handleErrors(u.data.code, "Aviso", true);
					// 	} else if(u.data.code === '400-20') {
					// 		handleErrors(u.data.code, "Aviso", true, u.data.attemps);
					// 	} else if(u.data.code === '202') {
					// 		handleErrors(u.data.code, "Operacion Exitosa", false, u.data.attemps);
					// 	} else if(u.data.code === '200') {
					// 		setIsRestore(false)
					// 	} else {
					// 		handleErrors(u.data.code, "Error", true);
					// 	}
					// }
				} else if (isCreate) {
					createNewUserPromise(countryCode, rut, values.email, values.nombre)
					.then(response => {
						notification.success({ message: response.data.status, description: response.data.message })
						setIsValidating(true)
						setIsCreate(false)
					}).catch(response => {
						notification.error({ message: response?.data?.status, description: response?.data?.message })
					})
					setIsLoading(false)

				} else if (isValidating) {
					registerUserPromise(countryCode, rut, values.verificationCode, values.newPw).then(response => {
						if (response.success) {
							notification.success({ message: "Se ha registrado con éxito" })
							setIsValidating(false)
						} else {
							notification.success({ message: "Ha ocurrido un error", description: "No se ha podido registrar" })
						}
					}).catch((res) => {
						notification.success({ message: "Ha ocurrido un error", description: "No se ha podido registrar" })
					})
					.finally(() => {
						setIsLoading(false)
					})

				} else {
					getLoginPortalDataPromise(countryCode, rut, values.password).then(response => {
						if (response) {
							successHandler(response.data.record, response.data.code)
						} else {
							notification.error({ message: "Error", description: response.data.status })
						}
					}).catch(response => {
						notification.error({ message: response.data.status, description: response.data.attempts ? "Quedan" + response.data.attempts + "intentos" : null })
					}).finally(() => setIsLoading(false))
				}

			} else {
				message.error("Verifique datos ingresados")
				setIsLoading(false)
			}
		});
	}

	const handleUsernameOnChange = (rut) => {
		setRut(rut);
		handleLoginRut(rut)
	}


	const validateToNextPassword = (rule, value, callback) => {
		if (value && confirmDirty) {
			validateFields(['confirmPw'], { force: true });
		}
		callback();
	};

	const compareToFirstPassword = (rule, value, callback) => {
		if (value && value !== form.getFieldValue('newPw')) {
			callback('Esta contraseña no es igual a la ingresada.');
		} else {
			callback();
		}
	};

	const handleSwitchToRestore = async (e) => {
		e.preventDefault()
		setIsRestore(true)
		handleCatpcha(false)
	}

	const handleConfirmBlur = (e) => {
		const { value } = e.target;
		setConfirmDirty(confirmDirty || !!value);
	};

	const handleSwitchToCreate = async (e) => {
		e.preventDefault()
		setIsCreate(true)
		setIsRestore(false)
		setIsValidating(false)
		handleCatpcha(false)
	}

	const handleSwitchToLogin = async (e) => {
		e.preventDefault()
		setIsRestore(false)
		setIsCreate(false)
		setIsValidating(false)
		handleCatpcha(false)
	}

	const handleCatpcha = async (success, response) => {
		setCaptchaSuccess(success);
		if (response) {
			setCaptchaResponse(response);
		} else {
			setCaptchaResponse(null);
			setCaptchaSuccess(false);
		}
	}


	// const handleErrors = (errorCode, title, isError, attemps = null) => {
	// 	let errorMsg = "Ocurrio un Error";
	// 	switch(errorCode){
	// 		case "202":
	// 			if(attemps){
	// 				errorMsg = `Por favor, revise su correo electrónico, le hemos enviado una contraseña, el usuario será bloqueado en ${attemps} intentos`
	// 			}else{
	// 				errorMsg = "Hemos enviado una contraseña al correo electrónico registrado en la plataforma"
	// 			}
	// 			break;
	// 		case "401":
	// 			if(attemps){
	// 				errorMsg = `Nombre de usuario y/o contraseña incorrectos, el usuario será bloqueado en ${attemps} intentos`
	// 			}else{
	// 				errorMsg = "Nombre de usuario y/o contraseña incorrectos"
	// 			}
	// 			break;
	// 		case "400-10":
	// 			errorMsg = "El usuario ha sido bloqueado, inténtelo más tarde.";
	// 			break;
	// 		case "409":
	// 			errorMsg = "Ocurrio un problema con su solicitud"
	// 			break;
	// 		case "500":
	// 			errorMsg = "Rut no registrado"
	// 	}

	// 	if(isError){
	// 		notification.error({
	// 			placement: 'topLeft',
	// 			message: title,
	// 			description: errorMsg
	// 		  })
	// 	}else{
	// 		notification.success({
	// 			placement: 'topLeft',
	// 			message: title,
	// 			description: errorMsg
	// 		  })
	// 	}

	// 	setIsCreate(false);
	// 	setIsRestore(false);
	// }

	return (

		<div className="stakeholder-public">
			<div className="login">
				<div className="login-content">
					<Row>
						<Col xs={20} offset={2} className="login-container">
							<div className="login-box">
								{ isLoading && <Spin /> }
								<Form onSubmit={handleSubmit} className="login-form">
									<Row>
										<Col xs={24}>
											{isRestore ?
												<h2>Olvidé mi contraseña</h2>
												:
												(isCreate ? <h2>Registro</h2>
													: isValidating ? <h2>Verifique su correo</h2> :
														(
															<h2 className="title">
																<span>Iniciar sesión <br /> Portal de Usuarios</span>
															</h2>
														)
												)
											}
										</Col>
									</Row>
									{!isValidating &&
										<Row>
											<Col xs={24}>
												{
													renderFormItem({
														label: "Ingrese su Rut (sin puntos ni guiones)",
														styleClass: "crud-label",
														initialValue: rut,
														name: 'rutForm',
														rules: [
															{ required: true, message: "Ingrese Rut." },
															{
																validator: rut !== null && countryCode === 'CHI' ? rutValidate : null
															},
														],
														item: (
															<Input
																autocomplete="nope"
																allowClear
																onChange={(e) => handleUsernameOnChange(e.target.value)}
																prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder={t('messages.aml.rutNumber')}
															/>
														)
													})
												}
											</Col>
										</Row>
									}

									{isCreate &&
										<Row>
											{/*
											<Col xs={24}>
												{
													renderFormItem({
														label: "Nombre",
														styleClass: "crud-label",
														name: 'nombre',
														rules: [{ required: true, message: t("messages.aml.dontForgetNombre") }],
														item: (
															<Input
																autocomplete="off"
																prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder={t("messages.aml.name")}
															/>
														)
													})
												}
											</Col>
											*/}
											<Col xs={24}>
												{
													renderFormItem({
														label: "Correo electrónico",
														styleClass: "crud-label",
														name: 'email',
														rules: [
															{
																required: true,
																message: "E-mail requerido."
															},
															{
																type: 'email',
																message: "El correo ingresado no es correcto."
															}
														],
														item: (
															<Input
																autocomplete="off"
																prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder={t("messages.aml.email")}
															/>
														)
													})
												}
											</Col>
										</Row>
									}

									{isValidating &&
										<Row>
											<Col xs={24}>
												{
													renderFormItem({
														label: "Código validación (enviado al correo ingresado)",
														hasFeedback: true,
														styleClass: "crud-label",
														name: 'verificationCode',
														rules: [
															{
																required: true,
																message: "Debe ingresar código."
															},
															{
																len: 8,
																message: "Código debe tener 8 caractéres."
															}
														],
														item: (
															<Input
																autocomplete="off"
																prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder="Ingrese código"
															/>
														)
													})
												}
											</Col>
											<Col xs={24}>
												{
													renderFormItem({
														label: "Crear contraseña",
														hasFeedback: true,
														styleClass: "crud-label",
														name: 'newPw',
														rules: [
															{
																required: true,
																message: "Debe ingresar contraseña."
															},
															{
																validator: validateToNextPassword
															},
														],
														item: (
															<Input.Password
																// onChange={(e) => handleValidationCode(e.target.value)}
																autocomplete="off"
																prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder="Ingrese contraseña"
															/>
														)
													})
												}
											</Col>
											<Col xs={24}>
												{
													renderFormItem({
														label: "Confirmar contraseña",
														hasFeedback: true,
														styleClass: "crud-label",
														name: 'confirmPw',
														rules: [
															{
																required: true,
																message: "Debe confirmar contraseña."
															},
															{
																validator: compareToFirstPassword
															}
														],
														item: (
															<Input.Password
																onBlur={handleConfirmBlur}								// onChange={(e) => handleValidationCode(e.target.value)}
																autocomplete="off"
																prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder="Confirme su contraseña"
															/>
														)
													})
												}
											</Col>
										</Row>
									}

									{!isRestore && !isCreate && !isValidating &&
										<Row>
											<Col xs={24}>
												{
													renderFormItem({
														label: "Contraseña",
														styleClass: "crud-label",
														name: 'password',
														rules: [{ required: true, message: t('messages.aml.dontForgetPassword') }],
														item: (
															<Input.Password
																// onChange={(e) => handlePasswordOnChange(e.target.value)}
																autocomplete="off"
																prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.2)' }} />}
																placeholder={t('messages.aml.password')}
															/>
														)
													})
												}
											</Col>
										</Row>
									}
									{(true || captchaSucess || (!isRestore && !isCreate)) &&
										<Row>
											<Col xs={24}>
												<Button
													className="login-form-button-portal"
													type="primary"
													htmlType="submit"
													disabled={rut === null || rut === undefined || rut === '' || isLoading}
													icon={isLoading && "loading"}
												>
													{isRestore ? "Enviar" : isCreate ? "Siguiente" : isValidating ? "Registrarse" : "Iniciar Sesión"}
												</Button>
											</Col>
										</Row>
									}
									{isRestore ?
										<Row className="login-description">
											<Col xs={24}>
												Una nueva contraseña será enviada al correo electrónico previamente registrado en la plataforma.
											</Col>
										</Row>
										: isCreate ?
											<Row className="login-description">
												<Col xs={24}>
													Se enviará un código de validación al correo que registró en el paso anterior.
												</Col>
											</Row>
											: isValidating ?
											<Row className="login-description">
												<Col xs={24}>
													Se enviará un código de validación al correo que registre aquí.
													<br/>
													El código expirará en <Countdown startingMinutes={15} startingSeconds={0}/>
												</Col>
											</Row>
											:
											<Row>
											</Row>
									}
									{(!isRestore && !isCreate) &&
										<>
											<Row className="forgot-container">
												<Button type="link" onClick={handleSwitchToRestore}> Olvidé mi contraseña </Button>
											</Row>

											<Row className="forgot-container">
												¿Primera vez que usa al Portal de Usuarios?
											</Row>
											<Row>
												<Button type="link" onClick={handleSwitchToCreate}> Registrese aquí </Button>
											</Row>
										</>
									}
									{(isRestore || isCreate) &&
										<Button type="link" className="login-link-portal" onClick={handleSwitchToLogin} style={{ display: 'block', textAlign: 'center', paddingTop: 15 }}>{t('messages.aml.backToLogin')}</Button>
									}
								</Form>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

const StakeForm = Form.create({ name: 'login_form' })(PublicPortal)
export default StakeForm
