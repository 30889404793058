import './dddd.scss'
import React from 'react'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Icon, Menu } from 'antd'
import { changePasswordPromise } from '../../../../promises'
import { ModalChangePassword } from '../'
import { ReportService } from '../../../../services'

class User extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalChangePasswordVisible: false
    }
  }

  handleOpenModalChangePassword() {
    this.setState({ isModalChangePasswordVisible: true })
  }

  handleCloseModalChangePassword() {
    this.setState({ isModalChangePasswordVisible: false })
  }
  
  async handleOpenApiRestModule (){
    let blobHtml = await ReportService.getRedocHtmlPage('/api-docs')
    const url = URL.createObjectURL( blobHtml.data );
    window.open(url)
  }

  async handleSaveModalChangePassword(passwordCurrent, passwordNew, passwordNewConfirm) {
    await changePasswordPromise(passwordCurrent, passwordNew, passwordNewConfirm)

    this.setState({ isModalChangePasswordVisible: false })
  }

  async handleLogout(e) {
    e.preventDefault()

    const { history, logoutHandler } = this.props

    await logoutHandler()

    history.push('/')
  }

  render() {
    const { t, currentUser, userParams } = this.props

    const dropdownMenu = (
      <Menu >
        { ( currentUser.type === 'SADMIN' || currentUser.type === 'ADMIN' || currentUser.type === 'AUDIT' ) &&
            <Menu.Item key="1">
              <Link to={ '/administracion' }>
                <Icon type="setting" /> &nbsp;{ t('messages.aml.administrationPageTitle') }
              </Link>
            </Menu.Item>
        }
        { currentUser.modules && ((currentUser.modules.includes('REGISTRO') && currentUser.cliente.modules.includes('REGISTRO')) ||
        (currentUser.modules.includes('MONITOR') && currentUser.cliente.modules.includes('MONITOR')) ||
        (currentUser.cliente.modules.includes('NEG') && (currentUser.type === 'SADMIN' || currentUser.modules.includes('LOADNEG')))) &&
          <Menu.Item key="123">
            <Link to={ '/historial-de-reportes' }>
              <Icon type="database" />&nbsp; {t('messages.aml.reportHistory')}
            </Link>
          </Menu.Item>
        }
        { (currentUser.type === 'SADMIN' || currentUser.type === 'ADMIN') &&
          <Menu.Item key="5">
            <Link to={ '#' } onClick={ this.handleOpenApiRestModule.bind(this) }>
              <Icon type="api" /> &nbsp; Documentación API Rest
            </Link>
          </Menu.Item>
        }
        <Menu.Item key="3">
          <Link to={ '#' } onClick={ this.handleOpenModalChangePassword.bind(this) }>
            <Icon type="lock" /> &nbsp;{ t('messages.aml.changePwd') }
          </Link>
        </Menu.Item>
        { userParams?.system?.mfa === 'opcional' && currentUser.cliente.modules?.includes('MFAOPT') && !currentUser.cliente.modules?.includes('MFAREQ') &&
          <Menu.Item key="6">
            <Link to="/mfa" >
              <Icon type="key" /> &nbsp;MFA
            </Link>
          </Menu.Item>
        }
        <Menu.Item key="4">
          <Link to={ '#' } onClick={ this.handleLogout.bind(this) }>
            <Icon type="logout" /> &nbsp;{ t('messages.aml.logout') }
          </Link>
        </Menu.Item>
      </Menu>
    )

    return (
      <div className="current-user">
        <Dropdown overlayClassName="menu-user" overlay={ dropdownMenu }>
          <Button>
            <Icon type="user" /> { currentUser.name } <Icon type="caret-down" />
          </Button>
        </Dropdown>
        { this.state.isModalChangePasswordVisible &&
          <ModalChangePassword
            visible={ this.state.isModalChangePasswordVisible }
            onOk={ this.handleSaveModalChangePassword.bind(this) }
            onCancel={ this.handleCloseModalChangePassword.bind(this) }
            />
        }
      </div>
    )
  }
}

export default withRouter(withTranslation()(User))
