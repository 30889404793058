import './AdvancedSearchFilters.scss'
import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Radio, Row, Select } from 'antd'
import {getCountriesPromise} from '../../../AdminParams/promises'

const AdvancedSearchFilters = ({ defaultValues, onChangeTypeHandler, onChangeSearchHandler, onChangeRegionHandler, currentUser }) => {
  const { t } = useTranslation()
  const [countries, setCountries] = useState([]);

useEffect(() => {
  getCountriesPromise().then((response) => {
    setCountries(response) 
  })
}, []);

  return (
    <div className='query-filters'>
      <div className="filters-inner">
        <Row>
          <Col xs={6}>
            <label className="default" for="query-type">{ t('messages.aml.registerTypes') } : </label>
            <Radio.Group id="query-type" defaultValue={ defaultValues.queryFiltersPersonType } value={ defaultValues.queryFiltersPersonType } buttonStyle="solid" onChange={ onChangeTypeHandler } size='small'>
              <Radio.Button value="Person">{ t('messages.aml.person') }</Radio.Button>
              <Radio.Button value="Entity">{ t('messages.aml.entity') }</Radio.Button>
              <Radio.Button value="">{ t('messages.aml.both') }</Radio.Button>
            </Radio.Group>
          </Col>
          <Col xs={6}>
            <label className="default" for="query-scope">{ t('messages.aml.queryScope') } : </label>
            <Radio.Group id="query-scope" defaultValue={ defaultValues.queryFiltersSearchScope } value={ defaultValues.queryFiltersSearchScope } buttonStyle="solid" onChange={ onChangeSearchHandler } size='small'>
              <Radio.Button value="fuzzy">{ t('messages.aml.wide') }</Radio.Button>
              <Radio.Button value="near">{ t('messages.aml.close') }</Radio.Button>
              <Radio.Button value="exact">{ t('messages.aml.exact') }</Radio.Button>
            </Radio.Group>
          </Col>
          { true &&
            <Col xs={6}>
              <label className="default" for="query-type">Región : </label>
              <Select
                size="small"
                placeholder="Seleccionar un País"
                showSearch
                style={{width: '100%'}}
                onChange={ onChangeRegionHandler }
                allowClear
              >
                { currentUser.cliente.pais === "CHI" &&
                <Select.Option
                value= "Chile">
                Chile
                </Select.Option> }
                { currentUser.cliente.pais === "PER" &&
                <Select.Option
                value= "Peru">
                Peru
                </Select.Option> }
                { countries.map(country => <Select.Option value={ country.name } >{ country.name }</Select.Option>) }
              </Select>
            </Col>
          }
        </Row>
      </div>
    </div>
  )
}

export default AdvancedSearchFilters;