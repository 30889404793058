import {FileManagerService} from "../services";

export default (fileList) => {
    return new Promise((resolve) => {
        FileManagerService.deleteClientFileList(fileList)
            .then(response => {
                resolve(response.data)
            })
            .catch(err => console.log(err))
    })

}