import './Content.scss'
import React from 'react'

const Content = ({ children }) => (
  <div className="content">
    { children }
  </div>
)

export default Content;
