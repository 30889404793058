import getCategories from './getCategories'
import Form from './Form'
import getClientsMin from './getClientsMin'
import onBoarding from './onBoarding'

export const getClientsMinService = getClientsMin
export const getCategoriesService = getCategories
export const FormService = Form
export const onBoardingService = onBoarding

