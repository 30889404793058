import { Col, Icon, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './TabBenFinales.scss'
import {getFormC57Promise} from '../../promises'
import { camelizerHelper } from '../../../../helpers'
import moment from 'moment'
import apiConfig from '../../../../config/api'

const TabBenFinales = ({currentUser}) => {

    const [formC57Data, setFormC57Data] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        handleRefresh()
    }, []);

    const handleRefresh = () => {
        setIsLoading(true)
        getFormC57Promise(currentUser.id).then(response => {
            setFormC57Data(response.data)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const completeForm = (item) => {
        window.open(apiConfig.urlAml + '/ubo/formC57FI?formId=' + item.id + '&formInterno=' + false)
    }

    const c57Columns = [
        {  
            key: '1',
            title: 'Estado',
            dataIndex: 'status',
            align: 'center',
            render: (text) => {
                return <span>{camelizerHelper(text)}</span>
            }
        },
        {  
            key: '2',
            title: 'Fecha de envío',
            dataIndex: 'fecEnvio',
            align: 'center',
            render: (text) => {
                return <span>{moment(text).format('DD/MM/YYYY hh:mm')}</span>
            }
        },
        {  
            key: '3',
            title: 'Fecha de recepción',
            dataIndex: 'fecRecibido',
            align: 'center',
            render: (text) => {
                return text && <span>{moment(text).format('DD/MM/YYYY hh:mm')}</span>
            }
        },
        {  
            key: '4',
            title: 'Fecha plazo',
            dataIndex: 'fecPlazo',
            align: 'center',
            render: (text) => {
                return <span>{moment(text).format('DD/MM/YYYY')}</span>
            }
        },
        {  
            key: '5',
            title: 'Días alerta / Días plazo',
            align: 'center',
            render: (item) => {
                return <span>{item.clCliente.alerta+' / '+item.clCliente.plazo}</span>
            }
        },
        {  
            key: '6',
            title: '',
            align: 'center',
            render: (item) => {
                return item.status === 'ENVIADO' &&
                    <Icon 
                        type='edit' 
                        style={{fontSize:20}}
                        onClick={() => {
                            completeForm(item)
                        }}
                    />
            }
        }
    ]

  return (
    <div className='tab-ben-finales-stakeholder'>
        <div className="section">
            <Row>
                <Col span={23}>
                    <h3>Historial</h3>
                </Col>
                <Col span={1} style={{textAlign:'right'}}>
                    <Icon 
                        type='reload' 
                        style={{fontSize: '20px'}}
                        onClick={() => handleRefresh()}
                    />
                </Col>
                <Col span={24}>
                    <Table dataSource={formC57Data} columns={c57Columns} loading={isLoading}/>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default TabBenFinales