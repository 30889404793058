import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const Searches = {
  read: (fromNum = undefined, resultsPerPage = undefined, filters = undefined, userId) => {
    let requestBody = {
      url: apiConfig.url + '/getBusquedas',
      method: 'post',
      body: {
        from: fromNum,
        size: resultsPerPage,
        userId
      }
    }

    if (filters !== null) {
      for (let key in filters) {
        requestBody.body[key] = filters[key]
      }
    }

    return apiRequestorHelper(requestBody)
  }
}

export default Searches;