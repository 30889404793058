import { stakeholderService } from '../services'

export default (clientId, rut, response) => {
	return new Promise(resolve => {
		stakeholderService.forgotUser(clientId, rut, response)
			.then(response => {
				if(response) resolve({ success: true, data: response.data })
			})
			.catch(error => {
				if(error && error.response) resolve({ success: false, data: error.response.data })
			})
	})
}
