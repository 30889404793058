import { Button, Col, Icon, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import './InvestigacionCdi.scss'
import {getCdiDataPromise, getVinculosPromise, getMatchesPromise} from './promises'
import moment from 'moment';
import { t } from 'i18next';
import { camelizerHelper } from '../../../../../../helpers';

const InvestigacionCdi = ({itemTable}) => {
    const [seeMore, setSeeMore] = useState(false);
    const [seeMore1, setSeeMore1] = useState(false);
    const [recordsFilteredByMatch, setRecordsFilteredByMatch] = useState([]);
    const [relatedByMatch, setRelatedByMatch] = useState([]);

    useEffect(() => {
        getCdiDataPromise(itemTable.id).then(response => {
            let tmpRecords = response?.records?.filter(element => element.type ==='M')
            setRecordsFilteredByMatch(tmpRecords)

            let tmpRelated = response?.related
            setRelatedByMatch(tmpRelated)
        })
    }, []);

    const investigationRequestsColumns = [
        {
            title: "Folio",
            dataIndex: 'folio'
        },
        {
            title: "Solicitud",
            dataIndex: "requestDate",
            render: text => moment(text).format('DD/MM/YYYY')
        },
        {
            title: "Estado",
            dataIndex: "status",
            render: text => t("messages.aml.cdi.status."+text)
        },
        {
            title: "Fecha Estado",
            dataIndex: 'creationDate',
            render: text => moment(text).format('DD/MM/YYYY')
        },
        {
            title: "Vínculos",
            align: 'center',
            dataIndex: 'hasPositives',
            render: text => text ? 'Si' : 'No'
        }
    ]

    const relatedColumns = [
        {
            title: "Tipo de Persona",
            dataIndex: 'type',
            render: (text) => t("messages.aml."+text.toLowerCase())
        },
        {
            title: "Categoría",
            dataIndex: "category",
            render: text => camelizerHelper(text)
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            render: text => camelizerHelper(text)
        },
        {
            title: "Rut",
            dataIndex: 'rut'
        },
        {
            title: "Relación",
            dataIndex: 'related',
        }
    ]

    
  return (
        <div className='investigacion-cdi-main-wrapper'>
            <Row>
                <Col span={22}>
                    <h3>Solicitudes de identificación de vínculos</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                    <Button type="link" onClick={() => {setSeeMore(!seeMore)}}>
                        {seeMore ?
                        <>Ver menos <Icon type="minus"/></>
                        :
                        <>Ver más <Icon type="plus"/></>
                        }
                    </Button>
                </Col>
            </Row>
            <hr/>
            {seeMore &&
                <div className="modal-risk-main-wrapper">
                    <Row className="field-section-row">
                        <Col span={3}>
                            <div className="info-yellow-card-cdi">
                            Solicitudes <br/>
                            {recordsFilteredByMatch?.length}
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="info-yellow-card-cdi">
                            Pendientes <br/>
                                {recordsFilteredByMatch?.filter(item => item.status === 'PENDING').length}
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="info-yellow-card-cdi">
                            Realizadas <br/>
                                {recordsFilteredByMatch?.filter(item => item.status === 'FINISHED').length}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[0,20]}>
                        <Col span={24}>
                            <Table dataSource={recordsFilteredByMatch} columns={investigationRequestsColumns}/>
                        </Col>
                    </Row>
                    <>
                    
                    </>
                </div>
            }
          
                {/* <>
                    <Row>
                        <Col span={22}>
                            <h3>Identificación entre partes</h3>
                        </Col>
                        <Col span={2} style={{textAlign:'right'}}>
                            <Button type="link" onClick={() => {setSeeMore1(!seeMore1)}}>
                                {seeMore1 ?
                                <>Ver menos <Icon type="minus"/></>
                                :
                                <>Ver más <Icon type="plus"/></>
                                }
                            </Button>
                        </Col>
                    </Row>
                    <hr/>
                    {seeMore1 &&
                        <div className="modal-risk-main-wrapper">
                            <Row gutter={[0,20]}>
                                <Col span={15}>
                                    <Table dataSource={relatedByMatch} columns={relatedColumns}/>
                                </Col>
                            </Row>
                        </div>
                    }
                </> */}
            
        </div>
  )
}

export default InvestigacionCdi;