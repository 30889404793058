import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

export default {

  getClientDomain: (domain) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getClienteByDomain/'+domain,
			method: 'post'
		})
	},
	getOauthConfig: (id) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getAuthConfigByClient/'+id,
			method: 'post'
		})
  },
  getUser: (clientId, rut, password) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getUser',
			method: 'post',
			body: { clientId, rut, password }
		})
	},

	loginPortal: (countryCode, rut, password) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/loginPortal',
			method: 'post',
			body: {countryCode, rut, password}
		})
	},
  getUserById: (clientId, id) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getUserById',
			method: 'post',
			body: {clientId, id }
		})
	},
  createUser: (clientId, rut, response) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/createUser',
			method: 'post',
			body: {clientId, rut, response}
		})
	},
	registerUser: (countryCode, rut, verificationCode, newPassword) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/validarCodigo',
			method: 'post',
			body: {countryCode, rut, verificationCode, newPassword}
		})
	},
	createPortalUser: (countryCode, rut, email, nombre) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/registro',
			method: 'post',
			body: {pais: countryCode, rut, email, nombre}
		})
	},
  forgotUser: (clientId, rut, response) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/forgotUser',
			method: 'post',
			body: {clientId, rut, response }
		})
	},
	forgotPassword: (countryCode, rut) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/forgotPassword',
			method: 'post',
			body: { countryCode, rut }
		})
	},
  changePwd: ( userId, password, newpwd ) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/changePwd',
			method: 'post',
			body: { userId, password, newpwd}
		})
	},
	changePwdUserPortal: ( dni, password, newPassword, countryCode ) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/changePasswordUserPortal',
			method: 'post',
			body: { rut: dni,
				 password, 
				 newPassword,
				 pais: countryCode
				}
		})
	},
  deleteDeclarations: (userId, items) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/deletedeclarations/' + userId,
			method: 'post',
			body: { items }
		})
	},
	getFormC57: ( clientId ) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/c57FormHistory/'+clientId,
			method: 'get',
		})
	},
}
