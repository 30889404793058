import React from 'react'
import './InsideSectionTitle.scss'

export const InsideSectionTitle = ({title}) => {
  return (
    <div className='inside-section-title-bar'>
      <span>{title}</span>
    </div>
  )
}
