import React, {useState} from 'react';
import {Row, Col, Descriptions, Switch, Modal} from 'antd'
import { useTranslation } from 'react-i18next';

const Acciones = ({currentUser, handleOnChange, item}) => {

    const { t } = useTranslation();
    const [itemTable, setItemTable] = useState(item);

    const handleOnChangeLocal = (attr, value) => {
        let actions = itemTable.actions
        if(actions === undefined) {
            actions = {}
        }
        actions[attr] = value
        itemTable.actions = actions
        setItemTable({...itemTable})
        handleOnChange('actions', actions)
    }

    return (
        <div>
            <>
                <Row className="field-section-row">
                    <Col span={24}>
                        <h3>
                            Acciones al guardar Registro
                        </h3>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={[24, 24]} style={{padding: 20}}>
                    <Col span={14}>
                        <Descriptions bordered colon={false} column={1} size="small">
                            <Descriptions.Item label="Acción">
                                Editar
                            </Descriptions.Item>
                            {currentUser.cliente.modules.includes("CDI-FORM") &&
                                ((currentUser.cliente.modules.includes("CDI-FORM-CDI") &&
                                    currentUser.modules.includes("CDI-FORM-GD-P") &&
                                    itemTable.category === "PROVEEDOR") ||
                                    ((currentUser.cliente.modules.includes("CDI-FORM-CDI") ||
                                    currentUser.cliente.modules.includes("CDI-FORM-REL")) &&
                                    (currentUser.modules.includes("CDI-FORM-GD-T") ||
                                        currentUser.modules.includes("CDI-FORM-GD")) &&
                                        itemTable.category === "COLABORADOR") ||
                                    (currentUser.cliente.modules.includes("CDI-FORM-DIR") &&
                                    currentUser.modules.includes("CDI-FORM-GDIR") &&
                                    itemTable.category === "DIRECTOR") ||
                                    (currentUser.cliente.modules.includes("CDI-FORM-PATR") &&
                                    currentUser.modules.includes("CDI-FORM-GPATR") &&
                                    itemTable.category === "CLIENTE") ||
                                    (currentUser.cliente.modules.includes("FORM-PEP") &&
                                    currentUser.modules.includes("FORM-PEP") &&
                                    itemTable.category === "CLIENTE")
                                ) &&
                                <>
                                { currentUser.modules.includes('CDI-FORM-GD') && currentUser.cliente.modules.includes('CDI-FORM-CDI') && (itemTable.category === 'COLABORADOR' || itemTable.category === 'PROVEEDOR') &&
                                    <Descriptions.Item label="Enviar Declaración de Conflicto de Interés">
                                        <Switch 
                                        checked={ itemTable.actions?.sendCDIForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendCDIForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GREL') && currentUser.cliente.modules.includes('CDI-FORM-REL') && (itemTable.category === 'COLABORADOR') &&
                                    <Descriptions.Item label="Enviar Declaración de Relacionados">
                                        <Switch 
                                        checked={ itemTable.actions?.sendRELForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendRELForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GDIR') && currentUser.cliente.modules.includes('CDI-FORM-DIR') && (itemTable.category === 'DIRECTOR') &&
                                    <Descriptions.Item label="Enviar Declaración de Directores">
                                        <Switch 
                                        checked={ itemTable.actions?.sendDIRForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendDIRForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GPATR') && currentUser.cliente.modules.includes('CDI-FORM-PATR') && (itemTable.category === 'CLIENTE') &&
                                    <Descriptions.Item label="Enviar Declaración Relaciones Patrimoniales">
                                        <Switch 
                                        checked={ itemTable.actions?.sendPATRForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendPATRForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GF-G') && currentUser.cliente.modules.includes('CDI-FORM-G') && (itemTable.category === 'COLABORADOR') &&
                                    <Descriptions.Item label="Enviar Formulario de Regalos">
                                        <Switch 
                                        checked={ itemTable.actions?.sendGIFTForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendGIFTForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GF-T') && currentUser.cliente.modules.includes('CDI-FORM-T') && (itemTable.category === 'COLABORADOR') &&
                                    <Descriptions.Item label="Enviar Formulario de Viajes">
                                        <Switch 
                                        checked={ itemTable.actions?.sendTRAVForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendTRAVForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GF-F') && currentUser.cliente.modules.includes('CDI-FORM-F') && (itemTable.category === 'COLABORADOR') &&
                                    <Descriptions.Item label="Enviar Formulario de Reuniones con Funcionarios Públicos">
                                        <Switch 
                                        checked={ itemTable.actions?.sendFPForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendFPForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('CDI-FORM-GF-S') && currentUser.cliente.modules.includes('CDI-FORM-S') && (itemTable.category === 'COLABORADOR') &&
                                    <Descriptions.Item label="Enviar Formulario de Participación en Asociaciones Empresariales">
                                        <Switch 
                                        checked={ itemTable.actions?.sendSOCForm } 
                                        onChange={ (checked) => handleOnChangeLocal('sendSOCForm', checked) } />
                                    </Descriptions.Item>
                                }
                                { currentUser.modules.includes('FORM-PEP') && currentUser.cliente.modules.includes('FORM-PEP') &&
                                    <Descriptions.Item label="Enviar Formulario PEP UAF">
                                        <Switch 
                                            checked={itemTable.actions?.sendPEPForm}
                                            onChange={(checked) => handleOnChangeLocal('sendPEPForm', checked)} />
                                    </Descriptions.Item>
                                }
                                </>
                            }
                            {currentUser.cliente.modules.includes("ONBOARDING") &&
                                <Descriptions.Item label="Enviar Formulario de Onboarding">
                                    <Switch 
                                    checked={ itemTable.actions?.sendONBForm } 
                                    onChange={ (checked) => handleOnChangeLocal('sendONBForm', checked) } />
                                </Descriptions.Item>
                            }
                        </Descriptions>
                    </Col>
                    <Col span={10}></Col>
                </Row>
            </>

        </div>
    );
};

export default Acciones;