import {getReportHistoryDataService} from '../services';

export default () => {
    return new Promise((resolve, reject) => {
        getReportHistoryDataService.getReportsHistory().then(async response => {
        resolve(response.data)
       }).catch(res => {
        console.log(res);
       });
    });
}