import "./ModalFiles.scss";
import React, { useEffect } from "react";
import {
  Col,
  Modal,
  Row,
} from "antd";

import { ReportService } from '../../../../../../services'

const ModalFiles = ({
  currentUser,
  fakeModalOutput,
  modalHandler,
  item,
  puntaje,
  onCancel,
  onOk,
  type,
  comment,
  files,
  alertId,
  origin,
  userId
}) => {

  const handleOnFile = async (path, fileName) =>{
    await ReportService.read('/portal/getFile', {alertId,path,origin,userId}, null, fileName)
  }

  useEffect(() => {
    console.log(fakeModalOutput);
    // handleGetMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFile = (file) => {
    let parts = file.split("/")
    let fileName = parts[parts.length-1]
    return (<a onClick = {e => handleOnFile(file, fileName)}>{fileName}</a>)
  }

  return (
    <Modal
      // className="modal-files"
      footer={null}
      title="Archivos"
      onCancel={onCancel}
      onOk={onOk}
      visible="true"
    >
        <div className="box">
          <div className="box-inner">
            <div>
              <Row gutter={[0, 30]}>
                <Col span={24}>
                  Se adjuntaron los siguientes documentos:
                </Col>
                <Col>
                  <ul className="value">
                    {
                      files.map(file=>
                        <li>
                          { getFile(file)}
                        </li>
                      )
                    }
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
    </Modal>
  );
};

export default ModalFiles;
