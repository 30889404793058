import './Dashboard.scss'
import {Col, Radio, Row, DatePicker, Spin} from 'antd'
import React, {useState, useEffect} from 'react'
import {
    AlertAreaChartComponent,
    AlertStackedChartComponent,
    InsideSectionTitleComponent,
    RelationsCdiComponent,
    AlertManagementComponent,
    PendingStatementsComponent,
    MonitoringSourceSumaryComponent,
    MonitoringRulesSumaryComponent,
    CdiDeclarationChartComponent,
    PeopleMonitoredQuantityComponent
} from './components'
import moment from 'moment'
import {useTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';

export const Dashboard = () => {
    const [date, setDate] = useState([]);
    const {RangePicker} = DatePicker;
    const dateFormat = 'DD/MM/YYYY'
    const [dateValue, setDateValue] = useState(undefined);
    const [dateOption, setDateOption] = useState('6month')

    const history = useHistory();
    const {t} = useTranslation()

    useEffect(() => {
        handleOnChangeDateRadio(dateOption)
    }, [])

    const radioDateTransform = {
        '1day': moment().subtract(1, 'days'),
        '1week': moment().subtract(1, 'week'),
        '1month': moment().subtract(1, 'month'),
        '3month': moment().subtract(3, 'month'),
        '6month': moment().subtract(6, 'month'),
        '1year': moment().subtract(1, 'year')
    };


    const handleOnChangeDateRadio = (radioDate) => {
        setDateOption(radioDate)
        const dateNumber = radioDateTransform[radioDate]
        handleOnChangeRangePicker([dateNumber, moment()])
    }

    const handleOnChangeRangePicker = (datePickerRange) => {
        setDate(datePickerRange)
        if (datePickerRange === null || datePickerRange.length === 0) {
            setDateValue(null)
            setDateOption(null)
        } else {
            setDateValue([moment(datePickerRange[0]).valueOf(), moment(datePickerRange[1]).valueOf()])
        }
    }

    const insideRiskColor = [
        {name: 'BLACK', color: '#000000FF', risk: 4},
        {name: 'RED', color: '#ee220c', risk: 3},
        {name: 'ORANGE', color: '#ff9300', risk: 2},
        {name: 'YELLOW', color: '#fae232', risk: 1}
    ];


    const insideChartColor = [
        { name: 'BLUE', color: 'rgb(0,162,255)' },
        { name: 'GREEN', color: 'rgb(97,216,54)' },
        { name: 'ORANGE', color: 'rgb(255,147,0)' },
        { name: 'RED', color: 'rgb(181,23,0)' },
    ];

    const handleTabRedirection = (filters, module) => {
        window.scrollTo(0, 0)

        if(module === 'records') {
            const params = btoa(JSON.stringify(filters))
            history.push("/registro/" + params)

        }else if(module === 'monitoring') {
            const params = btoa(JSON.stringify(filters))
            history.push("/monitoreo/" + params)

        }else if(module === 'daclaration') {
            const params = btoa(JSON.stringify(filters));
            history.push("/declaraciones/" + params)
        }
    }

    return (
        <div className='inside-dashboard'>
            <div className='inside-topbar-content'>
                <Row type='flex' align='middle' justify='center'>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Radio.Group
                            onChange={(data) => handleOnChangeDateRadio(data.target.value)}
                            value={dateOption}
                        >
                            <Radio.Button value="1day">1 Día</Radio.Button>
                            <Radio.Button value="1week">1 Semana</Radio.Button>
                            <Radio.Button value="1month">1 Mes</Radio.Button>
                            <Radio.Button value="3month">3 meses</Radio.Button>
                            <Radio.Button value="6month">6 meses</Radio.Button>
                            <Radio.Button value="1year">1 año</Radio.Button>
                        </Radio.Group>

                        <RangePicker
                            onChange={(datePickerRange) => handleOnChangeRangePicker(datePickerRange)}
                            value={date}
                            format={dateFormat}
                            style={{marginLeft: 20}}
                        />
                    </Col>
                </Row>
            </div>

            {dateValue === undefined ?
            <Spin></Spin>
             :
            <Row gutter={[60, 30]}>
                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title= {t('messages.aml.inside.title.activity')}/>
                    <AlertAreaChartComponent dateValue={dateValue} chartColor={insideChartColor}  handleTabRedirection={handleTabRedirection}/>
                </Col>
                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.alert')}/>
                    <AlertStackedChartComponent dateValue={dateValue} riskColor={insideRiskColor}  handleTabRedirection={handleTabRedirection}/>
                </Col>

                <Col lg={12} xxl={6}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.resume.source')}/>
                    <MonitoringSourceSumaryComponent dateValue={dateValue} riskColor={insideRiskColor}  handleTabRedirection={handleTabRedirection}/>
                </Col>
                <Col lg={12} xxl={6}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.Resume.rules')}/>
                    <MonitoringRulesSumaryComponent riskColor={insideChartColor} handleTabRedirection={handleTabRedirection}/>
                </Col>
                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.manager')}/>
                    <AlertManagementComponent dateValue={dateValue} riskColor={insideRiskColor} handleTabRedirection={handleTabRedirection}/>
                </Col>

                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.declaration')}/>
                    <CdiDeclarationChartComponent dateValue={dateValue} chartColor={insideChartColor}/>
                </Col>
                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.quantity')}/>
                    <PeopleMonitoredQuantityComponent chartColor={insideChartColor} />
                </Col>

                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.pending.declaration')} />
                    <PendingStatementsComponent dateValue={dateValue} handleTabRedirection={handleTabRedirection}/>
                </Col>
                <Col lg={24} xxl={12}>
                    <InsideSectionTitleComponent title={t('messages.aml.inside.title.relation')}/>
                    <RelationsCdiComponent chartColor={insideChartColor} handleTabRedirection={handleTabRedirection}/>
                </Col>
            </Row>
            }
        </div>
    )
}
