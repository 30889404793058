import getParams from './getParams'
import getCountries from './getCountries'
import getNewsLetter from './getNewsLetter'
import getNewsGesintel from './getNewsGesintel'
import getOptions from './getOptions'
import importSelected from './importSelected'
import getC57FormHistory from './getC57FormHistory'

export const getParamsPromise = getParams
export const getCountriesPromise = getCountries
export const getNewsLetterPromise = getNewsLetter
export const getNewsGesintelPromise = getNewsGesintel
export const getOptionsPromise = getOptions
export const importSelectedPromise = importSelected
export const getC57FormHistoryPromise = getC57FormHistory