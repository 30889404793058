import './Solicitudes.scss'
import React,{useState, useEffect} from 'react'
import {Table, Row, Col, Icon, message, DatePicker, Button, Pagination} from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {getDeclarationsPromise, getItemsPromise} from '../../promises'
import {ReportService} from '../../../../services/'
import {ModalNewRequestPage} from '../'


const Solicitudes = ({currentUser}) => {

	const [modalRequestIsVisible, setModalRequestIsVisible] = useState(false);
	const [items, setItems] = useState([])
	const [dateRange, setDateRange] = useState(null)
	const [clickedItem, setClickedItem] = useState(null);
	const [isReportLoading, setIsReportLoading] = useState(false);
	const [total, setTotal] = useState(null);
	const [isItemsLoading, setIsItemsLoading] = useState(false);
	const itemsPerPage = 10
	const [currentPage, setCurrentPage] = useState(1)
	const { t } = useTranslation()

	useEffect(() =>{
		handleSearch(1)
	},[dateRange])

	const handleSearch = (page) => {
		const fromNum = ((page - 1) * itemsPerPage);
		setIsItemsLoading(true)

		const fromDate = (dateRange && dateRange.length > 1) ? dateRange[0].format('DD/MM/YYYY') : null
		const toDate = (dateRange && dateRange.length > 1) ? dateRange[1].format('DD/MM/YYYY') : null
		
		getItemsPromise(fromNum, itemsPerPage, "", "", fromDate, toDate).then(results => {
			if(results.success) {
				setItems(results.data.records)
				setTotal(results.data.total)
			}else{
				message.error(results.message)
			}
			setIsItemsLoading(false)
		})
		setCurrentPage(page)
	}

	const handleDownloadReport = async (record) => {
		await ReportService.read('/cdi/exportMatchRequest/' + record.id, null, null, record.folio + ".xlsx")
	}

	const handlePaginationChange = async (selectedPage) => {
		handleSearch(selectedPage)
	}

	const getLevelDescription = (level) => {
		if(level === 'UBO') return 'UBOs'
		else if(level === 'PARENT') return 'Parientes'
		else if(level === 'PARENT-UBO') return 'Parientes UBOs'
	}

	const handleRangeChange = (value) => {
		setDateRange(value)
	}

	const tableColumns = [
		{
			title: 'Nombre',
			className: 'nombre',
			dataIndex: 'subject',
		},
		{
			title: ()=>(
				<>
					<div>
						Categoría 1
					</div>
					<div>
						Cantidad
					</div>
				</>
			)
			,
			className: 'categoria1',
			dataIndex: 'group1.category',
			render: (text,record) => {
				return (
					<>
						<div>{t('messages.aml.category.'+text)}</div>
						<small>{record.group1.nroRecipients}</small>
					</>
				)
			}
		},
		{
			title: ()=>(
				<>
					<div>
						Categoría 2
					</div>
					<div>
						Cantidad
					</div>
				</>
			),
			dataIndex: 'group2.category',
			className: 'categoria2',
			render: (text,record) => {
				if(record.group2 !== null) {
					return (
						<>
							<div>{t('messages.aml.category.'+text)}</div>
							<small>{record.group2.nroRecipients}</small>
						</>
					)
				}
			}
		},
		{
			title: 'Folio',
			className: 'folio',
			dataIndex: 'folio',
		},
		{
			title: ()=>(
				<>
					<div>
						Fecha de
					</div>
					<div>
						Solicitud
					</div>
				</>
			),
			dataIndex: 'creationDate',
			render: ((text) => {
				return text !== null ? moment(text).format('DD/MM/YYYY') : '-'
			})
		},
		{
			title: 'Niveles',
			width: '20%',
			className: 'creation-date',
			dataIndex: 'periodicity',
			render: ((text, record) => {
				if(record.levels !== null) {
					return (
						<>
							{ record.levels.map((level, index) =>
								<div className="level-match"><Icon type="check" size="small"/>{getLevelDescription(level)}</div>
							)}
						</>
					)
				}
			})
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: (text, record) => {
				const percent = parseInt(100 * record.completed / record.total)
				return (
					<>
					<div>{t('messages.aml.cdi.status.'+text)}</div>
					{ text === 'PROCESSING' &&
					<div className="bar">
						<div className="bar-inner" style={{ width: (record.total > 0 ? percent : 0) + '%' }}>
						</div>
						&nbsp;{ record.total > 0 ? percent + '%' : '-' }
					</div>
					}
					{ text === 'REJECTED' &&
						<small>{moment(record.dateStatus).format('DD/MM/YYYY')}</small>
					}
					</>
				)
			}
		},

		{
			title: 'Acciones',
			render: ((text, record, index) => {
				return (
					<>
						<div className="download-report" onClick={ () => handleDownloadReport(record) }><Icon type={isReportLoading && clickedItem.id === record.id ? 'loading' : 'cloud-download'} /> Reporte</div>
					</>
				)
			})
		}
	]

	const openModalRequest = () => {
			setModalRequestIsVisible(true);
	}

	const closeModalRequest = () => {
			setModalRequestIsVisible(false);
	}

    return (
		<div className="solicitudes-content">
			{!modalRequestIsVisible ?
				<>
					<Row>
						<Col span={3}>
							<div className="solicitudes-card">
								Solicitudes <br/>
								{total}
							</div>
						</Col>
						<Col span={6} offset={10}>
							<DatePicker.RangePicker
								placeholder={["Fec. Inicio", "Fec. Hasta"]}
								format = 'DD/MM/YYYY'
								style={{ width: "100%", marginTop: 8 }}
								onChange={handleRangeChange}
							/>
						</Col>
						<Col span={5}>
							<div className="action-button-form">
								<Button icon="plus" onClick={openModalRequest} style={{marginTop: 8}}>
									Nueva Solicitud
								</Button>
							</div>
						</Col>
					</Row>
					<div className="table-req-onb">
						<Row>
							<Table size="small" columns={tableColumns} dataSource={items} loading={isItemsLoading} pagination={false}/>
						</Row>
					</div>
					<div className="pagination-formtable">
						<Row>
							{total > 10  &&
								<Pagination
									onChange={ handlePaginationChange } pageSize={itemsPerPage} current={currentPage} total={total}
								/>
							}
						</Row>
					</div>
				</>
				:
				<ModalNewRequestPage currentUser={currentUser} closeModalRequest={closeModalRequest} />
			}
		</div>
    )
}

export default Solicitudes
