import { ParamsService } from '../services'

const getOptions = (option) => {
    return new Promise(async (resolve, reject) => {
        await ParamsService.getOptions(option)
            .then(response => resolve(response !== undefined ? response.data : []))
            .then(err => reject({ error: true }))
    })
}
export default getOptions;
