import FormProv from './FormProv/FormProv'
import FormTrab from './FormTrab/FormTrab';
import FormDir from './FormDir/FormDir';
import FormSoc from './FormSoc/FormSoc';
import FormTravel from './FormTravel/FormTravel';
import FormFP from './FormFP/FormFP';
import FormGift from './FormGift/FormGift';



export const FormProvPage = FormProv
export const FormTrabPage = FormTrab
export const FormDirPage = FormDir
export const FormSocPage = FormSoc
export const FormTravelPage = FormTravel
export const FormFPPage = FormFP
export const FormGiftPage = FormGift
