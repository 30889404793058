import React from 'react';
import {Card} from "antd";
import './ShortCardDashobard.scss'

const ShortCardDashboard = ({title, content}) => {

    return (
        <>
            <div className="short-card">
                <Card title={title}>
                    {content}
                </Card>
            </div>
        </>
    )
}

export default ShortCardDashboard;