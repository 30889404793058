import apiConfig from '../../../../../config/api'
import { apiRequestorHelper } from '../../../../../helpers'

export default (formData) => {
	return apiRequestorHelper({
		url: apiConfig.url + '/saveComments',
		method: 'post',
    body: formData
	})
}
