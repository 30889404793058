import React, {useEffect, useState} from 'react';
import {getMonitoringRulesPromise} from "./promises/getMonitoringRulesPromise";
import {Spin, Table} from "antd";
import './MonitoringRules.scss'
import {useTranslation} from "react-i18next";

export const MonitoringRules = ({riskColor, handleTabRedirection}) => {
    const {t} = useTranslation()


    const [loading, setLoading] = useState(false);
    const [sourcesSumaryRules, setSourcesSumaryRules] = useState([]);

    useEffect(() => {
        setLoading(true)
        getMonitoringRulesPromise().then((response) => {
            setSourcesSumaryRules(response.data)
        }).then(() => setLoading(false))
    }, []);

    const handleClick = () => {
        let filters = {
            activeTab: 'tab-rules',
        }
        handleTabRedirection(filters, 'monitoring')
    };


    const columns = [
        {
            title: <b>Tipo</b>,
            dataIndex: 'tipo',
            render: (text) => {
                return <span><a>{t('messages.aml.inside.alertas.' + text)}</a></span>
            },
            key: 'tipo',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (estado) => {
                const color = riskColor.find(item => item.name === estado)?.color || 'gray';
                return <div className="circle" style={{width: "20px", height: "20px",borderRadius: "50%",display: "inline-block", backgroundColor: color}}></div>
            }
        }
    ];

    return (
        <>
            <div className={"test"}>
                <Spin spinning={loading}>
                    {sourcesSumaryRules.length > 0 ? (
                        <Table
                            dataSource={
                                sourcesSumaryRules.map((item, index) => ({
                                    key: index.toString(),
                                    tipo: item.tipo,
                                    total: item.total,
                                    estado: item.estado,
                                }))
                            }
                            columns={columns}
                            pagination={false}
                            className="transparent-header-table"
                            size="small"
                            onRow={(record) => ({
                                onClick: () => handleClick(),
                            })}
                        />

                        ) : (
                        <div className="text-no-content-rule">No hay datos disponibles</div>
                        )}
                </Spin>
            </div>

            </>
            )

            };