import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export const getRelationCdiResumeService = () => {
    return apiRequestorHelper({
        url: apiConfig.url + '/transaction/inside/getRelationCdiResume',
        method: 'post',
        body: {}
    })
}
