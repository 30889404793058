import './InterestConflicts.scss'
import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import { Page, PageBottomBar, PageContent, PageHeader, PageTopBar } from '../../layouts/Private/components'
import { Content, Sidebar, Wrapper } from './layout'
import { DashboardPage, SolicitudesPage, FormManagerPage, ReportingPage } from './components'
import { getCategoriesPromise } from './promises'

class InterestConflicts extends Component {
	state = {
		activeTab: 'tab-dashboard',
		breadcrumbs: this.getBreadcrumbs(),
		categories: [],
		showTabForms: false,
		fromDashboard: false,
		defaultFilters: { },
		keySidebar: 1,
		filters: {},
	}

	async reloadCategories() {
		let c = await getCategoriesPromise()
		this.setState({ categories: c.data })
	}

	async componentDidMount() {
		const { match } = this.props;

		let { filters } = this.state;
		if (match.params && match.params.params) {
				const p = JSON.parse(atob(match.params.params));
				filters = {...filters, ...p};
			this.setState({
				defaultFilters: filters,
				activeTab:filters.tab
			});
		}

		await this.reloadCategories()
	}

	getBreadcrumbs() {
		const { t } = this.props

		const breadcrumbs = [
			{ title: 'Gestión de Declaraciones', icon: 'form', link: '/declaraciones' },
		]

		return breadcrumbs
	}

	handleTabChange(activeTab, filters) {
        if(this.state.activeTab !== activeTab && activeTab === 'tab-forms' && this.state.fromDashboard) {
            this.setState({
				fromDashboard: false,
				defaultFilters: { },
				keySidebar: Math.random()})
        }
        this.setState({activeTab, filters, randomKey: Math.random()})
    }

	handleTabForms(filters, module='forms') {
        window.scrollTo(0, 0)
        if(module === 'forms') {
            this.setState({activeTab: 'tab-forms', fromDashboard: true, showTabAlertas: true, filters, defaultFilters: filters, keySidebar: Math.random()})
        }else if(module === 'records') {
            const { history } = this.props
            const params = btoa(JSON.stringify(filters))
            history.push("/registro/" + params)
        }
    }

	handleChangeShowTabForms(show) {
        this.setState({showTabForms: show})
    }

	render() {
		const { activeTab, breadcrumbs, categories, showTabForms, keySidebar, defaultFilters } = this.state
		const { currentUser, t } = this.props

		return (
			<div className="cdi">
				<PageTopBar breadcrumbs={ breadcrumbs } />
				<Page>
					<PageHeader
						title="Gestión de Declaraciones"
						icon="form"
						description="Aquí podrá gestionar y visualizar todas las declaraciones enviadas y recibidas de la plataforma"
						/>
					<PageContent>
						<Wrapper>
							<Sidebar
								key={keySidebar}
								currentUser={currentUser}
								activeTab={ activeTab }
								onTabChange={ this.handleTabChange.bind(this) }
								categories={categories}
								showTabForms={showTabForms}
								handleChangeShowTabForms={this.handleChangeShowTabForms.bind(this)}
								defaultFilters={defaultFilters}
							/>
							<Content>
								{ activeTab === 'tab-dashboard' && <DashboardPage currentUser={currentUser} handleTabForms={this.handleTabForms.bind(this) } /> }
								{ activeTab === 'tab-solicitudes' && <SolicitudesPage currentUser={currentUser} /> }
								{ activeTab === 'tab-forms' && <FormManagerPage key={this.state.randomKey} currentUser={currentUser} categories={categories} filtersSideBar={defaultFilters} /> }
								{ activeTab === 'tab-reporting' && <ReportingPage currentUser = {currentUser}/> }
							</Content>
						</Wrapper>
					</PageContent>
				</Page>
				<PageBottomBar breadcrumbs={ breadcrumbs } />
			</div>
		)
	}
}
export default withRouter(InterestConflicts)
