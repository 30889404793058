import React from 'react';
import {Row, Col, Table, Button } from 'antd';
import moment from 'moment'
const InfoPep = ({infoPep={}, record, actual}) => {
    
    const publicRecColumns = [
        {
            title: "Organismo público",
            dataIndex: 'orgPublic'
        },
        {
            title: "Cargo",
            dataIndex: 'cargo'
        },
        {
            title: "País",
            dataIndex: 'pais'
        },
        {
            title: "Término",
            dataIndex: 'fecTermino',
            render: (date) => {
            return moment(date).format('DD/MM/YYYY')
            }
        },
    ]
    const familyRecColumns = [
        {
        title: "Organismo público",
        dataIndex: 'orgPublic'
        },
        {
        title: "País",
        dataIndex: 'pais'
        },
        {
        title: "Parentesco",
        dataIndex: 'parentesco'
        },
        {
        title: "Nombre",
        dataIndex: 'nombrePariente'
        },
        {
        title: "Número de identificación",
        dataIndex: 'nroIdPariente'
        },
        {
        title: "Cargo",
        dataIndex: 'cargo'
        },
        {
        title: "Término",
        dataIndex: 'fecTermino',
        render: (date) => {
            return moment(date).format('DD/MM/YYYY')
        }
        },
    ]

    const relationRecColumns = [
        {
            title: "Empresa",
            dataIndex: 'empresa'
        },
        {
            title: "Nro. Identificación",
            dataIndex: 'nroIdentificacion'
        },
        {
            title: "Grupo",
            dataIndex: 'grupo'
        },
        {
            title: "País",
            dataIndex: 'pais'
        },
        ]
    return (
        <div>
            <Row gutter={[32, 12]}>
                <Col span={22}>
                    {record.type === 'Person' ?
                    "Me desempeño actualmente o he desempeñado en el último año, alguno de los cargos indicados en Funciones Públicas destacadas."
                    :
                    "Desempeñan actualmente o han desempeñado en el último año, alguno de los cargos indicados en Funciones Públicas destacadas."
                    }
                </Col>
                <Col span={2} >
                    <Button type="primary" className={"no-btn radioDecision " + (actual && infoPep.isPublic !== actual.isPublic ? "difference" : "")}>
                        {infoPep.isPublic ? "Si" : "No"}
                    </Button>
                </Col>
            </Row>
            {infoPep.isPublic &&
                <Row gutter={[32, 12]}>
                    <Col span={24}>
                    <Table
                        className={actual && infoPep.publicRecords !== actual.publicRecords && "difference"}
                        columns={publicRecColumns}
                        dataSource={infoPep.publicRecords}
                        pagination={ infoPep.publicRecords && infoPep.publicRecords.length > 10 }
                    />
                    </Col>
                </Row>
            }
            <Row gutter={[32, 12]}>
                <Col span={22}>
                    {record.type === 'Person' ?
                    <>
                        Soy conyugue o pariente hasta segundo grado de consanguinidad (abuelo(a), 
                        padre, madre, hijo(a), hermano(a), nieto(a)) de personas que se desempeñan 
                        actualmente o hayan desempeñado en el último año, alguno de los cargos indicados 
                        en Funciones Públicas destacadas.
                    </>
                    :
                    <>
                        Son conyugues o parientes hasta segundo grado de consanguinidad (abuelo(a), 
                        padre, madre, hijo(a), hermano(a), nieto(a)) de personas que se desempeñan 
                        actualmente o hayan desempeñado en el último año, en alguno de los cargos indicados 
                        en Funciones Públicas destacadas.
                    </>
                    }
                </Col>
                <Col span={2}>
                    <Button type="primary" className={"no-btn radioDecision " + (actual && infoPep.hasFamily !== actual.hasFamily ? "difference" : "")}>
                    {infoPep.hasFamily ? "Si" : "No"}
                    </Button>
                </Col>
            </Row>
            {infoPep.hasFamily &&
                <Row gutter={[32, 12]}>
                    <Col span={24}>
                    <Table
                        className={actual && infoPep.familyRecords !== actual.familyRecords && "difference"}
                        columns={familyRecColumns}
                        dataSource={infoPep.familyRecords}
                        pagination={infoPep.familyRecords && infoPep.familyRecords.length > 10 }
                    />
                    </Col>
                </Row>
            }
            <Row gutter={[32, 12]}>
                <Col span={22}>
                    {record.type === 'Person' ?
                    <>
                        He celebrado un pacto de actuación conjunta que otorgue 
                        poder de voto suficiente para influir en sociedades constituidas en 
                        Chile, con una persona que desempeñe actualmente o haya 
                        desempeñado en el último año, alguno de los cargos indicados 
                        en Funciones Públicas destacadas.
                    </>
                    :
                    <>
                        Ha(n) celebrado un pacto de actuación conjunta que otorgue 
                        poder de voto suficiente para influir en sociedades constituidas en
                        Chile, con una persona que desempeñe actualmente o haya 
                        desempeñado en el último año, en alguno de los cargos indicados 
                        en Funciones Públicas destacadas.
                    </>
                    }
                </Col>
                <Col span={2}>
                    <Button type="primary" className={"no-btn radioDecision " + (actual && infoPep.hasRelation !== actual.hasRelation ? "difference" : "")}>
                    {infoPep.hasRelation ? "Si" : "No"}
                    </Button>
                </Col>
            </Row>
            {infoPep.hasRelation &&
                <Row gutter={[32, 12]}>
                    <Col span={24}>
                        <Table
                            className={actual && infoPep.relationRecords !== actual.relationRecords && "difference"}
                            columns={relationRecColumns}
                            dataSource={infoPep.relationRecords}
                            pagination={infoPep.relationRecords && infoPep.relationRecords.length > 10 }
                        />
                    </Col>
                </Row>
            }
        </div>
    );
};

export default InfoPep;