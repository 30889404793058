import React, {useState} from 'react';
import {Row, Col, Descriptions, Switch, Icon, Modal, Button, Select, notification} from 'antd'
import { useTranslation } from 'react-i18next';
import {savePeriodicityPromise} from '../../../../promises'
import moment from 'moment';

const Servicios = ({currentUser, item, mode, uboFinderParams, handleOnChange, loadClient}) => {
    const [itemTable, setItemTable] = useState(item);
    const [isPeriodicityVisible, setIsPeriodicityVisible] = useState(false);
    const [periodicity, setPeriodicity] = useState(item.periodicity);
    const [confirmLoading, setConfirmLoading] = useState(null)
    const [ficha, setFicha] = useState(item.ficha);
    const [typeForm, setTypeForm] = useState("");
    const { confirm } = Modal;
    const { t } = useTranslation();

    const handleOnChangeLocal = (attr, value) => {
        itemTable[attr] = value
        setItemTable({...itemTable})
        handleOnChange(attr, value)
    }

    const onCancelPeriodicity = () => {
        setIsPeriodicityVisible(false)
    }

    const validaTypeForm = (type) => {
        if(type === 'KYC' && currentUser.cliente.modules.includes('ONBOARDING')) {
          return true
        }else if(type === 'CDI' && currentUser.modules.includes('CDI-FORM-GD') && currentUser.cliente.modules.includes('CDI-FORM-CDI') && (itemTable.category === 'COLABORADOR' || itemTable.category === 'PROVEEDOR')) {
          return true
        }else if(type === 'REL' && currentUser.modules.includes('CDI-FORM-GREL') && currentUser.cliente.modules.includes('CDI-FORM-REL') && (itemTable.category === 'COLABORADOR')) {
          return true
        }else if(type === 'DIR' && currentUser.modules.includes('CDI-FORM-GDIR') && currentUser.cliente.modules.includes('CDI-FORM-DIR') && (itemTable.category === 'DIRECTOR')) {
          return true
        }else if(type === 'PATR' && currentUser.modules.includes('CDI-FORM-GPATR') && currentUser.cliente.modules.includes('CDI-FORM-PATR') && (itemTable.category === 'CLIENTE')) {
          return true
        }else if(type === 'GIFT' && currentUser.modules.includes('CDI-FORM-GF-G') && currentUser.cliente.modules.includes('CDI-FORM-G') && (itemTable.category === 'COLABORADOR')) {
          return true
        }else if(type === 'TRAVEL' && currentUser.modules.includes('CDI-FORM-GF-T') && currentUser.cliente.modules.includes('CDI-FORM-T') && (itemTable.category === 'COLABORADOR')) {
          return true
        }else if(type === 'FP' && currentUser.modules.includes('CDI-FORM-GF-F') && currentUser.cliente.modules.includes('CDI-FORM-F') && (itemTable.category === 'COLABORADOR')) {
          return true
        }else if(type === 'SOC' && currentUser.modules.includes('CDI-FORM-GF-S') && currentUser.cliente.modules.includes('CDI-FORM-S') && (itemTable.category === 'COLABORADOR')) {
          return true
        }
        return false
    }

    const descPeriodicities = {
        "KYC": "Actualización periódica de la ficha OnBoarding",
        "CDI": "Actualización periódica de Conflicto de Interés",
        "DIR": "Actualización periódica de Declaración de Directores",
        "REL": "Actualización periódica de Declaración de Relacionados",
        "PATR": "Actualización periódica de Relaciones Patrimoniales",
        "TRAVEL": "Actualización periódica de Formulario de Viajes",
        "GIFT": "Actualización periódica de Formulario de Regalos",
        "FP": "Actualización periódica de Reuniones con Funcionarios Públicos",
        "SOC": "Actualización periódica de Participación en Asociaciones Empresariales",
    }

    const cdiOpts = () => {
        let arr = []
        itemTable.periodicities && itemTable.periodicities.map(p => {
            if(validaTypeForm(p.tipo)) {
                arr.push(
                    <Descriptions.Item 
                        label={descPeriodicities[p.tipo] + " - " +
                        t('messages.aml.periodicity')+": "+(p.periodicity ? t('messages.aml.onboarding.period.'+p.periodicity) : 'Sin asignar')}
                        >
                        <Icon type="edit" style={{fontSize: 28}} onClick={() => handleSelectedOption(p.tipo)}/>
                    </Descriptions.Item>
                )
            }
        })
    
        return arr    
    }
        
    const handleSelectedOption = (type) => {
        setTypeForm(type)
        setIsPeriodicityVisible(true)
    }

    const savePeriodicity = () => {
        confirm({
            title: 'Modificar Periodicidad?',
            content: '¿Está seguro que desea modificar la Periodicidad?',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                setConfirmLoading(true)
                savePeriodicityPromise(item.id, typeForm, periodicity).then(r => {
                    if (r) {
                        notification.success({
                            message: 'Operación exitosa',
                            description: 'La periodicidad ha sido modificada correctamente',
                        })
                        setIsPeriodicityVisible(false)
                        loadClient()
                    }else{
                        notification.error({
                            message: 'Operación fallida',
                            description: 'No se ha actualizado la periodicidad',
                        })
                    }
                    setConfirmLoading(false)
                })
            },
        });
    }

    return (
        <div>
            { (currentUser.cliente.modules.includes('QUIEBRA')
                || (itemTable.type === 'Entity' && currentUser.cliente.modules.includes('ADMCLI') && uboFinderParams && !uboFinderParams.noInvestigaMalla)
                || (itemTable.type === 'Entity' && currentUser.cliente.modules.includes('ADMCLI') && uboFinderParams && uboFinderParams.formularioInterno && !uboFinderParams.sinFormulario)
                || ((currentUser.cliente.modules.includes('ONBOARDING') || currentUser.cliente.modules.includes('CDI-FORM')) && itemTable.periodicities?.length > 0)
                || currentUser.cliente.modules.includes('MONITOR-AML')
              ) &&
            <>
                <Row className="field-section-row">
                    <Col span={24}>
                        <h3>
                            Servicios
                        </h3>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={[24, 24]} style={{padding: 20}}>
                    <Col span={14}>
                        <Descriptions bordered colon={false} column={1} size="small">
                            <Descriptions.Item label="Solicitud">
                                Editar
                            </Descriptions.Item>
                            { currentUser.cliente.modules.includes('QUIEBRA') &&
                                <Descriptions.Item label="Activar Monitoreo de Quiebras">
                                    <Switch
                                        checked={ itemTable.hasQuiebras }
                                        disabled={ mode === 'view' }
                                        onChange={ (checked) => handleOnChangeLocal('hasQuiebras', checked) }
                                    />
                                </Descriptions.Item>
                            }
                            { itemTable.type === 'Entity' && currentUser.cliente.modules.includes('ADMCLI') && uboFinderParams && !uboFinderParams.noInvestigaMalla &&
                                <Descriptions.Item label={ t('messages.aml.activateUbosInvestigate') }>
                                    <Switch 
                                    checked={ itemTable.processUF } 
                                    disabled={ mode === 'view' || (mode==='newFilled' && itemTable.pais !== 'CHIL') } 
                                    //onChange={ (checked) => handleOnChangeUF(checked) } />
                                    onChange={ (checked) => handleOnChangeLocal('processUF', checked)} />
                                </Descriptions.Item>
                            }
                            { itemTable.type === 'Entity' && currentUser.cliente.modules.includes('ADMCLI') && uboFinderParams && uboFinderParams.formularioInterno && !uboFinderParams.sinFormulario &&
                                <Descriptions.Item label={t('messages.aml.sendForm57')}>
                                    <Switch 
                                    checked={ !itemTable.formularioInterno } 
                                    disabled={ mode === 'view' }
                                    onChange={ (checked) => handleOnChangeLocal('formularioInterno', !checked) } />
                                </Descriptions.Item>
                            }
                            { (currentUser.cliente.modules.includes('ONBOARDING') || currentUser.cliente.modules.includes('CDI-FORM')) &&
                                cdiOpts()
                            }
                            { currentUser.cliente.modules.includes('MONITOR-AML') &&
                                <Descriptions.Item label={t('messages.aml.permanentMonitoring')}>
                                    <Switch 
                                    checked={ itemTable.hasMonitoring } 
                                    disabled={ mode === 'view' }
                                    onChange={ (checked) => handleOnChangeLocal('hasMonitoring', checked) } />
                                </Descriptions.Item>
                            }
                        </Descriptions>
                    </Col>
                    <Col span={10}></Col>
                </Row>
            </>
            }

            { isPeriodicityVisible &&
                <Modal
                    title={<div><Icon type="clock-circle"/> Modificar periodicidad</div>}
                    header={null}
                    footer={[<Button onClick={onCancelPeriodicity}>Cerrar</Button>, <Button type="primary" disabled={confirmLoading} loading={confirmLoading} onClick={savePeriodicity}>{t('messages.aml.modify')}</Button>]}
                    onCancel={onCancelPeriodicity}
                    visible={true}
                >
                    <p style={{textAlign:'justify'}}>
                    Informamos que la siguiente solicitud del formulario de OnBoarding está programada para {moment(itemTable.nextDate).format('DD/MM/YYYY')}.
                    <br/>
                    Si lo requiere a continuación puede seleccionar una nueva periodicidad
                    </p>
                    <p>
                    <Row>
                        <Col span ={24}>
                            <Select style={{width: '100%', float:'right'}} onChange={(v) => setPeriodicity(v)} defaultValue={itemTable?.periodicities?.find(e => e.tipo === typeForm)?.periodicity}>
                                <Select.Option value={null}>{t('messages.aml.none')}</Select.Option>
                                <Select.Option value="MONTHLY">{t('messages.aml.onboarding.period.MONTHLY')}</Select.Option>
                                <Select.Option value="QUARTERLY">{t('messages.aml.onboarding.period.QUARTERLY')}</Select.Option>
                                <Select.Option value="BIANNUAL">{t('messages.aml.onboarding.period.BIANNUAL')}</Select.Option>
                                <Select.Option value="ANNUAL">{t('messages.aml.onboarding.period.ANNUAL')}</Select.Option>
                            </Select>
                        </Col>
                    </Row>
                    </p>
                    <p style={{textAlign:'justify'}}>
                        Recuerde si selecciona la opción Ninguno el sistema dejará sin efecto el envío de la solicitud del formulario.
                    </p>
                </Modal>
            }
        </div>
    );
};

export default Servicios;