import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Select, Spin} from "antd";

import './PrincipalRulesCard.scss'
import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import {getIndicatorsRulesPromise} from "../../../../promises";
import {camelizerHelper} from "../../../../../../helpers";
import TableModal from "../TableModal/TableModal";


const PrincipalRulesCard = ({categories, globalActiveCategory, handleTabAlertas}) => {

    const {t} = useTranslation()

    const [allRules, setAllRules] = useState([])
    const [slicedRules, setSlicedRules] = useState(null)
    const [activeCategory, setActiveCategory] = useState(globalActiveCategory);
    const [activeAlertType, setActiveAlertType] = useState(null);
    const [isModalRulesVisible, setIsModalRulesVisible] = useState(null);

    let filter = {
    };

    const alertType = ["PERSONA", "DEMOGRAFICA", "TRANSACCIONAL"]

    const rulesTableColumns = [
        {
            title: "Nombre",
            dataIndex: "name",
        },
        {
            title: "Registros",
            dataIndex: "registerCount",
        },
    ];

    useEffect(() => {
        handleSearch()
    }, [activeCategory, activeAlertType]);

    const handleSearch = () => {
        filter.category = activeCategory
        filter.alertType = activeAlertType

        getIndicatorsRulesPromise(filter).then((response) => {
            let data = response.data

            let sortData = [].concat(data)
                .sort((a, b) => b.registerCount > a.registerCount ? 1 : -1)

            sortData = sortData.filter(x => x.registerCount > 0)

            setAllRules([...sortData])
            setSlicedRules([...sortData].slice(0, 4))

        })
    }

    const handleSetActiveCategory = (category) => {
        setSlicedRules(null)
        setActiveCategory(category);
    };

    const handleSetActiveAlertType = (alertType) => {
        setSlicedRules(null)
        setActiveAlertType(alertType);
    };

    function getWidth(value) {

        if (slicedRules !== undefined) {
            let max = Object.values(slicedRules).map(x => x.registerCount)

            let percent = Math.max(...max)
            return (value * 100) / percent
        }
    }

    function getLegend() {
        return (
            <Row>
                <Col span={17} style={{paddingBottom: '20px'}}>
                    Se presenta el listado de las 4 principales reglas y el número
                    de {activeCategory ? t('messages.aml.monitor.principal.rules.cat.' + activeCategory) : t('messages.aml.records')} que la activaron</Col>
            </Row>
        )
    }

    const handleModalTotalRulesCancel = () => {
        setIsModalRulesVisible(false)
    }

    const handleModalTotalRules = () => {
        setIsModalRulesVisible(true)
    }

    const getFilters = (ruleId) => {
        let filters = { alerts: { ruleId }}
        if(activeCategory) filters.categories = [activeCategory]

        return filters
    }

    const getRulesResume = (totalRules) => {

        return (
            <>
                {
                    totalRules !== null ?

                        totalRules.length !== 0 ?
                            totalRules.map((rul, index) =>
                                <Row className={"row-principal-content"} key={index}>
                                    <Col span={8}>
                                        <div className={"Register-name"}>
                                            <a onClick={() => handleTabAlertas(getFilters(rul.ruleId), 'records')}>
                                                <span className="rul-name">{camelizerHelper(rul.name)} </span>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col span={12} offset={1}>
                                        <div className={"total-level-risk"}>
                                            <div className="level-risk-indicator" style={{width: getWidth(rul.registerCount) + '%'}}>
                                                <div className="text-risk-indicator">
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={2} offset={1}>
                                        <div className={"level-risk-value"}>
                                            <a onClick={() => handleTabAlertas(getFilters(rul.ruleId), 'records')}>
                                                <span className="rul-name">{rul.registerCount}</span>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <div className={"label-no-info"}>
                                    <Row>
                                        <Col offset={7}>
                                            {t('messages.aml.monitor.no.rules')}
                                        </Col>
                                    </Row>
                                </div>
                            )
                        :
                        <Row gutter={[0, 30]}>
                            <Col span={1} offset={11}>
                                <div className="spinner-principal-rul">
                                    <Spin spinning={true} size="large"/>
                                </div>
                            </Col>
                        </Row>

                }

                <br/><br/>

                <Row>
                    <Col span={6}>
                        <Select
                            style={{width: "100%"}}
                            value={activeAlertType}
                            placeholder={"Tipo de alerta"}
                            onChange={value => handleSetActiveAlertType(value)}
                        >
                            <Select.Option value={null}>Tipo de alerta</Select.Option>
                            {alertType.map((type, index) => (
                                <Select.Option key={index} value={type}>
                                    {camelizerHelper(type)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>

                    <Col span={6} offset={12}>
                        <Button className="show-all-alerts-by-rules"
                                onClick={totalRules !== null && totalRules.length !== 0 ? handleModalTotalRules : null}>
                            <span className={"text"}>{t('messages.aml.monitor.principal.rules.complete.list')} </span>
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <div className='principal-rules'>

                <LargeCardDashboard
                    key={activeCategory}
                    title={t("messages.aml.monitor.rules." + activeCategory)}
                    legend={getLegend()}
                    handleSetActiveCategory={handleSetActiveCategory}
                    activeCategory={activeCategory}
                    categories={categories}
                    content={getRulesResume(slicedRules)}

                />
            </div>

            {
                isModalRulesVisible &&
                <div id="modal-user">
                    <TableModal
                        onCancel={handleModalTotalRulesCancel}
                        dataSource={allRules}
                        columns={rulesTableColumns}
                        title={"Tabla de Reglas"}
                    />
                </div>
            }
        </>
    )

}

export default PrincipalRulesCard;