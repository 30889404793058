import apiConfig from '../../../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../../../helpers'

export default {
  getActivityRecord: (recordId) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/activityRecord/'+recordId,
      method: 'get',
    })
  }
}
