import './TabAlerts.scss'
import './newItemModal.scss'
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { Button, Icon, Pagination, Row, Modal, Empty, Table } from 'antd'
import { getItemsPromise } from './promises'
import { camelizerHelper } from '../../../../helpers/'
import { ModalNewAlert,AdvancedTabs } from "./components";
import {ModalAlertCardPage} from '../../../'
import { useTranslation } from 'react-i18next';

const TabAlerts = ({type, currentUser, categories, alertStatus, filtersSideBar = {}}) => {
    const [items, setItems] = useState([])
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [clickedItem, setClickedItem] = useState({})
    const [visibleAlertManager, setVisibleAlertManager] = useState(false)
    const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] = useState(null)
    const [isItemsLoading, setIsItemsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsTotalNum, setItemsTotalNum] = useState(-1)
    const itemsPerPage = 10;
    const [filters, setFilters] = useState(filtersSideBar);
    const [isModalNewAlertVisible, setIsNewAlertModalVisible] = useState(false)
    const [hasEmpresa, setHasEmpresa] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        if (currentUser.cliente.outsourcer && currentUser.cliente.clientes !== null && currentUser.cliente.clientes.length > 0 && currentUser.subcliente === null) {
            setHasEmpresa(true)
        }
        handleSearch(1, filters)
    }, [])

    const handleSearch = async (page, filters) => {
        setIsItemsLoading(true)
        const fromNum = ((page - 1) * itemsPerPage)
        filters.from = fromNum;
        filters.size = itemsPerPage;
        if(filters.states) {
            if(filters.states.includes('OPEN') && !filters.states.includes('READY')) {
                filters.states.push("READY")
            }else if(!filters.states.includes('OPEN') && filters.states.includes('READY')) {
                filters.states = filters.states.filter(element => element !== "READY")
            }
            if(filters.states.length === 0) {
                filters.states = null
            }
        }

        const apiResponse = await getItemsPromise(type, filters)
        setItems(apiResponse.data.records)

        setItemsTotalNum(apiResponse.data.total)
        setCurrentPage(page)

        setIsItemsLoading(false)
    }

    const cbFilters = (objFilters) => {
        let f = { ...filtersSideBar, ...objFilters }
        setFilters(f)
        handleSearch(1, f);
    };

    const handlePaginationChange = async (value) => {
        handleSearch(value, filters)
    }

    const handleShowAdvancedSearch = () => {
        setIsAdvancedSearchVisible(true)
    }

    const handleHideAdvancedSearch = () => {
        setIsAdvancedSearchVisible(false)
    }

    const handleTableOnRow = (text, item, index) => {
        setClickedItem(item)
        setClickedIndex(index);
        setVisibleAlertManager(true)
    }

    const handleOpenNewAlertModal = () => {
        setIsNewAlertModalVisible(true)
    }

    const closeModalHandler = (reload) => {
        setIsNewAlertModalVisible(false)
        if (reload === true) {
            handleSearch(currentPage, filters)
        }
    }

    const closeModalAlertHandler = () => {
        setVisibleAlertManager(false)
    }

    const getHandlePrevItem = () => {
        if (clickedIndex <= 0) {
            return null;
        } else {
            return () => {
                setClickedItem(items[clickedIndex - 1]);
                setClickedIndex(clickedIndex - 1);
            };
        }
    };

    const getHandleNextItem = () => {
        if (clickedIndex >= items.length - 1) {
            return null;
        } else {
            return () => {
                setClickedItem(items[clickedIndex + 1]);
                setClickedIndex(clickedIndex + 1);
            };
        }
    };

    const colorRisk = (scoreRisk) => {
        if (scoreRisk === 0) {
            return 'GREEN'
        }
        if (scoreRisk === 1) {
            return 'YELLOW'
        }
        if (scoreRisk === 2) {
            return 'ORANGE'
        }
        if (scoreRisk === 3) {
            return 'RED'
        }
        if (scoreRisk === 4) {
            return 'BLACK'
        }
    }
    const getTableColumns = () => {

        let columns = [
            {
                title: t('messages.aml.category'),
                width: '15%',
                render: (item => 
                    t("messages.aml.category."+ item.category)
                )
            },
            {
                title: t('messages.aml.type'),
                align: 'center',
                width: '10%',
                render: (item => {
                    return <div style={{fontSize: 16}}>
                        {item.type === 'PERSONA' ?
                            <Icon type="user"/>
                            :
                            <Icon type="reconciliation"/>
                        }
                    </div>
                }),
            },
            {
                title: 'Nombre/Rut',
                width: '15%',
                render: ((text, item, index) => {
                    return (
                        <div className={"col-nombre-rut"}>
                            <Button
                                type="link"
                                onClick={() =>{
                                        handleTableOnRow(text, item, index) }
                                    }>

                                { item.name ?
                                    <>
                                    <Row>
                                        { camelizerHelper(item.name) }
                                    </Row>
                                    <Row>
                                        {item.rut }
                                    </Row>
                                    </>
                                    :
                                    item.description !== null ? item.description.name : ''
                                }

                            </Button>
                        </div>
                    )
                })
            },
            {
                title: 'Recepción/Cierre',
                width: '16%',
                render: (item => {
                    return <>
                            <div>{moment(item.creationDate).format('DD/MM/YYYY')}</div>

                            <div>
                                {item.dateStatus? moment(item.dateStatus).format('DD/MM/YYYY') : t('messages.aml.request.PENDIENTE')}
                            </div>
                    </>
                })
            },
            {
                title: t('messages.aml.risk'),
                width: '11%',
                render: (item => {
                    return <>
                            <div className="col-risk">
                                <span className={'quadrate quadrate-risk-' + item.score}>{item.type.charAt(0)}</span>
                                &nbsp;&nbsp;&nbsp;
                                <span className={'circle risk-' + colorRisk(item.score)}>{item.score}</span>
                            </div>
                    </>
                })
            },
            {
                title: t('messages.aml.rules'),
                width: '8%',
                align: 'center',
                render: (item =>
                    item.rules?.length
                )
            },
            {
                title: 'Tareas',
                width: '8%',
                align: 'center',
                render: (item =>
                        <div>
                            {item.nroTareas > 0 ? 'SI' : ''}
                        </div>
                )
            },
            {
                title: 'Folio',
                width: '15%',
                dataIndex: 'folio'
            }
        ]

        return columns
    }

    return (
        <>
            <div className="tab-alerts">

                <div className="top-bar" id="top-bar-tab-task">
                    {type === 'EVENTO' &&
                        <Button type="primary" icon="plus" className="add-button" onClick={handleOpenNewAlertModal}>Nueva
                            Alerta Personalizada</Button>
                    }

                        <div
                            className={
                                isAdvancedSearchVisible ? "advanced-search on" : "advanced-search"
                            }
                            onClick={
                                !isAdvancedSearchVisible
                                    ? handleShowAdvancedSearch
                                    : handleHideAdvancedSearch
                            }
                        >
                            <Icon type="menu"/> &nbsp;
                            <span>Búsqueda avanzada</span> &nbsp;
                            <Icon type={!isAdvancedSearchVisible ? "down" : "close"}/>
                        </div>
                    </div>

                <div
                    className={
                        isAdvancedSearchVisible === null
                            ? "filters-wrapper null"
                            : isAdvancedSearchVisible
                                ? "filters-wrapper show"
                                : "filters-wrapper hide"
                    }
                >
                    <div className="filters-wrapper-inner">
                        {/* <AdvancedNav type={type} cbFilters={cbFilters} /> */}
                        <AdvancedTabs
                            type={type}
                            cbFilters={cbFilters}
                            currentUser={currentUser}
                            alertStatus={alertStatus}
                        />
                    </div>
                </div>

                {(itemsTotalNum > 0 || isItemsLoading) ?
                    <div className="alert-table">
                        <Row>
                            <Table
                                size="small"
                                loading={isItemsLoading}
                                pagination={false}
                                columns={getTableColumns()}
                                dataSource={items}
                            />
                        </Row>

                        { itemsTotalNum > itemsPerPage &&
                            <div className="bottom-bar">
                                <Pagination onChange={handlePaginationChange} pageSize={itemsPerPage} current={currentPage}
                                            total={itemsTotalNum}/>
                            </div>
                        }
                    </div>

                    :
                    itemsTotalNum === 0 && <Empty/>
                }
            </div>

            {isModalNewAlertVisible &&
                <Modal
                    className="new-item-modal"
                    header={null}
                    footer={null}
                    visible={true}
                    onOk={closeModalHandler}
                    onCancel={closeModalHandler}
                >
                    <ModalNewAlert closeModalHandler={closeModalHandler} categories={categories}/>
                </Modal>
            }

            { visibleAlertManager &&
			<Modal
				className="modal-alert-card"
				visible={ true }
				header={ null }
				footer={ null }
				onCancel={ closeModalAlertHandler }
				style={{top: '20px'}}
				bodyStyle={{padding: '0px 0px 0px 0px'}}
				width={'95vw'}
			>
				<ModalAlertCardPage
					key={"alert-detail-" + clickedItem.id}
					currentPage={currentPage}
					item={clickedItem}
					currentUser={currentUser}
					closeOverlayAlerta={closeModalAlertHandler}
					handlePrevItem={getHandlePrevItem()}
					handleNextItem={getHandleNextItem()}
				/>
			</Modal>
		    }
        </>
    )
}

export default TabAlerts
