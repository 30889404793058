import './ContentTopBar.scss'
import React, { useEffect, useState } from 'react'
import { Icon, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import { CakeChart } from '../'

const ContentTopBar = ({ isLoading, firstLoading, colors, colorsCake, onChangeFilters, filters }) => {
  const { t } = useTranslation()

  useEffect(() => {
    //init()
  }, [])

  return (
    <div className="content-top-bar">
      <div className="cake-wrapper">
        <CakeChart isLoading = {isLoading}  firstLoading = {firstLoading} colorsCake = {colorsCake} />
      </div>
      <div className="risk-levels-wrapper">
        <ul>
          <li className={ filters['risk'] === null || filters['risk'] === undefined || filters['risk'].length === 0 || filters['risk'].includes('BLACK') ? 'is-checked' : 'is-not-checked' }>
            <span className="percent">{ colorsCake.total === 0 ? 0 : Math.round((colorsCake.critical * 100) / colorsCake.total) }%</span>
            <span className="percent-locked"><Icon type="stop" /></span>

            <Switch size="small" checked={ filters['risk'] !== null && filters['risk'] !== undefined && filters['risk'].includes('BLACK') } onChange={ (value) => onChangeFilters('risk', 'BLACK', value) } loading={isLoading}/>
            <div className="risk-level-inner">
              <div id="animated-number-black" className="circle risk-BLACK">{ colors.black }</div>
              <span className="risk-level-name">{ t('messages.aml.riskCritical') }</span>
            </div>
          </li>
          <li className={ filters['risk'] === null || filters['risk'] === undefined || filters['risk'].length === 0 || filters['risk'].includes('RED') ? 'is-checked' : 'is-not-checked' }>
            <span className="percent">{ colorsCake.total === 0 ? 0 : Math.round((colorsCake.high * 100) / colorsCake.total) }%</span>
            <span className="percent-locked"><Icon type="stop" /></span>

            <Switch size="small" checked={ filters['risk'] !== null && filters['risk'] !== undefined && filters['risk'].includes('RED') } onChange={ (value) => onChangeFilters('risk', 'RED', value) } loading={isLoading}/>
            <div className="risk-level-inner">
              <div  id="animated-number-red" className="circle risk-RED">{ colors.red }</div>
              <span className="risk-level-name">{ t('messages.aml.riskHigh') }</span>
            </div>
          </li>
          <li className={ filters['risk'] === null || filters['risk'] === undefined || filters['risk'].length === 0 || filters['risk'].includes('ORANGE') ? 'is-checked' : 'is-not-checked' }>
            <span className="percent">{ colorsCake.total === 0 ? 0 : Math.round((colorsCake.medium * 100) / colorsCake.total) }%</span>
            <span className="percent-locked"><Icon type="stop" /></span>

            <Switch size="small" checked={ filters['risk'] !== null && filters['risk'] !== undefined && filters['risk'].includes('ORANGE') } onChange={ (value) => onChangeFilters('risk', 'ORANGE', value) } loading={isLoading}/>
            <div className="risk-level-inner">
              <div id="animated-number-orange" className="circle risk-ORANGE">{ colors.orange }</div>
              <span className="risk-level-name">{ t('messages.aml.riskMedium') }</span>
            </div>
          </li>
          <li className={ filters['risk'] === null || filters['risk'] === undefined || filters['risk'].length === 0 || filters['risk'].includes('YELLOW') ? 'is-checked' : 'is-not-checked' }>
            <span className="percent">{ colorsCake.total === 0 ? 0 : Math.round((colorsCake.low * 100)/ colorsCake.total) }%</span>
            <span className="percent-locked"><Icon type="stop" /></span>

            <Switch size="small" checked={ filters['risk'] !== null && filters['risk'] !== undefined && filters['risk'].includes('YELLOW') } onChange={ (value) => onChangeFilters('risk', 'YELLOW', value) } loading={isLoading}/>
            <div className="risk-level-inner">
              <div  id="animated-number-yellow" className="circle risk-YELLOW">{ colors.yellow }</div>
              <span className="risk-level-name">{ t('messages.aml.riskLow') }</span>
            </div>
          </li>
          <li className={ filters['risk'] === null || filters['risk'] === undefined || filters['risk'].length === 0 || filters['risk'].includes('GREEN') ? 'is-checked' : 'is-not-checked' }>
            <span className="percent">{ colorsCake.total === 0 ? 0 : Math.round((colorsCake.n * 100) / colorsCake.total) }%</span>
            <span className="percent-locked"><Icon type="stop" /></span>

            <Switch size="small" checked={ filters['risk'] !== null && filters['risk'] !== undefined && filters['risk'].includes('GREEN') } onChange={ (value) => onChangeFilters('risk', 'GREEN', value) } loading={isLoading}/>
            <div className="risk-level-inner">
              <div id="animated-number-green" className="circle risk-GREEN">{ colors.green }</div>
              <span className="risk-level-name">{ t('messages.aml.risk.N') }</span>
            </div>
          </li>
          <li className={ filters['risk'] === null || filters['risk'] === undefined || filters['risk'].length === 0 || filters['risk'].includes('NA') ? 'is-checked' : 'is-not-checked' }>
            <span className="percent">{ colorsCake.total === 0 ? 0 : Math.round((colorsCake.na * 100) / colorsCake.total) }%</span>
            <span className="percent-locked"><Icon type="stop" /></span>

            <Switch size="small"  checked={ filters['risk'] !== null && filters['risk'] !== undefined && filters['risk'].includes('NA') } onChange={ (value) => onChangeFilters('risk', 'NA', value) } loading={isLoading}/>
            <div className="risk-level-inner">
              <div id="animated-number-grey" className="circle risk-GRAY">{ colors.na }</div>
              <span className="risk-level-name">{ t('messages.aml.notProcessed') }</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ContentTopBar
