import React from 'react';
import {Row, Col, Input, Button} from 'antd';

const InfoCompliance = ({infoCom, actual, record}) => {
    return (
        <div>
            <Row gutter={[32, 12]}>
                <Col span={22}>
                    {record.type === 'Person' ?
                    <div>
                        ¿Usted se encuentra contemplado(a) en el artículo 3° de la Ley 19.913
                        como un sujeto obligado a informar sobre operaciones 
                        sospechosas que adviertan en el ejercicio de sus actividades? Tal como está 
                        actualmente, si la respuesta es afirmativa indique quién ocupa la posición de 
                        oficial de cumplimiento y entregue una descripción del Programa de Compliance.
                    </div>
                    :
                    <div>
                        ¿La sociedad se encuentra contemplada en el artículo 3° de la Ley 19.913 como
                        una sociedad obligada a informar sobre operaciones 
                        sospechosas que adviertan en el ejercicio de sus actividades? Tal como está 
                        actualmente, si la respuesta es afirmativa indique quién ocupa la posición de 
                        oficial de cumplimiento y entregue una descripción del Programa de Compliance.
                    </div>
                    }
                </Col>
                <Col span={2} >
                    <Button type="primary" className={"no-btn radioDecision " + (actual && infoCom.hasCompliance !== actual.hasCompliance ? "difference" : "")}>
                        {infoCom.hasCompliance ? "Si" : "No"}
                    </Button>
                </Col>
            </Row>
            {infoCom.hasCompliance &&
                <>
                    <Row gutter={[32, 12]}>
                        <Col span={22}>
                            ¿Cuenta con un Oficial de Cumplimiento?
                        </Col>
                        <Col span={2} >
                            <Button type="primary" className={"no-btn radioDecision " + (actual && infoCom.hasOfCto !== actual.hasOfCto ? "difference" : "")}>
                                {infoCom.hasOfCto ? "Si" : "No"}
                            </Button>
                        </Col>
                    </Row>
                    {infoCom.hasOfCto &&
                        <Row gutter={[32, 12]}>
                            <Col span={8}>
                                Nombre
                                <Input
                                    readOnly
                                    value={infoCom.nombreCompliance}
                                    className={actual && infoCom.nombreCompliance !== actual.nombreCompliance && "difference"}
                                />
                            </Col>
                            <Col span={8}>
                                Correo electrónico
                                <Input
                                    readOnly
                                    value={infoCom.correo}
                                    className={actual && infoCom.correo !== actual.correo && "difference"}
                                />
                            </Col>
                            <Col span={8}>
                                Teléfono fijo
                                <Input
                                    readOnly
                                    value={infoCom.telefono}
                                    className={actual && infoCom.telefono !== actual.telefono && "difference"}
                                />
                            </Col>
                            <Col span={24}>
                                Observaciones
                                <Input.TextArea
                                    readOnly
                                    value={infoCom.descripcion}
                                    className={actual && infoCom.descripcion !== actual.descripcion && "difference"}
                                />
                            </Col>
                        </Row>
                    }
                </>
            }
        </div>
    );
};

export default InfoCompliance;