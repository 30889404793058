import "./FormKyc.scss";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row, Spin, DatePicker, Select, Radio, Table, Icon, notification, Descriptions, Checkbox, InputNumber, Upload, message, List } from "antd";
import { FormLayout } from "../../../../../layouts";
import { withRouter } from "react-router-dom";
import apiConfig from '../../../../../config/api'
import { getFormPromise, getParamsPromise, saveFormOnbPromise, sendFormPromise, signCDIFormPromise, sendFileFormPromise } from "../../../promises";
import { getCountriesCodePromise } from '../../../promises';
import moment from "moment";
import Logo from '../../../../../layouts/commonComponent/components/Logo/Logo'
import { validateRutHelper, validateCompanyRutHelper, } from "../../../../../helpers";
import HelloSign from 'hellosign-embedded';
import { getRegionComunaPromise, getSIIActivitiesPromise } from "../../../../../promises";

const FormKyc = ({ form, match }) => {
  const { t } = useTranslation();
  const { getFieldDecorator, validateFields, getFieldsError, setFieldsValue } = form;
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [paises, setPaises] = useState(null);
  const [date, setDate] = useState(0);
  const [user, setUser] = useState({});
  const [apiForm, setApiForm] = useState(null);
  const [colLogo, setColLogo] = useState(4);
  const [format, setFormat] = useState("html");
  const [validarRegistros, setValidarRegistros] = useState(false);
  const [signed, setSigned] = useState(false);
  const [openSigner, setOpenSigner] = useState(false);
  const hasSign = false
  const [clientName, setClientName] = useState("");
  const [sentErrors, setSentErrors] = useState(0);
  const [tmpFilesList, setTmpFilesList] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [userFileName, setUserFileName] = useState("");
  const [SIIActivities, setSIIActivities] = useState([]);
  const [regionComuna, setRegionComuna] = useState([]);
  const [comunas, setComunas] = useState([]);

  let registersStop = {};

  const radioStyle = {
      display: 'block',
      height: '22px',
      lineHeight: '22px',
    };

  const toDescriptionsPdf = (collection, stateObject, typeEntity) => (
    <>
      {collection.map((collectionObject, index) => (
        <>
          <div className="descriptions-pdf">
            <h4 className="descriptions-numeral">#{index + 1}</h4>
            <Descriptions title="" column={1} bordered size="small">
              {Object.keys(stateObject).map((key) => {
                if(!typeEntity || !stateObject[key].typeEntity || stateObject[key].typeEntity.includes(typeEntity)) {
                  return (
                    <Descriptions.Item label={stateObject[key].title}>
                      {collectionObject[stateObject[key].key]}
                    </Descriptions.Item>
                  );
                }else {
                  return null
                }
              })}
            </Descriptions>
          </div>
          <br />
        </>
      ))}
    </>
  );

  const handleListDeleted= (index) =>{
    setFileNames(oldFileNames => {
      const newFileNames = oldFileNames.slice();
      newFileNames.splice(index, 1)

      return newFileNames
    })

    setTmpFilesList(oldTmpFilesList => {
      const newTmpFilesList = oldTmpFilesList.slice();
      newTmpFilesList.splice(index, 1)

      return newTmpFilesList
    })
  }

  const propsUpload = {
    accept: ".pdf, .png, .docx, .xlsx",
    onRemove: file => {
      const index = tmpFilesList.indexOf(file);
      handleListDeleted(index);
    },
    beforeUpload: file => {
      validateFields([attch.attchFileName.id]).then((error, values) => {
        const validFiles = file.type === 'application/pdf' || file.type === 'image/png'|| file.name.endsWith('.docx') || file.name.endsWith('.xlsx');
        if (!validFiles) {
          message.error('Formato no permitido.');
        }else{
          const validSize = file.size / 1024 / 1024 < 2;
          if (!validSize) {
            message.error('Tamaño máximo 2MB!');
          }else{
            setTmpFilesList(oldTmpFilesList => [...oldTmpFilesList, file]);
            setFileNames(oldFileNames => [...oldFileNames, attch.attchFileName.val]);
          }
          setFieldsValue({[attch.attchFileName.id]: null})
          handleOnChangeField(attch.attchFileName.id, "")
        }
      })
      return false
    },
    multiple: false,
    showUploadList: false
  }

  const handleOnClear = (section)=>{
    switch (section) {
      case 'prodAct':
        setProdAct({...prodActIS})
        setFieldsValue(Object.keys(prodActIS).reduce((acu,key)=>{
          return {...acu,[key]:undefined}
        },{}))
        break;
      case 'activityEsp':
      setActivityEsp({...activityEspIS})
      setFieldsValue(Object.keys(activityEspIS).reduce((acu,key)=>{
        return {...acu,[key]:undefined}
      },{}))
      break;
      case 'pepPublicObj':
        setPepPublicObj({...pepPublicObjIS})
        setFieldsValue(Object.keys(pepPublicObjIS).reduce((acu,key)=>{
          return {...acu,[key]:undefined}
        },{}))
        break;
      case 'pepFamilyObj':
        setPepFamilyObj({...pepFamilyObjIS})
        setFieldsValue(Object.keys(pepFamilyObjIS).reduce((acu,key)=>{
          return {...acu,[key]:undefined}
        },{}))
        break;
      case 'pepRelationObj':
        setPepRelationObj({...pepRelationObjIS})
        setFieldsValue(Object.keys(pepRelationObjIS).reduce((acu,key)=>{
          return {...acu,[key]:undefined}
        },{}))
        break;
      case 'infoCom':
        setInfoCom({...infoComIS})
        setFieldsValue(Object.keys(infoComIS).reduce((acu,key)=>{
          return {...acu,[key]:undefined}
        },{}))
      break;
      default:
        break;
    }
  }

  const basicInfoIS= {
    basicInfoName: { key: "name", val: "", title: "Nombre", maxLength:200, id: "basicInfoName", required:true, type: 'input', readOnly:true, section: 'basicInfo', disabled: true },
    basicInfoTipoDoc: { key:"tipoDocumento", title:"Tipo del documento", maxLength:200, id:'basicInfoTipoDoc',readOnly:true, type: 'select', section: 'basicInfo' },
    basicInfoNroDoc: { key: "nroDocumento", val: "", title: "Nro. de Identificación", maxLength:200, id: "basicInfoNroDoc", required:true, readOnly:true, type: 'input', section: 'basicInfo', disabled: true },
    basicInfoTipoSociedad: { key:"tipoSociedad",title:"Tipo Sociedad", maxLength:200, id:'basicInfoTipoSociedad',readOnly:true, type: 'select', section: 'additionalInfo' },
    basicInfoTipoSociedadOtra: { key:"tipoSociedadOtra",title:"Ingrese Otra", maxLength:200, id:'basicInfoTipoSociedadOtra',readOnly:true, section: 'additionalInfo'},
    basicInfoNombreFantasia: { key:"nombreFantasia",title:"Nombre de Fantasía:", maxLength:200, id:'basicInfoNombreFantasia',readOnly:true, type: 'input', section: 'additionalInfo' },
    basicInfoNationality: { key:"nationality", title:"Nacionalidad", size:"small", mFxLength:200, id:'basicInfoNationality',readOnly:true, type: 'select', section: 'basicInfo' },
    basicInfoPaisConst: { key:"paisConst", title:"País de Constitución", mFxLength:200, id:'basicInfoPaisConst',readOnly:true, type: 'select', section: 'basicInfo' },
    basicInfoPropositoRel: { key:"propositoRel",val: undefined, title:"Propósito de la Relación", maxLength:200, id:'basicInfoPropositoRel',readOnly:true, type:'select', section: 'basicInfo' },
    basicInfoResindenciaPais: {  key:"residenciaPais", title:"País de residencia", maxLength:200, id:'basicInfoResindenciaPais',readOnly:true, type:'select', section: 'basicInfo' },
    basicInfoOcupacion: { key:"oficio", title: "Ocupación u oficio", maxLength:200, id:'basicInfoOcupacion',readOnly:true, type: 'select', section: 'additionalInfo' },
    basicInfoOcupacionOtro: { key:"otroOficio",val: "", title:"Especifique otro oficio" ,maxLength:200, id:'basicInfoOcupacionOtro',readOnly:true, type: 'input', section: 'additionalInfo' },
    basicInfoContactName: { key:"contactName",val: "", title:"Nombre y Apellidos", maxLength:200, id:'basicInfoContactName',readOnly:true, section:'contacto' },
    basicInfoCargo: { key: "infoCargo", val:"",title:"Cargo", maxLength:200, id:'basicInfoCargo', readOnly:true, section:'contacto' },
    basicInfoResindencia: { key:"residencia",val: undefined, title:"¿Tiene residencia permanente en otro país?", maxLength:200, id:'basicInfoResindencia',readOnly:true, type:'select', section: 'additionalInfo' },
    basicInfoPropositoRelOtro: { key:"propositoRelOtro", title:"Describa Otro", maxLength:200, id:'basicInfoPropositoRelOtro',readOnly:true, type:'input' },
    basicInfoMail: { key:"mail",val: "", title:"Correo Electrónico" ,maxLength:200, id:'basicInfoMail',readOnly:true, section:'contacto' },
    basicInfoDir: { key:"direccion",val: "", title: "Dirección" ,maxLength:200, id:'basicInfoDir',readOnly:true, section:'domicilio' },
    basicInfoRegion: { key:"region",val: "", title: "Región", id:"basicInfoRegion", readOnly: true, section:'domicilio', type: 'select'},
    basicInfoComuna: { key:"comuna",val: "", title: "Comuna", id:"basicInfoComuna", readOnly: true, section:'domicilio', type: 'select'},
    basicInfoPais: { key:"pais", title: "País", id:"basicInfoPais", readOnly: true, type: 'select', section:'domicilio' },
    basicInfoCodigoPais: { key:"codigoPais", id:"basicInfoCodigoPais", readOnly: true, section:'domicilio' },
    basicInfoCodigoRegion: { key:"codigoRegion", id:"basicInfoCodigoRegion", readOnly: true, section:'domicilio' },
    basicInfoCodigoComuna: { key:"codigoComuna", id:"basicInfoCodigoComuna", readOnly: true, section:'domicilio' },
    basicInfoTel: { key:"tel",val: "", title: "Teléfono Fijo", id:"basicInfoTel", readOnly: true, required: false, type: 'input', section:'contacto' },
    basicInfoCel: { key:"cel",val: "", title: "Teléfono Celular", id:"basicInfoCel", type: 'input', section:'contacto', required: true },
    basicInfoMtrz: { key:"matriz", title: "Tiene Casa Matriz?", id:"basicInfoMtrz", type: 'select', section: 'additionalInfo' },
    basicInfoMtrzName: { key:"nombreMatriz",val: "", title: "Razón Social", id:"basicInfoMtrzName", readOnly: true, section: 'casaMatriz' },
    basicInfoMtrzDir: { key:"direccionMatriz",val: "", title: "Dirección", id:"basicInfoMtrzDir", readOnly: true, section: 'casaMatriz' },
    basicInfoMtrzPais: { key:"paisMatriz", title: "País", id:"basicInfoMtrzPais", readOnly: true, type: 'select', section: 'casaMatriz' },
    basicInfoMtrzCity: { key:"ciudadMatriz",val: "", title: "Ciudad", id:"basicInfoMtrzCity", readOnly: true, section: 'casaMatriz' },
    basicInfoNomFacturacion: { key:"nombreFacturacion",val: "", title: "Nombre y Apellidos", id:"basicInfoNomFacturacion", section: 'facturacion' },
    basicInfoMailFacturacion: { key:"mailFacturacion",val: "", title: "Correo electrónico", id:"basicInfoMailFacturacion", readOnly: true, section: 'facturacion' },
    basicInfoCelFacturacion: { key:"celFacturacion",val: "", title: "Teléfono Celular", id:"basicInfoCelFacturacion", readOnly: true, required: true, section: 'facturacion' },
    basicInfoTelFacturacion: { key:"telFacturacion",val: "", title: "Teléfono Fijo", id:"basicInfoTelFacturacion",required: false, section: 'facturacion' },
    basicInfoCargoFacturacion: { key:"cargoFacturacion",val: "", title: "Cargo", id:"basicInfoCargoFacturacion", readOnly: true, section: 'facturacion' },
  };
  const [basicInformation, setBasicInformation]=useState(basicInfoIS);


  const infoEcoIS= {
    infoEcoActRecords: { key: "actRecords", val: [], id: "infoEcoActRecords", section:'actividadEconomica' },
    infoEcoActEspRecords: { key: "actEspRecords", val: [], id: "infoEcoActEspRecords", section:'actividadEconomica'},
    infoEcoInitAct: { key: "initDate", val: null, title: "Fecha de Inicio de Actividades", id: "infoEcoInitAct", type:'date', required:true, readOnly:true, section:'actividadEconomica' },
    infoEcoAniosExp: { key:"aniosExp",val: "", title:"Experiencia en el mercado (meses)", maxLength:200, id:'infoEcoAniosExp',readOnly:false, section:'actividadEconomica', disabled: true },
    infoEcoPais: { key:"pais",val: "", title:"País", maxLength:200, id:'infoEcoPais',readOnly:true, type: 'select', section:'actividadEconomica' },
    infoEcoCustomActivities: { key:"customActivities",val: undefined, title:"Actividad comercial principal a desarrollar en el local", maxLength:200, id:'infoEcoCustomActivities',readOnly:true, type: 'select', section:'actividadEconomica' }
  };
  const [infoEco, setInfoEco]=useState(infoEcoIS);
  const prodActIS= {
    prodActDescripcion: { key: "linea", val: "", title: "Actividad Económica según códigos del SII (Indicar las 3 principales)", maxLength:200, id: "prodActDescripcion", required:true, readOnly:true, type: 'select'},
    prodActOtras: { key: "otras", val: "", title: "Ingrese la actividad realizada", maxLength:200, id: "prodActOtras", required:true, type: 'input'},
  };
  const [prodAct, setProdAct]=useState(prodActIS);

  const activityEspIS= {
    activityEspAct: { key: "activity", title: "Actividad", maxLength:200, id: "activityEspAct", required:true , readOnly:true, type: 'select'},
    activityEspPais: { key: "actPais", title: "País", maxLength:200, id: "activityEspPais", required:true, readOnly:true, type: 'select'},
  }
  const [activityEsp, setActivityEsp]=useState(activityEspIS);

  const infoPepIS= {
    infoPepIsPublic: { key: "isPublic", val: undefined, title: "", maxLength:200, id: "infoPepIsPublic", required: true},
    infoPepHasFamily: { key: "hasFamily", val: "", title: "", maxLength:200, id: "infoPepHasFamily", required: true},
    infoPepHasRelation: { key: "hasRelation", val: "", title: "", maxLength:200, id: "infoPepHasRelation", required: true},
    infoPepPublicRecords: { key: "publicRecords", val: [], id: "infoPepPublicRecords"},
    infoPepFamilyRecords: { key: "familyRecords", val: [], id: "infoPepFamilyRecords"},
    infoPepRelationRecords: { key: "relationRecords", val: [], id: "infoPepRelationRecords"},
  };
  const [infoPep, setInfoPep]=useState(infoPepIS);

  const  pepPublicObjIS={
    pepPublicGrupo: { key: "grupo", title: "Grupo de la persona relacionada", maxLength:200, id: "pepPublicGrupo", required: true, type: 'select', typeEntity: ['PJ']},
    pepPublicName: { key: "name", val: "", title: "Nombre Completo", maxLength:200, id: "pepPublicName", required: true, typeEntity: ['PJ']},
    pepPublicTipoDoc: { key: "tipoId", title: "Tipo de documento", maxLength:200, id: "pepPublicTipoDoc", required: true, type: 'select', typeEntity: ['PJ']},
    pepPublicNroId: { key: "nroIdentificacion", val: "", title: "Nro. de identificación", maxLength:200, id: "pepPublicNroId", required: true, type: 'input', typeEntity: ['PJ']},
    pepPublicOrgPublic: { key: "orgPublic", val: "", title: "Organismo Público", maxLength:200, id: "pepPublicOrgPublic", required: true, typeEntity: ['PJ','PN']},
    pepPublicPais: { key: "pais", title: "País", maxLength:200, id: "pepPublicPais", required: true, type: 'select', typeEntity: ['PJ','PN']},
    pepPublicCargo: { key: "cargo", val: "", title: "Cargo del PEP", maxLength:200, id: "pepPublicCargo", required: true, typeEntity: ['PJ','PN']},
    pepPublicFecTermino: { key: "fecTermino", val: null, title: "Fecha de término", maxLength:200, id: "pepPublicFecTermino", required: false, type: 'date', typeEntity: ['PJ','PN']},
  }
  const [pepPublicObj, setPepPublicObj]=useState(pepPublicObjIS);

  const  pepFamilyObjIS={
    pepFamilyGrupo: { key: "grupo", title: "Grupo de la persona relacionada", maxLength:200, id: "pepFamilyGrupo", required: true, type: 'select', typeEntity: ['PJ']},
    pepFamilyOrgPublic: { key: "orgPublic", val: "", title: "Organismo Público", maxLength:200, id: "pepFamilyOrgPublic", required: true, typeEntity: ['PJ','PN']},
    pepFamilyPais: { key: "pais", title: "País", maxLength:200, id: "pepFamilyPais", required: true, type: 'select', typeEntity: ['PJ','PN']},
    pepFamilyParentesco: { key: "parentesco", title: "Tipo de Parentesco", maxLength:200, id: "pepFamilyParentesco", required: true, type: 'select', typeEntity: ['PJ','PN']},
    pepFamilyNamePariente: { key: "nombrePariente", title: "Nombre Completo", maxLength:200, id: "pepFamilyNamePariente", required: true, type: 'input', typeEntity: ['PJ']},
    pepFamilyNroIdPariente: { key: "nroIdPariente", title: "Nro. de identificación", maxLength:200, id: "pepFamilyNroIdPariente", required: true, type: 'input', typeEntity: ['PJ']},
    pepFamilyTipoDocPariente: { key: "tipoDocPariente", title: "Tipo de documento", maxLength:200, id: "pepFamilyTipoDocPariente", required: true, type: 'select', typeEntity: ['PJ']},
    pepFamilyCargo: { key: "cargo", val: "", title: "Cargo del PEP", maxLength:200, id: "pepFamilyCargo", required: true, typeEntity: ['PJ' ,'PN']},
    pepFamilyFecTermino: { key: "fecTermino", val: null, title: "Fecha de término", maxLength:200, id: "pepFamilyFecTermino", required: false, type: 'date', typeEntity: ['PJ','PN']},
    pepFamilyTipoDoc: { key: "tipoDoc", title: "Tipo de documento", maxLength:200, id: "pepFamilyTipoDoc", required: true, type: 'select', typeEntity: ['PN']},
    pepFamilyName: { key: "name", title: "Nombre del Familiar", maxLength:200, id: "pepFamilyName", required: true, type: 'input', typeEntity: ['PN']},
    pepFamilyNroId: { key: "nroId", title: "Nro. de identicación", maxLength:200, id: "pepFamilyNroId", required: true, type: 'input', typeEntity: ['PJ','PN']},
  }
  const [pepFamilyObj, setPepFamilyObj]=useState(pepFamilyObjIS);

  const  pepRelationObjIS={
    pepRelationGrupo: { key: "grupo", title: "Grupo de la persona relacionada", maxLength:200, id: "pepRelationGrupo", required: true, type: 'select', typeEntity: ['PJ']},
    pepRelationName: { key: "name", val: "", title: "Nombre Completo", maxLength:200, id: "pepRelationName", required: true, typeEntity: ['PJ']},
    pepRelationTipoId: { key: "tipoId", title: "Tipo de documento", maxLength:200, id: "pepRelationTipoId", required: true, type: 'select', typeEntity: ['PJ']},
    pepRelationNroId: { key: "nroIdentificacion", val: "", title: "Nro. de identificación", maxLength:200, id: "pepRelationNroId", required: true, typeEntity: ['PJ']},
    pepRelationOrgPublico: { key: "orgPubic", val: "", title: "Organismo Público", maxLength:200, id: "pepRelationOrgPublico", required: true, typeEntity: ['PJ', 'PN']},
    pepRelationPais: { key: "pais", title: "País", maxLength:200, id: "pepRelationPais", required: true, type: 'select', typeEntity: ['PJ','PN']},
    pepRelationEmpresa: { key: "empresa", val: "", title: "Nombre de la Empresa relacionada", maxLength:200, id: "pepRelationEmpresa", required: true, typeEntity: ['PN']},
    pepRelationPepName: { key: "pepName", val: "", title: "Nombre del PEP", maxLength:200, id: "pepRelationPepName", required: true, typeEntity: ['PN']},
    pepRelationTipoDocPep: { key: "tipoDocPep", val: "", title: "Tipo de documento PEP", maxLength:200, id: "pepRelationTipoDocPep", required: true, type: 'select', typeEntity: ['PN']},
    pepRelationNroIdPep: { key: "nroId", val: "", title: "Número de identificación PEP", maxLength:200, id: "pepRelationNroIdPep", required: true, typeEntity: ['PN']},
    pepRelationCargoPep: { key: "cargoPep", val: "", title: "Cargo del PEP", maxLength:200, id: "pepRelationCargoPep", required: true, typeEntity: ['PN']},
    pepRelationFecTermino: { key: "fecTermino", val: null, title: "Fecha de término", maxLength:200, id: "pepRelationFecTermino", required: false, type: 'date', typeEntity: ['PJ','PN']},
    pepRelationNamePac: { key: "namePac", val: "", title: "Nombre Completo", maxLength:200, id: "pepRelationNamePac", required: true, typeEntity: ['PJ']},
    pepRelationTipoDocPac: { key: "tipoDocPac", title: "Tipo de documento", maxLength:200, id: "pepRelationTipoDocPac", required: true, type: 'select', typeEntity: ['PJ']},
    pepRelationNroIdPac: { key: "nroIdPac", val: "", title: "Nro. de documento", maxLength:200, id: "pepRelationNroIdPac", required: true, typeEntity: ['PJ']},
    pepRelationCargoPac: { key: "cargoPac", val: "", title: "Cargo", maxLength:200, id: "pepRelationCargoPac", required: true, typeEntity: ['PJ']},
    pepRelationNameEmp: { key: "nameEmp", val: "", title: "Razón Social", maxLength:200, id: "pepRelationNameEmp", required: true, typeEntity: ['PJ']},
    pepRelationTipoDocEmp: { key: "tipoIdEmp", title: "Tipo de documento", maxLength:200, id: "pepRelationTipoDocEmp", required: true, type: 'select', typeEntity: ['PJ']},
    pepRelationNroIdEmp: { key: "nroIdEmp", val: "", title: "Nro. de documento", maxLength:200, id: "pepRelationNroIdEmp", required: true, typeEntity: ['PJ', 'PN']},
  }
  const [pepRelationObj, setPepRelationObj]=useState(pepRelationObjIS);

  const  infoComIS={
    infoComNombre: { key: "nombreCompliance", val: "", title: "Nombres y Apellidos Oficial de Cumplimiento", maxLength:200, id: "infoComNombre", required: true},
    infoComCorreo: { key: "correo", val: "", title: "Correo electrónico", maxLength:200, id: "infoComCorreo", required: true},
    infoComTel: { key: "telefono", val: "", title: "Teléfono de contacto", maxLength:200, id: "infoComTel", required: true},
    infoComDescription: { key: "descripcion", val: "", title: "Breve descripción del programa de Compliance", maxLength:200, id: "infoComDescription", required:false, type: 'textarea' },
    infoComHasCompliance: { key: "hasCompliance", val: null, title: "", maxLength:200, id: "infoComHasCompliance", required: true},
    infoComHasOfCto: { key: "hasOfCto", val: null, title: "", maxLength:200, id: "infoComHasOfCto", required: true},
  }
  const [infoCom, setInfoCom]=useState(infoComIS);

  const attchIs={
    attchFileName: {key: "fileName", val: "", id: "attchFileName", maxLength: 200, required: true},
  }
  const [attch, setAttch] = useState(attchIs)
 // END INIT OBJ

  const validateLengthFieldWithInnerLength = (section)=>{
    return {
      max: section.maxLength,
      message: "Debe tener un máximo de "+ section.maxLength  + " caracteres"
    }
  }

  const prodActOptions = [
    {name: "Accesorios", val:"Accesorios"},
    {name: "Alimentos y bebidas", val:"Alimentos y bebidas"},
    {name: "Áreas comunes", val:"Áreas comunes"},
    {name: "Automóviles", val:"Automóviles"},
    {name: "Belleza y cuidado personal", val:"Belleza y cuidado personal"},
    {name: "Bodegas", val:"Bodegas"},
    {name: "Cajas de compensación", val:"Cajas de compensación"},
    {name: "Calzado y vestuario", val:"Calzado y vestuario"},
    {name: "Casinos", val:"Casinos"},
    {name: "Cine", val:"Cine"},
    {name: "Clínicas y centros de salud", val:"Clínicas y centros de salud"},
    {name: "Computación y electrónica", val:"Computación y electrónica"},
    {name: "Deporte y outdoor", val:"Deporte y outdoor"},
    {name: "Educación", val:"Educación"},
    {name: "Entretenimiento familiar", val:"Entretenimiento familiar"},
    {name: "Entretenimiento adulto (discotecas y pubs)", val:"Entretenimiento adulto (discotecas y pubs)"},
    {name: "Farmacia", val:"Farmacia"},
    {name: "Food court", val:"Food court"},
    {name: "Gimnasios", val:"Gimnasios"},
    {name: "Hipódromos", val:"Hipódromos"},
    {name: "Hogar y decoración", val:"Hogar y decoración"},
    {name: "Hotel", val:"Hotel"},
    {name: "Inmobiliarias y constructoras", val:"Inmobiliarias y constructoras"},
    {name: "Joyería y relojería", val:"Joyería y relojería"},
    {name: "Juegos de azar / casa de cambio", val:"Juegos de azar / casa de cambio"},
    {name: "Maletería", val:"Maletería"},
    {name: "Mejoramiento del hogar", val:"Mejoramiento del hogar"},
    {name: "Oficinas", val:"Oficinas"},
    {name: "Organizaciones de caridad benéficas o sin ánimos de lucro", val:"Organizaciones de caridad benéficas o sin ánimos de lucro"},
    {name: "Otros comidas", val:"Otros comidas"},
    {name: "Otros retail", val:"Otros retail"},
    {name: "Publicidad", val:"Publicidad"},
    {name: "Restaurantes", val:"Restaurantes"},
    {name: "Servicios", val:"Servicios"},
    {name: "Servicios financieros y bancos", val:"Servicios financieros y bancos"},
    {name: "Supermercado", val:"Supermercado"},
    {name: "Telecomunicaciones", val:"Telecomunicaciones"},
    {name: "Terminal de buses", val:"Terminal de buses"},
    {name: "Tienda departamental", val:"Tienda departamental"},
    {name: "Usuarios de zonas francas", val:"Usuarios de zonas francas"}
  ]

  const handleReadOnly = (field,readOnly,sectionId=null)=>{
    if (sectionId===null){
      const key = Object.entries(basicInformation).filter(([key,value])=>{
        return value.id === field
      })[0][0]
      setBasicInformation({...basicInformation,[key]:{...basicInformation[key],readOnly:readOnly}})
    }else{
      settingStateObj(sectionId,readOnly)
    }
  }

  const settingStateObj=(sectionId,readOnly)=>{
    const [stateObject,stateObjectSetter] = customState(sectionId)
    stateObjectSetter({...stateObject,[sectionId]:{...stateObject[sectionId],readOnly:readOnly}})
  }

  const customState=(sectionId)=>{
    if (sectionId.startsWith('basicInfo')) {
      return([basicInformation,setBasicInformation])
    }else if (sectionId.startsWith('infoEco')) {
      return([infoEco,setInfoEco])
    }else if (sectionId.startsWith('infoPep')) {
      return([infoPep,setInfoPep])
    }else if (sectionId.startsWith('prodAct')) {
      return([prodAct,setProdAct])
    }else if (sectionId.startsWith('activityEsp')) {
      return([activityEsp,setActivityEsp])
    }else if (sectionId.startsWith('pepPublic')) {
      return([pepPublicObj,setPepPublicObj])
    }else if (sectionId.startsWith('pepFamily')) {
      return([pepFamilyObj,setPepFamilyObj])
    }else if (sectionId.startsWith('pepRelation')) {
      return([pepRelationObj,setPepRelationObj])
    }else if (sectionId.startsWith('infoCom')) {
      return([infoCom,setInfoCom])
    }else if (sectionId.startsWith('attch')){
      return ([attch, setAttch])
    }
  }

  const entriesToObj = (obj) => {
    const objAttr = {}
    Object.entries(obj).map(([key, value]) => {
      if(value.section) {
        if(!objAttr[value.section]) objAttr[value.section] = {}
        objAttr[value.section][value.key] = value.val
      }else {
        objAttr[value.key] = value.val
      }
    })
    return objAttr
  }

  const handlerOnChangeObjAttr = async (sectionId, value, formObj) => {
    handleOnChangeField(sectionId, value)
    const [stateObject,stateObjectSetter] = customState(sectionId)
    stateObject[sectionId].val = value

    const basicInfo = entriesToObj(basicInformation)
    const iEco = entriesToObj(infoEco)
    const iPep = entriesToObj(infoPep)
    const comR = entriesToObj(infoCom)

    if(!formObj) formObj = apiForm
    const json = { basicInformation: basicInfo, infoEco: iEco, infoPep: iPep, infoCom: comR }
    let formToUpdate = { ...formObj, json };
    let ret = await saveFormOnbPromiseLocal(formToUpdate);
    if(!ret.success) {
    //  setFieldsValue({[field]: ret.form[field]})
    }
  }

  const handleOnChangeField = (sectionId, value) => {
    const [stateObject, stateObjectSetter] = customState(sectionId)
    stateObjectSetter({ ...stateObject, [sectionId]: { ...stateObject[sectionId], val: value }});
  };

  const objToVariable = (variable, obj) => {
    let varAttr = { ...variable }
    for(var key in obj) {
      for(var prop in variable) {
        if(variable[prop].key === key && varAttr[prop]) {
          varAttr[prop].val = obj[key]
        }else if(typeof obj[key] === "object" && variable[prop].section === key) {
          for(var attr in obj[key]) {
            if(variable[prop].key === attr && varAttr[prop]) {
              varAttr[prop].val = obj[key][attr]
            }
          }
        }
      }
    }
    return varAttr
  }


  useEffect(() => {
    if (match.params.view === "pdf") {
      setColLogo(5);
      setFormat("pdf");
    }
    setIsloading(true);
    getCountriesCodePromise().then((response) => {
      setPaises(response)
    })
    getFormPromise(match.params.id).then((response) => {
      if (
          response.data !== null &&
          response.data !== "" &&
          response.data.status !== undefined
      ) {
        if (response.data.recipient.record.type === 'Entity'){
          response.data["typeSol"] = 'PJ'
        }else{
          response.data["typeSol"] = 'PN'
        }
        setApiForm(response.data);
        initVariablesFromObject(response.data, response.data.recipient.record)
        setUser(response.data.recipient.request.createUser);
        setClientName(response.data.recipient.request.createUser.cliente.name);
        getParamsPromise(
          response.data.recipient.request.createUser.cliente.id
        ).then((response) => {
          //setParams(response.data);
        });

        if(response.data.status === "SENT") {
          setDate(moment(response.data.receiveDate).format("DD-MM-YYYY"));
        }else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
    });

    getSIIActivitiesPromise().then((response) => {
      setSIIActivities(response)
    })
  }, []);

  const initVariablesFromObject = (obj) => {
    if(obj.json) {
      if(obj.json.basicInformation) {
        if (obj.json.basicInformation.domicilio && obj.json.basicInformation.domicilio.codigoPais) {
          getRegionComunaPromise(obj.json.basicInformation.domicilio.codigoPais).then((response) => {
            setRegionComuna(response)
            if(obj.json.basicInformation.domicilio.region) {
              let comunas = response.filter(e => e.name === obj.json.basicInformation.domicilio.region)
              if (comunas && comunas.length > 0){
                setComunas(comunas[0].comunas)
              }else{
                setComunas([])
              }
            }
          })
        }
        let basicInfo = objToVariable(basicInformation, obj.json.basicInformation)
        setBasicInformation(basicInfo)
      }
      if(obj.json.infoEco) {
        let iEco = objToVariable(infoEco, obj.json.infoEco)
        setInfoEco(iEco)
      }
      if(obj.json.infoPep) {
        let iPep = objToVariable(infoPep, obj.json.infoPep)
        setInfoPep(iPep)
      }
      if(obj.json.infoCom) {
        let comR = objToVariable(infoCom, obj.json.infoCom)
        setInfoCom(comR)
      }
    }
  }

  const saveFormOnbPromiseLocal = async (form) => {
    let response = await saveFormOnbPromise(form);
    if(response.code !== 'OK' && response.form !== undefined && response.form !== null) {
      setApiForm(response.form)
      initVariablesFromObject(response.form)

      notification["error"]({
        message: t("messages.aml.notifications.anErrorOcurred"),
      });

      return { success: false, form: response.form}
    }else {
      setApiForm(form)
      return { success: true }
    }
  }

  const handleOnChangeRadioButton = async (field, value) => {
    let formToUpdate = { ...apiForm, [field]: value };
    let ret = await saveFormOnbPromiseLocal(formToUpdate);
    if(!ret.success) {
      setFieldsValue({[field]: ret.form[field]})
    }else{
      if (field === 'typeSol') {
        if (value === 'PJ'){
          handlerOnChangeObjAttr('basicInfoTipoDoc', 'Codigo de Identificación Fiscal', formToUpdate)
        }else{
          handlerOnChangeObjAttr('basicInfoTipoDoc', undefined, formToUpdate)
        }
      }
    }
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  const sendDocument = (requestId = "") => {
    sendFormPromise(match.params.id, requestId).then((response) => {
      if (response.code === "OK") {
        let formSend = { ...apiForm, status: "SENT" };
        setApiForm(formSend);
      } else {
        let errores = sentErrors+1
        setSentErrors(errores)

        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: errores === 1 ? 'Error Interno: Actualice y reintente.' : 'Comuníquese con soporte.aml@gesintel.cl'
        });
        setApiForm(response.form);
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldsValue({[attch.attchFileName.id]: undefined})
    setIsValidate(true);
    setValidarRegistros(true);

    validateFields(Object.keys(basicInformation));
    validateFields([infoPep.infoPepHasFamily.id, infoPep.infoPepHasRelation.id, infoPep.infoPepIsPublic.id, infoCom.infoComHasCompliance.id])

    if (infoCom.infoComHasCompliance.val){
      validateFields([infoCom.infoComHasOfCto.id])
      if (infoCom.infoComHasOfCto.val){
        validateFields(Object.keys(infoCom))
      }
    }

    if(apiForm.typeSol === "PJ") {
      if (infoEco.infoEcoActRecords.val.length === 0) {
        validateFields(Object.keys(prodAct));
        registersStop = { ...registersStop, prodAct: true };
      }
    }

    if (tmpFilesList.length === 0) {
      validateFields(Object.keys(attch));
      registersStop = { ...registersStop, attch: true };
    }

    
    if(infoPep.infoPepHasRelation.val && infoPep.infoPepRelationRecords.val.length === 0){
      validateFields(Object.keys(pepRelationObj))
      registersStop = { ...registersStop, infoPepRelationRecords: true };
    }

    if(infoPep.infoPepHasFamily.val && infoPep.infoPepFamilyRecords.val.length === 0){
      validateFields(Object.keys(pepFamilyObj))
      registersStop = { ...registersStop, infoPepFamilyRecords: true };
    }

    if(infoPep.infoPepIsPublic.val && infoPep.infoPepPublicRecords.val.length === 0){
      validateFields(Object.keys(pepPublicObj))
      registersStop = { ...registersStop, infoPepPublicRecords: true };
    }

    validateFields(Object.keys(infoEco));
    if (
      hasErrors(getFieldsError()) ||
      Object.values(registersStop).find((value) => value === true) !== undefined
    ) {
      notification["warning"]({
        message: t("messages.aml.missingRequiredField"),
      });
    } else {
      setOpenSigner(true)

      if(tmpFilesList !== null && tmpFilesList.length > 0){
        const formData = new FormData()
        tmpFilesList.forEach((file, index) => {
          formData.append('file', file)
          formData.append('fileName', fileNames[index])
        })
        formData.append('formId', apiForm.id);

        await sendFileFormPromise(formData);
      }

      if(hasSign) {
        let record = apiForm.recipient.record
        let _emails = ["no@email.com"];
        if(record.email != null) {
          _emails = record.email.split(",");
        }
        var _email = _emails[0].trim();
        let signParams = await signCDIFormPromise(apiForm.id, _email)
        if(signParams.embedUrl == "signed") {
          notification["warning"]({
            message: "Ya esta firmado"
          })
          setSigned(true)
          sendDocument(signParams.requestId)
        }else {
          if(signParams.clientId != "") {
            const client = new HelloSign({
              clientId: signParams.clientId
            });
            let testMode = !apiConfig.url.startsWith('https://api.amlupdate')
            client.open(signParams.embedUrl, {
              testMode
            });
            client.on('sign', () => {
              setSigned(true)
              sendDocument(signParams.requestId)
            });
          }else {
            notification["error"]({
              message: "Error al iniciar servicio de Firma Electronica",
              description: signParams.message
            })
          }
        }
      }else {
        sendDocument()
      }
      setOpenSigner(false)
    }
  };

  const renderFormItem = (formItem) => {
    return (
        <Col
          className={formItem.colClassName}
          span={formItem.wrapperCols}
          offset={formItem.offset}
        >
          <Form.Item
            className={formItem.itemClassName}
            label={formItem.label}
            labelCol={formItem.labelCol > 0 ? { span: formItem.labelCol } : {}}
            wrapperCol={
              formItem.labelCol > 0 ? { span: formItem.wrapperCol } : {}
            }
          >
            {getFieldDecorator(formItem.name, {
              rules: formItem.rules,
              initialValue: formItem.initialValue,
              validateTrigger: "onChange",
            })(formItem.item)}
          </Form.Item>
        </Col>
    );
  };

  const errorMsgSection3 = {
    pepPublicObj: "Campos requeridos en Pregunta 1, Sección III.",
    pepFamilyObj: "Campos requeridos en Pregunta 2, Sección III.",
    pepRelationObj: "Campos requeridos en Pregunta 3, Sección III."
  }

  const renderFormItemTable = ({ section, cols=format === 'html' ? 6 : 8, offset, labelCol=0, wrapperCol=0, options=[], validator=null, customRule=null, handlerOnChangeObj=handleOnChangeField, customTitle, colsOption }) => {
    const type = section.type ? section.type : 'input'
    const required = "required" in section ? section.required : true
    const disabled = "disabled" in section ? section.disabled : false
    const initialValue = section.val && section.val !== '' && format === 'html' && type === 'date' ? moment(section.val, "DD/MM/YYYY") : section.val
    return renderFormItem({
      label: customTitle ? customTitle : section.title,
      name: section.id,
      initialValue: initialValue,
      colClassName: "topLabel",
      labelCol: labelCol,
      wrapperCol: wrapperCol,
      rules:
      [
        { required: required, message: t( "messages.aml.requestedField")},
        ...validator ? [{ validator: validator }]:[],
        ...type==='input' ? [validateLengthFieldWithInnerLength(section)]:[],
        ...customRule ? [customRule]:[]
      ],
      wrapperCols: cols,
      offset: offset,
      item: (
        format === 'html' || type === 'check' ?
          type === 'input' ?
            <Input
              placeholder="Ingrese texto"
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
              readOnly = {section.readOnly}
              onChange={(e) => handlerOnChangeObj( section.id, e.target.value ) }
              disabled={disabled}
            />
          : type === 'number' ?
            <InputNumber
              placeholder="Ingrese un número"
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
              min= {section.min}
              max={section.max}
              formatter = { section.isCurrency ? (value => `$ ${value}`)  : section.isPercentage ? (value => `${value}%`) : null}
              readOnly = {section.readOnly}
              onChange={(value) => {
              handlerOnChangeObj( section.id, value )
              }}
              disabled={disabled}
            />
          : type === 'textarea' ?
            <Input.TextArea
              placeholder="Ingrese texto"
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
              readOnly = {section.readOnly}
              onChange={(e) => handlerOnChangeObj( section.id, e.target.value ) }
              disabled={disabled}
              style = {{width: '100%'}}
            />
          : type === 'select' ?
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={section.multiple ? "Seleccione una o más opciones" : "Seleccione una opción"}
              onChange={(value, codigo) => handlerOnChangeObj(section.id, value, null, codigo)}
              disabled={disabled}
              style={{ width: '100%' }}
              mode = {section.multiple ? 'multiple' : ''}
            >
              { options.map((option) => (<Select.Option value={option.val} key={option.key} code={option.code}>{ option.text }</Select.Option>)) }
            </Select>
          : type === 'date' ?
            <DatePicker
              onFocus= {(e)=>handleReadOnly(e.currentTarget.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.currentTarget.id,true,section.id)}
              readOnly = {section.readOnly}
              format={"DD/MM/YYYY"}
              placeholder={"Ingrese la fecha"}
              style = {{width: '100%'}}
              disabled={disabled}
              mode={type}
              onChange={(momentObj, str) => {
                handlerOnChangeObj( section.id, momentObj ? moment(momentObj).format( "DD/MM/YYYY" ) : null )
              }}
            />
          : type === 'check' ?
            <Checkbox.Group
              style={{width: '100%'}}
              className={section.title && section.title !== '' ? 'has-title' : ''}
              onChange={(value) => handlerOnChangeObj(section.id, value) }
              >
                <hr/>
              {options.map((option) => (
                <Col span={colsOption ? colsOption : section.colsOption}>
                  <Checkbox value={option.val}>
                    { option.text }
                  </Checkbox>
                </Col>
              ))}
            </Checkbox.Group>
          :<Input/>
        :
          <Input/>
      ),
    })
  }

  const renderFormItemObj = (params) => {
    return renderFormItemTable({...params, handlerOnChangeObj: handlerOnChangeObjAttr})
  }

  const handleOnAddAttrTable = (attr, attrObj, attrTable) => {
    setIsValidate(true);
    const [stateObject,stateObjectSetter] = customState(attr)
    validateFields(Object.keys(stateObject)).then((error, values) => {
      const tableOk = Object.keys(stateObject).reduce(
        (acc, e) => {
          return {
            ...acc,
            [stateObject[e].key]: stateObject[e].val,
          };
        },
        {}
      );
      const [stateObject1,stateObject1Setter] = customState(attrObj)
      let obj = { ...stateObject1 }
      let attr2 = Object.entries(obj).filter(([k,v]) => {
        return v.key === attrTable
      })[0]
      obj[attr2[1].id].val.push(tableOk)
      stateObject1Setter(obj)

      let formToUpdate = { ...apiForm }
      saveFormOnbPromiseLocal(formToUpdate).then(ret => {
        if(ret.success){
          handleOnClear(attr)
        }
      });

      registersStop[attr] = false;
    }).catch(error => notification.error({message: errorMsgSection3[attr]}));
  };

  // attrObj (obj con los campos)
  // attTable ()
  // index
  const handleDeleteAttrTable = (attrObj, attrTable, index) => {
    return () => {
      const [stateObject,stateObjectSetter] = customState(attrObj)
      let obj = { ...stateObject }
      let attr2 = Object.entries(obj).filter(([k,v]) => {
        return v.key === attrTable
      })[0]

      let xx = [...obj[attr2[1].id].val];
      xx.splice(index, 1);
      obj[attr2[1].id].val = xx
      stateObjectSetter(obj)

      let formToUpdate = { ...apiForm };
      formToUpdate.json[attrObj][attrTable] = xx
      saveFormOnbPromiseLocal(formToUpdate);
    };
  };

  const prodActColums = [
    {
      title: "Actividad Económica",
      dataIndex: "linea",
      width: "70%",
      render: (linea) => {
        const arr = linea.slice(7)
        return arr
      }
    },
    {
      title: "Código de actividad",
      dataIndex: "linea",
      width: "22%",
      render: (linea) => {
        const arr = linea.substr(0,6)
        return arr
      }
    },
    format === "html" && !signed ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            <a onClick={handleDeleteAttrTable('infoEco', 'actRecords', index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const getPepPublicColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PN') {
      columns.push(
        {
          title: "Organismo Público",
          dataIndex: "orgPublic",
          width:"23%"
        },
        {
          title: "Cargo",
          dataIndex: "cargo",
          width:"23%"
        },
        {
          title: "País",
          dataIndex: "pais",
          width:"23%"
        },
        {
          title: "Fecha de término",
          dataIndex: "fecTermino",
          width:"23%"
        },
      )
    }else{
    columns.push(
    {
      title: "Grupo de la persona relacionada",
      dataIndex: "grupo",
      width:"18.4%"
    },
    {
      title: "Nombre Completo",
      dataIndex: "name",
      width:"18.4%"
    },
    {
      title: "Nro. identificación",
      dataIndex: "nroIdentificacion",
      width:"18.4%"
    },
    {
      title: "Organismo Público",
      dataIndex: "orgPublic",
      width:"18.4%"
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
      width:"18.4%"
    },
    )}

    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable('infoPep', 'publicRecords', index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }

  const getPepFamilyColumns = () => {
    var columns = []
      if(apiForm.typeSol === 'PJ') {
        columns.push(
          {
            title: "Grupo de la persona relacionada",
            dataIndex: "grupo",
          },
          {
            title: "Nombre Completo Relacionado",
            dataIndex: "name",
          },
          {
            title: "Nro. identificación Relacionado",
            dataIndex: "nroId",
          },
          {
            title: "Nombre Completo Pariente",
            dataIndex: "nombrePariente",
          },
          {
            title: "Nro. identificación Pariente",
            dataIndex: "nroIdPariente"
          },
        )
      }else{
        columns.push(
          {
            title: "Nombre del Familiar",
            dataIndex: "name",
          },
          {
            title: "Nro. identificación",
            dataIndex: "nroId",
          },
          {
            title: "Cargo",
            dataIndex: "cargo"
          },
          {
            title: "Organismo público",
            dataIndex: "orgPublic",
          },
    )}

    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable('infoPep', 'familyRecords', index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }

  const getPepRelationColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PN') {
      columns.push(
        {
          title: "Nombre de la Empresa relacionada",
          dataIndex: "empresa",
        },
        {
          title: "Nro. de documento",
          dataIndex: "nroIdEmp",
        },
        {
          title: "Nombre del PEP",
          dataIndex: "pepName",
        },
        {
          title: "Cargo del PEP",
          dataIndex: "cargoPep",
        },
      )
    }else{
      columns.push(
        {
          title: "Grupo de la persona relacionada",
          dataIndex: "grupo"
        },
        {
          title: "Nombre Completo Relacionado",
          dataIndex: "name",
        },
        {
          title: "Nro. identificación Relacionado",
          dataIndex: "nroIdentificacion",
        },
        {
          title: "Nombre Completo Pacto",
          dataIndex: "namePac",
        },
        {
          title: "Nro. identificación Pacto",
          dataIndex: "nroIdPac",
        },
        {
          title: "Razón social",
          dataIndex: "nameEmp",
        },
      )
    }

    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable('infoPep', 'relationRecords', index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }

  const rutValidator = (rule, value, cb) => {
    if (value && !validateRutHelper(value)) {
      cb("Documento no válido");
    }
    cb();
  };

  const numTelValidator = (rule, value, callback) => {
    const regex = /^[0-9]*$/;
    if (value && value.length !== 9) {
      callback("Número debe tener 9 dígitos");
    }else if (value && !(regex.test(value))){
      callback("Debe contenter sólo números");
    }else{
      callback();
    }
  };

  const emailValidator = (rule, value, callback) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value && regex.test(value) === false){
      callback("Debe ingresar un correo electrónico válido");
    }else{
      callback();
    }
  }

  const docValidator = (tipoDoc,company=false) => {
    if (tipoDoc === "Chile-Rut")
      if (company === false)
        return rutValidator
      else
        return rutValidatorCompany;
    else return null;
  };

  const rutValidatorCompany = (rule, value, cb) => {
    if (value && !validateCompanyRutHelper(value)) {
      cb("Documento no válido");
    }
    cb();
  };

  return (
    <FormLayout view={match.params.view} >
      <div style={{ position: "relative" }}>
        {isLoading ? (
          <div className="form-header">
            <Row>
              <Col xs={9}></Col>
              <Col xs={6}>
                <div className="form-wrapper" style={{ textAlign: "center" }}>
                  <Spin style={{ fontColor: "#fff" }} size={"large"} />
                </div>
              </Col>
              <Col xs={9}></Col>
            </Row>
          </div>
        ) : (
          <>
            {apiForm !== null ? (
              <div
                className={
                  "form-content-kyc " + format + " " +(!isValidate ? " form-validate-messages" : "")
                }
              >
                <Form onSubmit={handleSubmit} className="form-form">
                  <Row
                    className="title-logo"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col
                      xs={24 - colLogo}
                      sm={24 - colLogo}
                      md={24 - colLogo}
                      lg={24 - colLogo}
                      xl={24 - colLogo}
                    >
                      <h3> {apiForm.typeSol === 'PN' ? "FORMULARIO ONBOARDING - PERSONA NATURAL":"FORMULARIO ONBOARDING - PERSONA JURÍDICA"}</h3>
                    </Col>
                    <Col
                      className="logo-col"
                      xs={colLogo}
                      sm={colLogo}
                      md={colLogo}
                      lg={colLogo}
                      xl={colLogo}
                    >
                      <Logo currentUser={{ userId: user.id, subclienteId: '0' }} isForm={true} />
                    </Col>
                  </Row>
                  <Row
                    className="date"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col span={2} style={{ textAlign: "left" }}>
                      Fecha:
                    </Col>
                    <Col span={4}>
                      <div
                        className="formDate"
                        style={{
                          marginLeft: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {date}
                      </div>
                    </Col>
                    <Col span={13} style={{ textAlign: "right" }}>
                      Folio:
                    </Col>
                    <Col span={5}>
                      <div
                        className="formDate"
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "8px",
                        }}
                      >
                        {apiForm.folio !== null ? apiForm.folio : ""}
                      </div>
                    </Col>
                    <Col span={19} style={{ textAlign: "right" }}>
                      Proceso:
                    </Col>
                    <Col span={5}>
                      <div
                        className="formDate"
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "8px",
                        }}
                      >
                        {apiForm.proceso === 'CREATE' ? 'Creación' : 'Actualización'}
                      </div>
                    </Col>
                  </Row>
                  {apiForm.status === "SENT" &&
                  (format === "html") ? (
                    <>
                      <br />
                      <h3 style={{ textAlign: "center" }}>
                        Estimado(a){/* {apiForm.recipient.record.nombre} */}
                        {apiForm.recipient.record.name}, le informamos que su declaración fue correctamente
                        completada, agradecemos su tiempo y disposición.
                        <br />
                        Hemos enviado una copia de la declaración realizada al
                        correo electrónico registrado:<br/><br/>
                        {apiForm.recipient.record.email}
                      </h3>
                    </>
                  ) : (
                    <>
                      <Col className="summary" style={{textAlign: "justify", marginRight: 10}}>
                        En cumplimiento con lo establecido en el Sistema de
                        Detección y Prevención de Lavado de Activos y Otros
                        Delitos Relacionados de {clientName}, que tiene
                        como objetivo evitar la comisión de delitos de corrupción,
                        lavado de activos, entre otros. Solicitamos
                        su participación para completar la siguiente información.
                      </Col>
                    { apiForm.typeSol !== undefined &&
                    <>
                      <Row
                        className="subheader"
                        style={{
                          marginTop: "0px",
                        }}
                      >
                        <Col xl={24}>
                        I. INFORMACIÓN GENERAL{" "}
                        </Col>
                      </Row>
                      <Row className="content">
                      <Row className="inner-row" gutter={[16, 8]}>
                          <Col className="subsection-title" xs={24}>
                            Identificación
                            <hr/>
                          </Col>
                          {renderFormItemObj({
                            section: basicInformation.basicInfoName,
                            customTitle: apiForm.typeSol === 'PN' ? "Nombre" : "Razón Social"
                          })}

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoTipoDoc,
                            options: [
                              { val: "Rut", text: "Rut" },
                            ]
                          })}

                          {apiForm.typeSol === 'PN' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoTipoDoc,
                            options: [
                              { val: "Rut", text: "Rut" },
                              { val: "Pasaporte", text: "Pasaporte" },
                            ]
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoNroDoc,
                          })}

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                              section: basicInformation.basicInfoTipoSociedad,
                              options: [
                                { val: "Sociedades Colectivas Civiles", text: "Sociedades Colectivas Civiles" },
                                { val: "Empresa individual de responsabilidad limitada ", text: "Empresa individual de responsabilidad limitada" },
                                { val: "Sociedad Anónima abierta", text: "Sociedad Anónima abierta" },
                                { val: "Sociedad Anónima Cerrada", text: "Sociedad Anónima Cerrada" },
                                { val: "Sociedad anónima con garantía recíproca", text: "Sociedad anónima con garantía recíproca" },
                                { val: "Sociedad anónima deportiva profesional", text: "Sociedad anónima deportiva profesional" },
                                { val: "Sociedad Colectiva Comercial", text: "Sociedad Colectiva Comercial" },
                                { val: "Sociedad de Responsabilidad Limitada", text: "Sociedad de Responsabilidad Limitada" },
                                { val: "Sociedad En Comandita por acciones", text: "Sociedad En Comandita por acciones" },
                                { val: "Sociedad en comandita simple", text: "Sociedad en comandita simple" },
                                { val: "Sociedad por Acciones (SpA)", text: "Sociedad por Acciones (SpA)" },
                                { val: "Sociedades en Comanditas Civiles", text: "Sociedades en Comanditas Civiles" },
                                { val: "Otra", text: "Otra" }
                              ]
                            })
                          }

                          {apiForm.typeSol === 'PJ' && basicInformation.basicInfoTipoSociedad.val === 'Otra' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoTipoSociedadOtra,
                          })}

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                              section: basicInformation.basicInfoNombreFantasia,
                            })
                          }
                          {renderFormItemObj({
                            section: basicInformation.basicInfoPropositoRel,
                            options: [
                              { val: "Contrato de arriendo", text: "Contrato de arriendo" },
                              { val: "Comodato", text: "Comodato" },
                              { val: "Otro", text: "Otro"}
                            ],
                          })}

                          {basicInformation.basicInfoPropositoRel.val === 'Otro' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoPropositoRelOtro,
                          })}

                          {renderFormItemObj({
                              section: apiForm.typeSol === 'PN' ? basicInformation.basicInfoNationality : basicInformation.basicInfoPaisConst,
                              options:
                                paises.map(item => {
                                  return {val: item.country, text: item.country}
                              })
                            })
                          }

                          {apiForm.typeSol === 'PN' &&
                            renderFormItemObj({
                              section: basicInformation.basicInfoOcupacion,
                              options:[
                                { val: "Empleado dependiente", text: "Empleado dependiente" },
                                { val: "Independiente / Empresario", text: "Independiente / Empresario" },
                                { val: "Otro", text: "Otro" },
                              ]
                            })
                          }

                          {basicInformation.basicInfoOcupacion.val === 'Otro' && apiForm.typeSol === 'PN' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoOcupacionOtro,
                          })
                          }

                          {/* {apiForm.typeSol === 'PN' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoResindencia,
                            options: [
                              { val: "Si", text: "Si" },
                              { val: "No", text: "No" },
                            ]
                          })
                          } */}

                          {/* {basicInformation.basicInfoResindencia.val === 'Si' && apiForm.typeSol === 'PN' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoResindenciaPais,
                            options:
                              paises.map(item => {
                                return {val: item.country, text: item.country}
                              })
                            })
                          } */}

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoMtrz,
                            options: [
                              {val:"SI",text:"Si"},
                              {val:"NO",text:"No"},
                            ]
                          })
                          }
                        {basicInformation.basicInfoMtrz.val === "SI" && apiForm.typeSol === 'PJ' && (
                        <>
                          <Col className="subsection-title" span= {24}>
                            Información Casa Matriz
                            <hr/>
                          </Col>
                            {renderFormItemObj({
                                section: basicInformation.basicInfoMtrzName,
                              })
                            }

                            {renderFormItemObj({
                                section: basicInformation.basicInfoMtrzPais,
                                options:
                                paises.map(item => {
                                return {val: item.country, text: item.country}
                              })
                              })
                            }

                            {renderFormItemObj({
                                section: basicInformation.basicInfoMtrzCity,
                              })
                            }

                            {renderFormItemObj({
                                section: basicInformation.basicInfoMtrzDir,
                              })
                            }
                        </>
                        )}
                          <Col className="subsection-title" span= {24}>
                            Domicilio
                            <hr/>
                          </Col>

                          {/* FIELD PAIS */}
                          {renderFormItemTable({
                            section: basicInformation.basicInfoPais,
                            options: paises.map(item => {
                              return {val: item.country, text: item.country, code: item.iso2}
                            }),
                            handlerOnChangeObj: async (id, value, obj, item) => {
                                await handlerOnChangeObjAttr(id, value)
                                if (item && item.props && item.props.code) {
                                  getRegionComunaPromise(item.props.code).then((response) => {
                                    setRegionComuna(response)
                                  })
                                  await handlerOnChangeObjAttr(basicInformation.basicInfoCodigoPais.id, item.props.code)
                                }else{
                                  setRegionComuna([])
                                  await handlerOnChangeObjAttr(basicInformation.basicInfoCodigoPais.id, null)
                                }
                                setComunas([])
                                setFieldsValue({[basicInformation.basicInfoComuna.id]: null})
                                setFieldsValue({[basicInformation.basicInfoRegion.id]: null})

                                await handlerOnChangeObjAttr(basicInformation.basicInfoComuna.id, null)
                                await handlerOnChangeObjAttr(basicInformation.basicInfoRegion.id, null)

                                await handlerOnChangeObjAttr(basicInformation.basicInfoCodigoRegion.id, null)
                                await handlerOnChangeObjAttr(basicInformation.basicInfoCodigoComuna.id, null)
                            }
                          })}
                          {/* FIELD REGIÓN */}
                          {renderFormItemTable({
                            section: regionComuna.length > 0 ? basicInformation.basicInfoRegion : {...basicInformation.basicInfoRegion, type: 'input', required: false} ,
                            options: regionComuna.map((item) => {
                              return {val: item.name, text: item.name, code: item.code}
                            }),
                            handlerOnChangeObj: async (id, value, obj, item) => {
                                await handlerOnChangeObjAttr(id, value)
                                if (item && item.props && item.props.code) {
                                  let comunas = regionComuna.filter(e => e.code === item.props.code)
                                  if (comunas && comunas.length > 0){
                                    setComunas(comunas[0].comunas)
                                  }else{
                                    setComunas([])
                                  }
                                  await handlerOnChangeObjAttr(basicInformation.basicInfoCodigoRegion.id, item.props.code)
                                }else {
                                  setComunas([])
                                  await handlerOnChangeObjAttr(basicInformation.basicInfoCodigoRegion.id, null)
                                }
                                setFieldsValue({[basicInformation.basicInfoComuna.id]: null})

                                await handlerOnChangeObjAttr(basicInformation.basicInfoComuna.id, null)
                                handlerOnChangeObjAttr(basicInformation.basicInfoCodigoComuna.id, null)
                            }
                          })}
                          {/* FIELD COMUNA */}
                          {renderFormItemTable({
                            section: comunas.length > 0 ? basicInformation.basicInfoComuna : {...basicInformation.basicInfoComuna, type: 'input', required: false} ,
                            options: comunas.map((item) => {
                              return {val: item.name, text: item.name, code: item.code}
                            }),
                            handlerOnChangeObj: async (id, value, obj, item) => {
                              await handlerOnChangeObjAttr(id, value)
                              if (item && item.props && item.props.code) {
                                handlerOnChangeObjAttr(basicInformation.basicInfoCodigoComuna.id, item.props.code)
                              }else {
                                handlerOnChangeObjAttr(basicInformation.basicInfoCodigoComuna.id, null)
                              }
                            }
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoDir,
                          })}

                          <Col className="subsection-title" span= {24}>
                            Contacto Comercial
                            <hr/>
                          </Col>

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                              section: basicInformation.basicInfoContactName,
                            })
                          }

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                              section: basicInformation.basicInfoCargo,
                            })
                          }

                          {renderFormItemObj({
                            section: basicInformation.basicInfoMail,
                            validator: emailValidator
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoTel,
                            validator: numTelValidator
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoCel,
                            validator: numTelValidator
                          })}

                          <Col className="subsection-title" span= {24}>
                            Contacto de Facturación
                            <hr/>
                          </Col>

                          {renderFormItemObj({
                            section: basicInformation.basicInfoNomFacturacion,
                          })}

                          {apiForm.typeSol === 'PJ' &&
                            renderFormItemObj({
                            section: basicInformation.basicInfoCargoFacturacion,
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoMailFacturacion,
                            validator: emailValidator
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoTelFacturacion,
                            validator: numTelValidator
                          })}

                          {renderFormItemObj({
                            section: basicInformation.basicInfoCelFacturacion,
                            validator: numTelValidator
                          })}
                        </Row>
                    </Row>
                  <>
                    <Row className="subheader">
                      <Col xl={24}>
                        II. INFORMACIÓN SOBRE ACTIVIDAD ECONÓMICA Y ACTIVIDAD COMERCIAL
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                      {/* ↓ CAMPO INICIO DE ACTIVIDADES ↓ */}
                      {renderFormItemTable({
                            section: infoEco.infoEcoInitAct,
                            handlerOnChangeObj: (id, value) => {
                            handlerOnChangeObjAttr(id, value);
                            if (value) {
                              var now = moment(new Date());
                              var end = moment(value, "DD/MM/YYYY");
                              var duration = moment.duration(now.diff(end));
                              var months = "" + Math.floor(duration.asMonths());
                              if (months < 0) {
                                months = "0";
                              }
                              setFieldsValue({ [infoEco.infoEcoAniosExp.id]: months });
                              handlerOnChangeObjAttr(infoEco.infoEcoAniosExp.id, months);
                            }
                          }
                        })
                      }
                      {/* ↓CAMPO ANIOS DE EXPERIENCIA↓ */}
                      {renderFormItemObj({
                          section: infoEco.infoEcoAniosExp,
                        })
                      }
                      {renderFormItemObj({
                          section: infoEco.infoEcoCustomActivities,
                          cols: 12,
                          options: prodActOptions.map(option => {
                            return {val: option.val, text: option.name}
                          })
                        })
                      }
                    </Row>
                    <div className="content">
                      {format === "html" && !signed && (
                        <>
                        <Row gutter={[16, 8]}>
                            {renderFormItemTable({
                              section: prodAct.prodActDescripcion,
                              cols: 19,
                              options: SIIActivities.map(item => {
                                return {val: item.codigo+"|"+ item.descripcion, text: item.codigo+" - "+ item.descripcion}
                              }),
                            })}

                            {prodAct.prodActDescripcion.val === 'Otras actividades' &&
                              renderFormItemTable({
                              section: prodAct.prodActOtras,
                              cols: 5,
                            })}

                            <Col className="button-col" xl={2}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('prodAct', 'infoEco', 'actRecords')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className="button-col" xl={2}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('prodAct')}> Limpiar </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                        {infoEco.infoEcoActRecords.val.length > 0 ?
                          <Table columns={prodActColums} dataSource={infoEco.infoEcoActRecords.val} size="middle" pagination={false} ></Table>
                          : infoEco.infoEcoActRecords.val.length < 1 &&
                            validarRegistros && (
                              <Row>
                                <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                >
                                  {t("messages.aml.registersRequired")}
                                </Col>
                              </Row>
                          )}
                        
                    </div>
                  </>
                      <Row className="subheader">
                        <Col xl={24}>
                          III. INFORMACIÓN DE PERSONA EXPUESTA POLÍTICAMENTE O PÚBLICAMENTE (PEP)
                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          {apiForm.typeSol === 'PN' ?
                          <div>
                            De acuerdo a la normativa actual, son Personas Expuestas 
                            Políticamente aquellos chilenos o extranjeros que desempeñan o han desempeñado <strong>funciones públicas 
                            destacadas (especificadas al final del ítem)</strong> en un país, hasta a lo menos un año de 
                            finalizado el ejercicio de las mismas. Se incluyen en esta categoría a 
                            jefes de Estado o de un Gobierno, políticos de alta jerarquía, funcionarios 
                            gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de 
                            empresas estatales, así como sus cónyuges, sus parientes hasta el segundo grado de 
                            consanguinidad, y las personas naturales con las que hayan celebrado un pacto de 
                            actuación conjunta, mediante el cual tengan poder de voto suficiente para influir en 
                            sociedades constituidas en Chile. <br/>
                            Yo, declaro que:
                          </div>
                          :
                          <div>
                            <p>
                              De acuerdo a la normativa actual, son Personas Expuestas Políticamente aquellas personas 
                              relacionadas a la empresa que poseen el título de: <strong>Beneficiario Final, Controlador 
                              Efectivo y/o Representante Legal</strong>, siendo estos chilenos o extranjeros que desempeñan o 
                              han desempeñado <strong>funciones públicas destacadas (especificadas al final del ítem)</strong> en 
                              un país, hasta a lo menos un año de 
                              finalizado el ejercicio de las mismas. Se incluyen en esta categoría a jefes de Estado o 
                              de un Gobierno, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o 
                              militares de alta jerarquía, altos ejecutivos de empresas estatales, así como sus cónyuges, 
                              sus parientes hasta el segundo grado de consanguinidad, y las personas naturales con las 
                              que hayan celebrado un pacto de actuación conjunta, mediante el cual tengan poder de voto 
                              suficiente para influir en sociedades constituidas en Chile.
                            </p>
                            Se definen como:<br/><br/>
                            <p>
                              <strong>Beneficiario final</strong>: aquella(s) persona(s) natural(es) que finalmente posee(n), 
                              directa o indirectamente, a través de sociedades u otros mecanismos, una participación igual o 
                              mayor al 10% del capital o de los derechos a voto de una persona jurídica determinada.
                            </p>
                            <p>
                            <strong>Controlador efectivo</strong>: aquella(s) persona(s) natural(es) que, sin perjuicio de poseer directa o 
                              indirectamente una participación inferior al 10% del capital o de los derechos a voto de 
                              una persona jurídica, a través de sociedades u otros mecanismos, ejerce(n) el control efectivo de 
                              la persona o estructura jurídica.
                            </p>
                            <p>
                              <strong>Representante Legal</strong>: aquella(s) persona(s) natural(es) que puede, en nombre de la empresa, 
                              adquirir derechos y contraer compromisos vinculantes para la empresa, así como actuar ante los 
                              tribunales y otras autoridades en nombre de la empresa.
                            </p>
                              En nombre de la empresa que represento y sus entidades relacionadas (las "Sociedades"), declaro que:
                          </div>
                          }
                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col span={20}>
                        {apiForm.typeSol === 'PN' ?
                          <div>
                            <u>PREGUNTA 1:</u> Me desempeño actualmente o he desempeñado en el último año, 
                            alguno de los cargos indicados en Funciones Públicas destacadas.
                          </div>
                          :
                          <div>
                            <u>PREGUNTA 1:</u> ¿El(los) Beneficiario(s) Final(es), Controlador(es) Efectivo(s) y/o Representante(s) 
                            Legal(es) desempeña(n) actualmente o ha(n) desempeñado en el último año, alguno de los cargos indicados 
                            en Funciones Públicas destacadas?
                          </div>
                        }
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item-flat",
                          name: infoPep.infoPepIsPublic.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 1,
                          initialValue: infoPep.infoPepIsPublic.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  "infoPepIsPublic",
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })
                        }
                      </Row>
                      {infoPep.infoPepIsPublic.val && (
                          <div className="content">
                            {format === "html" && !signed && (
                              <>
                                {apiForm.typeSol === 'PJ' ?
                                  <Row gutter={[16, 8]} style={{margin: 10}}>
                                    <Col className="subsection-title" span= {24}>
                                      Datos de la persona relacionada
                                      <hr/>
                                    </Col>
                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicGrupo,
                                      options:[
                                        {val: "Rep. Legal", text: "Representante Legal"},
                                        {val: "Ben. Final", text: "Beneficiario Final"},
                                        {val: "Controlador Efectivo", text: "Controlador Efectivo"},
                                      ],
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicName,
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicTipoDoc,
                                      options: [
                                        {val: "Rut", text: "Rut"},
                                        {val: "Pasaporte", text: "Pasaporte"},
                                      ],
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicNroId,
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicOrgPublic,
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicPais,
                                      cols: 6,
                                      options:
                                      paises.map(item => {
                                        return {val: item.country, text: item.country}
                                      })
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicCargo,
                                      customTitle: 'Cargo',
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepPublicObj.pepPublicFecTermino,
                                      cols: 6
                                    })}
                                  </Row>

                                  :

                                  <Row gutter={[16, 8]} style={{margin: 10}}>
                                      {renderFormItemTable({
                                        section: pepPublicObj.pepPublicCargo,
                                        customTitle: 'Cargo del PEP',
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepPublicObj.pepPublicOrgPublic,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepPublicObj.pepPublicPais,
                                        cols: 6,
                                        options:
                                        paises.map(item => {
                                          return {val: item.country, text: item.country}
                                        })
                                      })}

                                      {renderFormItemTable({
                                        section: pepPublicObj.pepPublicFecTermino,
                                        cols: 6
                                      })}
                                    </Row>
                                }
                                <Row className="button-row">
                                  {infoPep.infoPepPublicRecords.val.length < 1 &&
                                    validarRegistros && (
                                      <Col
                                        span={24}
                                        style= {{color: 'red'}}
                                        className="missing-registers ant-form-explain"
                                      >
                                        {t("messages.aml.registersRequired")}
                                      </Col>
                                    )}

                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('pepPublicObj', 'infoPep', 'publicRecords')} icon="plus" > Añadir </Button>
                                  </Col>
                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('pepPublicObj')}> Limpiar </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {infoPep.infoPepPublicRecords.val.length > 0 && format === "html" ?
                              <Table columns={getPepPublicColumns()} dataSource={infoPep.infoPepPublicRecords.val} size="middle" pagination={false} ></Table>
                              :
                              toDescriptionsPdf( infoPep.infoPepPublicRecords.val, pepPublicObj, apiForm.typeSol )
                            }
                          </div>
                      )}

                      <Row className="summary">
                        <Col span={20}>
                          {apiForm.typeSol === 'PN' ?
                            <div>
                              <u>PREGUNTA 2:</u> Soy cónyuge o pariente hasta segundo grado de consanguinidad (abuelo(a), 
                              padre, madre, hijo(a), hermano(a), nieto(a)) de personas que se desempeñan 
                              actualmente o hayan desempeñado en el último año, alguno de los cargos indicados 
                              en Funciones Públicas destacadas.
                            </div>
                            :
                            <div>
                              <u>PREGUNTA 2:</u> ¿El(los) Beneficiario(s) Final(es), Controlador(es) Efectivo(s) y/o Representante(s) 
                              Legal(es) es(son) cónyuge(s) o pariente(s) hasta segundo grado de consanguinidad (abuelo(a), 
                              padre, madre, hijo(a), hermano(a), nieto(a)) de persona(s) que se desempeña(n) actualmente o haya(n) 
                              desempeñado en el último año, en alguno de los cargos indicados en Funciones Públicas destacadas?
                            </div>
                          }
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item-flat",
                          name: infoPep.infoPepHasFamily.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 1,
                          initialValue: infoPep.infoPepHasFamily.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  "infoPepHasFamily",
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {infoPep.infoPepHasFamily.val && (
                          <div className="content">
                            {format === "html" && !signed && (
                              <>
                              {apiForm.typeSol === 'PJ' ?
                                <Row gutter={[16, 8]} style={{margin: 10}}>
                                  <Col className="subsection-title" span= {24}>
                                      Datos de la persona relacionada
                                      <hr/>
                                  </Col>

                                    {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyGrupo,
                                    options:[
                                      {val: "Rep. Legal", text: "Representante Legal"},
                                      {val: "Ben. Final", text: "Beneficiario Final"},
                                      {val: "Controlador Efectivo", text: "Controlador Efectivo"},
                                    ],
                                    cols: 6
                                    })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyName,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyTipoDoc,
                                    cols: 6,
                                    options: [
                                      {val: "Rut", text: "Rut"},
                                      {val: "Pasaporte", text: "Pasaporte"},
                                    ]
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyNroId,
                                    cols: 6
                                  })}

                                  <Col className="subsection-title" span= {24}>
                                      Datos del pariente que posee el cargo de PEP
                                      <hr/>
                                  </Col>

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyParentesco,
                                    cols: 6,
                                    options:
                                    [
                                      { val: "Cónyuge", text: "Cónyuge" },
                                      { val: "Padre", text: "Padre" },
                                      { val: "Madre", text: "Madre" },
                                      { val: "Abuelo(a)", text: "Abuelo(a)" },
                                      { val: "Hijo(a)", text: "Hijo(a)" },
                                      { val: "Hermano(a)", text: "Hermano(a)" },
                                      { val: "Nieto(a)", text: "Nieto(a)" },
                                    ],
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyNamePariente,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyTipoDocPariente,
                                    cols: 6,
                                    options: [
                                      {val: "Rut", text: "Rut"},
                                      {val: "Pasaporte", text: "Pasaporte"},
                                    ]
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyNroIdPariente,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyOrgPublic,
                                    cols: 6
                                  })}
                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyPais,
                                    cols: 6,
                                    options:
                                    paises.map(item => {
                                      return {val: item.country, text: item.country}
                                    })
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyCargo,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyFecTermino,
                                    cols: 6
                                  })}
                                </Row>
                              :
                              <Row gutter={[16, 8]} style={{margin: 10}}>
                                {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyParentesco,
                                    cols: 6,
                                    options:
                                    [
                                      { val: "Cónyuge", text: "Cónyuge" },
                                      { val: "Padre", text: "Padre" },
                                      { val: "Madre", text: "Madre" },
                                      { val: "Abuelo(a)", text: "Abuelo(a)" },
                                      { val: "Hijo(a)", text: "Hijo(a)" },
                                      { val: "Hermano(a)", text: "Hermano(a)" },
                                      { val: "Nieto(a)", text: "Nieto(a)" },
                                    ],
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyName,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyTipoDoc,
                                    cols: 6,
                                    options: [
                                      {val: "Rut", text: "Rut"},
                                      {val: "Pasaporte", text: "Pasaporte"},
                                    ]
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyNroId,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyCargo,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyOrgPublic,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyPais,
                                    cols: 6,
                                    options:
                                    paises.map(item => {
                                      return {val: item.country, text: item.country}
                                    })
                                  })}

                                  {renderFormItemTable({
                                    section: pepFamilyObj.pepFamilyFecTermino,
                                    cols: 6
                                  })}
                              </Row>
                              }

                                <Row className="button-row">
                                  {infoPep.infoPepFamilyRecords.val.length < 1 &&
                                    validarRegistros && (
                                      <Col
                                        span={24}
                                        style= {{color: 'red'}}
                                        className="missing-registers ant-form-explain"
                                      >
                                        {t("messages.aml.registersRequired")}
                                      </Col>
                                    )}

                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('pepFamilyObj', 'infoPep', 'familyRecords')} icon="plus" > Añadir </Button>
                                  </Col>
                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('pepFamilyObj')}> Limpiar </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {infoPep.infoPepFamilyRecords.val.length > 0 && format === "html" ?
                              <Table columns={getPepFamilyColumns()} dataSource={infoPep.infoPepFamilyRecords.val} size="middle" pagination={false} ></Table>
                              :
                              toDescriptionsPdf( infoPep.infoPepFamilyRecords.val, pepFamilyObj, apiForm.typeSol )
                            }
                          </div>
                      )}

                      <Row className="summary">
                        <Col span={20}>
                          {apiForm.typeSol === 'PN' ?
                          <div>
                            <u>PREGUNTA 3:</u> He celebrado un pacto de actuación conjunta que otorgue 
                            poder de voto suficiente para influir en sociedades constituidas en 
                            Chile, con una persona que desempeñe actualmente o haya 
                            desempeñado en el último año, alguno de los cargos indicados 
                            en Funciones Públicas destacadas.
                          </div>
                          :
                          <div>
                            <u>PREGUNTA 3:</u> ¿El(los) Beneficiario(s) Final(es), Controlador(es) Efectivo(s) y/o 
                            Representante(s) Legal(es) ha(n) celebrado un pacto de actuación conjunta que otorgue 
                            poder de voto suficiente para influir en sociedades constituidas en Chile, con una 
                            persona que desempeñe actualmente o haya desempeñado en el último año, en alguno de 
                            los cargos indicados en Funciones Públicas destacadas?
                          </div>
                          }
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item-flat",
                          name: infoPep.infoPepHasRelation.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 1,
                          initialValue: infoPep.infoPepHasRelation.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  "infoPepHasRelation",
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {infoPep.infoPepHasRelation.val && (
                          <div className="content">
                            {format === "html" && !signed && (
                              <>
                                {apiForm.typeSol === 'PJ' ?
                                  <Row className="" gutter={[16, 8]} style={{margin: 10}}>
                                      <Col className="subsection-title" span= {24}>
                                          Datos de la persona relacionada
                                          <hr/>
                                      </Col>
                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationGrupo,
                                        options:[
                                          {val: "Rep. Legal", text: "Representante Legal"},
                                          {val: "Ben. Final", text: "Beneficiario Final"},
                                          {val: "Controlador Efectivo", text: "Controlador Efectivo"},
                                        ],
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationName,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationTipoId,
                                        options: [
                                          {val: "Rut", text: "Rut"},
                                          {val: "Pasaporte", text: "Pasaporte"},
                                        ],
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNroId,
                                        cols: 6
                                      })}

                                      <Col className="subsection-title" span= {24}>
                                        Datos de la persona con quien se tiene el pacto de actuación conjunta y que posee el cargo de PEP
                                        <hr/>
                                      </Col>

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNamePac,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationTipoDocPac,
                                        options: [
                                          {val: "Rut", text: "Rut"},
                                          {val: "Pasaporte", text: "Pasaporte"},
                                        ],
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNroIdPac,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationOrgPublico,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationPais,
                                        options:
                                        paises.map(item => {
                                          return {val: item.country, text: item.country}
                                        }),
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationCargoPac,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationFecTermino,
                                        cols: 6,
                                      })}

                                      <Col className="subsection-title" span= {24}>
                                        Datos de la empresa que dio origen al pacto de actuación conjunta
                                        <hr/>
                                      </Col>

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNameEmp,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationTipoDocEmp,
                                        options: [
                                          {val: "Rut", text: "Rut"},
                                          {val: "Pasaporte", text: "Pasaporte"},
                                        ],
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNroIdEmp,
                                        cols: 6,
                                      })}
                                    </Row>
                                  :
                                    <Row className="" gutter={[16, 8]} style={{margin: 10}}>
                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationEmpresa,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNroIdEmp,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationPepName,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationTipoDocPep,
                                        options: [
                                          {val: "Rut", text: "Rut"},
                                          {val: "Pasaporte", text: "Pasaporte"},
                                        ],
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationNroIdPep,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationCargoPep,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationOrgPublico,
                                        cols: 6,
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationPais,
                                        options:
                                        paises.map(item => {
                                          return {val: item.country, text: item.country}
                                        }),
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: pepRelationObj.pepRelationFecTermino,
                                        cols: 6,
                                      })}
                                    </Row>
                                }

                                <Row className="button-row">
                                  {infoPep.infoPepRelationRecords.val.length < 1 &&
                                    validarRegistros && (
                                      <Col
                                        span={24}
                                        style= {{color: 'red'}}
                                        className="missing-registers ant-form-explain"
                                      >
                                        {t("messages.aml.registersRequired")}
                                      </Col>
                                    )}

                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('pepRelationObj', 'infoPep', 'relationRecords')} icon="plus" > Añadir </Button>
                                  </Col>
                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" icon="delete" onClick={()=>handleOnClear('pepRelationObj')}> Limpiar </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {infoPep.infoPepRelationRecords.val.length > 0 && format === "html" ?
                              <Table columns={getPepRelationColumns()} dataSource={infoPep.infoPepRelationRecords.val} size="middle" pagination={false} ></Table>
                              :
                              toDescriptionsPdf( infoPep.infoPepRelationRecords.val, pepRelationObj, apiForm.typeSol )
                            }
                          </div>
                      )}

                      <Row className="summary">
                        <Col span={21}>
                          <strong>Funciones públicas destacadas:</strong>
                          <br/><br/>
                          <ol>
                            <li> Presidente de la República. </li>
                            <li> Senadores, Diputados y Alcaldes.</li>
                            <li> Ministros de la Corte Suprema y Cortes de Apelaciones.</li>
                            <li> Ministros de Estado, Subsecretarios, Intendentes, Gobernadores, Secretarios Regionales Ministeriales,
                              Embajadores, Jefes Superiores de Servicio, tanto centralizados como descentralizados y
                              el directivo superior inmediato que deba subrogar a cada uno de ellos.</li>
                            <li> Comandantes en Jefe de las Fuerzas Armadas, Director General Carabineros,
                              Director General de Investigaciones, y el oficial superior inmediato que deba subrogar a cada uno de ellos.</li>
                            <li> Fiscal Nacional del Ministerio Público y Fiscales Regionales.</li>
                            <li> Contralor General de la República.</li>
                            <li> Consejeros del Banco Central.</li>
                            <li> Consejeros del Consejo de Defensa del Estado.</li>
                            <li> Ministros del Tribunal Constitucional.</li>
                            <li> Ministros del Tribunal de la Libre Competencia.</li>
                            <li> Integrantes titulares y suplentes del Tribunal de Contratación Pública.</li>
                            <li> Consejeros del Consejo de Alta Dirección Pública.</li>
                            <li> Los directores y ejecutivos principales de empresas públicas.</li>
                            <li> Directores de sociedades anónimas nombrados por el Estado.</li>
                          </ol>

                        </Col>
                      </Row>

                      <Row className="subheader">
                        <Col xl={24}>
                          IV. DECLARACIONES COMPLEMENTARIAS EN TEMAS DE CUMPLIMIENTO
                        </Col>
                      </Row>
                      <div className="content">
                        <Row className="summary">
                          {apiForm.typeSol === 'PJ' ?
                            <>
                              <Col>
                                <strong>1.</strong> Los recursos, fondos, dineros, productos y activos destinados a la ejecución de las relaciones
                                comerciales y contractuales con Parque Arauco S.A. y sus filiales, incluido los gastos
                                incurridos para la habilitación y puesta en marcha del local, pago de remuneraciones,
                                asignaciones, premios para trabajadores y cotizaciones previsionales, no provienen de
                                ninguna actividad sancionada por el Código Penal Chileno u otras normas que establezcan
                                delitos base de lavado de activos, de jurisdicciones señaladas por el GAFI con deficiencias
                                estratégicas en sus sistemas Antilavado de Activos y contra el Financiamiento del Terrorismo (ALA/CFT) o de los países y jurisdicciones que tienen un régimen
                                fiscal preferencial, según define el Servicio de Impuestos Internos.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>2.</strong> Las actividades de las Sociedades no están vinculadas, directa ni indirectamente, con el
                                lavado de activos o con alguno de sus delitos base, ni con el financiamiento del terrorismo.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>3.</strong> El controlador o los miembros del controlador de las Sociedades, sus beneficiarios finales y
                                ejecutivos principales, no están incluidos en ninguno de los listados de las Resoluciones del
                                Consejo de Seguridad de Naciones Unidas que sancionan el financiamiento del terrorismo y
                                el financiamiento de la proliferación de armas de destrucción masiva.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>4.</strong> El controlador o los miembros del controlador de las Sociedades, sus beneficiarios finales y
                                ejecutivos principales no han sido condenados bajo la ley chilena o extranjera por los delitos
                                de lavado de activos, o alguno de sus delitos base, o por financiamiento del terrorismo.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>5.</strong> Ninguna de las Sociedades ha sido sancionada en los términos de la Ley 20.393 que
                                establece Responsabilidad Penal de las Personas Jurídicas.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>6.</strong> En el caso de que la sociedad comercialice productos, éstos cumplen con los derechos de
                                propiedad intelectual e industrial y no se trata de copias hechas sin el consentimiento del
                                titular del derecho, o de la persona autorizada por él, en el país de producción y
                                cumpliendo, además, cuando proceda, con la normativa aduanera.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>7.</strong> Ninguna de las Sociedades viola los derechos humanos, tales como trata de personas,
                                trabajo forzoso y trabajo infantil, comprometiéndose a cumplir todas las leyes aplicables y
                                respetar los derechos humanos internacionalmente reconocidos, donde quiera que opere.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>8.</strong> En el evento de que el controlador o los miembros del controlador de las Sociedades, sus
                                beneficiarios finales o sus ejecutivos principales tomen conocimiento de un cambio en las
                                circunstancias descritas anteriormente, dicha situación se comunicará dentro de 24 horas al
                                Oficial de Cumplimiento de Parque Arauco S.A. y sus filiales, al correo electrónico <a href="mailto:mpd@parauco.com"> mpd@parauco.com.</a>
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>9.</strong> Conozco y acepto que:<br/><br/>
                                <ol type="i">
                                  <li>
                                  Parque Arauco S.A. y sus filiales están facultadas para efectuar las verificaciones que
                                  considere pertinentes y está en la obligación legal de solicitar las aclaraciones que
                                  estime adecuadas respecto a las operaciones de las Sociedades y del origen de sus
                                  activos, información que nos obligamos a entregar oportunamente.
                                  </li>
                                  <li>
                                    Las declaraciones deberán ser efectivas y veraces durante toda la vigencia de la
                                    relación contractual con Parque Arauco S.A. y sus filiales.
                                  </li>
                                  <li>
                                    En caso de incumplimiento, incongruencia o discrepancia respecto de las obligaciones
                                    y declaraciones incorporadas en el presente documento, Parque Arauco S.A. y sus
                                    filiales podrá dar por terminada, con justa causa, cualquier relación comercial o
                                    contractual que exista, sin derecho a indemnización alguna para las Sociedades.
                                  </li>
                                </ol>
                              </Col>
                            </>
                            :
                            <>
                              <Col>
                                <strong>1.</strong> Los recursos, fondos, dineros, productos y activos destinados a la ejecución de la relación
                                comercial y contractual con Parque Arauco S.A. y sus filiales, incluido los gastos incurridos
                                para la habilitación y puesta en marcha del local, pago de remuneraciones, asignaciones,
                                premios para trabajadores y cotizaciones previsionales no provienen de ninguna actividad
                                sancionada por el Código Penal Chileno u otras normas que establezcan delitos base de
                                lavado de activos, de jurisdicciones señaladas por el GAFI con deficiencias estratégicas en
                                sus sistemas Antilavado de Activos y contra el Financiamiento del Terrorismo (ALA/CFT) o de los países y jurisdicciones que tienen un régimen fiscal
                                preferencial, según define el Servicio de Impuestos Internos.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>2.</strong> Mis actividades comerciales no están vinculadas, directa ni indirectamente, con el lavado de
                                activos o con alguno de sus delitos base, ni con el financiamiento del terrorismo.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>3.</strong> No me encuentro incluido en ninguno de los listados de las Resoluciones del Consejo de
                                Seguridad de Naciones Unidas que sancionan el financiamiento del terrorismo y el
                                financiamiento de la proliferación de armas de destrucción masiva.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>4.</strong> No he sido condenado/a bajo la ley chilena o extranjera por los delitos de lavado de activos,
                                o alguno de sus delitos base, o por financiamiento del terrorismo.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>5.</strong> En el caso de que comercialice productos, éstos cumplen con los derechos de propiedad
                                intelectual e industrial y no se trata de copias hechas sin el consentimiento del titular del
                                derecho, o de la persona autorizada por él, en el país de producción y cumpliendo, además,
                                cuando proceda, con la normativa aduanera.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>6.</strong> No violo los derechos humanos, tales como trata de personas, trabajo forzoso y trabajo
                                infantil, y me comprometo a cumplir todas las leyes aplicables y respetar los derechos
                                humanos internacionalmente reconocidos, donde quiera que opere.
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>7.</strong> En el evento de tomar conocimiento de un cambio en las circunstancias descritas
                                anteriormente, dicha situación la comunicaré dentro de 24 horas al oficial de cumplimiento
                                de Parque Arauco S.A. y sus filiales, al correo electrónico <a href="mailto:mpd@parauco.com"> mpd@parauco.com.</a>
                                <br/><br/>
                              </Col>
                              <Col>
                                <strong>8.</strong> Conozco y acepto que:<br/><br/>
                                <ol type="i">
                                  <li>
                                    Parque Arauco S.A. y sus filiales están facultadas para efectuar las verificaciones que
                                    considere pertinentes y está en la obligación legal de solicitar las aclaraciones que
                                    estime adecuadas respecto de mis actividades comerciales y del origen de mis activos,
                                    información que me obligo a entregar oportunamente.
                                  </li>
                                  <li>
                                    Las declaraciones deberán ser efectivas y veraces durante toda la vigencia de la
                                    relación contractual con Parque Arauco S.A. y sus filiales.
                                  </li>
                                  <li>
                                    En caso de incumplimiento, incongruencia o discrepancia respecto de las obligaciones
                                    y declaraciones incorporadas en el presente documento, Parque Arauco S.A. y sus
                                    filiales podrá dar por terminada, con justa causa, cualquier relación comercial o
                                    contractual que exista, y no tendré derecho a indemnización alguna.
                                  </li>
                                </ol>
                              </Col>
                            </>
                          }
                        </Row>
                      </div>

                    {/* {apiForm.typeSol === 'PN' &&
                      <>
                        <Row className="subheader">
                          <Col xl={24}>
                            IV. DECLARACIÓN DE PREVENCIÓN DEL LAVADO DE ACTIVOS Y FINANCIACIÓN AL TERRORISMO
                          </Col>
                        </Row>
                        <div className="content">
                          <Row className="summary">
                            <Col xl={24}>
                            Declaro qué: <br/><br/>
                              <strong>1.</strong> Los recursos, fondos, dineros, productos y activos destinados a la ejecución de la relación
                                comercial y contractual con {clientName} y sus filiales, incluido los gastos incurridos para la
                                habilitación y puesta en marcha del local, pago de remuneraciones, asignaciones, premios para
                                trabajadores y cotizaciones previsionales no provienen de ninguna actividad sancionada por el
                                Código Penal Chileno u otras normas que establezcan delitos base de lavado de activos, de
                                jurisdicciones señaladas por el GAFI con deficiencias estratégicas en sus sistemas ALA/CFT o de los
                                países y jurisdicciones que tienen un régimen fiscal preferencial, según define el Servicio de
                                Impuestos Internos.<br/><br/>
                              <strong>2.</strong> Mis actividades comerciales no están vinculadas, directa ni indirectamente, con el lavado de
                                  activos o con alguno de sus delitos base, ni con el financiamiento del terrorismo.<br/><br/>
                              <strong>3.</strong> En el caso de que comercialice productos, éstos cumplen con los derechos de propiedad
                                intelectual e industrial y no se trata de copias hechas sin el consentimiento del titular del derecho,
                                o de la persona autorizada por él, en el país de producción y cumpliendo, además, cuando
                                proceda, con la normativa aduanera.<br/><br/>
                              <strong>4.</strong> No me encuentro incluido en ninguno de los listados de las Resoluciones del Consejo de
                                Seguridad de Naciones Unidas que sancionan el financiamiento del terrorismo y el financiamiento
                                de la proliferación de armas de destrucción masiva.<br/><br/>
                              <strong>5.</strong> No he sido condenado/a bajo la ley chilena o extranjera por los delitos de lavado de activos, o
                                alguno de sus delitos base, o por financiamiento del terrorismo.<br/><br/>
                              <strong>6.</strong> En el evento de tomar conocimiento de un cambio en las circunstancias descritas
                                anteriormente, dicha situación la comunicaré dentro de 24 horas al oficial de cumplimiento de
                                {clientName} y sus filiales, al email <a href="mailto:mpd@parauco.com"> mpd@parauco.com.</a>
                                <br/><br/>
                              <strong>7.</strong> Conozco y acepto que:<br/><br/>
                                <ol type="i">
                                  <li>
                                    {clientName} y sus filiales está facultada para efectuar las verificaciones que
                                    considere pertinentes y está en la obligación legal de solicitar las aclaraciones que estime
                                    adecuadas respecto de mis actividades comerciales y del origen de mis activos,
                                    información que me obligo a entregar oportunamente.
                                  </li>
                                  <li>
                                    Las declaraciones serán efectivas y veraces durante toda la vigencia de la relación
                                    contractual con {clientName} y sus filiales.
                                  </li>
                                  <li>
                                    En caso de incumplimiento, incongruencia o discrepancia respecto de las obligaciones y
                                    declaraciones incorporadas en el presente documento, {clientName} y sus filiales
                                    podrá dar por terminada, con justa causa, cualquier relación comercial o contractual que
                                    exista, y no tendré derecho a indemnización alguna.
                                  </li>
                                </ol>
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                {apiForm.typeSol === 'PJ' &&
                  <>
                      <Row className="subheader">
                        <Col xl={24}>IV. DECLARACIÓN SOBRE GESTIÓN DEL RIESGO & COMPLIANCE</Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          En nombre de mi representada y sus entidades relacionadas (las "Sociedades”), declaro que:
                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>1. </strong>

                          Los recursos, fondos, dineros, productos y activos destinados a la ejecución de las relaciones
                          comerciales y contractuales con {clientName} y sus filiales, incluido los gastos incurridos
                          para la habilitación y puesta en marcha del local, pago de remuneraciones, asignaciones, premios
                          para trabajadores y cotizaciones previsionales, no provienen de ninguna actividad sancionada por
                          el Código Penal Chileno u otras normas que establezcan delitos base de lavado de activos, de
                          jurisdicciones señaladas por el GAFI con deficiencias estratégicas en sus sistemas ALA/CFT o de los
                          países y jurisdicciones que tienen un régimen fiscal preferencial, según define el Servicio de
                          Impuestos Internos.

                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>2. </strong>

                          Las actividades de las Sociedades no están vinculadas, directa ni indirectamente, con el lavado
                          de activos o con alguno de sus delitos base, ni con el financiamiento del terrorismo.

                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>3. </strong>

                          En el caso de que la sociedad comercialice productos, éstos cumplen con los derechos de
                          propiedad intelectual e industrial y no se trata de copias hechas sin el consentimiento del titular
                          del derecho, o de la persona autorizada por él, en el país de producción y cumpliendo, además,
                          cuando proceda, con la normativa aduanera.

                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>4. </strong>

                          El Controlador de las Sociedades, persona natural o jurídica, no está incluido en ninguno de los
                          listados de las Resoluciones del Consejo de Seguridad de Naciones Unidas que sancionan el
                          financiamiento del terrorismo y el financiamiento de la proliferación de armas de destrucción
                          masiva.

                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>5. </strong>

                          Ninguna de las personas naturales controladoras o ejecutivos principales de las sociedades ha
                          sido condenada bajo la ley chilena o extranjera por los delitos de lavado de activos, o alguno de
                          sus delitos base, o por financiamiento del terrorismo.

                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>6. </strong>
                          Ninguna de las Sociedades ha sido sancionada en los términos de la Ley 20.393 que establece
                          Responsabilidad Penal de las Personas Jurídicas
                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>7. </strong>
                          En el evento de que las personas naturales controladoras de las Sociedades o los ejecutivos
                          principales de las Sociedades tomen conocimiento de un cambio en las circunstancias descritas
                          anteriormente, dicha situación se comunicará dentro de 24 horas al Oficial de Cumplimiento de
                          {clientName} y sus filiales, al email <a href="mailto:mpd@parauco.com">mpd@parauco.com</a>
                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <strong>8. </strong>
                            Conozco y acepto que: <br/>
                            <ol type="i">
                              <li>
                                {clientName} y sus filiales está facultada para efectuar las verificaciones que
                                considere pertinentes y está en la obligación legal de solicitar las aclaraciones que estime
                                adecuadas respecto a las operaciones de las Sociedades y del origen de sus activos,
                                información que nos obligamos a entregar oportunamente.
                              </li>
                              <li>
                                Las declaraciones serán efectivas y veraces durante toda la vigencia de la relación
                                contractual con {clientName} y sus filiales.
                              </li>
                              <li>
                                En caso de incumplimiento, incongruencia o discrepancia respecto de las obligaciones y
                                declaraciones incorporadas en el presente documento, {clientName} y sus filiales
                                podrá dar por terminada, con justa causa, cualquier relación comercial o contractual que
                                exista, sin derecho a indemnización alguna para las Sociedades.
                              </li>
                            </ol>
                        </Col>
                      </Row>
                  </>
                } */}
                <Row className="subheader">
                  <Col xl={24}>
                    V. INFORMACIÓN DEL PROGRAMA DE COMPLIANCE
                  </Col>
                </Row>
                <Row className="summary">
                  <Col span={20}>
                  {apiForm.typeSol === 'PN' ? 
                      <div>
                        ¿Usted se encuentra contemplado(a) en el artículo 3° de la Ley 19.913
                        como un sujeto obligado a informar sobre operaciones 
                        sospechosas que adviertan en el ejercicio de sus actividades? Tal como está 
                        actualmente, si la respuesta es afirmativa indique quién ocupa la posición de 
                        oficial de cumplimiento y entregue una descripción del Programa de Compliance.
                      </div>
                    :
                      <div>
                        ¿La sociedad se encuentra contemplada en el artículo 3° de la Ley 19.913 como
                        una sociedad obligada a informar sobre operaciones 
                        sospechosas que adviertan en el ejercicio de sus actividades? Tal como está 
                        actualmente, si la respuesta es afirmativa indique quién ocupa la posición de 
                        oficial de cumplimiento y entregue una descripción del Programa de Compliance.
                      </div>
                  }
                  </Col>
                  {renderFormItem({
                      label: "",
                      colClassName: "switch-col",
                      itemClassName: "radio-item-flat",
                      name: infoCom.infoComHasCompliance.id,
                      labelCol: 0,
                      wrapperCol: 0,
                      offset: 1,
                      initialValue: infoCom.infoComHasCompliance.val,
                      rules: [
                        {
                          required: true,
                          message: t("messages.aml.dontForgetSelect"),
                        },
                      ],
                      wrapperCols: 3,
                      item: (
                        <Radio.Group
                          onChange={({ target }) =>
                            handlerOnChangeObjAttr(
                              "infoComHasCompliance",
                              target.value
                            )
                          }
                        >
                          <Radio style={radioStyle} value={true}>
                            Sí
                          </Radio>
                          <Radio style={radioStyle} value={false}>
                            No
                          </Radio>
                        </Radio.Group>
                      ),
                    })
                  }
                </Row>
                {infoCom.infoComHasCompliance.val &&
                  <>
                    <Row className="summary">
                      <Col span={20}>
                        ¿Cuenta con un Oficial de Cumplimiento?
                      </Col>
                      {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item-flat",
                          name: infoCom.infoComHasOfCto.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 1,
                          initialValue: infoCom.infoComHasOfCto.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  "infoComHasOfCto",
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })
                      }
                    </Row>
                    {infoCom.infoComHasOfCto.val && (
                      <div className="content">
                        <>
                          <Row gutter={[16, 8]}>
                            {renderFormItemObj({
                              section: infoCom.infoComNombre,
                              cols: 8
                            })}
                            {renderFormItemObj({
                              section: infoCom.infoComCorreo,
                              validator: emailValidator,
                              cols: 8
                            })}
                            {renderFormItemObj({
                              section: infoCom.infoComTel,
                              validator: numTelValidator,
                              cols: 8
                            })}
                            {renderFormItemObj({
                              section: infoCom.infoComDescription,
                              cols: 24
                            })}
                          </Row>
                        </>
                      </div>
                    )}
                  </>
                }
                      <Row className="subheader">
                        <Col xl={24}>
                          VI. DOCUMENTOS ADJUNTOS
                        </Col>
                      </Row>
                      <Row className="summary" style={{marginBottom:20}}>
                        A continuación, adjunte la <strong>Carpeta tributaria para solicitar créditos</strong> con una extracción no mayor a 30 días.
                        Recuerde que debe indicar el <strong>nombre del documento</strong> que adjuntará.
                        Puede adjuntar documentos en <strong>formato pdf, jpg, Word, Excel</strong> cuyo
                        peso no supere los <strong>2MB</strong> por archivo. En total se pueden adjuntar
                        hasta 10 archivos.
                      </Row>
                      { format === 'pdf' ?
                        <Row>
                          { apiForm?.files?.length > 0 ?
                            <List
                              size="small"
                              header="Documentos adjuntos"
                              itemLayout="horizontal"
                              dataSource={apiForm.files}
                              renderItem={item => (
                                <List.Item>
                                  <List.Item.Meta
                                      description={item.name}
                                  />
                                  <List.Item.Meta
                                    description={item.originalName}
                                  />
                                </List.Item>
                              )}
                            />
                            :
                            <div style={{textAlign: 'center'}}>
                              No se adjuntaron documentos como sustento.
                            </div>
                          }
                        </Row>
                        : format === 'html' &&
                        <>
                          <Row>
                              {renderFormItemTable({
                                section: attch.attchFileName,
                                cols: 8
                              })}

                              <Col span={6} offset={1}>
                                <Upload {...propsUpload}>
                                  <Button style={{marginTop:5}}  disabled={!attch.attchFileName.val || tmpFilesList.length > 9} >
                                    <Icon type="upload" /> Subir Archivo
                                  </Button>
                                </Upload>
                              </Col>
                          </Row>
                            {tmpFilesList.length < 1 && validarRegistros && (
                              <Col
                                span={24}
                                style={{paddingTop: 20}}
                                className="missing-registers ant-form-explain"
                              >
                                Se requieren al menos 1 archivo adjunto.
                              </Col>
                            )}
                          <Row>
                            <List
                              size="small"
                              header="Documentos adjuntos"
                              itemLayout="horizontal"
                              dataSource={tmpFilesList.map((file, index)=> {return {fileName: file.name, docName: fileNames[index], index: index}})}
                              renderItem={item => (
                                <List.Item
                                  actions={[<a onClick={() => handleListDeleted(item.index)}>Eliminar</a>]}
                                >
                                  <List.Item.Meta
                                      description={item.docName}
                                  />
                                  <List.Item.Meta
                                    description={item.fileName}
                                  />
                                </List.Item>
                              )}
                            />
                          </Row>
                        </>
                      }
                      {format === "html" &&
                      <>
                        <Row className="subheader">
                          <Col xl={24}>
                            VII. ENVÍO
                          </Col>
                        </Row>

                        {!signed &&
                          <Row className="button-row">
                            <Col className="submitTrabajador" xl={24}>
                              <Button type="primary" onClick={handleSubmit} disabled={openSigner} icon={openSigner ? 'loading' : 'file-protect'}>
                                { hasSign ? 'Firmar' : 'Enviar' }
                              </Button>
                            </Col>
                          </Row>
                        }
                      </>
                      }
                    </>
                    }
                    </>
                  )}
                </Form>
              </div>
            ) : (
              <h2 style={{ textAlign: "center" }}>Formulario no encontrado</h2>
            )}
          </>
        )}
      </div>
    </FormLayout>
  );
};

export default withRouter(Form.create()(FormKyc));
