import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Pagination, Row, Select, Spin} from "antd";

import './AlertsGeneratedByRegisterCard.scss'
import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import getAlertResumeByRegisterPromise from "../../../../promises/getAlertResumeByRegister";
import {camelizerHelper} from "../../../../../../helpers";


const AlertsGeneratedByRegisterCard = ({categories, globalActiveCategory, handleTabAlertas}) => {

    const {t} = useTranslation()

    const [allRegisters, setAllRegisters] = useState([])
    const [itemsTotalNum, setItemsTotalNum] = useState(-1)
    const [pendingOnlyAlerts, setPendingOnlyAlerts] = useState(0)
    const [activeCategory, setActiveCategory] = useState(globalActiveCategory ? globalActiveCategory : null);
    const [currentPage, setCurrentPage] = useState(1)


    const itemsPerPage = 6;
    const percent = [0, 20, 40, 60, 80, 100]

    let filters = {}

    useEffect(() => {
        handleSearch(1, filters)
    }, [activeCategory, pendingOnlyAlerts]);

    const handleSearch = async (page, filters) => {
        //busca las alertas con mayor concurrencia
        const fromNum = ((page - 1) * itemsPerPage)

        filters.from = fromNum
        filters.size = itemsPerPage
        if(pendingOnlyAlerts) filters.pendingOnlyAlerts = pendingOnlyAlerts
        if(activeCategory) filters.category = activeCategory

        const response = await getAlertResumeByRegisterPromise(filters)
        let data = response.data
        setItemsTotalNum(data.total)

        setAllRegisters(data.records)
        setCurrentPage(page)

    }

    //Assigned alert card
    const getLegend = () => {
        return (
            <Col span={17} style={{paddingBottom: '20px'}}>
                Se presentanlos últimos 6 últimos &nbsp;
                {activeCategory ? (t('messages.aml.monitor.principal.rules.cat.' + activeCategory)) : t('messages.aml.records')}
                &nbsp; que acumulan la mayor cantidad de alertas.
            </Col>)
    }

    const handleSetPendingalertsOnly = (pendingOnly) => {
        setPendingOnlyAlerts(pendingOnly);
    };

    const handlePaginationChange = async (value) => {
        handleSearch(value, filters)
    }

    function getWidth(alertData) {
        return alertData.alertsClosed * 100 / alertData.totalCount
    }

    const handleSetActiveCategory = (category) => {
        setActiveCategory(category);
    };

    const getFilters = (rut, status) => {
        let filters = {rutNombre: rut}
        if(activeCategory) filters.categories = [activeCategory]
        if(status) filters.states = [status]
        return filters
    }

    const getContent = (registers) => {

        return (
            <>
                {registers.length > 0 ?

                    <>
                        <br/>
                        <br/>
                        {registers.map((alert, index) =>
                            <Row className={"row-principal-content"} key={index}>
                                <Col span={6} offset={1}>
                                    <div className={"alert-generated-name"}>
                                        <a onClick={() => handleTabAlertas(getFilters(alert.rut))}>
                                            <span className={"asigned-name"}>{camelizerHelper(alert.name)} </span>
                                        </a>
                                    </div>
                                </Col>

                                <Col span={14} offset={1}>
                                    <div className={"full-advance"}>
                                        <div className={"level-open-alerts"}
                                            onClick={() => handleTabAlertas(getFilters(alert.rut, 'OPEN'))}
                                        >{alert.alertsOpen > 0 && alert.alertsOpen}</div>

                                        {alert.alertsClosed > 0 &&
                                            <div className="level-advance-indicator" style={{width: getWidth(alert) + '%'}}
                                                onClick={() => handleTabAlertas(getFilters(alert.rut, 'CLOSED'))}
                                                >
                                                <div className={"level-closed-alerts"}>{alert.alertsClosed} </div>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>)}

                        <Row className={"row-percent"}>
                            <Col span={16} offset={7}>
                                <div>
                                    {percent.map(scale =>
                                            <Col span={4} className={"col-space"}>
                                    {scale}%
                                </Col>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row className={"row-info-color-label"}>
                            <Col span={13} offset={9}>

                                <Row>
                                    <Col span={8}>
                                        <div className={"label-legend closed"}></div>
                                        <div
                                            className={"label-content"}>&nbsp;{t("messages.aml.monitor.status.CLOSED")}</div>
                                    </Col>

                                    <Col span={8} offset={1}>
                                        <div className={"label-legend open"}></div>
                                        &nbsp;&nbsp;
                                        <div
                                            className={"label-content"}> &nbsp;{t("messages.aml.monitor.status.OPEN")}</div>
                                    </Col>
                                    <br/>
                                </Row>
                            </Col>
                        </Row>
                    </>

                    :
                        <div className={"label-no-info"}>
                            <Row>
                                <Col offset={7}>
                                    {t('messages.aml.monitor.no.info')}
                                </Col>
                            </Row>
                        </div>
                }

                <br/>
                <Row>
                    <Col span={8}>
                        <Select
                            style={{width: "100%"}}
                            value={pendingOnlyAlerts}
                            placeholder={"Todas las alertas"}
                            onChange={value => handleSetPendingalertsOnly(value)}
                        >
                            <Select.Option value={0}>Todas las alertas</Select.Option>
                            <Select.Option value={1}>Solo alertas pendientes</Select.Option>

                        </Select>
                    </Col>
                    {registers.length > 0 &&
                        <Col span={6} offset={10}>
                            <Pagination onChange={handlePaginationChange}
                                        pageSize={itemsPerPage}
                                        current={currentPage}
                                        total={itemsTotalNum}
                                        simple={true}
                                //showTitle={false}
                            />
                        </Col>
                    }
                </Row>
            </>
        )
    }


    return (
        <>
            <div className={'alerts-generated-content'}>


                <LargeCardDashboard
                    key={activeCategory}
                    title={t("messages.aml.monitor.rules.by.category." + activeCategory)}
                    legend={getLegend(null)}
                    content={getContent(allRegisters)}
                    categories={categories}
                    activeCategory={activeCategory}
                    handleSetActiveCategory={handleSetActiveCategory}
                />


            </div>

        </>
    )

}

export default AlertsGeneratedByRegisterCard;