import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ShortCardDashobard from "../CardDashboard/ShortCardDashobard/ShortCardDashobard";
import {Col, Input, Modal, notification, Row, Spin, Select} from "antd";
import './NotificationCard.scss'
import { getResumeAlertsPromise } from "../../../../promises";
import moment from "moment";
import {ModalClientCard} from "../../../../../Register/components";
import {getClientsPromise} from "../../../../../Register/promises";


const NotificationCard = ({currentUser, categories, handleTabAlertas, handleSetActiveCategory }) => {

    const {t} = useTranslation()

    const [alertResume, setAlertResume] = useState([]);
    const [dateNow, setDateNow] = useState([moment().valueOf(), moment().valueOf()])
    const [recordFicha, setRecordFicha] = useState(null)


    const filter = {
        dateRange: dateNow,
        fechaAlerta: dateNow
    }

    useEffect(() => {
        handleSearch();
    }, []);


    const handleSearch = async () => {
        getResumeAlertsPromise(filter).then(response => {
            setAlertResume(response.data);
        })
    };

    const handleSearchByRut = rut => {
        getClientsPromise(0, 1, {rut}).then(items => {
            if (items && items.records && items.records.length > 0) {
                setRecordFicha(items.records[0])
            } else {
                notification.info({
                    message: t('messages.aml.monitor.notification.message'),
                    description: t('messages.aml.monitor.notification.not.info')
                })
            }
        })
    }

    const handleCloseModalFicha = () => {
        setRecordFicha(null)
    }

    const contentCategories = () => {
        return (
            <Select
                style={{width: "100%"}}
                onChange={(value) => handleSetActiveCategory(value === undefined ? null : value)}
                placeholder={t('messages.aml.category')}
                allowClear
            >
                {categories.map((category, index) =>
                    <Select.Option value={category} key={index}>
                        <span>{t('messages.aml.category.' + category)} </span>
                    </Select.Option>
                )}
            </Select>
        )
    }

    const contentNotification = () => {
        let countTotal = 0
        alertResume.map(resume => {
            countTotal += resume.alertCount
        })


        let countRisk = 0
        alertResume.map(resume => {
            if (resume.risk === 3 || resume.risk === 4) {
                countRisk += resume.alertCount
            }
        })

        return (
            <>
                <div>
                    Bienvenido {currentUser.name} al Gestor de Alertas.
                </div>

                <div className={"welcome-message"}>
                    Informamos que el día de hoy llegaron&nbsp;
                    { countTotal > 0 ?
                        <a className={"notification-count"} onClick={() => handleTabAlertas(filter)}>{countTotal}</a>
                        :
                        0
                    }
                    &nbsp;alertas y&nbsp;
                    { countRisk > 0?
                        <a className={"notification-count"} onClick={() => handleTabAlertas({ ...filter, risks: [3,4] })}>{countRisk}</a>
                        :
                        0
                    }&nbsp;de ellas son de riesgo alto o crítico.
                </div>
            </>
        )
    }

    const contentQuery = () => {
        return (
            <>
                <div>
                    <div>Ingrese el documento de identidad.</div>
                    El resultado lo llevará a ficha de la persona consultada.
                </div>
                <div className="search-rut">
                    <Input.Search
                        style={{marginTop: '13px'}}
                        placeholder= {t('messages.aml.monitor.notification.search.rut')}
                        onSearch={handleSearchByRut}
                    />
                </div>
            </>
        )
    }

    return (
        <>

            <div className='notification-card'>
                <Row gutter={12} type="flex">
                    <Col span={7}>
                        <div className="section-General-category">
                            <ShortCardDashobard
                                    title={t('messages.aml.category')}
                                    content={contentCategories()}
                                />
                        </div>
                    </Col>
                    <Col span={10}>
                        <div className="left-card">

                            {!alertResume ?

                                <div className="cake-is-loading">
                                    <Spin spinning={true}/>
                                </div>
                                :

                                <ShortCardDashobard
                                    title={t('messages.aml.monitor.dashboard.notification')}
                                    content={contentNotification()}
                                />
                            }
                        </div>
                    </Col>
                    <Col span={7}>
                        <div className="right-card">

                            {!alertResume ?
                                <div className="cake-is-loading">
                                    <Spin spinning={true}/>
                                </div>
                                :
                                <ShortCardDashobard
                                    title={t('messages.aml.monitor.dashboard.quick.reference')}
                                    content={contentQuery()}/>
                            }
                        </div>
                    </Col>
                </Row>

                {recordFicha &&
                    <Modal
                        wrapClassName="modal-fichaCliente-register"
                        style={{top: '10px'}}
                        title={t('messages.aml.monitor.notification.client.record')}
                        visible={true}
                        onCancel={handleCloseModalFicha}
                        cancelText="Cerrar"
                        footer={null}
                        width={'95vw'}
                    >
                        <ModalClientCard item={recordFicha} handleCancel={handleCloseModalFicha}
                                         currentUser={currentUser}/>
                    </Modal>
                }
            </div>

        </>

    )
}

export default NotificationCard;