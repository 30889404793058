import { notification } from 'antd'
import { stakeholderService } from '../services'
import i18nextConfig from '../../../config/i18next'

const changePasswordUserPortal = (dni, password, newPassword, countryCode) => {
  return new Promise(async resolve => {
    if (password === '' || newPassword === '' || countryCode === '') {
      notification.error({ message: i18nextConfig.t('messages.aml.missingRequiredField') })
      return false
    }
    await stakeholderService.changePwdUserPortal(dni, password, newPassword, countryCode)
      .then(response => {
        if (response.data.code === '202') {
          notification.success({ message: i18nextConfig.t('messages.aml.success.passwordSuccesfullyUpdated') })
          resolve(true)
        } else {
          notification.error({message: response.data.message})
        }
      })
      .catch(err => { 
        return notification.error({message: "Code: "+err.response.data.code, description: err.response.data.message})})
  })
}
 export default changePasswordUserPortal;