import getCategories from './getCategories'
import getForm from './getForm'
import sendForm from './sendForm'
import getParams from './getParams'
import saveFormOnb from './saveFormOnb'
import signCDIForm from './signCDIForm'
import getAreas from './getAreas'
import getClientsMin from './getClientsMin'

import getDeclarations from './getDeclarations'
import sendFileForm from './sendFileForm'
import getCountriesCode from './getCountriesCode'
import getItems from './getItems'
import getTransferData from './getTransferData'
import sendRequestMatches from '../services/sendRequestMatches'
import requestMatchFile from './requestMatchFile'

export const getClientsMinPromise = getClientsMin
export const getAreasPromise = getAreas
export const getCategoriesPromise = getCategories
export const getFormPromise = getForm
export const sendFormPromise = sendForm
export const getParamsPromise = getParams
export const saveFormOnbPromise = saveFormOnb
export const signCDIFormPromise = signCDIForm

export const getDeclarationsPromise = getDeclarations
export const sendFileFormPromise = sendFileForm
export const getCountriesCodePromise = getCountriesCode
export const getItemsPromise = getItems
export const getTransferDataPromise = getTransferData;
export const sendRequestMatchesPromise = sendRequestMatches;
export const requestMatchFilePromise = requestMatchFile;
