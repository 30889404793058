import './Dashboard.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Icon, Input, Button, DatePicker, Progress, Select, Modal, notification, Spin, message } from 'antd'
import {ModalNewRequestPage} from '../'
import { ModalClientCard } from '../../../Register/components'
import { getItemsPromise as getItemForm } from '../FormManager/promises'
import { getClientsPromise, getFiltersCLClientesPromise, getRiskCLClientesPromise } from '../../../Register/promises'
import moment from "moment";
import Plot from "react-plotly.js";

const {RangePicker} = DatePicker;
const {Option} = Select;

const Dashboard = ({currentUser, handleTabChange}) =>{
    const [modalRequestIsVisible, setModalRequestIsVisible] = useState(false);
    const {t} = useTranslation();
    const [recordFicha, setRecordFicha] = useState(null)
    const [rut, setRut] = useState(null)
    const [itemsFormReceived, setItemsFormReceived] = useState(null)
    const [itemsForm, setItemsForm] = useState(null)
    const [pendientesForm, setPendientesForm] = useState({})

    const [recordsReal, setRecordsReal] = useState({})
    const [recordsPend, setRecordsPend] = useState({})

    //RISK STATES
    const [risks, setRisks] = useState({})

    const [sendDateFormPend, setSendDateFormPend] = useState(null)
    const [sendDateFormReal, setSendDateFormReal] = useState(null)
    const [typePersonFicReal, setTypePersonFicReal] = useState(null)
    const [typePersonFicPend, setTypePersonFicPend] = useState(null)
    const [typePersonRisk, setTypePersonRisk] = useState(null)

    useEffect(() => {
        handleFormDateReceived()
        handlePendientesDate()
        handleFormDate()
        loadRecordsReal()
        loadRecordsPend()
        loadFichasRisk()
    }, [])

    useEffect(() => {
    }, [recordsReal])

    const colors ={
        black: 'rgba(0,0,0,.7)',
        red: '#d9534f',
        orange: '#FE9F0C',
        yellow: '#fcdc6b',
        green: '#598756',
        na: '#999'
    }
    const clientPieData = [
        { title: t('messages.aml.riskCritical')+": ", value: risks.CLIENTE?.black, color: colors.black },
        { title: t('messages.aml.riskHigh')+": ", value: risks.CLIENTE?.red, color: colors.red },
        { title: t('messages.aml.riskMedium')+": ", value: risks.CLIENTE?.orange, color: colors.orange },
        { title: t('messages.aml.riskLow')+": ", value: risks.CLIENTE?.yellow, color: colors.yellow },
        { title: t('messages.aml.risk.N')+": ", value: risks.CLIENTE?.green, color: colors.green },
        { title: t('messages.aml.notProcessed')+": ", value: risks.CLIENTE?.na, color: colors.na }
    ]

    const provPieData = [
        { title: t('messages.aml.riskCritical')+": ", value: risks.PROVEEDOR?.black, color: colors.black },
        { title: t('messages.aml.riskHigh')+": ", value: risks.PROVEEDOR?.red, color: colors.red },
        { title: t('messages.aml.riskMedium')+": ", value: risks.PROVEEDOR?.orange, color: colors.orange },
        { title: t('messages.aml.riskLow')+": ", value: risks.PROVEEDOR?.yellow, color: colors.yellow },
        { title: t('messages.aml.risk.N')+": ", value: risks.PROVEEDOR?.green, color: colors.green },
        { title: t('messages.aml.notProcessed')+": ", value: risks.PROVEEDOR?.na, color: colors.na }
    ]

    const dirPieData = [
        { title: t('messages.aml.riskCritical')+": ", value: risks.DIRECTOR?.black, color: colors.black },
        { title: t('messages.aml.riskHigh')+": ", value: risks.DIRECTOR?.red, color: colors.red },
        { title: t('messages.aml.riskMedium')+": ", value: risks.DIRECTOR?.orange, color: colors.orange },
        { title: t('messages.aml.riskLow')+": ", value: risks.DIRECTOR?.yellow, color: colors.yellow },
        { title: t('messages.aml.risk.N')+": ", value: risks.DIRECTOR?.green, color: colors.green },
        { title: t('messages.aml.notProcessed')+": ", value: risks.DIRECTOR?.na, color: colors.na }
    ]

    const colabPieData = [
        { title: t('messages.aml.riskCritical')+": ", value: risks.COLABORADOR?.black, color: colors.black},
        { title: t('messages.aml.riskHigh')+": ", value: risks.COLABORADOR?.red, color: colors.red },
        { title: t('messages.aml.riskMedium')+": ", value: risks.COLABORADOR?.orange, color: colors.orange },
        { title: t('messages.aml.riskLow')+": ", value: risks.COLABORADOR?.yellow, color: colors.yellow },
        { title: t('messages.aml.risk.N')+": ", value: risks.COLABORADOR?.green, color: colors.green },
        { title: t('messages.aml.notProcessed')+": ", value: risks.COLABORADOR?.na, color: colors.na }
    ]

    const handleFormDateReceived = (dates) =>{
        let startdate = moment().valueOf()
        let endDate = moment().valueOf()
        if(dates && dates.length > 0) {
            startdate = dates[0].valueOf()
            endDate = dates[1].valueOf()
        }
        const completeDate = [ startdate, endDate ]

        getItemForm('KYC', {completeDate}).then(results => {
            if(results){
                setItemsFormReceived(results.data.filters.status.filter(i => i.status !== 'PENDIENTE').reduce((acc, value) => {return acc+value.total},0))
            }else{
                message.error("Error al cargar los datos")
            }
        })
    }

    const handlePendientesDate = (dates) =>{
        let startdate = moment().subtract('days', 30).valueOf()
        let endDate = moment().valueOf()
        if(dates && dates.length > 0) {
            startdate = dates[0].valueOf()
            endDate = dates[1].valueOf()
        }
        const sendDate = [ startdate, endDate ]
        setSendDateFormPend(sendDate)

        getItemForm('KYC', {sendDate, statusDecl: 'PENDIENTE'}).then(results => {
            if(results){
                const PROVEEDOR = results.data.filters.status.filter(i => i.categoria === 'PROVEEDOR').reduce((acc, value) => {return acc+value.total},0)
                const COLABORADOR = results.data.filters.status.filter(i => i.categoria === 'COLABORADOR').reduce((acc, value) => {return acc+value.total},0)
                const DIRECTOR = results.data.filters.status.filter(i => i.categoria === 'DIRECTOR').reduce((acc, value) => {return acc+value.total},0)
                const CLIENTE = results.data.filters.status.filter(i => i.categoria === 'CLIENTE').reduce((acc, value) => {return acc+value.total},0)

                setPendientesForm({CLIENTE, PROVEEDOR, COLABORADOR, DIRECTOR})
            }else{
                message.error("Error al cargar los datos")
            }
        })
    }

    const handleFormDate = (dates) =>{
        let startdate = moment().subtract('days', 30).valueOf()
        let endDate = moment().valueOf()
        if(dates && dates.length > 0) {
            startdate = dates[0].valueOf()
            endDate = dates[1].valueOf()
        }
        const sendDate = [ startdate, endDate ]
        setItemsForm(null)
        setSendDateFormReal(sendDate)

        getItemForm('KYC', {sendDate}).then(results => {
            if(results){
                const formProv = results.data.filters.status.filter(i => i.categoria === 'PROVEEDOR' && i.status !== 'PENDIENTE').reduce((acc, value) => {return acc+value.total},0)
                const formColab = results.data.filters.status.filter(i => i.categoria === 'COLABORADOR' && i.status !== 'PENDIENTE').reduce((acc, value) => {return acc+value.total},0)
                const formDire = results.data.filters.status.filter(i => i.categoria === 'DIRECTOR' && i.status !== 'PENDIENTE').reduce((acc, value) => {return acc+value.total},0)
                const formClient = results.data.filters.status.filter(i => i.categoria === 'CLIENTE' && i.status !== 'PENDIENTE').reduce((acc, value) => {return acc+value.total},0)

                const totalFormProv = results.data.filters.status.filter(i => i.categoria === 'PROVEEDOR').reduce((acc, value) => {return acc+value.total},0)
                const totalFormColab = results.data.filters.status.filter(i => i.categoria === 'COLABORADOR').reduce((acc, value) => {return acc+value.total},0)
                const totalFormDir = results.data.filters.status.filter(i => i.categoria === 'DIRECTOR').reduce((acc, value) => {return acc+value.total},0)
                const totalFormClient = results.data.filters.status.filter(i => i.categoria === 'CLIENTE').reduce((acc, value) => {return acc+value.total},0)

                const PROVEEDOR = {cant: formProv, total: totalFormProv}
                const COLABORADOR = {cant: formColab, total: totalFormColab}
                const DIRECTOR = {cant: formDire, total: totalFormDir}
                const CLIENTE = {cant: formClient, total: totalFormClient}
                setItemsForm({ PROVEEDOR, COLABORADOR, DIRECTOR, CLIENTE })
            }else{
                message.error("Error al cargar los datos")
            }
        })
    }

    const handleFicRealTypePerson = (tipoPersona) => {
        setTypePersonFicReal(tipoPersona)
        loadRecordsReal(tipoPersona)
    }

    const handleFicPendTypePerson = (tipoPersona) => {
        setTypePersonFicPend(tipoPersona)
        loadRecordsPend(tipoPersona)
    }

    const loadRecordsReal = async (tipoPersona) => {
        await setRecordsReal({})
        let filters = {estados: ['ACTIVE']}
        if(tipoPersona) filters.types = [tipoPersona]
        getFiltersCLClientesPromise(filters).then(results => {
            if(results.categories.filter(c => 'CLIENTE' === c.categoria).length === 0) results.categories.push({categoria: 'CLIENTE', cant: 0})
            if(results.categories.filter(c => 'COLABORADOR' === c.categoria).length === 0) results.categories.push({categoria: 'COLABORADOR', cant: 0})
            if(results.categories.filter(c => 'PROVEEDOR' === c.categoria).length === 0) results.categories.push({categoria: 'PROVEEDOR', cant: 0})
            if(results.categories.filter(c => 'DIRECTOR' === c.categoria).length === 0) results.categories.push({categoria: 'DIRECTOR', cant: 0})
            results.categories.map(cat => {
                let filters2 = {estados: ['ACTIVE'], categories: [cat.categoria], onb: {hasCompletedForm: 'Y'}}
                if(tipoPersona) filters2.types = [tipoPersona]
                getClientsPromise(0, 0, filters2).then(r => {
                    let objCat = {total: cat.cant, cant: r.total}
                    setRecordsReal(oldRecordsReal => {
                        let newRecordsReal = {...oldRecordsReal}
                        newRecordsReal[cat.categoria] = objCat

                        return newRecordsReal
                    })
                })
            })
        })
    }

    const loadRecordsPend = async (tipoPersona) => {
        await setRecordsPend({})
        let filters = {estados: ['ACTIVE']}
        if(tipoPersona) filters.types = [tipoPersona]
        getFiltersCLClientesPromise(filters).then(results => {
            if(results.categories.filter(c => 'CLIENTE' === c.categoria).length === 0) results.categories.push({categoria: 'CLIENTE', cant: 0})
            if(results.categories.filter(c => 'COLABORADOR' === c.categoria).length === 0) results.categories.push({categoria: 'COLABORADOR', cant: 0})
            if(results.categories.filter(c => 'PROVEEDOR' === c.categoria).length === 0) results.categories.push({categoria: 'PROVEEDOR', cant: 0})
            if(results.categories.filter(c => 'DIRECTOR' === c.categoria).length === 0) results.categories.push({categoria: 'DIRECTOR', cant: 0})
            results.categories.map(cat => {
                let filters2 = {estados: ['ACTIVE'], categories: [cat.categoria], onb: {hasCompletedForm: 'N'}}
                if(tipoPersona) filters2.types = [tipoPersona]
                getClientsPromise(0, 0, filters2).then(r => {
                    let objCat = {total: cat.cant, cant: r.total}
                    setRecordsPend(oldRecordsPend => {
                        let newRecordsPend = {...oldRecordsPend}
                        newRecordsPend[cat.categoria] = objCat

                        return newRecordsPend
                    })
                })
            })
        })
    }

    const loadRiskCategory = (category, tipoPersona) => {
        let filters = {estados: ['ACTIVE'], categories: [category]}
        if(tipoPersona) filters.types = [tipoPersona]
        getRiskCLClientesPromise(filters).then(results => {
            setRisks(oldRisks => {
                let newRisks = {...oldRisks}
                let obj = {total: results.total}
                obj.green = results.GREEN ? results.GREEN : 0
                obj.yellow = results.YELLOW ? results.YELLOW : 0
                obj.orange = results.ORANGE ? results.ORANGE : 0
                obj.red = results.RED ? results.RED : 0
                obj.black = results.BLACK ? results.BLACK : 0
                obj.na = results.NA ? results.NA : 0
                newRisks[category] = obj
                return newRisks
            })
        })
    }

    const loadFichasRisk = async (tipoPersona) => {
        await setRisks({})

        loadRiskCategory('CLIENTE', tipoPersona)
        loadRiskCategory('PROVEEDOR', tipoPersona)
        loadRiskCategory('DIRECTOR', tipoPersona)
        loadRiskCategory('COLABORADOR', tipoPersona)
    }

    const handleTypePersonRisk = (tipoPersona) =>{
        setTypePersonRisk(tipoPersona)
        loadFichasRisk(tipoPersona)
    }

    const openModalRequest = () => {
        setModalRequestIsVisible(true);
    }
    const closeModalRequest = () => {
        setModalRequestIsVisible(false);
    }

    const handleSearchRut = rut => {
        getClientsPromise(0, 1, {rut}).then(items => {
            if(items && items.records && items.records.length > 0) {
                setRecordFicha(items.records[0])
            }else {
                notification.info({
                    message: 'Consulta rápida',
                    description: 'No hay coincidencias'
                })
            }
        })
    }

    const handleCloseModalFicha = () => {
        setRecordFicha(null)
    }

    const handleTabChangeFormsPend = (category, options) => {
        handleTabChange('tab-forms', {...options, category, sendDate: sendDateFormPend})
    }

    const handleTabChangeFormsReal = (category, options) => {
        handleTabChange('tab-forms', {...options, category, sendDate: sendDateFormReal})
    }

    const handleTabChangeFichasReal = (category, hasCompletedForm) => {
        let filters = {categories: [category], estados: ['ACTIVE']}
        if(typePersonFicReal) filters.types = [typePersonFicReal]
        if(hasCompletedForm) filters.onb = {hasCompletedForm}
        handleTabChange('records', filters)
    }

    const handleTabChangeFichasPend = (category, hasCompletedForm) => {
        let filters = {categories: [category], estados: ['ACTIVE']}
        if(typePersonFicPend) filters.types = [typePersonFicPend]
        if(hasCompletedForm) filters.onb = {hasCompletedForm}
        handleTabChange('records', filters)
    }

    const handleTabChangeFichasRisk = (category, risk) => {
        let filters = {estados: ['ACTIVE'], categories: [category], amlStatus: [risk]}
        if(typePersonRisk) filters.types = [typePersonRisk]
        handleTabChange('records', filters)
    }

    const graphGenerator = (graphData) => {
        const values = graphData.map(x => x.value);
        const labels = graphData.map(x => x.title);
        const color =  graphData.map(x => x.color);
        const total = graphData.reduce((acc, value) => acc+value.value, 0)

        const dataGraph = [{
                type: "pie",
                values: values,
                labels: labels,
                hoverinfo: "label+percent+name",
                textposition: "inside",
                textinfo: 'none',
                automargin: false,
                hole: .7,
                marker: {
                    colors: color
                },
            },
        ]
        const layoutGraph = {
            showlegend: false,
            height: 127,
            width: 127,
            margin: {
                l: 4,
                r: 4,
                b: 4,
                t: 4,
            },
            annotations: [
                {
                    font: {
                        size: 18
                    },
                    showarrow: false,
                    text: total
                }
            ]
        }

        return (
            <Plot
                data={dataGraph}
                layout={layoutGraph}
            />
        )
    }

    return (
        <div className="dashboard">
            {!modalRequestIsVisible ?
            <>
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <div className="section">
                            <h3>{t('messages.aml.monitor.dashboard.notification')}</h3>
                            <div>
                                <div>Bienvenido {currentUser.name} al módulo de OnBoarding.</div>
                                <div>Informamos que el día de hoy llegaron {itemsFormReceived} formularios</div>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="section">
                            <h3>{t('messages.aml.request')}</h3>
                            <div>
                                <Icon type="right" /> <a href="#" onClick={openModalRequest}> Seleccione las personas a quienes enviará el formulario OnBoarding </a>
                            </div> 
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <div className="section">
                            <Row>
                                <h3>{t('messages.aml.monitor.dashboard.quick.reference')}</h3>
                            </Row>
                            <Row>
                                <p> 
                                    Ingrese el documento de indentidad.
                                    <br/>
                                    El resultado lo llevará a la ficha de la persona consultada
                                </p>
                            </Row>
                            <Row>
                                <Col span={18} offset={6}>
                                    <Input.Search
                                        style={{marginTop: '11px'}}
                                        placeholder="Buscar rut"
                                        onChange={(e) => setRut(e.target.value)}
                                        onSearch={handleSearchRut}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <Button style={{float:'right', marginTop:'11px', backgroundColor:'rgb(53 53 195)', color:'white'}} onClick={() => rut && handleSearchRut(rut)}>Buscar</Button>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="section">
                            <Row>
                              <h3>Formularios pendientes</h3>
                            </Row>
                            <Row>
                              <p>
                                  A continuación se presentan los formularios que se 
                                  encuentran pendientes en el último mes.
                              </p>
                            </Row>
                            <Row>
                              <Row>
                                    <Col span={12}>
                                        <a className="pendientes-text-onb" onClick={() => handleTabChangeFormsPend('CLIENTE', {statusDecl: 'PENDIENTE'})}>
                                            {t('messages.aml.menu.category.CLIENTE')}: {pendientesForm.CLIENTE}
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className="pendientes-text-onb" onClick={() => handleTabChangeFormsPend('DIRECTOR', {statusDecl: 'PENDIENTE'})}>
                                            {t('messages.aml.menu.category.DIRECTOR')}: {pendientesForm.DIRECTOR}
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className="pendientes-text-onb" onClick={() => handleTabChangeFormsPend('PROVEEDOR', {statusDecl: 'PENDIENTE'})}>
                                            {t('messages.aml.menu.category.PROVEEDOR')}: {pendientesForm.PROVEEDOR}
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a className="pendientes-text-onb" onClick={() => handleTabChangeFormsPend('COLABORADOR', {statusDecl: 'PENDIENTE'})}>
                                            {t('messages.aml.menu.category.COLABORADOR')}: {pendientesForm.COLABORADOR}
                                        </a>
                                    </Col>
                              </Row>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <div className="rp-inner-wrapper">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            placeholder={["Fec. Desde", "Hasta"]}
                                            onChange={(value) => {handlePendientesDate(value)}}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className="section">
                            {itemsForm ?
                                <>
                                    <Row gutter={[16, 16]}>
                                        <h3>Formularios de OnBoarding solicitados</h3>
                                        <p>Se presenta el avance del último mes</p>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <div className="percent-block-wrapper-onb">
                                            <Col span={6}>
                                                <Progress
                                                    type="circle"
                                                    percent={itemsForm.CLIENTE.total > 0 && parseInt((itemsForm.CLIENTE.cant*100/itemsForm.CLIENTE.total).toFixed(0))}
                                                    format={percent => `${percent}%`}
                                                    onClick={() => handleTabChangeFormsReal('CLIENTE')}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <Progress
                                                    type="circle"
                                                    percent={itemsForm.PROVEEDOR.total > 0 && parseInt((itemsForm.PROVEEDOR.cant*100/itemsForm.PROVEEDOR.total).toFixed(0))}
                                                    format={percent => `${percent}%`}
                                                    onClick={() => handleTabChangeFormsReal('PROVEEDOR')}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <Progress
                                                    type="circle"
                                                    percent={itemsForm.DIRECTOR.total > 0 && parseInt((itemsForm.DIRECTOR.cant*100/itemsForm.DIRECTOR.total).toFixed(0))}
                                                    format={percent => `${percent}%`}
                                                    onClick={() => handleTabChangeFormsReal('DIRECTOR')}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <Progress
                                                    type="circle"
                                                    percent={itemsForm.COLABORADOR.total > 0 && parseInt((itemsForm.COLABORADOR.cant*100/itemsForm.COLABORADOR.total).toFixed(0))}
                                                    format={percent => `${percent}%`}
                                                    onClick={() => handleTabChangeFormsReal('COLABORADOR')}
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <div className="label-percent-wrapper-onb">
                                            <Col span={6}>
                                                <a onClick={() => handleTabChangeFormsReal('CLIENTE')}>
                                                    <h3>{t('messages.aml.menu.category.CLIENTE')}</h3>
                                                </a>
                                                <a onClick={() => handleTabChangeFormsReal('CLIENTE', { statusesDecl: ['EVALUACION', 'AUTORIZADA', 'RECHAZADA']})}>
                                                    <h4>Realizados: {itemsForm.CLIENTE.cant}</h4>
                                                </a>
                                            </Col>
                                            <Col span={6}>
                                                <a onClick={() => handleTabChangeFormsReal('PROVEEDOR')}>
                                                    <h3>{t('messages.aml.menu.category.PROVEEDOR')}</h3>
                                                </a>
                                                <a onClick={() => handleTabChangeFormsReal('PROVEEDOR', { statusesDecl: ['EVALUACION', 'AUTORIZADA', 'RECHAZADA']})}>
                                                    <h4>Realizados: {itemsForm.PROVEEDOR.cant}</h4>
                                                </a>
                                            </Col>
                                            <Col span={6}>
                                                <a onClick={() => handleTabChangeFormsReal('DIRECTOR')}>
                                                    <h3>{t('messages.aml.menu.category.DIRECTOR')}</h3>
                                                </a>
                                                <a onClick={() => handleTabChangeFormsReal('DIRECTOR', { statusesDecl: ['EVALUACION', 'AUTORIZADA', 'RECHAZADA']})}>
                                                    <h4>Realizados: {itemsForm.DIRECTOR.cant}</h4>
                                                </a>
                                            </Col>
                                            <Col span={6}>
                                                <a onClick={() => handleTabChangeFormsReal('COLABORADOR')}>
                                                    <h3>{t('messages.aml.menu.category.COLABORADOR')}</h3>
                                                </a>
                                                <a onClick={() => handleTabChangeFormsReal('COLABORADOR', { statusesDecl: ['EVALUACION', 'AUTORIZADA', 'RECHAZADA']})}>
                                                    <h4>Realizados: {itemsForm.COLABORADOR.cant}</h4>
                                                </a>
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <RangePicker
                                                style={{ width: "100%" }}
                                                placeholder={["Fec. Desde", "Hasta"]}
                                                onChange={(value) => {handleFormDate(value)}}
                                                format="DD/MM/YYYY"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            :
                                <Row>
                                    <Col span={24}>
                                        <div className="spin-container-dashboard">
                                            <Spin/>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className="section">
                            <Row gutter={[16, 16]}>
                                <h3>Registros con formulario de OnBoarding realizado</h3>
                                <p>Se presentan aquellos registros que cuentan con al menos un formulario de OnBoarding realizado.</p>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <div className="percent-block-wrapper-onb">
                                    <Col span={6}>
                                        { recordsReal.CLIENTE ?
                                            <Progress
                                                type="circle"
                                                percent={recordsReal.CLIENTE.total > 0 && parseInt((recordsReal.CLIENTE.cant*100/recordsReal.CLIENTE.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasReal('CLIENTE')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        { recordsReal.PROVEEDOR ?
                                            <Progress
                                                type="circle"
                                                percent={recordsReal.PROVEEDOR.total > 0 && parseInt((recordsReal.PROVEEDOR.cant*100/recordsReal.PROVEEDOR.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasReal('PROVEEDOR')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        { recordsReal.DIRECTOR ?
                                            <Progress
                                                type="circle"
                                                percent={recordsReal.DIRECTOR.total > 0 && parseInt((recordsReal.DIRECTOR.cant*100/recordsReal.DIRECTOR.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasReal('DIRECTOR')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        { recordsReal.COLABORADOR ?
                                            <Progress
                                                type="circle"
                                                percent={recordsReal.COLABORADOR.total  > 0 && parseInt((recordsReal.COLABORADOR.cant*100/recordsReal.COLABORADOR.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasReal('COLABORADOR')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </Col>
                                </div>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <div className="label-percent-wrapper-onb">
                                    <Col span={6}>
                                        <a onClick={() => handleTabChangeFichasReal('CLIENTE')}>
                                            <h3>{t('messages.aml.menu.category.CLIENTE')}</h3>
                                        </a>
                                        { recordsReal.CLIENTE &&
                                            <a onClick={() => handleTabChangeFichasReal('CLIENTE', 'Y')}>
                                                <h4>Registros con formulario realizado: {recordsReal.CLIENTE.cant}</h4>
                                            </a>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        <a onClick={() => handleTabChangeFichasReal('PROVEEDOR')}>
                                            <h3>{t('messages.aml.menu.category.PROVEEDOR')}</h3>
                                        </a>
                                        { recordsReal.PROVEEDOR &&
                                            <a onClick={() => handleTabChangeFichasReal('PROVEEDOR', 'Y')}>
                                                <h4>Registros con formulario realizado: {recordsReal.PROVEEDOR.cant}</h4>
                                            </a>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        <a onClick={() => handleTabChangeFichasReal('DIRECTOR')}>
                                            <h3>{t('messages.aml.menu.category.DIRECTOR')}</h3>
                                        </a>
                                        { recordsReal.DIRECTOR &&
                                            <a onClick={() => handleTabChangeFichasReal('DIRECTOR', 'Y')}>
                                                <h4>Registros con formulario realizado: {recordsReal.DIRECTOR.cant}</h4>
                                            </a>
                                        }
                                    </Col>
                                    <Col span={6}>
                                        <a onClick={() => handleTabChangeFichasReal('COLABORADOR')}>
                                            <h3>{t('messages.aml.menu.category.COLABORADOR')}</h3>
                                        </a>
                                        { recordsReal.COLABORADOR &&
                                            <a onClick={() => handleTabChangeFichasReal('COLABORADOR', 'Y')}>
                                                <h4>Registros con formulario realizado: {recordsReal.COLABORADOR.cant}</h4>
                                            </a>
                                        }
                                    </Col>
                                </div>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <Select style={{width:'100%'}} placeholder="Tipo de Persona" onChange={(e)=>{handleFicRealTypePerson(e)}}>
                                        <Option value={null}>
                                            Todos
                                        </Option>
                                        <Option value='Person'>
                                            Persona Natural
                                        </Option>
                                        <Option value='Entity'>
                                            Persona Jurídica
                                        </Option>
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className="section">
                            <Row gutter={[16, 16]}>
                                <h3>Registros con formulario de OnBoarding pendiente</h3>
                                <p>Se presentan aquellos registros que cuentan con al menos un formulario de OnBoarding pendiente y ningún formulario de OnBoarding realizado.</p>
                            </Row>
                            <Row gutter={[16, 16]} className="percent-block-wrapper-onb" type="flex">
                                <Col span={6}>
                                    <div className="content">
                                        { recordsPend.CLIENTE ?
                                            <Progress
                                                type="circle"
                                                percent={recordsPend.CLIENTE.total > 0 && parseInt((recordsPend.CLIENTE.cant*100/recordsPend.CLIENTE.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasPend('CLIENTE')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="content">
                                        { recordsPend.PROVEEDOR ?
                                            <Progress
                                                type="circle"
                                                percent={recordsPend.PROVEEDOR.total > 0 && parseInt((recordsPend.PROVEEDOR.cant*100/recordsPend.PROVEEDOR.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasPend('PROVEEDOR')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="content">
                                        { recordsPend.DIRECTOR ?
                                            <Progress
                                                type="circle"
                                                percent={recordsPend.DIRECTOR.total > 0 && parseInt((recordsPend.DIRECTOR.cant*100/recordsPend.DIRECTOR.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasPend('DIRECTOR')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="content">
                                        { recordsPend.COLABORADOR ?
                                            <Progress
                                                type="circle"
                                                percent={recordsPend.COLABORADOR.total > 0 && parseInt((recordsPend.COLABORADOR.cant*100/recordsPend.COLABORADOR.total).toFixed(0))}
                                                format={percent => `${percent}%`}
                                                onClick={() => handleTabChangeFichasPend('COLABORADOR')}
                                            />
                                            :
                                            <Spin/>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} className="label-percent-wrapper-onb">
                                <Col span={6}>
                                    <a onClick={() => handleTabChangeFichasPend('CLIENTE')}>
                                        <h3>{t('messages.aml.menu.category.CLIENTE')}</h3>
                                    </a>
                                    { recordsPend.CLIENTE &&
                                        <a onClick={() => handleTabChangeFichasPend('CLIENTE', 'N')}>
                                            <h4>Registros con formulario pendiente: {recordsPend.CLIENTE && recordsPend.CLIENTE.cant}</h4>
                                        </a>
                                    }
                                </Col>
                                <Col span={6}>
                                    <a onClick={() => handleTabChangeFichasPend('PROVEEDOR')}>
                                        <h3>{t('messages.aml.menu.category.PROVEEDOR')}</h3>
                                    </a>
                                    { recordsPend.PROVEEDOR &&
                                        <a onClick={() => handleTabChangeFichasPend('PROVEEDOR', 'N')}>
                                            <h4>Registros con formulario pendiente: {recordsPend.PROVEEDOR && recordsPend.PROVEEDOR.cant}</h4>
                                        </a>
                                    }
                                </Col>
                                <Col span={6}>
                                    <a onClick={() => handleTabChangeFichasPend('DIRECTOR')}>
                                        <h3>{t('messages.aml.menu.category.DIRECTOR')}</h3>
                                    </a>
                                    { recordsPend.DIRECTOR &&
                                        <a onClick={() => handleTabChangeFichasPend('DIRECTOR', 'N')}>
                                            <h4>Registros con formulario pendiente: {recordsPend.DIRECTOR && recordsPend.DIRECTOR.cant}</h4>
                                        </a>
                                    }
                                </Col>
                                <Col span={6}>
                                    <a onClick={() => handleTabChangeFichasPend('COLABORADOR')}>
                                        <h3>{t('messages.aml.menu.category.COLABORADOR')}</h3>
                                    </a>
                                    { recordsPend.COLABORADOR &&
                                        <a onClick={() => handleTabChangeFichasPend('COLABORADOR', 'N')}>
                                            <h4>Registros con formulario pendiente: {recordsPend.COLABORADOR && recordsPend.COLABORADOR.cant}</h4>
                                        </a>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <Select style={{width:'100%'}} placeholder="Tipo de Persona" onChange={(e)=>{handleFicPendTypePerson(e)}}>
                                        <Option value={null}>
                                            Todos
                                        </Option>
                                        <Option value='Person'>
                                            Persona Natural
                                        </Option>
                                        <Option value='Entity'>
                                            Persona Jurídica
                                        </Option>
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className="section">
                            <Row gutter={[16, 16]}>
                                <h3>Riesgo</h3>
                                <p>Se presenta el riesgo asociado a los registros por categoría</p>
                            </Row>
                            <Row gutter={[16, 16]} className="percent-block-wrapper-onb">
                                <Col span={6}>
                                    <div className="pie-wrapper-dashboard">
                                        {risks.CLIENTE ?
                                            (
                                            risks.CLIENTE.total > 0 ?
                                                <>{graphGenerator(clientPieData)}</>
                                            :
                                                <div className="na">N/A</div>
                                            )
                                        :
                                            <Spin/>
                                        }
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="pie-wrapper-dashboard">
                                        {(risks.PROVEEDOR?.total) > 0 ?
                                            <>{graphGenerator(provPieData)}</>
                                            :
                                            <div className="na">N/A</div>
                                        }
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="pie-wrapper-dashboard">
                                        {(risks.DIRECTOR?.total) > 0 ?
                                            <>{graphGenerator(dirPieData)}</>
                                            :
                                            <div className="na">N/A</div>
                                        }
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="pie-wrapper-dashboard">
                                        {(risks.COLABORADOR?.total) > 0 ?
                                            <>{graphGenerator(colabPieData)}</>
                                            :
                                            <div className="na">N/A</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]} className="label-percent-wrapper-onb">
                                <Col span={6}>
                                    <h3>{t('messages.aml.menu.category.CLIENTE')}</h3>
                                    { risks.CLIENTE &&
                                    <>
                                        <a onClick={() => handleTabChangeFichasRisk('CLIENTE', 'BLACK')}>
                                            <div className="risk">{t('messages.aml.riskCritical')}: {risks.CLIENTE.black}</div>
                                        </a>
                                        <a onClick={() => handleTabChangeFichasRisk('CLIENTE', 'RED')}>
                                            <div className="risk">{t('messages.aml.riskHigh')}: {risks.CLIENTE.red}</div>
                                        </a>
                                    </>
                                    }
                                </Col>
                                <Col span={6}>
                                    <h3>{t('messages.aml.menu.category.PROVEEDOR')}</h3>
                                    { risks.PROVEEDOR &&
                                    <>
                                        <a onClick={() => handleTabChangeFichasRisk('PROVEEDOR', 'BLACK')}>
                                            <div className="risk">{t('messages.aml.riskCritical')}: {risks.PROVEEDOR.black}</div>
                                        </a>
                                        <a onClick={() => handleTabChangeFichasRisk('PROVEEDOR', 'RED')}>
                                            <div className="risk">{t('messages.aml.riskHigh')}: {risks.PROVEEDOR.red}</div>
                                        </a>
                                    </>
                                    }
                                </Col>
                                <Col span={6}>
                                    <h3>{t('messages.aml.menu.category.DIRECTOR')}</h3>
                                    { risks.DIRECTOR &&
                                    <>
                                        <a onClick={() => handleTabChangeFichasRisk('DIRECTOR', 'BLACK')}>
                                            <div className="risk">{t('messages.aml.riskCritical')}: {risks.DIRECTOR.black}</div>
                                        </a>
                                        <a onClick={() => handleTabChangeFichasRisk('DIRECTOR', 'RED')}>
                                            <div className="risk">{t('messages.aml.riskHigh')}: {risks.DIRECTOR.red}</div>
                                        </a>
                                    </>
                                    }
                                </Col>
                                <Col span={6}>
                                    <h3>{t('messages.aml.menu.category.COLABORADOR')}</h3>
                                    { risks.COLABORADOR &&
                                    <>
                                        <a onClick={() => handleTabChangeFichasRisk('COLABORADOR', 'BLACK')}>
                                            <div className="risk">{t('messages.aml.riskCritical')}: {risks.COLABORADOR.black}</div>
                                        </a>
                                        <a onClick={() => handleTabChangeFichasRisk('COLABORADOR', 'RED')}>
                                            <div className="risk">{t('messages.aml.riskHigh')}: {risks.COLABORADOR.red}</div>
                                        </a>
                                    </>
                                    }
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={6}>
                                    <Select style={{width:'100%'}} placeholder="Tipo de Persona" onChange={(e) => {handleTypePersonRisk(e)}}>
                                        <Option value={null}>
                                            Todos
                                        </Option>
                                        <Option value='Person'>
                                            Persona Natural
                                        </Option>
                                        <Option value='Entity'>
                                            Persona Jurídica
                                        </Option>
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </>
            :
                <ModalNewRequestPage currentUser={currentUser} closeModalRequest={closeModalRequest} />
            }

            {recordFicha &&
                <Modal
                    wrapClassName="modal-fichaCliente-register"
                    style={{top:'10px'}}
                    title={"Ficha de Cliente"}
                    visible={true}
                    onCancel={handleCloseModalFicha}
                    cancelText="Cerrar"
                    footer={null}
                    width={'95vw'}
                >
                    <ModalClientCard item={recordFicha} handleCancel={handleCloseModalFicha} currentUser={currentUser} />
                </Modal>
            }
        </div>

    )
}

export default Dashboard
