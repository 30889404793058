import './RelLaboralAnterior.scss';
import { Button, Col, DatePicker, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, tipoDocOptions } from '../../optList';
import moment from 'moment';
import { useState } from 'react';

export default function RelLaboralAnterior({ form, sectionName, apiForm, clientName, format, docValidator }) {

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    handleDeleteRow,
    handleSaveOnChangeField,
    toDescriptionsPdf
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []
  
  const fieldsParams = [
    {  
      type: 'input',
      jsonKeyName: "razonSocial",
      label: "Razón Social",
      id: "relLabAnteriorRazonSocial",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "relLabAnteriorTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rut",
      id: "relLabAnteriorRut",
      customValidator: docValidator(form.getFieldValue('relLabAnteriorTipoDoc'), true)
    },
    {
      type: 'select',
      label: "Cargo ejercido",
      jsonKeyName: "cargo",
      options: cargosOptions,
      id: "relLabAnteriorHireDate",
    },
    {
      type: 'date',
      label: "Fecha de ingreso",
      jsonKeyName: "hireDate",
      id: "relLabAnteriorHireDate",
    },
    {
      type: 'date',
      label: "Fecha de término",
      jsonKeyName: "firedDate",
      id: "relLabAnteriorFiredDate",
    }
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>RELACIONES LABORALES ANTERIORES</Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que en los últimos 18 meses he tenido relaciones laborales anteriores, con un empleador distinto a {clientName}
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasRelLabAnterior", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(true);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.relLabAnterior?.hasRelLabAnterior
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasRelLabAnterior", "hasRelLabAnterior")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.relLabAnterior?.hasRelLabAnterior && (
        <>
        {  format === 'html' &&
        <>
          <Row gutter={[20, 10]}>
            {fieldsParams.map(item => (
              <>
                {item.type === 'divider' &&
                  <Divider orientation={item.orientation ?? 'left'}>
                    {item?.dividerText ?? ''}
                  </Divider>
                }
                {(item.type === 'input' || item.type === 'select' || item.type === 'date') &&
                  <Col span={8} key={item.id}>
                    <Form.Item label={item.label}>
                      {form.getFieldDecorator(item.id, {
                        rules: [{ required: true, message: t("messages.aml.requiredData") }],
                      })(
                        <div>
                          {item.type === 'input' && (
                            <Input
                              onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                              value={rowData[item.jsonKeyName] ?? ""}
                            />
                          )}
                          
                          {item.type === 'select' && (
                            <Select
                              allowClear
                              onChange={(value) => {
                                handleOnChangeTableElement(value, item.jsonKeyName);
                                form.setFieldsValue({ [item.id]: value });
                              }}
                              style={{ width: '100%' }}
                              value={rowData[item.jsonKeyName] ?? ""}
                            >
                              {item.options.map(option => (
                                <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                              ))}
                            </Select>
                          )}

                          {item.type === 'date' && (
                            <DatePicker
                              format="DD/MM/YYYY"
                              placeholder=''
                              style={{width: '100%'}}
                              onChange={(momentObj) => {
                                const dateToString = momentObj ? moment(momentObj).format("YYYY/MM/DD") : null
                                handleOnChangeTableElement(dateToString, item.jsonKeyName);
                                form.setFieldsValue({ [item.id]: dateToString });
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                }
              </>
            ))}
          </Row>
          <Row className="button-row">
            {records.length === 0 &&
              <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                {t("messages.aml.registersRequired")}
              </Col>
            }
            <Col className="addRelation" xl={3}>
              <Button type="primary" htmlType="button" onClick={() => {
                handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
              }} icon="plus"> Añadir </Button>
            </Col>
            <Col className="addRelation" xl={3}>
              <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
            </Col>
          </Row>
          </>
        }
          <Row>
            {records.length > 0 && format === 'html' ?
              <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
              :
              toDescriptionsPdf(sectionName, fieldsParams)
            }
          </Row>
        </>
      )}
    </div>
  );
}
