import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

export default {
	getReportsHistory: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/getReportByClient',
			method: 'post'
		})
	}
}
