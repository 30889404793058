import './FormManager.scss'
import React, { useState, useEffect } from 'react';
import {Tabs } from 'antd';
import {Row, Col, Input, Icon } from 'antd'
import {FormTablePage} from './components'
import { getAreasPromise } from '../../promises';

const FormManager = ({filtersSideBar = {}}) => {

	const [areas, setAreas] = useState(null)

	useEffect(() => {
		getAreasPromise().then((areas) => {
			setAreas(areas)
		})
	}, []);

    return (
		<div className="onb-form-manager">
			<div className="form-manager-content">
				<Row>
					<Col span={24}>
						<FormTablePage areas={areas} filtersSideBar={filtersSideBar} />
					</Col>
				</Row>
			</div>
		</div>
    );
}
export default FormManager
