import './Filters.scss'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {Collapse, DatePicker, Input, Select, Switch} from 'antd'
import ImageResources from "../../../../layouts/commonComponent/components/ExternalResources/ImageResources";

const { Panel } = Collapse;

const Filters = ({currentUser, parametersClient, filters, groups, categories, areas, onChangeFilters, origin}) => {
    const {t} = useTranslation()
    const {Option} = Select

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        window.setTimeout(() => {
            const sidebarEl = document.getElementById('register-sidebar')
            const contentEl = document.getElementById('register-content')
            if (sidebarEl && contentEl) {
                let contentHeight = contentEl.offsetHeight
                if(contentHeight > 1385) contentHeight = 1385;
                sidebarEl.style.height = contentHeight + 'px'
            }

        }, 2000)
    }

  const getTitleFilter = (title, options) => {
    return <div className={"filter-title"+(options && options.length > 0 ? " filter-title-activated" : "")}>
      {title}
    </div>
  }

    const getUrlPerson =(id) => {
        return  ('/getImageClientUser/0/' + id)
    }

  return (
    <div id="filters" className="filters-register">

        <Collapse expandIconPosition="right" bordered={false} defaultActiveKey={["filter-1", "filter-2", "filter-3", "filter-4", "filter-5", "filter-6", "filter-7", "filter-8", "filter-9", "filter-10", "filter-11"]}>
          { currentUser.cliente.outsourcer && currentUser.cliente.clientes !== null && currentUser.cliente.clientes.length > 0 && currentUser.subcliente === null &&
          <Panel header={ t('messages.aml.subclient') } key="filter-1">
            <div className="filter">
              <Select onChange={ (value) => onChangeFilters('subclienteId', value, value) }
                placeholder="Seleccionar empresa"
                className="subclient-dropdown"
                allowClear
                >
                { currentUser.cliente.clientes.map(item =>
                    <Option className="subclient-option" value={ item.id }>
                      <div className="subclient-option-inner">
                        <figure className="subclient-logo">
                            <ImageResources url={getUrlPerson(item.id)} styleResource={{ height: '15px' }} ></ImageResources>
                        </figure>
                        <span className="subclient-name" style={{ paddingLeft: '10px' }}>{ item.name }</span>
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
          </Panel>
        }
        { currentUser.cliente.modules.includes('MONITOR-AML') && (origin === 'new' || (filters['filterFalsosPositivos'] && filters['filterFalsosPositivos'] > 0)) &&
          <Panel header="Coincidencias por Nombre" key="filter-2">
            <div className="filter">
              <ul>
                { origin === 'new' &&
                  <li>Tiene coincidencias <Switch size="small" checked={ filters['falsosPositivos'] } onChange={ (value) => onChangeFilters('falsosPositivos', value, value) } /></li>
                }
                { filters['filterFalsosPositivos'] > 0 &&
                  <li className="falsos-positivos">{ t('messages.aml.posibleFalsosPositivos') } ({ filters['filterFalsosPositivos'] }) <Switch size="small" checked={ filters['falsosPositivosPendientes'] } onChange={ (value) => onChangeFilters('falsosPositivosPendientes', value, value) } /></li>
                }
              </ul>
            </div>
          </Panel>
        }
        { filters['filterFecAlerta'] && filters['filterFecAlerta'] !== '' &&
          <Panel header={ t('messages.aml.alertDate') } key="filter-3">
            <div className="filter">
              <ul>
                <li>{ moment(moment(filters['filterFecAlerta'],'YYYY-MM-DD')).format('DD MMMM YYYY') } <Switch size="small" checked={ filters['fecAlerta'] !== null || filters['fecAlerta'] === undefined } onChange={ (value) => onChangeFilters('fecAlerta', filters['filterFecAlerta'], value) } /></li>
              </ul>
            </div>
          </Panel>
        }
        { origin !== "new" &&
          <Panel header={ t('messages.aml.keywordSearch') } key="filter-4">
            <div className="filter">
              <Input.Search placeholder={ t('messages.aml.searhcDniNameEmail') } onSearch={ (value) => onChangeFilters('text', value, value!=='') } allowClear />
            </div>
          </Panel>
        }
        <Panel header={ getTitleFilter(t('messages.aml.type'), filters['types'])} key="filter-5">
          <div className="filter">
            <ul>
              <li>{ t('messages.aml.naturalPerson') } <Switch size="small" checked={ filters['types'] && filters['types'].includes('Person') } onChange={ (value) => onChangeFilters('types', 'Person', value) } /></li>
              <li>{ t('messages.aml.legalPerson') } <Switch size="small" checked={ filters['types'] && filters['types'].includes('Entity') } onChange={ (value) => onChangeFilters('types', 'Entity', value) } /></li>
            </ul>
          </div>
        </Panel>
        <Panel header={getTitleFilter(t('messages.aml.pjStatus'), filters['estados'])} key="filter-9">
          <div className="filter">
            <ul>
              <li>{ t('messages.aml.active') } <Switch size="small" checked={ filters['estados'] && filters['estados'].includes('ACTIVE') } onChange={ (value) => onChangeFilters('estados', 'ACTIVE', value) } /></li>
              <li>{ t('messages.aml.inactive') } <Switch size="small" checked={ filters['estados'] && filters['estados'].includes('INACTIVE') } onChange={ (value) => onChangeFilters('estados', 'INACTIVE', value) }/></li>
            </ul>
          </div>
        </Panel>
        { categories && categories.length > 0 && (categories.length > 1 || categories[0].categoria !== 'N/A') &&
          <Panel header={ getTitleFilter(t('messages.aml.category'), filters['categories']) } key="filter-6">
            <div className="filter">
              <ul>
                { categories.map(category =>
                  <li>
                  { category.categoria !== 'N/A' ? t('messages.aml.category.' + category.categoria) : 'N/A' } ({ category.cant }) <Switch size="small" checked={ filters['categories'] && filters['categories'].includes(category.categoria) } onChange={ (value) => onChangeFilters('categories', category.categoria, value) } />
                  </li>
                )}
              </ul>
            </div>
          </Panel>
        }
        { areas && areas.length > 0 && (areas.length > 1 || areas[0].area !== 'N/A') &&
          <Panel header={getTitleFilter("Area", filters['areas'])} key="filter-7">
            <div className="filter">
              <ul>
                { areas.map(area =>
                  <li>
                  { area.area } ({ area.cant }) <Switch size="small" checked={ filters['areas'] && filters['areas'].includes(area.area) } onChange={ (value) => onChangeFilters('areas', area.area, value) } />
                  </li>
                )}
              </ul>
            </div>
          </Panel>
        }
        { groups && groups.length > 0 && (groups.length > 1 || groups[0].grupo !== 'N/A') &&
          <Panel header={getTitleFilter(t('messages.aml.group'), filters['grupos'])} key="filter-8">
            <div className="filter">
              <ul>
                { groups.map(group =>
                  <li>
                  { group.grupo } ({ group.cant }) <Switch size="small" checked={ filters['grupos'] && filters['grupos'].includes(group.grupo) } onChange={ (value) => onChangeFilters('grupos', group.grupo, value) } />
                  </li>
                )}
              </ul>
            </div>
          </Panel>
        }
        
        <Panel header="Fecha de carga" key="filter-10">
          <div className="filter">
            <DatePicker format="DD/MM/YYYY" placeholder={ t('messages.aml.from') } style={{ marginBottom: '5px', width: '100%' }} onChange={ (value) => onChangeFilters('fromDate', value === null ? null : moment(value).format('DD/MM/YYYY'), value !== '') } />
            <DatePicker format="DD/MM/YYYY" placeholder={ t('messages.aml.to') } style={{ width: '100%' }} onChange={ (value) => onChangeFilters('toDate', value === null ? null : moment(value).format('DD/MM/YYYY'), value !== '') } />
          </div>
        </Panel>
        { currentUser.cliente.modules.includes('ADMCLI') && parametersClient && parametersClient.ubofinder && parametersClient.ubofinder.noInvestigaMalla === false &&
            <Panel header={getTitleFilter("Estado Malla", filters['estadoMalla'])} key="filter-11">
              <div className="filter">
                <ul>
                  <li>{ t('messages.aml.malla.SOLICITADO') } <Switch size="small" checked={ filters['estadoMalla'] && filters['estadoMalla'].includes('SOLICITADO') } onChange={ (value) => onChangeFilters('estadoMalla', 'SOLICITADO', value) } /></li>
                  <li>{ t('messages.aml.malla.ENTREGADO') } <Switch size="small" checked={ filters['estadoMalla'] && filters['estadoMalla'].includes('ENTREGADO') } onChange={ (value) => onChangeFilters('estadoMalla', 'ENTREGADO', value) } /></li>
                </ul>
              </div>
            </Panel>
        }
        { currentUser.cliente.modules.includes('ADMCLI') && parametersClient && parametersClient.ubofinder && !parametersClient.ubofinder.sinFormulario && parametersClient.ubofinder.formularioInterno &&
          <Panel header={getTitleFilter("Formulario Circular 57", filters['form57'])} key="filter-13">
            <div className="filter">
              <ul>
                <li>{ t('messages.aml.active') } <Switch size="small" checked={ filters['form57']?.includes('ACTIVE') } onChange={ (value) => onChangeFilters('form57', 'ACTIVE', value) } /></li>
                <li>{ t('messages.aml.inactive') } <Switch size="small" checked={ filters['form57']?.includes('INACTIVE') } onChange={ (value) => onChangeFilters('form57', 'INACTIVE', value) }/></li>
              </ul>
            </div>
          </Panel>
        }
        { currentUser.cliente.modules.includes('ADMCLI') && parametersClient && parametersClient.ubofinder && !parametersClient.ubofinder.sinFormulario &&
          <Panel header={getTitleFilter("Estado formulario Circular 57", filters['estadoForm'])} key="filter-12">
            <div className="filter">
              <ul>
                <li>{ t('messages.aml.notDelivered') } <Switch size="small" checked={ filters['estadoForm'] && filters['estadoForm'].includes('N') } onChange={ (value) => onChangeFilters('estadoForm', 'N', value) } /></li>
                <li>{ t('messages.aml.delivered') } <Switch size="small" checked={ filters['estadoForm'] && filters['estadoForm'].includes('ENVIADO') } onChange={ (value) => onChangeFilters('estadoForm', 'ENVIADO', value) } /></li>
                <li>{ t('messages.aml.received') } <Switch size="small" checked={ filters['estadoForm'] && filters['estadoForm'].includes('RECIBIDO') } onChange={ (value) => onChangeFilters('estadoForm', 'RECIBIDO', value) } /></li>
                <li>{ t('messages.aml.validated') } <Switch size="small" checked={ filters['estadoForm'] && filters['estadoForm'].includes('VALIDADO') } onChange={ (value) => onChangeFilters('estadoForm', 'VALIDADO', value) } /></li>
              </ul>
            </div>
          </Panel>
        }
        { origin === 'new' && currentUser.cliente.modules.includes('MONITOR-AML') &&
          <Panel header={getTitleFilter("Cumplimiento", filters['compliance'])} key="filter-15">
            <div className="filter">
              <ul>
                <li>Listas Obligatorias <Switch size="small" checked={ filters['compliance'] && filters['compliance'].includes('PEPSAN') } onChange={ (value) => onChangeFilters('compliance', 'PEPSAN', value) } /></li>
                <li>KYC <Switch size="small" checked={ filters['compliance'] && filters['compliance'].includes('KYCAME') } onChange={ (value) => onChangeFilters('compliance', 'KYCAME', value) } /></li>
                <li>UBO & Companies <Switch size="small" checked={ filters['compliance'] && filters['compliance'].includes('UBOCOM') } onChange={ (value) => onChangeFilters('compliance', 'UBOCOM', value) } /></li>
              </ul>
            </div>
          </Panel>
        }
        { origin === 'new' &&
          <Panel header={getTitleFilter("Riesgo asignado", filters['asignedRisk'])} key="filter-16">
            <div className="filter">
              <ul>
                <li>Crítico <Switch size="small" checked={ filters['asignedRisk'] && filters['asignedRisk'].includes('BLACK') } onChange={ (value) => onChangeFilters('asignedRisk', 'BLACK', value) } /></li>
                <li>Alto <Switch size="small" checked={ filters['asignedRisk'] && filters['asignedRisk'].includes('RED') } onChange={ (value) => onChangeFilters('asignedRisk', 'RED', value) } /></li>
                <li>Medio <Switch size="small" checked={ filters['asignedRisk'] && filters['asignedRisk'].includes('ORANGE') } onChange={ (value) => onChangeFilters('asignedRisk', 'ORANGE', value) } /></li>
                <li>Bajo <Switch size="small" checked={ filters['asignedRisk'] && filters['asignedRisk'].includes('YELLOW') } onChange={ (value) => onChangeFilters('asignedRisk', 'YELLOW', value) } /></li>
                <li>No Posee <Switch size="small" checked={ filters['asignedRisk'] && filters['asignedRisk'].includes('GREEN') } onChange={ (value) => onChangeFilters('asignedRisk', 'GREEN', value) } /></li>
              </ul>
            </div>
          </Panel>
        }
        { origin === 'new' &&
          <Panel header={getTitleFilter("Comentarios", filters['hasComments'])} key="filter-14">
            <div className="filter">
              <ul>
                <li>Tiene Comentarios <Switch size="small" checked={ filters['hasComments']?.includes('ON') } onChange={ (value) => onChangeFilters('hasComments', 'ON', value) } /></li>
                <li>No tiene comentarios <Switch size="small" checked={ filters['hasComments']?.includes('OFF') } onChange={ (value) => onChangeFilters('hasComments', 'OFF', value) }/></li>
              </ul>
            </div>
          </Panel>
        }
                {/*<div className="filter">
                    <h5>{ t('messages.aml.term') }</h5>
                    <ul>
                      <li>{ t('messages.aml.green') } <Switch size="small" checked={ filters['estadoPlazo'] !== null && filters['estadoPlazo'] !== undefined && filters['estadoPlazo'].includes('GREEN') } onChange={ (value) => onChangeFilters('estadoPlazo', 'GREEN', value) } /></li>
                      <li>{ t('messages.aml.yellow') } <Switch size="small" checked={ filters['estadoPlazo'] !== null && filters['estadoPlazo'] !== undefined && filters['estadoPlazo'].includes('YELLOW') } onChange={ (value) => onChangeFilters('estadoPlazo', 'YELLOW', value) } /></li>
                      <li>{ t('messages.aml.red') } <Switch size="small" checked={ filters['estadoPlazo'] !== null && filters['estadoPlazo'] !== undefined && filters['estadoPlazo'].includes('RED') } onChange={ (value) => onChangeFilters('estadoPlazo', 'RED', value) } /></li>
                    </ul>
                  </div>
                  <div className="filter">
                    <h5>{ t('messages.aml.verification') }</h5>
                    <ul>
                      <li>{ t('messages.aml.green') } <Switch size="small" checked={ filters['estadoVerif'] !== null && filters['estadoVerif'] !== undefined && filters['estadoVerif'].includes('GREEN') } onChange={ (value) => onChangeFilters('estadoVerif', 'GREEN', value) } /></li>
                      <li>{ t('messages.aml.yellow') } <Switch size="small" checked={ filters['estadoVerif'] !== null && filters['estadoVerif'] !== undefined && filters['estadoVerif'].includes('YELLOW') } onChange={ (value) => onChangeFilters('estadoVerif', 'YELLOW', value) } /></li>
                      <li>{ t('messages.aml.red') } <Switch size="small" checked={ filters['estadoVerif'] !== null && filters['estadoVerif'] !== undefined && filters['estadoVerif'].includes('RED') } onChange={ (value) => onChangeFilters('estadoVerif', 'RED', value) } /></li>
                    </ul>
                  </div>*/}
      </Collapse>
    </div>
  )
}

export default Filters
