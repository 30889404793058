import React, {useEffect, useState} from 'react';
import {getSourcesSumaryPromise} from "./promises/getMonitoringSourcePromise";
import {Spin, Table} from "antd";
import './MonitoringSource.scss'
import {useTranslation} from "react-i18next";

export const MonitoringSource = ({dateValue}) => {
    const {t} = useTranslation()


    const [loading, setLoading] = useState(false);
    const [monitoringSources, setMonitoringSources] = useState([]);

    useEffect(() => {
        setLoading(true)
        getSourcesSumaryPromise({dateValue}).then((response) => {
            setMonitoringSources(response.data)
        }).then(() => setLoading(false))
    }, [dateValue]);

    const columns = [
        {
            title: <b>Tipo</b>,
            dataIndex: 'tipo',
            render: (text) => {
                return <span>{t('messages.aml.inside.alertas.' + text)}</span>
            },
            key: 'tipo',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        }
    ];

    return (
        <>
            <Spin spinning={loading}>
                {monitoringSources.length > 0 ? (
                    <div>
                    <Table
                        dataSource={
                            monitoringSources.map((item, index) => ({
                                key: index.toString(),
                                tipo: item.tipo,
                                total: item.total
                            }))
                        }
                        columns={columns}
                        pagination={false}
                        className="transparent-header-table"
                        size="small"
                    />
                </div>

                ) : (
                <div className="text-no-content-rule">No hay datos disponibles</div>
                )}
            </Spin>
        </>
    )

};