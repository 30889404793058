import MallaParentalSection from "./MallaParentalSection/MallaParentalSection";
import ParticipacionEmpresaSection from "./ParticipacionEnEmpresaSection/ParticipacionEmpresaSection";
import ParticipacionSociedades from "./ParticipacionSociedades/ParticipacionSociedades";
import Introduccion from "./Introduccion/Introduccion";
import Interlocking from "./Interlocking/Interlocking";
import RelacionProveedores from "./RelacionProveedores/RelacionProveedores";
import ActividadesFueraEmpresa from "./ActividadesFueraEmpresa/ActividadesFueraEmpresa";
import RelLaboralAnterior from "./RelLaboralAnterior/RelLaboralAnterior";
import RelacionCompetencia from "./RelacionCompetencia/RelacionCompetencia";
import RelacionParentesco from "./RelacionParentesco/RelacionParentesco";
import RelacionPep from "./RelacionPep/RelacionPep";
import RelacionFundaciones from "./RelacionFundaciones/RelacionFundaciones";
import OtrosConflictos from "./OtrosConflictos/OtrosConflictos";
import OtrosConflictosSimple from "./OtrosConflictosSimple/OtrosConflictosSimple";

export const MallaParentalSectionComponent = MallaParentalSection;
export const ParticipacionEmpresaSectionComponent = ParticipacionEmpresaSection;
export const ParticipacionSociedadesSection = ParticipacionSociedades;
export const IntroduccionSection = Introduccion;
export const InterlockingSection = Interlocking;
export const RelacionProveedoresSection = RelacionProveedores;
export const ActividadesFueraEmpresaSection = ActividadesFueraEmpresa;
export const RelLaboralAnteriorSection = RelLaboralAnterior;
export const RelacionCompetenciaSection = RelacionCompetencia;
export const RelacionParentescoSection = RelacionParentesco;
export const RelacionPepSection = RelacionPep;
export const RelacionFundacionesSection = RelacionFundaciones;
export const OtrosConflictosSection = OtrosConflictos
export const OtrosConflictosSimpleSection = OtrosConflictosSimple