import {Modal, Row, Col, Button, Icon, Table, Upload, message} from 'antd'
import React, {useEffect, useState} from 'react'
import {getOptionsPromise, importSelectedPromise} from '../../promises'
import { ReportService } from '../../../../services'
import { useTranslation } from "react-i18next";

const EditRowRisk = ({editCode, modalTitle, handleCancel}) => {

  const [options, setOptions] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const { t } = useTranslation();

    useEffect(() => {
      refreshTable()
    }, []);

    const refreshTable = () => {
      getOptionsPromise(editCode).then(response =>{
        if (response){
        setSelectedOptions(response.filter(op => op.selected === "X").length)
        setOptions(response)
        setIsTableLoading(false)
        }else{
          message.error("No se pudieron cargar los datos")
        }
      })
    }

    const optionColumns = [
      {
        title: "Elemento",
        dataIndex: 'name',
        width: '85%'
      },
      // {
      //   title: "Sugerido",
      //   width: '25%'
      // },
      {
        title: "Seleccionado",
        dataIndex: 'selected',
        render: (text) => {
          return text === "X" ? 'Si' : ""
        },
        width: '15%'
      }

    ]

    const propsUpload = {
      accept: ".xlsx",
      beforeUpload: file => {
        if(file) {
          handleImport(file)
        }
        return false
      },
      multiple: false,
      showUploadList: false
    }

    const handleExport = async () => {
      setExportLoading(true)
      await ReportService.read('/excelOptionsDemoRisk', {option: editCode}, null, modalTitle+'.xlsx')
      setExportLoading(false)
    }

    const handleImport =  async (file) => {
      setImportLoading(true)
      const formData = new FormData()
          formData.append('file', file)
          formData.append('option', editCode)
          setIsTableLoading(true)
      await importSelectedPromise(formData).then(response => {
        setImportLoading(false)
        if(response === "OK"){
          setIsTableLoading(false)
          refreshTable()
          message.success("Se Importo el archivo correctamente")
        }else{
          message.error("Ha ocurrido un error al importar")
        }
      })
    }

    return (
        <Modal
        title={modalTitle}
        visible={true}
        footer={false}
        onCancel={handleCancel}
        width={800}
        style={{top:10}}
      >
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <h3>Actualmente tiene seleccionado {selectedOptions ?? <Icon type="loading" />} elementos de riesgo, a continuación se presenta el detalle.</h3>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={1}>
            1.
          </Col>
          <Col span={18}>
            Para modificar el listado, debe descargar el siguiente archivo Excel y seleccionar los elementos (marque con una x los elementos que desee incorporar)
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Button loading={exportLoading} onClick={() => {handleExport()}}>{!exportLoading && <Icon type="arrow-down" />} Exportar </Button>
          </Col>
        </Row>
        <Row gutter={[0, 20]}>
          <Col span={1}>
            2.
          </Col>
          <Col span={18}>
            Una vez realizado los cambios en el Excel los puede subir aca (recuerde que se reemplazará el listado anterior)
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Upload {...propsUpload}>
              <Button loading={importLoading}>{!importLoading && <Icon type="arrow-up" />} Importar </Button>
            </Upload>
          </Col>
        </Row>
          <Row>
            <Table size="small" dataSource={options} columns={optionColumns} pagination={false} loading={isTableLoading} scroll={{ y: 400 }}/>
          </Row>
      </Modal>
    );
}

export default EditRowRisk;