import { Button, Col, Icon, Input, notification, Row, Popconfirm, Upload, Modal, Switch, Checkbox } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { camelizerHelper } from '../../../../helpers';
import { ReportService } from '../../../../services';
import { saveTaskPromise, uploadFilesTaskPromise } from './promises'
import './TaskRevision.scss'

const TaskRevision = ({alert, origin, currentUser, taskId=null, refreshHandler}) => {
    const [tasksCollection, setTasksCollection] = useState([])
    const { t } = useTranslation()
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
    const [currentActiveTask, setCurrentActiveTask] = useState(null)
    const [ fileList, setFileList ] = useState([])
    const [ filesUploaded, setFilesUploaded ] = useState(null)
    const [seeMore, setSeeMore] = useState('auto');
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [isSavingTask, setIsSavingTask] = useState(false);

    useEffect(() => {
        if (alert !== null) {
            handleSetTasksCollection()
        }
    }, [alert])

    const getUploadProps = (task) => {
      return {
        accept: ".pdf",
        onRemove: file => {
          setFileList(oldFileList => {
            const index = oldFileList.indexOf(file)
            const newFileList = oldFileList.slice()
            newFileList.splice(index, 1)
            return newFileList
          })
        },
        beforeUpload: file => {
          setFileList(oldFileList => [...oldFileList, file])
          return false
        },
        multiple: true,
      }
    }

    const handleSetTasksCollection = async () => {
        await setTasksCollection(oldTasks => {
        const arr = JSON.parse(JSON.stringify(alert.tasks))

    for (let i = 0; i < arr.length; i++) {
            arr[i].changes = 0
            arr[i].estadoRespOrig = arr[i].estadoResp
            arr[i].filesOrig = arr[i].files
            arr[i].filesRespOrig = arr[i].filesResp
            if(origin === 'portal')
                arr[i].origin = origin
            else if(arr[i].responsable === null && arr[i].estadoResp !== 'TERMINADO') {
                arr[i].origin = 'portal'
            }else {
                arr[i].origin = origin
            }
        }
            return arr
        })
    }

    const handleCommentsChange = (value, index) => {
        setTasksCollection(oldCollection => {
            const obj = JSON.parse(JSON.stringify(oldCollection))

            obj[index].comentarios = value
            obj[index].changes = obj[index].changes + 1

            return obj
        })
    }

    const handleSetResponseState = (name, index) => {
        if(tasksCollection[index].estadoRespOrig === 'PENDIENTE' || (tasksCollection[index].estadoRespOrig === 'EN_PROCESO' && name !== 'PENDIENTE') || (tasksCollection[index].estadoRespOrig === 'TERMINADO'  && name === 'TERMINADO')) {
            setTasksCollection(oldCollection => {
            const arr = JSON.parse(JSON.stringify(oldCollection))

            arr[index].estadoResp = name
            arr[index].changes = arr[index].changes + 1

            return arr
        })
        }
    }

  const handleSave = async (index, selectedTask = null) => {
    setIsSavingTask(true)
    const defaultTask = {
      alertId: alert.id,
      userId: currentUser.id,
      origin: tasksCollection[index].origin,
      task: {
        id: tasksCollection[index].id,
        estado: tasksCollection[index].estado,
        estadoResp: tasksCollection[index].estadoResp,
        comentarios: tasksCollection[index].comentarios,
        comentariosResp: tasksCollection[index].comentariosResp
      }
    }

    if(selectedTask !== null) {
      defaultTask.task = selectedTask
    }
    defaultTask.task.files = filesUploaded

    await setTasksCollection(oldCollection => {
        const arr = JSON.parse(JSON.stringify(oldCollection))
        arr[index].changes = 0
        return arr
    })


    saveTaskPromise(defaultTask)
      .then(response => {
        notification['success']({
          message: "Operación exitosa",
          description: "Tarea guardada exitosamente"
        })

        setTasksCollection(oldCollection => {
          const arr = JSON.parse(JSON.stringify(oldCollection))
          arr[index] = response.data
          arr[index].estadoRespOrig = arr[index].estadoResp
          if(origin === 'portal')
            arr[index].origin = origin
          else if(arr[index].responsable === null && arr[index].estadoResp !== 'TERMINADO') {
            arr[index].origin = 'portal'
          }else {
            arr[index].origin = origin
          }
          return arr
        })

        closeModalFiles()
        if(refreshHandler !== undefined) refreshHandler()
        setIsSavingTask(false)
      })
      .catch(error => {
        window.alert(error)
      })
  }

  const handleResponsibleCommentsChange = (value, index) => {
    setTasksCollection(oldCollection => {
      const arr = JSON.parse(JSON.stringify(oldCollection))
      arr[index].comentariosResp = value
      arr[index].changes = arr[index].changes + 1
      return arr
    })
  }

  const handleShowUploadModal = task => {
    setIsUploadModalVisible(true)
    setCurrentActiveTask(task)
    setFileList([])
  }

  const handleCloseTask = index => {
    setTasksCollection(oldCollection => {
      const arr = JSON.parse(JSON.stringify(oldCollection))
      arr[index].estado = "CERRADA"
      arr[index].changes = arr[index].changes + 1
      return arr
    })

    const task = {
      id: tasksCollection[index].id,
      estado: "CERRADA",
      estadoResp: tasksCollection[index].estadoResp,
      comentarios: tasksCollection[index].comentarios,
      comentariosResp: tasksCollection[index].comentariosResp
    }

    handleSave(index, task)
  }

  const closeModalFiles = () => {
    setFileList([])
    setFilesUploaded(null)
    setIsUploadModalVisible(false)
  }

  const loadFiles = () => {
    setIsLoadingFiles(true)
    const formData = new FormData()
    fileList.forEach(file => {
      formData.append('file', file)
    })
    uploadFilesTaskPromise(formData, currentUser.id, currentActiveTask.origin, alert.id, currentActiveTask.nro)
      .then(response => {
        setFilesUploaded(response.data)
        setTasksCollection(oldCollection => {
          const arr = JSON.parse(JSON.stringify(oldCollection))
          for(let i=0;i<arr.length;i++) {
            if(arr[i].id === currentActiveTask.id) {
              arr[i].changes = arr[i].changes + 1
              if(currentActiveTask.origin === 'portal') {
                if(arr[i].filesRespOrig === null) {
                  arr[i].filesResp = response.data
                }else {
                  let files = arr[i].filesRespOrig
                  response.data.map((item, index) => (
                    files.push(item)
                  ))
                  arr[i].filesResp = files
                }
              }else {
                if(arr[i].filesOrig === null) {
                  arr[i].files = response.data
                }else {
                  let files = arr[i].filesOrig
                  response.data.map((item, index) => (
                    files.push(item)
                  ))
                  arr[i].files = files
                }
              }
            }
          }
          return arr
        })
        setIsLoadingFiles(false)
      })
    setIsUploadModalVisible(false)
  }

  const handleOnFile = async (path, fileName) =>{
    await ReportService.read('/portal/getFile', {alertId: alert.id, path, origin, userId: currentUser.id}, null, fileName)
  }

  const getFile = (file) => {
    let parts = file.split("/")
    let fileName = parts[parts.length-1]
    return (<a onClick = {e => handleOnFile(file, fileName)}>{fileName}</a>)
  }

    return (
        <main className='task-revision-section-class'>
            <div className="title-section">
                <Row>
                    <Col span={19}>
                        <h3>Revisión de Tareas</h3>
                    </Col>
                    <Col span={5}>
                        <div style={{textAlign: 'right'}}>
                            <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                                {seeMore ?
                                    <>Ver menos <Icon type="minus"/></>
                                    :
                                    <>Ver más <Icon type="plus"/></>
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
            <AnimateHeight
                duration={ 600 }
                height={ seeMore }
                animateOpacity={true}
            >
              <section className='task-revision-body'>
                <article className='tasks-revision-content-wrapper'>
                  {tasksCollection.map((task, index) => {
                      return (
                        (origin === "aml" || (task.responsable !== null && task.responsable.id === currentUser.id && task.id === taskId)) &&
                          <div className='tasks-revision-module-wrapper'>
                            <header>
                              <Row gutter={[8, 0]}>
                                  <Col span={3}>
                                    <div className='task-revision-header' style={{textAlign: 'center'}}>
                                      { t('messages.aml.task') } #{ task.nro }
                                    </div>
                                  </Col>
                                  <Col span={11}>
                                      <div className='task-revision-header' style={{textAlign: 'center'}}>
                                          {task.tarea}
                                      </div>
                                  </Col>
                                  <Col span={((origin === "portal" && task.estadoRespOrig !== 'TERMINADO') || (origin === "aml" && task.estado !== 'CERRADA')) && task.changes > 0 ? 7 : 10}>
                                      <div className='task-revision-header' style={{textAlign: 'center'}}>
                                          {"Actualización: "+moment(task.creationDate).format('DD/MM/YYYY HH:mm')}
                                      </div>
                                  </Col>
                                  {((origin === "portal" && task.estadoRespOrig !== 'TERMINADO') || (origin === "aml" && task.estado !== 'CERRADA')) && task.changes > 0 &&
                                    <Col span={3}>
                                      <div style={{display: 'flex', justifyContent: 'center'}}>
                                      {isSavingTask ?
                                        <Icon type="loading"/>
                                      :
                                        <Button className="save-button" type="primary" icon="save" size="small" onClick={ () => handleSave(index) }>{origin === "aml" ? "Guardar cambios" : "Guardar"}</Button>
                                      }
                                      </div>
                                    </Col>
                                  }
                              </Row>
                              <Row gutter={[8]} style={{marginTop: 8}} type='flex'>
                                <Col span={task.origin === "aml" ? 16 : 8}>
                                  <div style={{height: '100%'}}>
                                    <header className='task-revision-card-header'>
                                      <Icon type="forward" /> Solicitud
                                    </header>
                                    <div className='task-revision-card-body'>
                                      <Col span={12}>
                                        <label>Usuario solicitante</label>
                                        <p>{ camelizerHelper(task.userAsig.name) }</p>
                                      </Col>
                                      <Col span={12}>
                                        <label>{ t('messages.aml.creationDate') }</label>
                                        <p>{ moment(task.creationDate).format('DD/MM/YYYY') }</p>
                                      </Col>
                                      <Col span={12}>
                                        <label>Tiempo asignado</label>
                                        {task.dias ?
                                          <p>{ task.dias } días</p>
                                          :
                                          <p>N/A</p>
                                        }
                                      </Col>
                                      <Col span={12}>
                                        <label>Tiempo transcurrido</label>
                                        {task.diasTranscurridos ?
                                          <p>{ task.diasTranscurridos } días</p>
                                          :
                                          <p>N/A</p>
                                        }
                                      </Col>
                                      <Col span={24}>
                                        <label>{ t('messages.aml.comments') }</label>
                                        <p><Input.TextArea disabled={ task.origin === "aml" && task.estado !== "CERRADA" ? false : true } onChange={ (e) => handleCommentsChange(e.target.value, index)} defaultValue={task.comentarios}></Input.TextArea></p>
                                      </Col>

                                    {/* INICIO ULTIMA ROW */}
                                      <Col span={8}>
                                        <label>Estado</label>
                                          <p className={'status-'+task.estado}>{ camelizerHelper(task.estado).replace('_', ' ') }</p>
                                          { task.origin === "aml" && task.estado !== "CERRADA" && task.estadoResp === "TERMINADO" &&
                                            <Popconfirm
                                              title="Está seguro de cerrar la tarea?"
                                              onConfirm={() => handleCloseTask(index)}>
                                              <Button type="primary" className="close-task" size="small" icon="check">Cerrar tarea</Button>
                                            </Popconfirm>
                                          }
                                      </Col>
                                      { task.origin === "aml" && task.estado !== "CERRADA" &&
                                        <Col span={4}>
                                          <label>Archivos adjuntos</label>
                                          <p>
                                            <Button type="primary" icon="upload" size="small" style={{ width: '100%' }} onClick={ () => handleShowUploadModal(task) }>{ t('messages.aml.select') }</Button>
                                          </p>
                                        </Col>
                                      }
                                      <Col span={task.origin === "aml" && task.estado !== "CERRADA" ? 12 : 16}>
                                        {/* <Col className="files" span={task.origin === "aml" && task.estado !== "CERRADA" ? 15 : 24}> */}
                                        <div style={{textAlign: 'center', fontSize: 12}}>
                                          { task?.files?.map((file, indexFile) => {
                                            return (
                                              <Col className={'file1 file-attach'}>
                                                { isLoadingFiles ?
                                                  <Icon type="loading"/>
                                                :
                                                  <li>{ getFile(file) }</li>
                                                }
                                              </Col>
                                            )
                                            })
                                          }
                                        </div>
                                        {/* </Col> */}
                                      </Col>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={task.origin === "aml" ? 8 : 16}>
                                  <div style={{height: '100%'}}>
                                    <header className='task-revision-card-header'>
                                      <Icon type="backward" /> Respuesta entregada
                                    </header>
                                    <div className='task-revision-card-body'>
                                      <Row gutter={[0, 5]}>
                                        <Col span={24}>
                                          <label>Responsable</label>
                                          <p><Input size='small' readOnly value={ camelizerHelper(task?.responsable?.nombre ?? task?.userAsig?.name) }/></p>
                                        </Col>
                                        <Col span={12}>
                                          <label>Fecha final respuesta</label>
                                          <p>{ moment(task.fecPlazo).format('DD/MM/YYYY') }</p>
                                        </Col>
                                        <Col span={12}>
                                          <label>Avance</label>
                                          <p>{ camelizerHelper(task.statusPlazo).replace('_', ' ') }</p>
                                        </Col>
                                        <Col span={24}>
                                          <label>{ t('messages.aml.comments') }</label>
                                          <p><Input.TextArea disabled={ task.origin === "aml" || task.estadoRespOrig === 'TERMINADO' } defaultValue={task.comentariosResp} onChange={ e => handleResponsibleCommentsChange(e.target.value, index) }></Input.TextArea></p>
                                        </Col>
                                          <Col span={task.origin === "aml" ? 10 : 6}>
                                            <div style={{verticalAlign: 'middle'}}>
                                              <Checkbox
                                                disabled={task.origin !== "portal"}
                                                onChange={e => {
                                                  if (e){
                                                    handleSetResponseState("TERMINADO", index)
                                                  }else{
                                                    handleSetResponseState("PENDIENTE", index)
                                                  }
                                                }}
                                                defaultChecked={task.estadoResp === "TERMINADO"}
                                              >
                                                Terminar proceso
                                              </Checkbox>
                                            </div>
                                          </Col>
                                        {task.origin === "portal" && task.estadoRespOrig !== 'TERMINADO' &&
                                          <Col span={10}>
                                            <Row>
                                              <Col span={10}>
                                                Archivos adjuntos
                                              </Col>
                                              <Col span={9} offset={1}>
                                                <Button type="primary" icon="upload" size="small"  onClick={ () => handleShowUploadModal(task) }>{ t('messages.aml.select') }</Button>
                                              </Col>
                                            </Row>
                                          </Col>
                                        }
                                        <Col span={task.origin === "aml" ? 14 : 8}>
                                          { task.filesResp != null &&
                                          <>
                                            {/* <hr/> */}
                                              { task.filesResp.map((file, indexFile) => {
                                                return (
                                                  <div style={{fontSize: 12}}>
                                                    { isLoadingFiles ?
                                                      <Icon type="loading"/>
                                                    :
                                                      <li>{ getFile(file) }</li>
                                                    }
                                                  </div>
                                                )
                                              })
                                              }
                                          </>
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </header>
                          </div>
                      )
                    })
                  }
                </article>
              </section>
            </AnimateHeight>
            { isUploadModalVisible && currentActiveTask !== null &&
              <Modal
                  title="Subir archivos"
                  visible={true}
                  onCancel={ closeModalFiles }
                  footer={[
                    <Button onClick={ closeModalFiles }>{ t('messages.aml.cancel') }</Button>,
                    <Button onClick={ loadFiles }>{ t('messages.aml.load') }</Button>
                  ]}
              >
                <div style={{textAlign: 'center'}}>
                  <Upload {...getUploadProps(currentActiveTask)}>
                    <Button icon="upload">{t('messages.aml.file')}</Button>
                  </Upload>
                </div>
              </Modal>
            }
        </main>
    );
};

export default TaskRevision;