import React, {useState} from "react"
import './Step2File.scss'
import {Form, Row, Col, Select, Upload, Icon, Button, message} from 'antd';
import apiConfig from '../../../../../../config/api'

const Step2File = ({sendFormFile , prev}) => {

    const { Dragger } = Upload;
    const [fileGroup1, setFileGroup1] = useState([])
    const [fileGroup2, setFileGroup2] = useState([]);
    const [category1, setCategory1] = useState(null);
    const [category2, setCategory2] = useState(null);

    const propsUpload1 = {
        accept: ".xlsx",
        onRemove: file => {
            setFileGroup1(oldTmpFilesList => {
            const index = oldTmpFilesList.indexOf(file)
            const newTmpFilesList = oldTmpFilesList.slice()

            newTmpFilesList.splice(index, 1)

            return newTmpFilesList
          })
        },
        beforeUpload: file => {
          if(fileGroup1.length === 0) {
            setFileGroup1(oldTmpFilesList => [...oldTmpFilesList, file])
          }

          return false
        },
        multiple: false,
        fileList: fileGroup1,
    }

    const propsUpload2 = {
        accept: ".xlsx",
        onRemove: file => {
            setFileGroup2(oldTmpFilesList => {
            const index = oldTmpFilesList.indexOf(file)
            const newTmpFilesList = oldTmpFilesList.slice()

            newTmpFilesList.splice(index, 1)

            return newTmpFilesList
          })
        },
        beforeUpload: file => {
          if(fileGroup2.length === 0) {
            setFileGroup2(oldTmpFilesList => [...oldTmpFilesList, file])
          }

          return false
        },
        multiple: false,
        fileList: fileGroup2,
    }


    const nextStepLocal = () => {
        if(category1 && category2){
            if(fileGroup1.length === 0 || fileGroup2.length === 0){
                message.error("Ambos grupos deben tener un archivo asignado");
            }else{
                sendFormFile(category1, category2, fileGroup1[0], fileGroup2[0])
            }
        }else{
            message.error("Ambos grupos deben tener una categoría asignada")
        }
      }

    const downloadPlantilla = () => {
        window.open(apiConfig.url + '/../templates/carga_registro.xlsx')
    }

    return (
        <div className="step2file-content">
            <Row>
                <Col span={6}>
                    <div className="step2file-title">
                        Paso 2: Suba el archivo
                    </div>
                </Col>
                <Col span={18}>
                    <div className="plantilla"><a onClick={downloadPlantilla}>Plantilla</a></div>
                </Col>
            </Row>
            <Row gutter={[60, 60]}>
                <Col span={12}>
                    Categoría grupo 1
                    <Select style={{width:'100%'}} onChange={(e) => setCategory1(e)}>
                        <Select.Option value="CLIENTE">Cliente</Select.Option>
                        <Select.Option value="COLABORADOR">Colaborador</Select.Option>
                        <Select.Option value="PROVEEDOR">Proveedor</Select.Option>
                        <Select.Option value="DIRECTOR">Director</Select.Option>
                    </Select>
                </Col>
                <Col span={12}>
                Categoría grupo 2
                    <Select style={{width:'100%'}} onChange={(e) => setCategory2(e)}>
                        <Select.Option value="CLIENTE">Cliente</Select.Option>
                        <Select.Option value="COLABORADOR">Colaborador</Select.Option>
                        <Select.Option value="PROVEEDOR">Proveedor</Select.Option>
                        <Select.Option value="DIRECTOR">Director</Select.Option>
                    </Select>
                </Col>
            </Row>
            <Row gutter={[60, 20]}>
              <Col span={12}>
                    <Dragger {...propsUpload1}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Haga click o arraste un archivo excel aquí</p>
                        <p className="ant-upload-hint">
                            El formato debe ser .xlsx | Cualquier otro formato no está permitido
                        </p>
                    </Dragger>
                </Col>
              <Col span={12}>
                    <Dragger {...propsUpload2}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Haga click o arraste un archivo excel aquí</p>
                        <p className="ant-upload-hint">
                            El formato debe ser .xlsx | Cualquier otro formato no está permitido
                        </p>
                    </Dragger>
                </Col>
            </Row>
            <div className="steps-buttons">
                <Button  onClick={() => prev()}>
                    Atrás
                </Button>
                <Button style={{ marginLeft: 8 }} type="primary" onClick={() => nextStepLocal(fileGroup1, fileGroup2)}>
                    Enviar
                </Button>
            </div>
        </div>
    )
}
export default Form.create()(Step2File);
