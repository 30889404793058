import React from 'react';
import './MallaParentalSection.scss';
import { Button, Col, Form, Icon, Input, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { relationshipOptions, tipoDocOptions } from '../../optList';

export default function MallaParentalSection({ form ,apiForm,sectionName, format, docValidator }) {

  const {
    obj,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    handleDeleteRow,
    toDescriptionsPdf
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []

  const fieldsParams = [
    {  
      jsonKeyName: "parentesco",
      label: "Parentesco",
      id: "mallaParentesco",
      type: 'select',
      options: relationshipOptions.slice(1)
    },
    {
      jsonKeyName: "nombre",
      label: "Nombre",
      id: "mallaNombre",
      type: 'input', 
    },
    {
      jsonKeyName: "tipoDoc",
      label: "Tipo de documento de identidad",
      id: "mallaTipoDoc", 
      type: 'select',
      options: tipoDocOptions
    },
    { 
      jsonKeyName: "rut",
      label: "Documento de Identidad",
      id: "mallaRut",
      type: 'input',
      customValidator: docValidator(form.getFieldValue('mallaTipoDoc'))
    },
  ];

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }

  

  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>MALLA PARENTAL </Col>
      </Row>
      <Row className="summary">
        <Col xl={24}>
          <p>
            Indique a su cónyuge, conviviente civil y/o
            parientes hasta el segundo grado de consanguinidad o
            afinidad
            inclusive, mayores de 18 años.
          </p>
          <span>
            Es obligatorio ingresar al menos 2 registros.
          </span>
        </Col>
      </Row>
      {format === 'html' &&
      <>
        <Row gutter={[20, 0]}>
          {fieldsParams.map(item => (
            <Col span={6} key={item.id}>
              <Form.Item label={item.label}>
                {form.getFieldDecorator(item.id, {
                  rules: [
                    { required: true, message: t("messages.aml.requiredData") },
                    { validator: item.customValidator }
                  ],
                })(
                  <div>
                    {item.type === 'input' && (
                      <Input
                        onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                        value={form.getFieldValue(item.id)}
                      />
                    )}
                    
                    {item.type === 'select' && (
                      <Select
                        allowClear
                        onChange={(value) => {
                          handleOnChangeTableElement(value, item.jsonKeyName);
                          form.setFieldsValue({ [item.id]: value });
                        }}
                        value={form.getFieldValue(item.id)}
                        style={{ width: '100%' }}
                      >
                        {item.options.map(option => (
                          <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </div>
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row className="button-row">
        {records.length < 2 &&
          <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
            Se requieren al menos 2 registros al declarar afirmativamente en esta sección; luego de ingresarlo, presione añadir
          </Col>
        }
          <Col className="addRelation" xl={3}>
            <Button
              type="primary" 
              htmlType="button" 
              onClick={() => {
                handleSaveOnAddRow(sectionName, 'records', form, fieldsParams);
              }}
              icon="plus"
            >
              Añadir 
            </Button>
          </Col>
          <Col className="addRelation" xl={3}>
            <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
          </Col>
        </Row>
      </>
      }
      <Row>
        {records.length > 0 && format === 'html' ?
          <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
          :
          toDescriptionsPdf(sectionName, fieldsParams)
        }
      </Row>
    </div>
  );
}
