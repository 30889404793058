import { cdiService } from '../services'

const getDeclarations = (fromDate, toDate) => {
	return new Promise(resolve => {
		cdiService.declarations(fromDate, toDate)
			.then(response => {
				resolve({ success: true, data: response.data })
			})
	})
}

export default getDeclarations;
