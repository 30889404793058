import React, {useState} from 'react';
import {Row, Col, Table, Button, Modal, Input, Select, DatePicker} from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { validateRutHelper } from "../../../../../../helpers";

const Relacionados = ({ mode, repLegales, setRepLegales, currentUser, countries}) => {
    const [showListRelEntidad, setShowListRelEntidad] = useState(false);
    const [newRelEntidad, setNewRelEntidad] = useState(null);
    const [indexRelated, setIndexRelated] = useState(-1);
    const { t } = useTranslation();
    const NEW_ITEM = "NEW_ITEM"

    const handleChangeRepLegal = (field, value, index) => {
        let rL = [ ...repLegales ]
        rL[index][field] = value
        rL[index].isBlank = false
        if(rL[index].tipoDoc === 'RUT' && rL[index].dni && currentUser.cliente.pais === "CHI") {
            rL[index].isValid = validateRutHelper(rL[index].dni)
        }else {
            rL[index].isValid = true
        }

        if(field === 'type') {
            if (value === NEW_ITEM) {
                rL[index][field] = null
                setShowListRelEntidad(true)
            }
        }
            setRepLegales(rL)
            setIndexRelated(index)
    }

    const handleRemoveRepLegal = (index) => {
        if(repLegales.length > 0) {
            let rL = [ ...repLegales ]
            rL.splice(index, 1)
            setRepLegales(rL)
        }
    }

    const handleNewRepLegal = () => {
    let rL = [ ...repLegales ]
    rL.push({isBlank: true, isValid: false})
    setRepLegales(rL)
    }

    const relatedTableColumns = [
    {
        title: t('messages.aml.name'),
        dataIndex: 'name',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return <Input value={ text } style={{width: '100%'}} onChange={ (e) => handleChangeRepLegal('name', e.target.value, index) } maxLength={255}/>
        }
        }
    },
    {
        title: t('messages.aml.dniType'),
        dataIndex: 'tipoDoc',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return(<Select defaultValue={text} style={{width: '100%'}} onChange={ (value) => handleChangeRepLegal('tipoDoc', value, index) }>
            <Select.Option value="RUT">{ t('messages.aml.rut') }</Select.Option>
            <Select.Option value="OTRO">Otro</Select.Option>
            </Select>)
        }
        }
    },
    {
        title: t('messages.aml.dniLarge'),
        dataIndex: 'dni',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return (
                <div className="dni">
                <Input value={ text } style={{width: '100%'}} onChange={ (e) => handleChangeRepLegal('dni', e.target.value, index) } maxLength={20}/>
                <div className="error">
                    Documento de identidad no es valido
                </div>
                </div>
            )
        }
        }
    },
    {
        title: t('messages.aml.entityRelation'),
        dataIndex: 'type',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return(<Select value={text} style={{width: '100%'}} onChange={ (value) => handleChangeRepLegal('type', value, index) }>
            <Select.Option value="REPLEGAL">{t('messages.aml.repLegal')}</Select.Option>
            <Select.Option value="DIRECTOR">Director</Select.Option>
            <Select.Option value="GERENTE_GENERAL">Gerente</Select.Option>
            <Select.Option value="EJ_PRINCIPAL">Ejecutivo Principal</Select.Option>
            <Select.Option value="AVAL">Aval o Coaval</Select.Option>
            <Select.Option value="APODERADO">Apoderado</Select.Option>
            <Select.Option value={NEW_ITEM}>+ Nuevo Item</Select.Option>
            </Select>)
        }
        }
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return <Input value={ text } style={{width: '100%'}} onChange={ (e) => handleChangeRepLegal('email', e.target.value, index) } maxLength={150}/>
        }
        }
    },
    {
        title: 'Nacionalidad',
        dataIndex: 'citizenship',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return <Select 
                    showSearch 
                    defaultValue={ text }
                    style={{width: '100%'}}
                    onChange={ (value) => handleChangeRepLegal('citizenship', value, index) }
                    allowClear
                    >
                    { currentUser.cliente.pais === "CHI" &&
                    <Select.Option
                    value= "Chile">
                    Chile
                    </Select.Option> }
                    { currentUser.cliente.pais === "PER" &&
                    <Select.Option
                    value= "Peru">
                    Peru
                    </Select.Option> }
                    { countries.map(country => <Select.Option value={ country.country }>{ country.country }</Select.Option>) }
                </Select>
        }
        }
    },
    {
        title: 'Fec. Nacimiento',
        dataIndex: 'dateBirth',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return <DatePicker
                    placeholder="Fec. Nacimiento"
                    style={{width: '100%'}}
                    format="DD/MM/YYYY"
                    defaultValue={ text ? moment(text, "DD/MM/YYYY") : null }
                    onChange={(momentObj) =>
                    handleChangeRepLegal(
                        "dateBirth",
                        momentObj !== null
                        ?
                        moment(momentObj).format("DD/MM/YYYY")
                        : null,
                        index
                    )
                    }
                />
        }
        }
    },
    {
        title: 'Pais residencia',
        dataIndex: 'residentOf',
        render: (text, record, index) => {
        if(mode === 'view') {
            return text
        }else {
            return <Select 
                    showSearch 
                    defaultValue={ text }
                    style={{width: '100%'}}
                    onChange={ (value) => handleChangeRepLegal('residentOf', value, index) }
                    allowClear
                    >
                    { currentUser.cliente.pais === "CHI" &&
                    <Select.Option
                    value= "Chile">
                    Chile
                    </Select.Option> }
                    { currentUser.cliente.pais === "PER" &&
                    <Select.Option
                    value= "Peru">
                    Peru
                    </Select.Option> }
                    { countries.map(country => <Select.Option value={ country.country }>{ country.country }</Select.Option>) }
                </Select>
        }
        }
    },
    {
        dataIndex: 'id',
        width: 50,
        render: (text, record, index) => {
        if(mode !== 'view') {
            return <Button type="primary" icon="close" size="small" onClick={ (e) => handleRemoveRepLegal(index) }/>
        }
        }
    }
    ]

    return (
        <div>
            <Row className="field-section-row">
                <Col span={24}>
                <h3>
                    { t('messages.aml.related') }
                </h3>
                </Col>
            </Row>
            <hr/>
            <Row style={{padding: 10}}>
                <Col xs={ 24 }>
                <Table 
                    className="tbl-related" 
                    dataSource={ repLegales } 
                    columns={ relatedTableColumns } 
                    pagination={ false } size='small' 
                    rowClassName={(record, index) => {return record.isValid === false && record.isBlank === false ? 'no-valid' : ''}} />
                </Col>
            </Row>
            { mode !== 'view' &&
                <Row>
                <Col xs={ 24 }>
                    <Button type="primary" icon="plus" size="small" onClick={ () => {handleNewRepLegal()} } className="btn-add-replegal">
                    { t('messages.aml.addRelated') }
                    </Button>
                </Col>
                </Row>
            }
            { showListRelEntidad &&
                <Modal
                title="Relación con la Entidad"
                className="modal-area-cliente"
                visible={ true }
                width={ 400 }
                onOk={() => {
                    let inputValue = newRelEntidad.trim()
                    let rL = repLegales
                    rL[indexRelated].type = inputValue
                    setShowListRelEntidad(false)
                    setRepLegales(rL)
                    setNewRelEntidad(null)
                }}
                onCancel={() => {
                    setShowListRelEntidad(false);
                }}
                >
                <Input value={newRelEntidad} onChange={(e) => setNewRelEntidad(e.target.value)}/>
                </Modal>
            }
        </div>
    );
}

export default Relacionados;