import React, { useState, useEffect } from 'react';
import './Interlocking.scss';
import { Button, Col, DatePicker, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, tipoDocOptions } from '../../optList';
import moment from 'moment';

export default function Interlocking({ form, sectionName, apiForm, clientName, formato, docValidator }) {

  const [showTipoPropiedad, setShowTipoPropiedad] = useState(false);
  const [showEmpresaIndirectaFields, setShowEmpresaIndirectaFields] = useState(false);

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    handleDeleteRow,
    handleSaveOnChangeField,
    toDescriptionsPdf
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []

  useEffect(() => {
    setShowTipoPropiedad(rowData.cargo === "Accionista");
    setShowEmpresaIndirectaFields(rowData.cargo === "Accionista" && rowData.tipoPropiedad === "Indirecta");
  }, [rowData.cargo, rowData.tipoPropiedad]);
  
  const fieldsParams = [
    {  
      type: 'input',
      jsonKeyName: "razonSocial",
      label: "Razón Social",
      id: "interlockingRazonSocial",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "interlockingTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rut",
      id: "interlockingRut",
      customValidator: docValidator(form.getFieldValue('interlockingTipoDoc'), true)
    },
    {
      type: 'date',
      label: "Fecha de ingreso",
      jsonKeyName: "hireDate",
      id: "interlockingHireDate",
    },
    {
      type: 'date',
      label: "Fecha de término",
      jsonKeyName: "firedDate",
      isRequired: false,
      id: "interlockingFiredDate",
    },
    {
      type: 'select',
      label: "Cargo",
      jsonKeyName: "cargo",
      id: "interlockingCargo",
      options: cargosOptions,
    },
    showTipoPropiedad && {
      type: 'select',
      label: "Tipo de Propiedad",
      jsonKeyName: "tipoPropiedad",
      id: "interlockingTipoPropiedad",
      options: [{val: "Directa", text: "Directa"}, {val: "Indirecta", text: "Indirecta"}],
    },
    showTipoPropiedad && {
      type: 'input',
      label: "Porcentaje de Participación",
      jsonKeyName: "porcentajeParticipacion",
      id: "interlockingporcentajeParticipacion",
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Nombre de Empresa Propiedad Indirecta",
      jsonKeyName: "nombreEmpresaIndirecta",
      id: "interlockingnombreEmpresaIndirecta",
    },
    showEmpresaIndirectaFields && {
      type: 'select',
      label: "Tipo de documento Empresa Propiedad Indirecta",
      jsonKeyName: "tipoDocEmpresaIndirecta",
      id: "interlockingtipoDocEmpresaIndirecta", 
      options: tipoDocOptions
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Documento Empresa Propiedad Indirecta",
      jsonKeyName: "rutEmpresaIndirecta",
      id: "interlockingrutEmpresaIndirecta", 
      options: tipoDocOptions,
      customValidator: docValidator(form.getFieldValue('interlockingtipoDocEmpresaIndirecta'), true)
    }
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>INTERLOCKING </Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Interlocking: De acuerdo a lo establecido en el artículo 3 inciso segundo letra d) del DL 211 es: (…) d) La participación simultánea de una persona en cargos ejecutivos relevantes o de director en dos o más empresas competidoras entre sí, siempre que el grupo empresarial al que pertenezca cada una de las referidas empresas tenga ingresos anuales por ventas, servicios y otras actividades del giro que excedan las cien mil unidades de fomento en el último año calendario. Con todo, sólo se materializará esta infracción si transcurridos noventa días corridos, contados desde el término del año calendario en que fue superado el referido umbral, se mantuviere la participación simultánea en tales cargos”.
          </p>
          <p>
            Declaro ejercer o haber ejercido durante el año calendario anterior, cargos de ejecutivo relevante o de director en empresas competidoras de {clientName} y sus filiales. 
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasInterlocking", {
              rules: [
                { required: true, message: t("messages.aml.dontForgetSelect")},
                {
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(false);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.interlocking?.hasInterlocking
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasInterlocking", "hasInterlocking")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.interlocking?.hasInterlocking && (
        <>
          { formato === 'html' &&
            <>
              <Row gutter={[20, 10]}>
                {fieldsParams.map(item => (
                  <>
                    {item.type === 'divider' &&
                      <Divider orientation={item.orientation ?? 'left'}>
                        {item?.dividerText ?? ''}
                      </Divider>
                    }
                    {(item.type === 'input' || item.type === 'select' || item.type === 'date') &&
                      <Col span={8} key={item.id}>
                        <Form.Item label={item.label}>
                          {form.getFieldDecorator(item.id, {
                            rules: [
                              { required: item.isRequired ?? true, message: t("messages.aml.requiredData") },
                              { validator: item.customValidator }
                            ],
                          })(
                            <div>
                              {item.type === 'input' && (
                                <Input
                                  onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                                  value={rowData[item.jsonKeyName] ?? ""}
                                />
                              )}
                              
                              {item.type === 'select' && (
                                <Select
                                  allowClear
                                  onChange={(value) => {
                                    handleOnChangeTableElement(value, item.jsonKeyName);
                                    form.setFieldsValue({ [item.id]: value });
                                  }}
                                  style={{ width: '100%' }}
                                  value={rowData[item.jsonKeyName] ?? ""}
                                >
                                  {item.options.map(option => (
                                    <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                                  ))}
                                </Select>
                              )}

                              {item.type === 'date' && (
                                <DatePicker
                                format="DD/MM/YYYY"
                                value={rowData[item.jsonKeyName] ? moment(rowData[item.jsonKeyName], "YYYY/MM/DD") : null}
                                placeholder=""
                                style={{ width: "100%" }}
                                onChange={(momentObj) => {
                                  const dateToString = momentObj ? moment(momentObj).format("YYYY/MM/DD") : null;
                                  handleOnChangeTableElement(dateToString, item.jsonKeyName);
                                  form.setFieldsValue({ [item.id]: dateToString });
                                }}
                              />
                              )}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    }
                  </>
                ))}
              </Row>
              <Row className="button-row">
                {records.length === 0 &&
                  <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                    {t("messages.aml.registersRequired")}
                  </Col>
                }
                <Col className="addRelation" xl={3}>
                  <Button type="primary" htmlType="button" onClick={() => {
                    handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
                  }} icon="plus"> Añadir </Button>
                </Col>
                <Col className="addRelation" xl={3}>
                  <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
                </Col>
              </Row>
            </>
          }
          <Row>
          {records.length > 0 && formato !== 'pdf' ?
            <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
            :
            toDescriptionsPdf(sectionName, fieldsParams)
          }
          </Row>
        </>
      )}
    </div>
  );
}
