import './AlertDetailsBlock.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Col, Icon, Card, Row, Pagination } from 'antd';
import { useTranslation } from 'react-i18next'
import { camelizerHelper } from '../../../../helpers'
import apiConfig from '../../../../config/api'
import { sanitize } from 'dompurify';

const AlertDetailsBlock = ({ alert }) => {
  const [risks, setRisks] = useState([])
  const [itemsTotalNum, setItemsTotalNum] = useState(0)
  const [currentPageRisk, setCurrentPageRisk] = useState(1)
  const itemsPerPage = 10

  const { t } = useTranslation()


  useEffect(() => {
    if(alert !== null && alert.risks && alert.risks.length > 0) {
      handlePaginationChange(1)
      setItemsTotalNum(alert.risks.length)
    }
  }, [alert])

  const handleAddDotsToNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const handlePaginationChange = (page) => {
    const fromNum = ((page - 1) * itemsPerPage)
    setCurrentPageRisk(page)
    setRisks(alert.risks.slice(fromNum, fromNum + itemsPerPage))
  }

  const downloadReportRisk = (pdfFile) => {
    window.open(apiConfig.url + '/ubo/getPDFUboFinder?path=' + pdfFile)
  }

  return (
    <div className="alert-details-block block">
      <div className="block-title" style={{ textAlign: 'center' }}>
        <Icon type="info-circle" />
        <h3>{ t('messages.aml.alertInformation') }</h3>
      </div>
      <div className="block-content show">
        <div className="alert-item">
          <Row>
            <Col span={24}>
              <div className="rules">
                <h4><Icon type="fork" />&nbsp;&nbsp; { t('messages.aml.rules') }</h4>
                  {alert !== null &&
                    alert?.rules.map((rule) => {
                      return (
                        <div className="rules-items">
                          <div className="subitem">
                            { t('messages.aml.name') }: <strong>{ rule.name }</strong>
                          </div>
                          <div className="subitem">
                            { t('messages.aml.code') }: <strong>{ rule.code }</strong>
                          </div>
                          <div className="subitem">
                            { t('messages.aml.score') }: <strong>{ rule.score }</strong>
                          </div>
                        </div>
                      )
                    })
                  }
              </div>
            </Col>
          </Row>
          {alert?.description?.rules &&
            <Row>
              <Col span={24}>
                <div className="rules">
                  <h4><Icon type="bars" />&nbsp;&nbsp; Detalle</h4>
                  <div className="rules-cards-wrapper">
                    { alert.description.rules.map((rule) =>
                      <Row type="flex" gutter={[8,16]} style={{padding:'0px 10px'}}>
                        <Col span={10}>
                          <Card bodyStyle={{textAlign: "left", verticalAlign:"center"}}>
                              {alert.rules.map(alertRule => {
                                if (alertRule.code === rule.code){
                                  return <div>
                                    <p style={{marginBottom: 5}}>{alertRule.name}:</p>
                                    <p>{alertRule.description}</p>
                                  </div>
                                }
                              })}
                          </Card>
                        </Col>
                          {rule?.params?.fecCierre ?
                            <>
                              <Col span={7} >
                                  <Card title="Evento Actual" headStyle={{backgroundColor: '#0000004d', color: 'white'}}>
                                    <Row className="data">
                                      { rule.params.operacion &&
                                        <>
                                          <Col span={14}>
                                            Operación:
                                          </Col>
                                          <Col span={10}>
                                              {rule.params.operacion}
                                          </Col>
                                        </>
                                      }
                                      { rule.params.monto !== undefined &&
                                        <>
                                          <Col span={14}>
                                            Monto:
                                          </Col>
                                          <Col span={10}>
                                            ${rule.params.monto?.toLocaleString('cl')}
                                          </Col>
                                        </>
                                      }
                                      { rule.params.fechaOperacion &&
                                        <>
                                          <Col span={14}>
                                            Fecha:
                                          </Col>
                                          <Col span={10}>
                                            {moment(rule.params.fechaOperacion).format('DD/MM/YYYY')}
                                          </Col>
                                        </>
                                      }
                                      { rule.params.aportePatrimonial ?
                                        <>
                                          <Col span={14}>
                                            Aporte Patrimonial:
                                          </Col>
                                          <Col span={10}>
                                              ${rule.params.aportePatrimonial?.toLocaleString('cl')}
                                          </Col>
                                        </>
                                        : rule.params.patAdministradoCurrent &&
                                        <>
                                          <Col span={14}>
                                              Patrimonio Administrado:
                                          </Col>
                                          <Col span={10}>
                                              ${rule.params.patAdministradoCurrent?.toLocaleString('cl')}
                                          </Col>
                                        </>
                                      }
                                      <Col span={14}>
                                        { rule.params.aportePatrimonial ? "Fecha Operación:"
                                        : "Fecha de cierre:"
                                        }
                                      </Col>
                                      <Col span={10}>
                                          {moment(rule.params.fecCierre).format('DD/MM/YYYY')}
                                      </Col>
                                      { rule.params.perfil &&
                                        <>
                                          <Col span={14}>
                                            Perfil:
                                          </Col>
                                          <Col span={10}>
                                            {rule.params.perfil == '3' ? 'Riesgo Alto'
                                            : rule.params.perfil == '2' ? 'Riesgo Medio'
                                            : rule.params.perfil == '1' && 'Riesgo Bajo'
                                            }
                                          </Col>
                                        </>
                                      }
                                      {rule.params.pep && rule.params.pep === 'Y' &&
                                        <>
                                          <Col span={14}>
                                            PEP:
                                          </Col>
                                          <Col span={10}>
                                            Sí, ver detalle
                                          </Col>
                                        </>
                                      }
                                      {rule.params.pais &&
                                        <>
                                          <Col span={14}>
                                            País de riesgo:
                                          </Col>
                                          <Col span={10} style={{textTransform: 'capitalize'}}>
                                            {rule.params.pais.toLowerCase()}
                                          </Col>
                                        </>
                                      }
                                      {rule.params.actividad &&
                                        <>
                                          <Col span={14}>
                                            Actividad de riesgo:
                                          </Col>
                                          <Col span={10} style={{textTransform: 'capitalize'}}>
                                            {rule.params.actividad.toLowerCase()}
                                          </Col>
                                        </>
                                      }
                                    </Row>
                                  </Card>
                              </Col>
                              <Col span={7} >
                                  <Card title="Evento Pasado" headStyle={{backgroundColor: '#0000004d', color: 'white'}}>
                                    <Row className="data">
                                      { rule.params.patAdministrar ?
                                        <>
                                            <Col span={14}>
                                                Patrimonio por Administrar:
                                            </Col>
                                            <Col span={10}>
                                                ${rule.params.patAdministrar.toLocaleString('cl')}
                                            </Col>
                                        </>
                                      : rule.params.patAdministradoPrevious &&
                                        <>
                                            <Col span={14}>
                                                Patrimonio Administrado:
                                            </Col>
                                            <Col span={10}>
                                                ${rule.params.patAdministradoPrevious.toLocaleString('cl')}
                                            </Col>
                                        </>
                                      }
                                      {rule.params.fecPrevious &&
                                        <>
                                          <Col span={14}>
                                              Fecha de cierre:
                                          </Col>
                                          <Col span={10}>
                                            {moment(rule.params.fecPrevious).format('DD/MM/YYYY')}
                                          </Col>
                                        </>
                                      }
                                    </Row>
                                  </Card>
                              </Col>
                            </>
                          : alert.type === "TRANSACCIONAL" ?
                            <Col span={14} >
                              <Card>
                                <Row gutter={[16]}>
                                  <Col span={12}>
                                    <Row className="data">
                                      <Col span={12}>
                                          ID Operación:
                                      </Col>
                                      <Col span={12}>
                                        {alert.transaction.id ?? '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Producto:
                                      </Col>
                                      <Col span={12} style={{textTransform: 'capitalize'}}>
                                        {alert.transaction.producto ? alert.transaction.producto.toLowerCase() : '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Operación:
                                      </Col>
                                      <Col span={12} style={{textTransform: 'ze'}}>
                                        {alert.transaction.operacion ? alert.transaction.operacion.toLowerCase() : '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Monto:
                                      </Col>
                                      <Col span={12}>
                                        {alert.transaction.monto ? alert.transaction.monto.toLocaleString('cl') + ' ' + alert.transaction.codMoneda : '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Saldo:
                                      </Col>
                                      <Col span={12}>
                                        {alert.transaction.saldo ? alert.transaction.saldo.toLocaleString('cl') + ' ' + alert.transaction.codMoneda : '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Fecha Operación:
                                      </Col>
                                      <Col span={12}>
                                        {alert.transaction.fechaOperacion ? moment(alert.transaction.fechaOperacion).format('DD/MM/YYYY') : '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Método de pago:
                                      </Col>
                                      <Col span={12} style={{textTransform: 'capitalize'}}>
                                        {alert.transaction.medioPago ? alert.transaction.medioPago.toLowerCase() : '------------'}
                                      </Col>
                                      <Col span={12}>
                                          Canal:
                                      </Col>
                                      <Col span={12} style={{textTransform: 'capitalize'}}>
                                        {alert.transaction.canal ? alert.transaction.canal.toLowerCase() : '------------'}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={12}>
                                    { rule.params &&
                                      <Row className="data">
                                        {rule.params.nroTransacciones !== undefined &&
                                          <>
                                            <Col span={12}>
                                                Historia acumulada:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.nroTransacciones}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.std !== undefined &&
                                          <>
                                            <Col span={12}>
                                                Desviación estandar:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.std}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.avg !== undefined &&
                                          <>
                                            <Col span={12}>
                                                Promedio:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.avg}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.situacionLaboral &&
                                          <>
                                            <Col span={12}>
                                                Situación laboral:
                                            </Col>
                                            <Col span={12} style={{textTransform: 'capitalize'}}>
                                              {rule.params.situacionLaboral}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.umbralPagos !== undefined &&
                                          <>
                                            <Col span={12}>
                                              Umbral de Pagos:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.umbralPagos?.toLocaleString('cl')}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.umbralRetiros !== undefined &&
                                          <>
                                            <Col span={12}>
                                              Umbral de Retiros:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.umbralRetiros?.toLocaleString('cl')+ ' ' +alert.transaction.codMoneda}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.sueldoPromedio !== undefined &&
                                          <>
                                            <Col span={12}>
                                              Sueldo promedio:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.sueldoPromedio.toLocaleString('cl')}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.factorSueldo !== undefined &&
                                          <>
                                            <Col span={12}>
                                              Nro. veces sueldo:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.factorSueldo}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.ventaPromedio !== undefined &&
                                          <>
                                            <Col span={12}>
                                              Ventas promedio:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.ventaPromedio.toLocaleString('cl')}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.fechaInicioVigencia &&
                                          <>
                                            <Col span={12}>
                                              Inicio vigencia producto:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.fechaInicioVigencia}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.porcSobreSaldo &&
                                          <>
                                            <Col span={12}>
                                              % sobre el saldo:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.porcSobreSaldo}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.pais &&
                                          <>
                                            <Col span={12}>
                                              País de Riesgo:
                                            </Col>
                                            <Col span={12} style={{textTransform: 'capitalize'}}>
                                              {rule.params.pais}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.nroCuenta &&
                                          <>
                                            <Col span={12}>
                                              Nro. de Cuenta:
                                            </Col>
                                            <Col span={12}>
                                              {rule.params.nroCuenta}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.fechaCierre &&
                                          <>
                                            <Col span={12}>
                                              Fecha de Cierre:
                                            </Col>
                                            <Col span={12}>
                                              {moment(rule.params.fechaCierre).format('DD/MM/YYYY')}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.bloqueado && rule.params.bloqueado === 'Y' && 
                                          <>
                                            <Col span={12}>
                                              Estado de Cuenta:
                                            </Col>
                                            <Col span={12}>
                                              Bloqueado
                                            </Col>
                                          </>
                                        }
                                        {rule.params.pep && rule.params.pep === 'Y' &&
                                        <>
                                          <Col span={12}>
                                            PEP:
                                          </Col>
                                          <Col span={12}>
                                            Sí, ver detalle
                                          </Col>
                                        </>
                                        }
                                      </Row>
                                    }
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                            :
                            <Row>
                              <Col span={11}>
                                <Card>
                                    País Riesgoso
                                </Card>
                              </Col>
                              <Col span={13}>
                                <Card>
                                    Corea del Norte
                                </Card>
                              </Col>
                            </Row>
                          }
                        </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          }
          { itemsTotalNum > 0 &&
            <Row>
              <Col span={24}>
              <div className="rules rules-details">
                <h4><Icon type="fork" />&nbsp;&nbsp; Detalle de Reglas</h4>
                <div className="info-content">
                  <div className="risks-theads">
                    <div className="risks-theads-inner">
                      <Row>
                        <Col span={2} className="col-inner">
                            <p>{ t('messages.aml.type')}</p>
                        </Col>
                        <Col span={6} className="col-inner">
                            <p>{ t('messages.aml.type')}</p>
                        </Col>
                        <Col span={3} className="col-inner">
                            <p>DNI</p>
                        </Col>
                        <Col span={5} className="col-inner">
                            <p>{ t('messages.aml.databases')}</p>
                        </Col>
                        <Col span={5} className="col-inner">
                            <p>{ t('messages.aml.rules')}</p>
                        </Col>
                        <Col span={1} className="col-inner">
                            <p>{ t('messages.aml.risk')}</p>
                        </Col>
                        <Col span={2} className="col-inner">
                            <p>{ t('messages.aml.downloadReport')}</p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <ul className="risks-childs">
                  {risks.map((risk, index) =>
                      <li>
                        <div className="risks-child">
                          <div className="risks-child-inner">
                            <Row className="data">
                              <Col span={2} className="col-inner">
                                { risk.type === 'UBO' ? risk.type : t('messages.aml.' + risk.type.toLowerCase()) }
                              </Col>
                              <Col span={6} className="col-inner">
                                  {camelizerHelper(risk.name)}
                              </Col>
                              <Col span={3} className="col-inner">
                                  { risk.rut !== null ? risk.rut : t('messages.aml.notFound') }
                              </Col>
                              <Col span={5} className="col-inner">
                                  { risk.basesAML }
                              </Col>
                              <Col span={5} className="col-inner">
                                { risk.rulesId !== null && risk.rulesId.join(', ')}
                              </Col>
                              <Col span={1} className="col-inner">
                                  <div className={ 'child-risk risk-' + risk.risk }>
                                    { risk.risk === null && 'N/A'}
                                    { risk.risk === 'BLACK' && 'C' }
                                    { risk.risk === 'RED' && 'A' }
                                    { risk.risk === 'ORANGE' && 'M' }
                                    { risk.risk === 'YELLOW' && 'B' }
                                    { risk.risk === 'GREEN' && 'N' }
                                  </div>
                              </Col>
                              <Col span={2} className="col-inner">
                                { risk.path &&
                                  <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(risk.path ) }>PDF</Button>
                                }
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </li>
                    )
                  }
                  </ul>
                  { itemsTotalNum > itemsPerPage &&
                    <Pagination onChange={ handlePaginationChange }
                      pageSize={ itemsPerPage } current={ currentPageRisk } total={ itemsTotalNum } size="small"/>
                  }
                </div>
              </div>
              </Col>
            </Row>
          }
          { alert && alert.description && alert.description.name &&
            <Row>
              <Col span={24}>
                <div className="info-block description">
                  <h3><Icon type="info-circle" />&nbsp;&nbsp; { t('messages.aml.description') }</h3>
                  <div className="info-content">
                    <Row>
                      <Col span="24">
                        <label>Creado por</label>
                        <p>{ alert.username }</p>
                      </Col>
                      <Col span="24">
                        <label>Descripción Corta</label>
                        <p>{ alert.description.name }</p>
                      </Col>
                      { alert.description.description &&
                        <Col span="24">
                          <label>Descripción Larga</label>
                            <p dangerouslySetInnerHTML={{__html: sanitize(alert.description.description.replace('\n','<br/>'))}}></p>
                        </Col>
                      }
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          }
          { alert && alert.transaction && alert.transaction.msgSwift &&
            <Row>
              <Col span={24}>
                <div className="info-block">
                  <h3><Icon type="transaction" />&nbsp;&nbsp; { t('messages.aml.transaction') }</h3>
                  <div className="info-content">
                    <Row>
                      <Col span={24}>
                        <div className="col-inner">
                          <div className="data-block">
                            <div className="data-block-inner">
                              { alert.transaction.msgSwift !== null && alert.transaction.msgSwift !== undefined ?
                                <ul className="swift-row">
                                  <li>
                                    <label>Mensaje Swift</label>:<br/>
                                    <pre>{ alert.transaction.msgSwift }</pre>
                                  </li>
                                  <li>
                                    <label>Bloque Mensaje</label>:<br/>
                                    <pre>{ alert.transaction.blockSwift }</pre>
                                  </li>
                                  <li>
                                    <label>Line</label>: { alert.transaction.lineSwift }
                                  </li>
                                </ul>
                                :
                                <>
                                  <ul>
                                    <li>
                                      <label>ID</label>: { alert.transaction.id }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.product') }</label>: { alert.transaction.producto }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.operation') }</label>: { alert.transaction.operacion }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.amount') }</label>: { handleAddDotsToNumber(alert.transaction.monto) } { alert.transaction.moneda }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.balance') }</label>: { alert.transaction.saldo !== null && alert.transaction.saldo !== undefined && <>$ { handleAddDotsToNumber(alert.transaction.saldo) } </> }
                                    </li>
                                  </ul>
                                  <ul>
                                    <li>
                                      <label>{ t('messages.aml.date') }</label>: { alert.transaction.fechaOperacion !== null && alert.transaction.fechaOperacion !== '' && moment(alert.transaction.fechaOperacion).format('DD-MM-YYYY HH:mm:ss') }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.identificator') }</label>: { alert.transaction.identificador }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.paymentMethod') }</label>: { alert.transaction.medioPago }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.channel') }</label>: { alert.transaction.canal }
                                    </li>
                                    <li>
                                      <label>{ t('messages.aml.currency') }</label>: { alert.transaction.tipoCambio }
                                    </li>
                                  </ul>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          }
        </div>
      </div>
    </div>
  )
}

export default AlertDetailsBlock
