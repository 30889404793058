import { stakeholderService } from '../services'

export default (countryCode, rut, email, nombre) => {
	return new Promise((resolve, reject) => {
		stakeholderService.createPortalUser(countryCode, rut, email, nombre)
			.then(response => resolve({ success: true, data: response.data }))
      .catch(err => {
				reject({success: false, data: err.response.data})
			})
	})
}
