import React , {useState, useEffect} from 'react'
import {Row, Col, Modal, Icon, Button, Divider, Form, Select, Radio, Input, Switch, DatePicker, notification} from 'antd'
import moment from 'moment'
import { withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getAreasPromise} from '../../../../promises';
import { getUsersByClientPromise } from '../../../../../../promises'
import {ContactoFacturacion,
        BasicInfoAdicional,
        ActivityModal,
        InfoTrib,
        MonedaExt,
        OrigenFondos,
        CasaMatriz,
        ActividadEco,
        RepLegal,
        InfoFinanciera,
        InfoPep,
        BenFinales,
        InfoCompliance,
        ParticipacionSoc
        } from './components';
import ImageResources from "../../../../../../layouts/commonComponent/components/ExternalResources/ImageResources";

const FichaCliente = ({currentUser, item, form, countries, handleSaveClient, handleEditClient, handleOnChange, mode, groups, regiones, comunas, areas, users}) => {
    const [itemTable, setItemTable] = useState(item);
    const [showPrevBasicInfo, setShowPrevBasicInfo] = useState(false);
    const [showPrevCasaMatriz, setShowPrevCasaMatriz] = useState(false);
    const [showPrevDomicilio, setShowPrevDomicilio] = useState(false);
    const [showPrevContactoComercial, setShowPrevContactoComercial] = useState(false);
    const [showPrevContactoFacturacion, setShowPrevContactoFacturacion] = useState(false);
    const [showPrevActividadEco, setShowPrevActividadEco] = useState(false);
    const [showPrevRepLegal, setShowPrevRepLegal] = useState(false);
    const [showPrevInfoTrib, setShowPrevInfoTrib] = useState(false);
    const [showPrevInfoFinanciera, setShowPrevInfoFinanciera] = useState(false);
    const [showPrevMonedaExt, setShowPrevMonedaExt] = useState(false);
    const [showPrevOrigenFondos, setShowPrevOrigenFondos] = useState(false);
    const [showPrevInfoPep, setShowPrevInfoPep] = useState(false);
    const [showPrevBenFinales, setShowPrevBenFinales] = useState(false);
    const [showPrevInfoCompliance, setShowPrevInfoCompliance] = useState(false);
    const [showPrevParticipacionSoc, setShowPrevParticipacionSoc] = useState(false);
    const [showPrevInfoBasicAdicional, setShowPrevInfoBasicAdicional] = useState(false);
    const [showListArea, setShowListArea] = useState(false);
    const [newArea, setNewArea] = useState(null);
    const [localAreas, setLocalAreas] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [seeMore1, setSeemore1] = useState(false);
    const [localUsers, setLocalUsers] = useState([{}]);
    const [isActivityModalActive, setIsActivityModalActive] = useState(false);
    const { getFieldDecorator, validateFields } = form;
    const [ficha, setFicha] = useState(itemTable.ficha);
    const { t } = useTranslation();
    const NEW_ITEM = "NEW_ITEM"
    // const [itemTable, setItemTable] = useState({status: 'ACTIVE', type: 'Person', category: 'CLIENTE', formularioInterno: true, subcliente: currentUser.subcliente, dateBirth: null});

    useEffect(() => {
        let empresas = []
        if(currentUser.cliente.outsourcer && currentUser?.cliente?.clientes?.length > 0) {
            empresas = currentUser.cliente.clientes
            if(currentUser?.empresas?.length > 1) {
                empresas = currentUser.empresas
            }
        }
        if(!users) {
            getUsersByClientPromise().then((response) => {
                setLocalUsers(response)
            })
        }else {
            setLocalUsers(users)
        }

        setEmpresas(empresas)

        if(!areas) {
            getAreasPromise().then((response) => {
                setLocalAreas(response)
            })
        }else {
            setLocalAreas(areas)
        }
    }, []);

    const handleOnChangeAttr = (key, value) => {
        itemTable[key] = value
        handleOnChange(key, value)
    }

    const handleActivityModal = () =>{
        setIsActivityModalActive(true)
    }

    const handleOnChangeArea = (value) => {
            if (value !== NEW_ITEM) {
                itemTable.area = value
                setItemTable(itemTable)
                handleOnChange('area', value)
            } else {
                setShowListArea(true);
            }
    }

    const getUsersAsig = (users) => {
        let _users = []
        for(let i=0;i<users.length;i++) {
            if((users[i].type === 'ADMIN' || users[i].type === 'SADMIN' || users[i].type === 'USUARIO') && ((currentUser.cliente.oficialCto !== null && currentUser.cliente.oficialCto.id === users[i].id) || (users[i].modules !== null && users[i].modules.includes('REGISTRO')))) {
            _users.push(<Select.Option value={ users[i].id }>{ users[i].name }</Select.Option>)
            }
        }
        return _users
    }

    const getButtonsMode = (mode) => {
        if(currentUser.type !== 'AUDIT') {
            if(mode === 'view') {
            return <Button type="primary" onClick={ (e) => {handleEditClient(e)} }>{ t('messages.aml.edit') }</Button>
            }else {
            return <Button type="primary" onClick={ (e) => {handleSaveValidate(e)} }>{ t('messages.aml.save') }</Button>
            }
        }
    }

    const handleOnChangeSubcliente = (value) => {
        itemTable.subcliente = { id: value }
        setItemTable(itemTable)
        handleOnChange('subcliente', itemTable.subcliente)
    }

    const handleSaveValidate = (e) => {
        e.preventDefault()
        validateFields(err => {
            if (!err) {
                handleSaveClient()
            }else{
                notification.error({
                    message: t('messages.aml.notifications.anErrorOcurred'),
                    description: t('messages.aml.missingRequiredField')
                })
            }
        })
    }

    const handleOnChangeAsignedUser = (value) => {
        itemTable.userAsig = { id: value }
        setItemTable(itemTable)
        handleOnChange('userAsig', itemTable.userAsig)
    }

    const onCancelModalSection = (section) => {
        if (section === 'casaMatriz'){
            setShowPrevCasaMatriz(false)
        }else if (section === 'infoBasicAdicional'){
            setShowPrevInfoBasicAdicional(false)
        }else if (section === 'contacto'){
            setShowPrevContactoComercial(false)
        }else if (section === 'domicilio'){
            setShowPrevDomicilio(false)
        }else if (section === 'basicInfo'){
            setShowPrevBasicInfo(false)
        }else if (section === 'actividadEco'){
            setShowPrevActividadEco(false)
        }else if (section === 'repLegal'){
            setShowPrevRepLegal(false)
        }else if (section === 'infoFinanciera'){
            setShowPrevInfoFinanciera(false)
        }else if (section === 'infoTributaria'){
            setShowPrevInfoTrib(false)
        }else if (section === 'monedaExt'){
            setShowPrevMonedaExt(false)
        }else if (section === 'origenFondos'){
            setShowPrevOrigenFondos(false)
        }else if (section === 'infoPep'){
            setShowPrevInfoPep(false)
        }else if (section === 'benFinales'){
            setShowPrevBenFinales(false)
        }else if (section === 'infoCom'){
            setShowPrevInfoCompliance(false)
        }else if (section === 'facturacion'){
            setShowPrevContactoFacturacion(false)
        }else if (section === 'sociedades'){
            setShowPrevParticipacionSoc(false)
        }
    }

    const textFormHeader = () => {
        return  <Row gutter={[0, 16]}>
                    <Col span={24}>
                        Se informa un cambio de circunstancia
                        ya que en fecha {moment(ficha?.declAnt?.receiveDate).format("DD/MM/YYYY")},
                        la persona presentó la siguiente información. Los cambios se señalan en un marco rojo.
                    </Col>
                </Row>
    }

    const getUrlPerson =(id) => {
        return  ('/getImageClientUser/0/' + id)
    }

    return (
        <div>
            <Row className="titleRow">
                <Col span={8} style={{marginTop: mode === 'edit' ? 5 : 10}}>
                    {mode === 'edit' ?
                    <h3>Ficha de {t('messages.aml.category.'+itemTable.category)}</h3>
                    : mode === 'new' ?
                    <h3>Nuevo Registro</h3>
                    :
                    <h3>Completar Datos</h3>
                    }
                </Col>
                <Col span={8} style={{textAlign:"center"}}>
                    {mode === 'new' &&
                    <Form.Item label= "Seleccione el Tipo de Persona" style={{marginTop:0}}>
                        { getFieldDecorator('type', {
                            rules: [
                                {
                                required: false
                                },
                            ],
                            initialValue: itemTable.type
                            })
                            (
                            <Radio.Group onChange={(e) => {handleOnChangeAttr('type', e.target.value)}} value={itemTable.type}>
                                <Row style={{ width: "100%" }}>
                                { ((!currentUser.cliente.modules.includes('REG-PERSON') && !currentUser.cliente.modules.includes('REG-ENTITY')) || currentUser.cliente.modules.includes('REG-PERSON')) &&
                                    <Radio value="Person">{ t('messages.aml.naturalPerson') }</Radio>
                                }
                                { ((!currentUser.cliente.modules.includes('REG-PERSON') && !currentUser.cliente.modules.includes('REG-ENTITY')) || currentUser.cliente.modules.includes('REG-ENTITY')) &&
                                    <Radio value="Entity">{ t('messages.aml.legalPerson') }</Radio>
                                }
                                </Row>
                            </Radio.Group>
                            )
                        }
                        </Form.Item>
                    }
                </Col>
                { itemTable.id &&
                    <Col span={2} style={{textAlign: 'right'}}>
                        <Button type="primary" onClick={ handleActivityModal }>Actividad</Button>
                    </Col>
                }
                <Col span={2} style={{textAlign: 'right', marginTop: mode === 'edit' ? 0 : 10}} offset={itemTable.id ? 0 : 4}>
                    {mode !== null &&
                        getButtonsMode(mode)
                    }
                </Col>
                <Col span={2} style={{textAlign: 'right', marginTop: mode === 'edit' ? 4 : 15}}>
                    Activo: <Switch defaultChecked={ itemTable.status === 'ACTIVE' } disabled={ mode === 'view' || mode === 'new' || mode === 'newFilled' } onChange={ (checked) => handleOnChange('status', checked?'ACTIVE':'INACTIVE') } />
                </Col>

                { itemTable.id &&
                    <Col span={2}>
                        <div style={{width:100, float: 'right'}}>
                            <Button type="link" onClick={() => setSeemore1(!seeMore1)}>
                            {seeMore1 ?
                                <>Ver menos <Icon type="minus"/></>
                                :
                                <>Ver más <Icon type="plus"/></>
                            }
                            </Button>
                        </div>
                    </Col>
                }
            </Row>
            <hr/>
            {mode === 'edit' &&
                <Row>
                    <div style={{float: 'right', marginLeft: 30}}>
                        Actualización Registro: {moment(itemTable.fecLastUpdate).format('DD/MM/YYYY')}
                    </div>
                    {ficha && currentUser.cliente.modules.includes('ONBOARDING') &&
                        <div style={{float: 'right', marginLeft: 30}}>
                        Actualización Formulario: {ficha.declDate ? moment(ficha.declDate).format('DD/MM/YYYY') : 'N/A'}
                        </div>
                    }
                </Row>
            }

            {/* INFORMACION BASICA */}
            <Row className="field-section-row">
                <Col span={24}>
                    <div className="title-section">
                    <Row>
                        <Col span={22}>
                        <h4>Información requerida para el registro</h4>
                        </Col>
                    </Row>
                    </div>
                </Col>
            </Row>
            <Form colon={false}>
                <Row gutter={[36]} style={{padding: 20}}>
                    <Col span={6}>
                        <Form.Item label="Categoría">
                            { getFieldDecorator('category', {
                                rules: [
                                    {
                                    required: true
                                    }
                                ],
                                initialValue: itemTable.category
                                })(
                                <Select onChange={ (value) => handleOnChange('category', value) } className={ mode === 'view' ? 'select-readonly' : '' }>
                                    <Select.Option value="CLIENTE">{ t('messages.aml.category.CLIENTE') }</Select.Option>
                                    <Select.Option value="PROVEEDOR">{ t('messages.aml.category.PROVEEDOR') }</Select.Option>
                                    <Select.Option value="COLABORADOR">{ t('messages.aml.category.COLABORADOR') }</Select.Option>
                                    <Select.Option value="DIRECTOR">{ t('messages.aml.category.DIRECTOR') }</Select.Option>
                                </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Área">
                            {getFieldDecorator('area', {
                                    rules: [
                                    {
                                        required: currentUser.cliente.modules.includes('CDI-FORM')
                                    },
                                    ],
                                    initialValue: itemTable.area
                                })(
                                    <Select onChange={ (value) => handleOnChangeArea(value) } className={ mode === 'view' ? 'select-readonly' : '' }>
                                        <Select.Option value={ null }>[{ t('messages.aml.none') }]</Select.Option>
                                        { localAreas.map(area => <Select.Option value={ area }>{ area }</Select.Option>) }
                                        <Select.Option value={NEW_ITEM}>+ Nuevo Item</Select.Option>
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    { groups !== null && groups.length > 0 &&
                        <Col span={6}>
                            <Form.Item label={ t('messages.aml.group') }>
                            { getFieldDecorator('grupo', {
                                rules: [
                                {
                                    required: true
                                },
                                ],
                                initialValue: itemTable.grupo
                            })(
                                <Select defaultValue={ itemTable.grupo } onChange={ (value) => handleOnChange('grupo', value) } className={ mode === 'view' ? 'select-readonly' : '' }>
                                { groups.map(grupo => <Select.Option value={ grupo }>{ grupo }</Select.Option>) }
                                </Select>
                                )}
                            </Form.Item>
                        </Col>
                    }
                    { empresas.length > 0 &&
                    <Col span={ 6 }>
                        <Form.Item label={ t('messages.aml.subclientAsigned') }>
                            { getFieldDecorator('subcliente', {
                                rules: [
                                {
                                    required: currentUser.cliente.modules.includes('CDI-FORM')
                                }
                                ],
                                initialValue: itemTable.subcliente && itemTable.subcliente.id
                            })(
                                <Select onChange={ (value) => handleOnChangeSubcliente(value) } className={ mode === 'view' ? 'select-readonly' : '' } >
                                    { empresas.map(itemTable =>
                                    <Select.Option className="subclient-option" value={ itemTable.id }>
                                        <div className="subclient-option-inner">
                                        <figure className="subclient-logo">
                                            <ImageResources url={getUrlPerson(itemTable.id)} styleResource={{ height: '15px' }}></ImageResources>                                        </figure>

                                            <span className="subclient-name" style={{ paddingLeft: '10px' }}>{ itemTable.name }</span>
                                        </div>
                                    </Select.Option>
                                    )
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    }
                    { currentUser.cliente.modules.includes('MONITOR-AML') &&
                    <Col span={6}>
                        <Form.Item label={ t('messages.aml.asignedUser') }>
                        { getFieldDecorator('userAsig', {
                        rules: [
                            {
                            required: true
                            },
                        ],
                        initialValue: itemTable?.userAsig?.id ?? currentUser.id
                        })(
                        <Select
                            onChange={ (value) => handleOnChangeAsignedUser(value) } 
                            className={ mode === 'view' ? 'select-readonly' : '' }>
                            { getUsersAsig(localUsers) }
                        </Select>
                        )}
                        </Form.Item>
                    </Col>
                    }
                </Row>
                <Row className="field-section-row">
                    <Col span={24}>
                    <div className="title-section">
                        <Row>
                        <Col span={22}>
                            <h4>Información Básica</h4>
                        </Col>
                        </Row>
                    </div>
                    </Col>
                </Row>

                <Row gutter={[36]} style={{padding: 20}}>
                    <Col span={6}>
                        <Form.Item label={ itemTable.type === 'Person' ? t('messages.aml.name') : t('messages.aml.legalName') }>
                            { getFieldDecorator('nombre', {
                                rules: [
                                {
                                    required: true
                                }
                                ],
                                initialValue: itemTable.nombre
                            })(
                                <Input
                                type="text"
                                readOnly={ mode === 'view' }
                                onChange={ (e) => handleOnChange('nombre', e.target.value)}
                                />
                            )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Tipo de documento">
                            { getFieldDecorator('tipoDoc', {
                                rules: [
                                {
                                    required: false
                                },
                                ],
                                initialValue: itemTable.tipoDoc
                            })(
                                <Select style={{width: '100%'}} placeholder="Tipo de Documento" onChange={ (value) => handleOnChange('tipoDoc', value) }  >
                                    <Select.Option value={ t('messages.aml.rut') }>{ t('messages.aml.rut') }</Select.Option>
                                    <Select.Option value="Otro">Otro</Select.Option>
                                </Select>
                            )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label= "Nro de Documento">
                            { getFieldDecorator('rut', {
                                rules: [
                                {
                                    required: true
                                },
                                ],
                                initialValue: itemTable.rut
                            })
                            (
                                <Input value={itemTable.rut } onChange={ (e) => handleOnChange('rut', e.target.value) } disabled={ mode === 'newFilled' || mode === 'edit' || mode === 'view' } readOnly={ mode === 'view' }/>
                            )
                            }
                        </Form.Item>
                    </Col>
                    {mode !== 'new' &&
                    <Col span={6}>
                        <Form.Item label= "Tipo de Persona">
                        { getFieldDecorator('type', {
                            rules: [
                                {
                                required: false
                                },
                            ],
                            initialValue: itemTable.type
                            })
                            (
                            <Select disabled={ mode === 'newFilled' || mode === 'edit' || mode === 'view' } onChange={ (value) => handleOnChange('type', value) }>
                                { ((!currentUser.cliente.modules.includes('REG-PERSON') && !currentUser.cliente.modules.includes('REG-ENTITY')) || currentUser.cliente.modules.includes('REG-PERSON')) &&
                                    <Select.Option value="Person">{ t('messages.aml.naturalPerson') }</Select.Option>
                                }
                                { ((!currentUser.cliente.modules.includes('REG-PERSON') && !currentUser.cliente.modules.includes('REG-ENTITY')) || currentUser.cliente.modules.includes('REG-ENTITY')) &&
                                    <Select.Option value="Entity">{ t('messages.aml.legalPerson') }</Select.Option>
                                }
                            </Select>
                            )
                        }
                        </Form.Item>
                    </Col>
                    }
                    <Col xs={6}>
                        <Form.Item label={ itemTable.type === 'Person' ? t('messages.aml.title.profession') : t('messages.aml.title.comercialActivity') } >
                            <Input type="text" defaultValue={ itemTable.giroProfesion } onChange={ (e) => handleOnChange('giroProfesion', e.target.value) } readOnly={ mode === 'view' } />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={itemTable.type === 'Person' ? "Nacionalidad":"País de registro"} >
                        { getFieldDecorator('citizenship', {
                            rules: [
                                {
                                required: false
                                },
                            ],
                            initialValue: itemTable.citizenship
                            })(
                            <Select 
                                showSearch 
                                onChange={ (value) => 
                                handleOnChange('citizenship', value) } 
                                readOnly={ mode === 'view' } 
                                className={ mode === 'view' ? 'select-readonly' : '' } 
                            >
                                { countries.map(country => <Select.Option value={ country.country }>{ country.country }</Select.Option>) }
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={ 6 }>
                        <Form.Item label={ itemTable.type === 'Person' ? t('messages.aml.birthDate') : t("messages.aml.registerDate") } >
                            <DatePicker
                                style={{width: "100%" }}
                                placeholder={ itemTable.type === 'Person' ? "Fec. Nacimiento" : "Fec. Registro" }
                                format="DD/MM/YYYY"
                                defaultValue={ itemTable.dateBirth !== null ? moment(itemTable.dateBirth, "DD/MM/YYYY") : null }
                                onChange={(momentObj) =>
                                    handleOnChange(
                                    "dateBirth",
                                    momentObj !== null
                                    ?
                                        moment(momentObj).format("DD/MM/YYYY")
                                    : null,
                                    true
                                    )
                                } 
                            />
                        </Form.Item>
                    </Col>
                    {itemTable.type === 'Person' &&
                        <Col span={ 6 }>
                            <Form.Item label={ t('messages.aml.countryofresindent') } >
                            { getFieldDecorator('pais', {
                                rules: [
                                {
                                    required: false
                                },
                                ],
                                initialValue: itemTable.pais
                            })(
                                <Select 
                                    showSearch 
                                    onChange={ (value) => 
                                    handleOnChange('pais', value) } 
                                    readOnly={ mode === 'view' } 
                                    className={ mode === 'view' ? 'select-readonly' : '' } 
                                >
                                { countries.map(country => <Select.Option value={ country.country }>{ country.country }</Select.Option>) }
                            </Select>
                            )}
                            </Form.Item>
                        </Col>
                    }
                    <Col span={6}>
                        <Form.Item label={ t('messages.aml.title.purpose') } >
                            <Input type="text" defaultValue={ itemTable.proposito } onChange={ (e) => handleOnChange('proposito', e.target.value) } readOnly={ mode === 'view' }/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={ t('messages.aml.title.alias.' + itemTable.type ) } >
                            <Input type="text" defaultValue={ itemTable.aliasFantasia } onChange={ (e) => handleOnChange('aliasFantasia', e.target.value) } readOnly={ mode === 'view' }/>

                        </Form.Item>
                    </Col>
                </Row>

                <Row className="field-section-row">
                    <Col span={24}>
                    <div className="title-section">
                        <Row>
                        <Col span={22}>
                            <h4>Contacto</h4>
                        </Col>
                        </Row>
                    </div>
                    </Col>
                </Row>
                <Row gutter={[36]} style={{padding: 20}}>
                    <Col span={6}>
                        <Form.Item label="Email">
                            { getFieldDecorator('email', {
                                rules: [
                                {
                                    required: currentUser.cliente.modules.includes('CDI-FORM') || currentUser.cliente.modules.includes('ONBOARDING')
                                }
                                ],
                                initialValue: itemTable.email
                            })(
                                <Input type="text" onChange={ (e) => handleOnChange('email', e.target.value) } readOnly={ mode === 'view' } />
                            )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={ t('messages.aml.title.cellphone') } >
                            <Input type="text" defaultValue={ itemTable.mobileNumber } onChange={ (e) => handleOnChange('mobileNumber', e.target.value) } readOnly={ mode === 'view' } />
                        </Form.Item>
                    </Col>
                    {itemTable.type === 'Entity' &&
                        <>
                            <Col span={6}>
                            <Form.Item label={ t('messages.aml.name') } >
                                <Input type="text" defaultValue={ itemTable.contactName } onChange={ (e) => handleOnChange('contactName', e.target.value) } readOnly={ mode === 'view' } />
                            </Form.Item>

                            </Col>
                            <Col span={6}>
                            <Form.Item label={ t('messages.aml.position') } >
                                <Input type="text" defaultValue={ itemTable.cargo } onChange={ (e) => handleOnChange('cargo', e.target.value) } readOnly={ mode === 'view' } />
                            </Form.Item>
                            </Col>
                        </>
                    }
                    <Col span={6}>
                        <Form.Item label={ t('messages.aml.title.phone') } >
                            <Input type="text" defaultValue={ itemTable.telefono } onChange={ (e) => handleOnChange('telefono', e.target.value) } readOnly={ mode === 'view' } />
                        </Form.Item>
                    </Col>
                </Row>
                {(seeMore1 || mode === 'new' || mode === 'newFilled') &&
                    <>
                        <Row className="field-section-row">
                            <Col span={24}>
                            <div className="title-section">
                                <Row>
                                <Col span={22}>
                                    <h4>Ubicación</h4>
                                </Col>
                                </Row>
                            </div>
                            </Col>
                        </Row>
                        <Row gutter={[36]} style={{padding: 20}}>
                            <Col span={6}>
                                <Form.Item label="País" >
                                    <Select
                                        showSearch
                                        defaultValue={ itemTable.paisDir }
                                        readOnly={ mode === 'view' } style={{width: '100%'}}
                                        className={ mode === 'view' ? 'select-readonly' : '' }
                                        onChange={ (value, item) => {
                                            handleOnChange('paisDir', value, item) }}
                                        allowClear
                                    >
                                    { currentUser.cliente.pais === "CHI" &&
                                    <Select.Option
                                    value= "Chile">
                                    Chile
                                    </Select.Option> }
                                    { currentUser.cliente.pais === "PER" &&
                                    <Select.Option
                                    value= "Peru">
                                    Peru
                                    </Select.Option> }
                                    { countries.map(country => <Select.Option value={ country.country } code={country.iso2}>{ country.country }</Select.Option>) }
                                </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Región" >
                                    {regiones !== [] && regiones.length > 0 ?
                                    <Select
                                        defaultValue={itemTable.region}
                                        allowClear
                                        showSearch
                                        onChange={ (value) => {
                                            handleOnChange('region', value) }} 
                                    >
                                        { regiones.map(region => <Select.Option value={ region.name }>{ region.name }</Select.Option>) }
                                    </Select>
                                    :
                                    <Input onChange={(e) => handleOnChange('region', e.target.value)} defaultValue={itemTable.region}/>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={ t('messages.aml.neighbourhood') } >
                                    {comunas && comunas.length > 0 ?
                                        <Select
                                            defaultValue={itemTable.ciudad}
                                            onChange={ (value) => {
                                            handleOnChange('ciudad', value) }}
                                        >
                                        { comunas.map(comuna => <Select.Option value={ comuna.name }>{ comuna.name }</Select.Option>) }
                                        </Select>
                                    :
                                        <Input type="text" defaultValue={ itemTable.ciudad } onChange={ (e) => handleOnChange('ciudad', e.target.value) } readOnly={ mode === 'view' } />
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={ t('messages.aml.address') } >
                                    <Input type="text" defaultValue={ itemTable.direccion } onChange={ (e) => handleOnChange('direccion', e.target.value) } readOnly={ mode === 'view' } />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                }
            </Form>

            { showListArea &&
                <Modal
                    title="Area"
                    className="modal-area-cliente"
                    visible={ true }
                    width={ 400 }
                    onOk={() => {
                        let inputValue = newArea.trim()
                        itemTable.area = inputValue
                        setItemTable(itemTable)
                        setShowListArea(false)
                        setNewArea(null)
                        if (!localAreas.includes(inputValue)) {
                        localAreas.push(inputValue)
                        setLocalAreas(localAreas)
                        }
                    }}
                    onCancel={() => {
                        setShowListArea(false);
                    }}
                    >
                    <Input value={newArea} onChange={(e) => setNewArea(e.target.value)}/>
                </Modal>
            }
            {ficha && currentUser.cliente.modules.includes('ONBOARDING') && seeMore1 &&
                <div className="div-more-info" style={{marginTop:15}}>
                    {ficha.json?.basicInformation?.additionalInfo &&
                        <>
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                    <Row>
                                    <Col span={22}>
                                        <h4>Información Básica adicional</h4>
                                    </Col>
                                    {ficha.json?.basicInformation?.additionalInfo?.hasChanges &&
                                        <Col span={1} offset={1} >
                                        <a onClick={() => setShowPrevInfoBasicAdicional(true)}>
                                            <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                        </a>
                                        </Col>
                                    }
                                    </Row>
                                </div>
                                <Row className="section-wrapper">
                                    <BasicInfoAdicional
                                        infoBasicAdicional={ficha.json?.basicInformation?.additionalInfo}
                                        record={itemTable} />
                                    { showPrevInfoBasicAdicional &&
                                    <Modal
                                        title="Información Básica adicional"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('infoBasicAdicional')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('infoBasicAdicional')}
                                    >
                                        {textFormHeader()}
                                        <BasicInfoAdicional
                                            infoBasicAdicional={ficha?.declAnt?.json?.basicInformation?.additionalInfo ?? {}}
                                            record={itemTable}
                                            actual={ficha.json?.basicInformation?.additionalInfo}
                                        />
                                    </Modal>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        {itemTable.type==='Entity' && ficha.json?.basicInformation.additionalInfo.matriz === 'SI' && ficha.json?.basicInformation?.casaMatriz &&
                            <>
                            <Row className="field-section-row">
                                <Col span={24}>
                                    <div className="onb-title-section">
                                        <Row>
                                        <Col span={22}>
                                            <h4>Casa Matriz</h4>
                                        </Col>
                                        {ficha.json?.basicInformation?.casaMatriz?.hasChanges &&
                                            <Col span={1} offset={1} >
                                            <a onClick={() => setShowPrevCasaMatriz(true)}>
                                                <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                            </a>
                                            </Col>
                                        }
                                        </Row>
                                    </div>
                                    <Row className="section-wrapper">
                                        <CasaMatriz
                                            casaMatriz={ficha.json?.basicInformation?.casaMatriz}
                                            record={itemTable} />
                                        { showPrevCasaMatriz &&
                                        <Modal
                                            title="Casa Matriz"
                                            wrapClassName="modal-client-previous-data"
                                            visible={true}
                                            header={null}
                                            width={'95vw'}
                                            footer={[<Button onClick={() => onCancelModalSection('casaMatriz')}>Cerrar</Button>]}
                                            onCancel={() => onCancelModalSection('casaMatriz')}
                                        >
                                            {textFormHeader()}
                                            <CasaMatriz
                                                casaMatriz={ficha?.declAnt?.json?.basicInformation?.casaMatriz ?? {}}
                                                record={itemTable}
                                                actual={ficha.json?.basicInformation?.casaMatriz}
                                            />
                                        </Modal>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            </>
                        }
                        </>
                    }
                    {ficha.json?.basicInformation?.facturacion &&
                        <Row className="field-section-row">
                        <Col span={24}>
                            <div className="onb-title-section">
                                <Row>
                                    <Col span={23}><h4>Contacto de facturación</h4></Col>
                                        {ficha.json?.basicInformation?.facturacion?.hasChanges &&
                                        <Col span={1}>
                                            <a onClick={() => setShowPrevContactoFacturacion(true)}>
                                            <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                            </a>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <Row className="section-wrapper">
                                <ContactoFacturacion
                                    contactoFacturacion={ ficha.json?.basicInformation.facturacion }
                                    record={itemTable}
                                />
                                { showPrevContactoFacturacion &&
                                <Modal
                                    title="Contacto de facturación"
                                    wrapClassName="modal-client-previous-data"
                                    visible={true}
                                    header={null}
                                    width={'95vw'}
                                    footer={[<Button onClick={() => onCancelModalSection('facturacion')}>Cerrar</Button>]}
                                    onCancel={() => onCancelModalSection('facturacion')}
                                >
                                    {textFormHeader()}
                                    <ContactoFacturacion
                                        contactoFacturacion={ficha?.declAnt?.json?.basicInformation?.facturacion ?? {}}
                                        record={itemTable}
                                        actual={ ficha.json?.basicInformation.facturacion }
                                    />
                                </Modal>
                                }
                            </Row>
                        </Col>
                        </Row>
                    }
                    {ficha.json?.infoEco?.actividadEconomica &&
                        <Row className="field-section-row">
                        <Col span={24}>
                            <div className="onb-title-section">
                                <Row>
                                    <Col span={22}><h4>Actividad Económica</h4></Col>
                                    {ficha.json?.infoEco.actividadEconomica?.hasChanges &&
                                    <Col span={1} offset={1} >
                                        <a onClick={() => setShowPrevActividadEco(true)}>
                                        <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                        </a>
                                    </Col>
                                    }
                                </Row>
                            </div>
                            <Row className="section-wrapper">
                                <ActividadEco
                                    actividadEco={ ficha.json?.infoEco.actividadEconomica }
                                />
                                { showPrevActividadEco &&
                                <Modal
                                    title="Actividad Económica"
                                    wrapClassName="modal-client-previous-data"
                                    visible={true}
                                    header={null}
                                    width={'95vw'}
                                    footer={[<Button onClick={() => onCancelModalSection('actividadEco')}>Cerrar</Button>]}
                                    onCancel={() => onCancelModalSection('actividadEco')}
                                >
                                    {textFormHeader()}
                                    <ActividadEco
                                        actividadEco={ficha?.declAnt?.json?.infoEco?.actividadEconomica ?? {}}
                                        actual={ ficha.json?.infoEco.actividadEconomica }
                                    />
                                </Modal>
                                }
                            </Row>
                        </Col>
                        </Row>
                    }
                    {itemTable.type==='Entity' && 
                        <>
                        {ficha.json?.repLegal &&
                            <Row className="field-section-row">
                                <Col span={24}>
                                    <div className="onb-title-section">
                                        <Row>
                                            <Col span={23}><h4>Representante Legal que firma documento</h4></Col>
                                            {ficha.json?.repLegal?.hasChanges &&
                                            <Col span={1}>
                                                <a onClick={() => setShowPrevRepLegal(true)}>
                                                <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                                </a>
                                            </Col>
                                            }
                                        </Row>
                                    </div>
                                    <Row className="section-wrapper">
                                        <RepLegal
                                            repLegal={ ficha.json?.repLegal }
                                        />
                                        { showPrevRepLegal &&
                                        <Modal
                                            title="Representante Legal que firma documento"
                                            wrapClassName="modal-client-previous-data"
                                            visible={true}
                                            header={null}
                                            width={'95vw'}
                                            footer={[<Button onClick={() => onCancelModalSection('repLegal')}>Cerrar</Button>]}
                                            onCancel={() => onCancelModalSection('repLegal')}
                                        >
                                            {textFormHeader()}
                                            <RepLegal
                                                repLegal={ficha?.declAnt?.json?.repLegal ?? {}}
                                                actual={ ficha.json?.repLegal}
                                            />
                                        </Modal>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        }
                        {ficha.json?.infoFinanciera &&
                            <Row className="field-section-row">
                                <Col span={24}>
                                    <div className="onb-title-section">
                                        <Row>
                                            <Col span={22}><h4>Información Financiera</h4></Col>
                                            {ficha.json?.infoFinanciera?.hasChanges &&
                                                <Col span={1} offset={1} >
                                                    <a onClick={() => setShowPrevInfoFinanciera(true)}>
                                                    <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                                    </a>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                    <Row className="section-wrapper">
                                        <InfoFinanciera
                                            infoFinanciera={ ficha.json?.infoFinanciera }
                                            record={itemTable}
                                        />
                                        { showPrevInfoFinanciera &&
                                        <Modal
                                            title="Información Financiera"
                                            wrapClassName="modal-client-previous-data"
                                            visible={true}
                                            header={null}
                                            width={'95vw'}
                                            footer={[<Button onClick={() => onCancelModalSection('infoFinanciera')}>Cerrar</Button>]}
                                            onCancel={() => onCancelModalSection('infoFinanciera')}
                                        >
                                            {textFormHeader()}
                                            <InfoFinanciera
                                                infoFinanciera={ficha?.declAnt?.json?.infoFinanciera ?? {}}
                                                actual={ ficha.json?.infoFinanciera }
                                            />
                                        </Modal>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        }
                        </>
                    }
                    {ficha.json?.infoEco?.tributaria &&
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                    <Row>
                                        <Col span={22}><h4>Información Tributaria</h4></Col>
                                        {ficha.json?.infoEco.tributaria?.hasChanges &&
                                        <Col span={1} offset={1} >
                                            <a onClick={() => setShowPrevInfoTrib(true)}>
                                            <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                            </a>
                                        </Col>
                                        }
                                    </Row>
                                </div>
                                <Row className="section-wrapper">
                                    <InfoTrib
                                        infoTributaria={ ficha.json?.infoEco.tributaria }
                                    />
                                    { showPrevInfoTrib &&
                                    <Modal
                                        title="Información Tributaria"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('infoTributaria')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('infoTributaria')}
                                    >
                                        {textFormHeader()}
                                        <InfoTrib
                                            infoTributaria={ficha?.declAnt?.json?.infoEco?.tributaria ?? {}}
                                            actual={ ficha.json?.infoEco.tributaria }
                                        />
                                    </Modal>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    }
                    {ficha.json?.infoEco?.monedaExtranjera &&
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                <Row>
                                    <Col span={22}><h4>Cuentas en Moneda Extranjera</h4></Col>
                                    {ficha.json?.infoEco.monedaExtranjera?.hasChanges &&
                                    <Col span={1} offset={1} >
                                        <a onClick={() => setShowPrevMonedaExt(true)}>
                                        <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                        </a>
                                    </Col>
                                    }
                                </Row>
                                </div>
                                <Row className="section-wrapper">
                                    <MonedaExt
                                        monedaExt={ ficha.json?.infoEco.monedaExtranjera }
                                    />
                                    { showPrevMonedaExt &&
                                    <Modal
                                        title="Cuentas en Moneda Extranjera"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('monedaExt')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('monedaExt')}
                                    >
                                        {textFormHeader()}
                                        <MonedaExt
                                            monedaExt={ficha?.declAnt?.json?.infoEco?.monedaExtranjera ?? {}}
                                            actual={ ficha.json?.infoEco.monedaExtranjera }
                                        />
                                    </Modal>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    }
                    {  ficha.json?.fondos &&
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                    <Row>
                                        <Col span={22}><h4>Origen de los fondos</h4></Col>
                                        {ficha.json?.fondos?.hasChanges &&
                                        <Col span={1} offset={1}>
                                            <a onClick={() => setShowPrevOrigenFondos(true)}>
                                            <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                            </a>
                                        </Col>
                                        }
                                    </Row>
                                </div>
                                <Row className="section-wrapper">
                                    <OrigenFondos
                                        origenFondos={ ficha.json?.fondos }
                                    />
                                    { showPrevOrigenFondos &&
                                        <Modal
                                            title="Origen de los fondos"
                                            wrapClassName="modal-client-previous-data"
                                            visible={true}
                                            header={null}
                                            width={'95vw'}
                                            footer={[<Button onClick={() => onCancelModalSection('origenFondos')}>Cerrar</Button>]}
                                            onCancel={() => onCancelModalSection('origenFondos')}
                                        >
                                            {textFormHeader()}
                                            <OrigenFondos
                                                origenFondos={ficha?.declAnt?.json?.fondos ?? {}}
                                                actual={ ficha.json?.fondos}
                                            />
                                        </Modal>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    }
                    {ficha.json?.infoPep &&
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                    <Row>
                                        <Col span={22}><h4>Personas expuestas políticamente</h4></Col>
                                        {ficha.json?.infoPep?.hasChanges &&
                                            <Col span={1} offset={1} >
                                                <a onClick={() => setShowPrevInfoPep(true)}>
                                                <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                                </a>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                                <Row className="section-wrapper">
                                {itemTable.type === 'Entity' &&
                                    <Row>
                                        <Col span={20}>
                                            En la empresa existe un director, administrador,
                                            representante legal, miembro de junta directiva, accionista,
                                            socios con participación directa o indirecta con más del 5%
                                            del capital social que:
                                            <Divider />
                                        </Col>
                                    </Row>
                                }
                                    <InfoPep
                                        infoPep={ ficha.json?.infoPep }
                                        record={itemTable}
                                    />
                                    { showPrevInfoPep &&
                                    <Modal
                                        title="Personas expuestas políticamente"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('infoPep')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('infoPep')}
                                    >
                                        {textFormHeader()}
                                        <InfoPep
                                            infoPep={ ficha?.declAnt?.json?.infoPep ?? {}}
                                            actual={ ficha.json?.infoPep }
                                            record={itemTable}
                                        />
                                    </Modal>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    }
                    {itemTable.type === 'Entity' && ficha.json?.benFinales &&
                        <Row>
                            <Row className="field-section-row">
                                <Col span={24}>
                                    <div className="onb-title-section">
                                        <Row>
                                            <Col span={22}><h4>Beneficiarios Finales</h4></Col>
                                            { ficha.json?.benFinales?.hasChanges &&
                                            <Col span={1} offset={1} >
                                                <a onClick={() => setShowPrevBenFinales(true)}>
                                                <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                                </a>
                                            </Col>
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="field-section-row">
                                Aquellas persona(s) natural(es) que finalmente posee, directa o indirectamente,
                                a través de sociedades u otros mecanismos, una participación igual o mayor al 5%
                                del capital o de los derechos a voto de una persona jurídica determinada. Asimismo,
                                se entenderá como Beneficiario Final a la(s) persona(s) natural(es) que, sin
                                perjuicio de poseer directa o indirectamente una participación inferior al 5% del
                                capital o de los derechos a voto de una persona jurídica, a través de sociedades
                                u otros mecanismos, ejerce el control efectivo de la persona o estructura jurídica.
                            </Row>
                            <Row className="section-wrapper">
                                <BenFinales
                                    benFinales={  ficha.json?.benFinales }
                                />
                                { showPrevBenFinales &&
                                    <Modal
                                        title="Beneficiarios Finales"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('benFinales')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('benFinales')}
                                    >
                                    {textFormHeader()}
                                    <BenFinales
                                        benFinales={ficha?.declAnt?.json?.benFinales ?? {}}
                                        actual={ ficha.json?.benFinales }
                                    />
                                    </Modal>
                                }
                            </Row>
                        </Row>
                    }
                    {ficha.json?.infoCom && ficha.json?.infoCom &&
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                    <Row>
                                        <Col span={22}><h4>Información del Programa de Compliance</h4></Col>
                                        {ficha.json?.infoCom?.hasChanges &&
                                        <Col span={1} offset={1}>
                                            <a onClick={() => setShowPrevInfoCompliance(true)}>
                                            <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                            </a>
                                        </Col>
                                        }
                                    </Row>
                                </div>
                                <Row className="section-wrapper">
                                <InfoCompliance
                                    infoCom={ ficha.json?.infoCom }
                                    record={itemTable}
                                />
                                { showPrevInfoCompliance &&
                                    <Modal
                                        title="Información del programa de compliance"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('infoCom')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('infoCom')}
                                    >
                                    {textFormHeader()}
                                    <InfoCompliance
                                        infoCom={ficha?.declAnt?.json?.infoCom ?? {}}
                                        actual={ ficha.json?.infoCom }
                                        record={itemTable}
                                    />
                                    </Modal>
                                }
                                </Row>
                            </Col>
                        </Row>
                    }
                    {ficha.json?.sociedades &&
                        <Row className="field-section-row">
                            <Col span={24}>
                                <div className="onb-title-section">
                                    <Row>
                                        <Col span={22}><h4>Participación en Sociedades</h4></Col>
                                        {ficha.json?.sociedades?.hasChanges &&
                                        <Col span={1} offset={1} >
                                            <a onClick={() => setShowPrevParticipacionSoc(true)}>
                                            <Icon type="block" style={{fontSize: "20px", float:'right', color: 'white'}} />
                                            </a>
                                        </Col>
                                        }
                                    </Row>
                                </div>
                                <Row className="section-wrapper">
                                <ParticipacionSoc
                                    sociedades={ ficha.json?.sociedades }
                                    record={itemTable}
                                />
                                { showPrevParticipacionSoc &&
                                    <Modal
                                        title="Participación en Sociedades"
                                        wrapClassName="modal-client-previous-data"
                                        visible={true}
                                        header={null}
                                        width={'95vw'}
                                        footer={[<Button onClick={() => onCancelModalSection('sociedades')}>Cerrar</Button>]}
                                        onCancel={() => onCancelModalSection('sociedades')}
                                    >
                                    {textFormHeader()}
                                    <ParticipacionSoc
                                        sociedades={ficha?.declAnt?.json?.sociedades ?? {}}
                                        record={itemTable}
                                        actual={ ficha.json?.sociedades }
                                    />
                                    </Modal>
                                }
                                </Row>
                            </Col>
                        </Row>
                    }
                </div>
            }
            {isActivityModalActive && 
                <Modal 
                    visible
                    width={1000}
                    title="Actividades"
                    footer={false}
                    onCancel={() => setIsActivityModalActive(false)}
                >
                    <ActivityModal recordId={itemTable.id}/>
                </Modal>
            }
        </div>
    )
}

export default withRouter(Form.create({ name: 'client_form' })(FichaCliente))