import React from 'react';
import {Row, Col, Input } from 'antd'

const ContactoFacturacion = ({contactoFacturacion={}, record, actual}) => {
    return (
        <div>
            <Row gutter={[32, 12]}>
                <Col span={6}>
                Nombre y Apellidos
                <Input readOnly value={contactoFacturacion.nombreFacturacion} className={actual && contactoFacturacion.nombreFacturacion !== actual.nombreFacturacion && "difference"}/>
                </Col>
                <Col span={6}>
                Correo electrónico
                <Input readOnly value={contactoFacturacion.mailFacturacion} className={actual && contactoFacturacion.mailFacturacion !== actual.mailFacturacion && "difference"}/>
                </Col>
                <Col span={6}>
                Teléfono celular
                <Input readOnly value={contactoFacturacion.celFacturacion} className={actual && contactoFacturacion.celFacturacion !== actual.celFacturacion && "difference"}/>
                </Col>
                <Col span={6}>
                Teléfono fijo
                <Input readOnly value={contactoFacturacion.telFacturacion} className={actual && contactoFacturacion.telFacturacion !== actual.telFacturacion && "difference"}/>
                </Col>
                {record.type === 'Entity' &&
                    <Col span={6}>
                        Cargo
                        <Input readOnly value={contactoFacturacion.cargoFacturacion} className={actual && contactoFacturacion.cargoFacturacion !== actual.cargoFacturacion && "difference"}/>
                    </Col>
                }
            </Row>
        </div>
    );
};

export default ContactoFacturacion;