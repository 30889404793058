import apiConfig from '../config/api'
import { apiRequestorHelper } from '../helpers'

const Query = {
  create: (query, subclienteId, resultsPerPage, language, type = undefined, typeSearch = undefined, pais) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/search',
      method: 'post',
      body: {
        name: query,
        subclienteId,
        size: resultsPerPage,
        language,
        type,
        typeSearch,
        pais
      }
    })
  },

  update: (from, searchId, resultsPerPage) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/search',
      method: 'post',
      body: {
        from,
        searchId,
        size: resultsPerPage
      }
    })
  },
  getTransfenciasEstadoResumen: (rut) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getTransfenciasEstadoResumen',
      method: 'post',
      body: {
        rut
      }
    })
  },
  getTransfenciasEstadoDetalle: (rut) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getTransfenciasEstadoDetalle',
      method: 'post',
      body: {
        rut
      }
    })
  }
}

export default Query;