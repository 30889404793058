import getCategories from './getCategories'
import getForm from './getForm'
import sendForm from './sendForm'
import getParams from './getParams'
import saveFormOnb from './saveFormOnb'
import signCDIForm from './signCDIForm'
import getAreas from './getAreas'
import getClientsMin from './getClientsMin'
import getDeclarations from './getDeclarations'
import sendFileForm from './sendFileForm'
import getCountriesCode from './getCountriesCode'
import saveForm from './saveForm'
import saveFormCDItrab from './saveFormCDItrab'
import saveFormCDIprov from './saveFormCDIprov'
import sendCdiRequest from './sendCdiRequest'
import getCdiDataGraph from './getCdiDataGraph'


export const saveFormPromise = saveForm
export const saveFormCDItrabPromise = saveFormCDItrab
export const saveFormCDIprovPromise = saveFormCDIprov
export const sendCdiRequestPromise = sendCdiRequest
export const getClientsMinPromise = getClientsMin
export const getAreasPromise = getAreas
export const getCategoriesPromise = getCategories
export const getFormPromise = getForm
export const sendFormPromise = sendForm
export const getParamsPromise = getParams
export const saveFormOnbPromise = saveFormOnb
export const signCDIFormPromise = signCDIForm
export const getDeclarationsPromise = getDeclarations
export const sendFileFormPromise = sendFileForm
export const getCountriesCodePromise = getCountriesCode
export const getCdiDataGraphPromise = getCdiDataGraph
