import "./AdvancedTabs.scss";
import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  DatePicker,
  Select,
  Badge,
  Tooltip,
  Tabs,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";

const AdvancedTabs = ({ cbFilters, filters={}, currentUser }) => {
  const { Option } = Select;
  const [advancedObj, setAdvancedObj] = useState(filters);
  const [advancedObjMenu, setAdvancedObjMenu] = useState({
    onb: null,
    cdi: null,
    quiebra: null
  });
  const { TabPane } = Tabs;
  const { t } = useTranslation();

  const handlerChange = (menu, field, value=null, enter) => {
    const obj = { ...advancedObj, [field]: value };
    if (value === null || value === "") {
      delete obj[field];
    }
    setAdvancedObj(obj);
    if (enter) {
      let objMenu = advancedObjMenu[menu];
      const obj2 = { ...objMenu, [field]: value };
      if (value === null || value === "") {
        delete obj2[field];
      }
      const obj3 = { ...advancedObjMenu, [menu]: obj2 };

      cbFilters(obj3)
      setAdvancedObjMenu(obj3);
    }
  };

  const stageOptions = [
    "Fondo de Limitación de Responsabilidad - Empresa Deudora",
    "Insolvencia Transfronteriza",
    "Insolvencia Transfronteriza - Empresa Deudora",
    "Liquidación Artículo 57 N° 4 de la Ley N° 20.720 - Empresa Deudora",
    "Liquidación Artículo N° 269 de la Ley N° 20.720",
    "Liquidación Artículo N° 269 de la Ley N° 20.720 - Persona Deudora",
    "Liquidación forzosa",
    "Liquidación forzosa - Empresa Deudora",
    "Liquidación forzosa - Persona Deudora",
    "Liquidación Refleja - Empresa Deudora",
    "Liquidación voluntaria",
    "Liquidación voluntaria - Empresa Deudora",
    "Liquidación voluntaria - Persona Deudora",
    "Quiebra",
    "Renegociación de la Persona Deudora",
    "Reorganización de la Empresa Deudora",
    "Reorganización extrajudicial (simplificada) - Empresa Deudora"
  ]
  

  const handleClear = () => {
    setAdvancedObj({});
    setAdvancedObjMenu({ onb: null, cdi: null, quiebra: null });
    cbFilters({});
  };

  return (
    <div className="adv-tabs-filters">
      <div className="filters-inner">
        <Tooltip title="Borrar Filtros">
          <Button
            icon="delete"
            className="btn-clear"
            shape="circle"
            ghost
            onClick={handleClear}
          />
        </Tooltip>
        <Tabs>
          {currentUser.cliente.modules.includes('ONBOARDING') &&
            <TabPane
                key="1"
                tab={
                  <span>
                    Onboarding{" "}
                    <Badge count={advancedObjMenu.onb ? Object.entries(advancedObjMenu.onb).length : 0} />
                  </span>
                }
            >
              <Row gutter={4}>
                <Col span={7}>
                  <DatePicker.RangePicker
                    placeholder={["Fec. Actualización", "Hasta"]}
                    style={{ width: "100%" }}
                    value={
                      advancedObjMenu?.onb?.declUpdate
                        ? [
                            moment(advancedObjMenu?.onb?.declUpdate[0]),
                            moment(advancedObjMenu?.onb?.declUpdate[1]),
                          ]
                        : null
                          }
                    onChange={(momentObj) =>
                      handlerChange(
                        "onb",
                        "declUpdate",
                        momentObj !== null && momentObj.length > 0
                          ? [
                              moment(momentObj[0]).valueOf(),
                              moment(momentObj[1]).valueOf(),
                            ]
                          : null,
                        true
                      )
                    }
                  />
                </Col>
                <Col span={5}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Periodicidad"
                      allowClear
                      value={advancedObjMenu?.onb?.periodicity}
                      onChange={(value) =>
                        handlerChange("onb", "periodicity", value, true)
                      }
                    >
                      <Option value="ANNUAL">Anual</Option>
                      <Option value="BIANNUAL">Semestral</Option>
                      <Option value="QUARTERLY">Trimestral</Option>
                      <Option value="MONTHLY">Mensual</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Estado Ficha"
                      allowClear
                      value={advancedObjMenu?.onb?.fichaState}
                      onChange={(value) =>
                        handlerChange("onb", "fichaState", value, true)
                      }
                    >
                      <Option value="PENDIENTE">Pendiente</Option>
                      <Option value="AUTORIZADA">Autorizado</Option>
                      <Option value="RECHAZADA">Rechazado</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Estado Formulario"
                      allowClear
                      value={advancedObjMenu?.onb?.formState}
                      onChange={(value) =>
                        handlerChange("onb", "formState", value, true)
                      }
                    >
                      <Option value="PENDIENTE">Pendiente</Option>
                      <Option value="EVALUACION">Realizado</Option>
                      <Option value="AUTORIZADA">Autorizado</Option>
                      <Option value="RECHAZADA">Rechazado</Option>
                    </Select>
                  </Col>
              </Row>
            </TabPane>
          }
        {currentUser.cliente.modules.includes('CDI-FORM') &&
          <TabPane
              key="2"
              tab={
                <span>
                  Conflictos de Interés{" "}
                  <Badge count={advancedObjMenu.cdi ? Object.entries(advancedObjMenu.cdi).length : 0} />
                </span>
              }
          >
            <Row gutter={4}>
              <Col span={7}>
                <DatePicker.RangePicker
                  placeholder={["Fec. Actualización", "Hasta"]}
                  style={{ width: "100%" }}
                  value={
                    advancedObjMenu?.cdi?.declUpdate
                      ? [
                          moment(advancedObjMenu?.cdi?.declUpdate[0]),
                          moment(advancedObjMenu?.cdi?.declUpdate[1]),
                        ]
                      : null
                        }
                  onChange={(momentObj) =>
                    handlerChange(
                      "cdi",
                      "declUpdate",
                      momentObj !== null && momentObj.length > 0
                        ? [
                            moment(momentObj[0]).valueOf(),
                            moment(momentObj[1]).valueOf(),
                          ]
                        : null,
                      true
                    )
                  }
                />
              </Col>
              <Col span={5}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Periodicidad"
                    allowClear
                    value={advancedObjMenu?.cdi?.periodicity}
                    onChange={(value) =>
                      handlerChange("cdi", "periodicity", value, true)
                    }
                  >
                    <Option value="ANNUAL">Anual</Option>
                    <Option value="BIANNUAL">Semestral</Option>
                    <Option value="QUARTERLY">Trimestral</Option>
                    <Option value="MONTHLY">Mensual</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Estado declaración"
                    allowClear
                    value={advancedObjMenu?.cdi?.fichaState}
                    onChange={(value) =>
                      handlerChange("cdi", "fichaState", value, true)
                    }
                  >
                    <Option value="PENDIENTE">Pendiente</Option>
                    <Option value="AUTORIZADA">Autorizado</Option>
                    <Option value="RECHAZADA">Rechazado</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Vínculos"
                    allowClear
                    value={advancedObjMenu?.cdi?.vinculos}
                    onChange={(value) =>
                      handlerChange("cdi", "vinculos", value, true)
                    }
                  >
                    <Option value="SI">Tiene vínculos</Option>
                    <Option value="NO">No tiene vínculos</Option>
                  </Select>
                </Col>
            </Row>
          </TabPane>
        }
        { currentUser.cliente.modules.includes('QUIEBRA') && currentUser.modules.includes('QUIEBRA') &&
          <TabPane
            key="3"
            tab={
              <span>
                Monitoreo de Quiebras{" "}
                <Badge count={advancedObjMenu.quiebra ? Object.entries(advancedObjMenu.quiebra).length : 0} />
              </span>
            }
          >
            <Row gutter={4}>
              <Col span={10}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Etapa"
                  allowClear
                  value={advancedObjMenu?.quiebra?.stage}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) =>
                    handlerChange("quiebra", "stage", value, true)
                  }
                >
                  {stageOptions.map(opt => <Option value={opt}>{opt}</Option>)}
                </Select>
              </Col>
              <Col span={6}>
                <Select
                    style={{ width: "100%" }}
                    placeholder="Riesgo"
                    allowClear
                    value={advancedObjMenu?.quiebra?.risk}
                    onChange={(value) =>
                      handlerChange("quiebra", "risk", value, true)
                    }
                  >
                    {/* <Option value="GREEN">{t("messages.aml.risk.GREEN")}</Option> */}
                    <Option value="GREEN">{t("messages.aml.risk.YELLOW")}</Option>
                    <Option value="YELLOW">{t( "messages.aml.risk.ORANGE")}</Option>
                    <Option value="RED">{t("messages.aml.risk.RED")}</Option>
                    <Option value="BLACK">{t("messages.aml.risk.BLACK")}</Option>
                </Select>
              </Col>
            </Row>
          </TabPane>
        }
        </Tabs>
      </div>
    </div>
  );
};

export default AdvancedTabs;
