import { notification } from 'antd'
import {getPeopleMonitoredQuantityService} from '../services/getPeopleMonitoredQuantityService'

export const getNumberMonitoredPersonPromise= () => {
	return new Promise(resolve => {
		getPeopleMonitoredQuantityService()
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "Alert Resume : "+error.message})})
	})
}

