import './Inside.scss'
import React, { useState } from 'react'
import {withRouter} from 'react-router-dom'
import { Page, PageBottomBar, PageContent, PageHeader, PageTopBar } from '../../layouts/Private/components'
import { Content, Wrapper, Sidebar } from './layout'
import { DashboardPage } from './components'


const Inside = ({currentUser, history}) => {

	const [activeTab, setActiveTab] = useState('tab-dashboard');
	const [options, setOptions] = useState({});

	const getBreadcrumbs = () => {
		const breadcrumbs = [
			{ title: 'Inside Compliance', icon: 'bar-chart', link: '/inside-compliance' },
		]
		return breadcrumbs
	}

	const handleTabChange = (activeTab, options) => {
		setActiveTab(activeTab)
		setOptions(options)
	}

		return (
			<div className="inside-compliance">
				<PageTopBar breadcrumbs={ getBreadcrumbs() } />
				<Page>
					<PageHeader
						title="Inside Compliance"
						icon="bar-chart"
						description="Aquí podrá conocer a profundidad toda la información Compliance de sus registros"
						/>
						<PageContent>
							<Wrapper>
								<Sidebar currentUser={currentUser} activeTab={ activeTab } onTabChange={ () => handleTabChange }/>
								<Content>
									{ activeTab === 'tab-dashboard' && <DashboardPage currentUser={currentUser} handleTabChange={ () => handleTabChange } /> }
								</Content>
							</Wrapper>
						</PageContent>
				</Page>
				<PageBottomBar breadcrumbs={ getBreadcrumbs } />
			</div>
		)
	}

export default withRouter(Inside)
