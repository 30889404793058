import './Logo.scss'
import React from 'react'
import ImageResources from "../ExternalResources/ImageResources";

const Logo = ({currentUser, isForm}) => {


    const getUrlPerson = (userId, subclienteId) => {
        return ('/getImageClientUser/' + userId + '/' + subclienteId)
    }

    const getUrlClient = (clientId) => {
        return ('/getImageClient?clientId=' + clientId)
    }

    return (
        <div className={isForm ? 'logoForm' : 'logo'}>
            { currentUser.userId ?
                <ImageResources url={getUrlPerson(currentUser.userId, currentUser.subclienteId)}></ImageResources>
            :
                <ImageResources url={getUrlClient(currentUser.clientId)}></ImageResources>
            }
        </div>
    )
}

export default Logo