import apiConfig from '../config/api'
import { apiRequestorHelper } from '../helpers'

const User = {
  read: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/user/currentUser',
      method: 'post'
    })
  },

  changePwd: (password, newpwd) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/user/changePwd',
      method: 'post',
      body: {
        password,
        newpwd
      }
    })
  },
  logout: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/auth/logout',
      method: 'post'
    })
  },
  getQrMfaImage: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/user/qrMfaImage',
      method: 'get',
      responseType: 'arraybuffer'
    })
  },
  genMfa: (action, codigo) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/user/genMfa',
      method: 'post',
      body: {
        action,
        codigo
      }
    })
  },
  validaMfa: (codigo) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/user/validaMfa',
      method: 'post',
      body: {
        codigo
      }
    })
  },
  delUserMfa: (userId) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/user/delUserMfa',
      method: 'post',
      body: {
        userId
      }
    })
  }
}
export default User