import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Select, Spin} from "antd";

import './PrincipalRegisterByRulesCard.scss'
import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import {getIndicatorsRulesPromise, getItemsPromise} from "../../../../promises";
import {camelizerHelper} from "../../../../../../helpers";
import TableModal from "../TableModal/TableModal";


const PrincipalRegisterByRulesCard = ({categories, globalActiveCategory, handleTabAlertas}) => {

    const {t} = useTranslation()

    const [isModalRulesVisible, setIsModalRulesVisible] = useState(null);

    const [activeAlertType, setActiveAlertType] = useState(null);
    const [activeCategory, setActiveCategory] = useState(globalActiveCategory);
    const [activeRule, setActiveRule] = useState();
    const [allRules, setAllRules] = useState([])
    const [slicedAlerts, setSlicedAlerts] = useState(null)

    let filterRules = {
        size: 100
    };

    let filterAlerts = {
        size: 6
    };

    let allTypeAlert = 'N'
    const alertType = ["PERSONA", "DEMOGRAFICA", "TRANSACCIONAL"]

    const rulesTableColumns = [
        {
            title: "Nombre",
            dataIndex: "name",
        },
        {
            title: "Nro. Alertas",
            dataIndex: "alertCount",
        },
    ];

    useEffect(() => {
        handleSearch()
    }, [activeCategory, activeAlertType]);

    const handleSearch = async () => {
        //como validar filtro si no existe registro para reglas con filtro de alerta transaccional
        filterRules.alertType = activeAlertType
        filterRules.category = activeCategory

        const rulesResponse = await getIndicatorsRulesPromise(filterRules)
        let data = rulesResponse.data

        setAllRules(data)

        let ruleSelected = (data.length !== 0 ? data[0] : null)
        setActiveRule(ruleSelected)

        if (ruleSelected !== null) {
            handleSearchAlertsByRule(ruleSelected.ruleId)
        } else {
            setSlicedAlerts([])
        }
    }

    const handleSearchAlertsByRule = async (ruleId) => {
        setSlicedAlerts(null)

        filterAlerts.ruleId = ruleId
        activeAlertType ? filterAlerts.types = [activeAlertType] : delete filterAlerts.types

        const alertsResponse = await getItemsPromise((activeCategory ? activeCategory : allTypeAlert), filterAlerts)
        let alertData = alertsResponse.data.records

        //setSlicedAlerts(alertData.slice(0, 6))
    }

    const handleSetActiveCategory = (category) => {
        setSlicedAlerts(null)
        setActiveCategory(category);
    };

    const handleSetActiveAlertType = (alertType) => {
        setSlicedAlerts(null)
        setActiveAlertType(alertType);
    };

    const handleSetActiveRule = (id) => {
        let newRule = allRules.filter(x => x.ruleId === id)[0]
        setActiveRule(newRule);
        handleSearchAlertsByRule(id)
    };

    function getLegend() {
        return (
            <Row>
                <Col span={17} style={{paddingBottom: '10px'}}>
                    Se presenta el listado de hasta 6 últimos &nbsp;
                    {activeCategory ? t('messages.aml.monitor.principal.rules.cat.' + activeCategory) : (t('messages.aml.records'))}
                    &nbsp;que activaron la regla presentada
                </Col>
            </Row>
        )
    }

    const handleModalTotalRulesCancel = () => {
        setIsModalRulesVisible(false)
    }

    const handleModalTotalRules = () => {
        setIsModalRulesVisible(true)
    }

    const getFilters = (ruleId, folio) => {
        let filters = { ruleId }
        if(activeCategory) filters.categories = [activeCategory]
        if(folio) filters.folio = folio

        return filters
    }

    const getRulesResume = () => {

        return (
            <>
                { activeRule &&
                    <Row>
                        <Col span={10}>
                            <a onClick={() => handleTabAlertas(getFilters(activeRule.ruleId))}>
                                <span className={"rul-name"}>{activeRule.name}: {activeRule.alertCount}</span>
                            </a>
                        </Col>
                    </Row>
                }
                <Row className={"row-table-content"}>

                    { slicedAlerts ?
                        <>
                            { slicedAlerts.length > 0 ?
                            <Row className={"content-register-table"}>
                                { slicedAlerts.map((record, index) =>
                                    <Col span={12} className="record-cell">
                                        <Row>
                                            <Col span={16}>
                                                <a onClick={() => handleTabAlertas(getFilters(activeRule.ruleId, record.folio))}>
                                                    <div className={"alert-name"}> {index+1}) {camelizerHelper(record.name)}</div>
                                                </a>
                                            </Col>
                                            <Col span={8}>
                                                <div className={"alert-date"}> {(record.dateShortAsString).split(" ")[0]}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                { (slicedAlerts.length % 2) === 1 &&
                                    <Col span={12} className="record-cell">
                                        &nbsp;
                                    </Col>
                                }
                            </Row>
                            :
                                <div className={"label-no-info"}>
                                        <Row>
                                            <Col offset={7}>
                                                {t('messages.aml.monitor.no.rules')}
                                            </Col>
                                        </Row>
                                </div>
                            }
                        </>
                        :
                            <Row gutter={[0, 30]}>
                                <Col span={1} offset={11}>
                                    <div className="spinner-principal-rul">
                                        <Spin spinning={true} size="large"/>
                                    </div>
                                </Col>
                            </Row>

                    }
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col span={6}>
                        <Select
                            style={{width: "100%"}}
                            value={activeAlertType}
                            placeholder={"Tipo de alerta"}
                            onChange={value => handleSetActiveAlertType(value)}
                        >
                            <Select.Option value={null}>Tipo de alerta</Select.Option>
                            {alertType.map((type, index) => (
                                <Select.Option key={index} value={type}>
                                    {camelizerHelper(type)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>

                    <Col span={9} offset={1}>
                        <Select
                            style={{width: "100%"}}
                            value={activeRule && activeRule.name}
                            placeholder={"Regla"}
                            onChange={value => handleSetActiveRule(value)}
                        >
                            {allRules.map(rule =>
                                <Select.Option value={rule.ruleId}>
                                    {camelizerHelper(rule.name)}
                                </Select.Option>
                            )}
                        </Select>
                    </Col>

                    <Col span={3} offset={4}>
                        <Button className="show-all-alerts"
                                onClick={slicedAlerts !== null && slicedAlerts.length !== 0 ? handleModalTotalRules : null}>
                            <span className={"text"}>{t('messages.aml.monitor.rules.see.more')} </span>
                        </Button>
                    </Col>

                </Row>
            </>
        )
    }

    return (
        <>
            <div className='principal-rules-register'>

                <LargeCardDashboard
                    key={activeCategory}
                    title={t("messages.aml.monitor.rules.category." + activeCategory)}
                    legend={getLegend()}
                    handleSetActiveCategory={handleSetActiveCategory}
                    activeCategory={activeCategory}
                    categories={categories}
                    content={getRulesResume()}
                />

            </div>

            {
                isModalRulesVisible &&
                <div id="modal-user">
                    <TableModal
                        onCancel={handleModalTotalRulesCancel}
                        dataSource={allRules}
                        columns={rulesTableColumns}
                        title={"Tabla de Reglas"}
                    />
                </div>
            }
        </>
    )

}

export default PrincipalRegisterByRulesCard;