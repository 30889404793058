import { notification } from 'antd'
import {getTransactionalActivityService} from '../services/getTransactionalActivityService'

export const getTransactionalActivityPromise = (dateValue) => {
	return new Promise(resolve => {
		getTransactionalActivityService(dateValue)
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "transactional activity: "+error.message})})
	})
}

