import { useEffect, useState, useCallback } from "react";
import { updateFormPromise } from "../../promises";
import { Descriptions, message } from "antd";

export default function useFormItems(apiForm) {
  const [obj, setObj] = useState({
    ...apiForm,
    json: apiForm.json || {},
  });
  const [isObjLoading, setIsObjLoading] = useState(false);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    if (obj) {
      updateFormPromise(obj).catch(error => {
        console.error("Error actualizando formulario:", error);
      });
    }
  }, [obj]);
  

  const handleSaveOnChangeField = useCallback((form, value, section, fieldName, id) => {
    setObj(prevObj => {
      // Hacer una copia profunda del estado anterior para evitar mutaciones no deseadas
      const currentJson = JSON.parse(JSON.stringify(prevObj.json));
  
      // Asegurarse de que la sección exista, si no, crearla
      if (!currentJson[section]) {
        currentJson[section] = {};
      }
  
      // Actualizar solo el campo específico dentro de la sección
      currentJson[section][fieldName] = value;
      console.log(currentJson)
      // Retornar el nuevo estado del objeto completo
      return {
        ...prevObj,
        json: currentJson,
      };
    });

    
  
    // Actualizar el valor del formulario en Ant Design
    form.setFieldsValue({
      [fieldName]: value
    });
  }, []);
  
  
  const handleOnChangeTableElement = useCallback((value, jsonKeyName) => {
    const updatedRowData = {
      ...rowData,
      [jsonKeyName]: value,
    };
    setRowData(updatedRowData);
  }, [rowData]);
  

  const toDescriptionsPdf = (sectionName, fieldsParams, typeEntity) => {
    // Acceder a los records desde la sección especificada en el estado obj
    const records = obj.json?.[sectionName]?.records || [];
  
    return (
      <>
        {records.map((record, index) => (
          <div key={index} className="descriptions-pdf">
            <h4 className="descriptions-numeral">#{index + 1}</h4>
            <Descriptions title="" column={1} bordered size="small" >
              {fieldsParams.map((field) => {
                const { id, label, jsonKeyName, dividerText, typeEntity: fieldTypeEntity } = field;
    
                // Verificar si se debe mostrar el campo según el typeEntity
                if (!typeEntity || !fieldTypeEntity || fieldTypeEntity.includes(typeEntity)) {
                  return (
                    <> 
                    {/* {dividerText &&
                     <Descriptions.Item label={dividerText} span={2}/>
                    } */}
                    {record[jsonKeyName] !== undefined &&
                      <Descriptions.Item key={id} label={label}>
                        {record[jsonKeyName] || "N/A"} {/* Mostrar "N/A" si el dato no está presente */}
                      </Descriptions.Item>
                    }
                    </>
                  );
                }else{
                  return null;
                }
              })}
            </Descriptions>
            <br />
          </div>
        ))}
      </>
    );
  };

  const handleDeleteRow = useCallback((sectionName, arrayName, rowIndex) => {
    setObj((prevObj) => {
      const currentJson = prevObj.json || {};
      const sectionData = currentJson[sectionName] || {};
  
      const updatedArray = Array.isArray(sectionData[arrayName])
        ? sectionData[arrayName].filter((_, index) => index !== rowIndex)
        : [];
  
      const json = {
        ...currentJson,
        [sectionName]: {
          ...sectionData,
          [arrayName]: updatedArray,
        },
      };
  
      return { ...prevObj, json };
    });
    setRowData({})
  }, []);

  const handleCleanRowData = useCallback((sectionName, form) => {
    const fields = Object.keys(rowData).reduce((acc, key) => {
      acc[key] = undefined; // Usa undefined para limpiar correctamente los campos
      return acc;
    }, {});
    form.setFieldsValue(fields);
    setRowData({});
    form.resetFields()
  }, [rowData]);

  const handleSaveOnAddRow = useCallback((sectionName, arrayName, form, fieldsParams) => {
    form.validateFields(fieldsParams.map(item => item.id))
      .then(() => {
        // Crear el nuevo estado para obj
        const newObj = (prevObj) => {
          const currentJson = prevObj.json || {};
          const sectionData = currentJson[sectionName] || {};
  
          const updatedArray = Array.isArray(sectionData[arrayName])
            ? [...sectionData[arrayName], rowData]
            : [rowData];
  
          const json = {
            ...currentJson,
            [sectionName]: {
              ...sectionData,
              [arrayName]: updatedArray,
            },
          };
  
          return { ...prevObj, json };
        };
  
        // Guardar el nuevo estado
        setObj(newObj);
  
        // Limpiar los datos de la fila actual después de agregarla
        handleCleanRowData(sectionName, form);
  
        // Reiniciar la validación del formulario
        form.resetFields(fieldsParams.map(item => item.id));
      })
      .catch(() => {
        message.error("Faltan campos que completar.");
      });
  }, [rowData, handleCleanRowData]);
  



  return {
    obj,
    isObjLoading,
    rowData,
    handleSaveOnAddRow,
    handleSaveOnChangeField,
    handleCleanRowData,
    handleOnChangeTableElement,
    handleDeleteRow,
    toDescriptionsPdf
  };
}
