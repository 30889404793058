import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export const getAlertResumeByClClientService = ({dateValue}) => {
    return apiRequestorHelper({
        url: apiConfig.url + '/transaction/inside/getAlertResumeByClClient',
        method: 'post',
        body: {
            dateRange: dateValue
        }
    })
}