import { GoogleOAuthProvider } from "@react-oauth/google"
import { LoginGooglePage } from '..';

export default function AuthLogin({successHandler, config}) {

    return (
        <GoogleOAuthProvider clientId={config.clientId}>
            <LoginGooglePage successHandler={successHandler} />
        </GoogleOAuthProvider>
    )
}