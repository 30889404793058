import { AutoComplete, Button, Checkbox, Col, Icon, Input, Modal, notification, Radio, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { getClientsMinPromise } from './promises';
import { getCategoriesPromise } from '../../../Register/promises';
import { createTasksPromise } from '../../promises';
import './TasksCreator.scss'

const TasksCreator = ({alert, refreshHandler}) => {
    const [seeMore, setSeeMore] = useState(0);
    const NEW_ITEM = "+ Nueva Tarea"
    const [alerts, setAlerts] = useState([])
    const tasks = ['Solicitamos el Origen de Fondos', 'Solicitamos mayor información de la persona', 'Solicitamos una explicación de la transacción realizada', NEW_ITEM]
    const [ destinatario, setDestinatario] = useState(null)
    const [ cc, setCc] = useState(null)
    const [ cc2, setCc2] = useState(null)
    const { t } = useTranslation()
    const { Option } = AutoComplete
    const [changes, setChanges] = useState(0)
    const [showNewTask, setShowNewTask] = useState(false)
    const [indexTask, setIndexTask] = useState(-1)
    const [newTask, setNewTask] = useState(null)
    const [asignedToMe, setAsignedToMe] = useState(false)
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(null)
    const [hasAlertDetail, setHasAlertDetail] = useState(null);

    useEffect(() => {
        handleAddAlert()
        getCategoriesPromise().then((response) => {
          setCategories(response)
        })
      }, [])

    const handleAddAlert = () => {
        const newAlert = {}
        setAlerts(currentAlerts => [...currentAlerts, newAlert])
    }

    const handleRemoveAlert = (index) => {
        if (index > 0) {
            const list = JSON.parse(JSON.stringify(alerts))

            list.splice(index, 1)

            setAlerts(list)
        }
    }

    const handleTaskSelect = async (task, index) => {
    if (task !== NEW_ITEM) {
        await setAlerts(oldAlerts => {
        const obj = JSON.parse(JSON.stringify(oldAlerts))
        obj[index].tarea = task
        return obj
        })
    }else {
        setIndexTask(index)
        setShowNewTask(true)
    }
    handleAddChange()
    }

    const handleAddChange = () => {
        setChanges(oldChanges => {
            let number = oldChanges + 1
            return number
        })
    }

    const handleTimeInDaysSelect = async (days, index) => {
        await setAlerts(oldAlerts => {
            const obj = JSON.parse(JSON.stringify(oldAlerts))
            obj[index].dias = days
            return obj
        })

        handleAddChange()
    }

    const onSelectDest = async (data) => {
        await setDestinatario(data)
        handleAddChange()
    };

    const handleCommentsChange = async (comments, index) => {
        await setAlerts(oldAlerts => {
            const obj = JSON.parse(JSON.stringify(oldAlerts))
            obj[index].comentarios = comments
            return obj
        })

        handleAddChange()
    }

    const changeCategoryHandler = (value) => {
        setCategory(value)
    }

    const changeAsignedToMeHandler = (e) => {
        setAsignedToMe(e.target.checked)
    }

    const handleRenderCurrentDateTime = () => {
        let today = new Date()
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()

        return date+' '+time
    }

    const handleSave = async () => {
        let errors = 0
        let timeInDays = null
        let days = document.getElementById('global-time-in-days')

        if(days != null && days.value !== '') {
            timeInDays = parseInt(days.value)
        }else if(alert.assign !== null && alert.assign.days !== null) {
            timeInDays = alert.assign.days
        }

        if (timeInDays !== null) {
            for (let i = 0; i < alerts.length; i++) {
                const days = parseInt(alerts[i].dias)

                if (days > timeInDays) {
                    errors++
                    notification['error']({
                        message: "Error en número de días",
                        description: "El número de días no debe exceder a " + timeInDays
                    })
                }
            }
        }

        for (let i = 0; i < alerts.length; i++) {
            if (!("tarea" in alerts[i])) {
                errors++

                notification['error']({
                message: "Campo Requerido",
                description: "El campo 'Tarea' es obligatorio"
                })

                break
            }
        }

        if(!asignedToMe) {
            for (let i = 0; i < alerts.length; i++) {
                if (destinatario === null) {
                errors++

                notification['error']({
                    message: "Campo Requerido",
                    description: "El campo 'Responsable' es obligatorio"
                })

                break
                }
            }
        }

        if (errors === 0) {
            const tasksCollection = JSON.parse(JSON.stringify(alerts))

            tasksCollection.map((task, index) => {
                tasksCollection[index].responsable = { id: (asignedToMe ? null : destinatario) }
                tasksCollection[index].informe1 = { id: (asignedToMe ? null : cc) }
                tasksCollection[index].informe2 = { id: (asignedToMe ? null : cc2) }
            })
            const res = await createTasksPromise(alert.id, tasksCollection)

            setAlerts([])
            handleAddAlert()
            setChanges(0)
            setAsignedToMe(false)
            setCategory(null)

            notification['success']({
                message: "Operación exitosa",
                description: "Las tareas han sido guardadas exitosamente"
            })

            refreshHandler()
        }
    }

    const DestinationComplete = (placeholder, onSelectCallback) => {
        const [result, setResult] = useState([]);
        //const [text, setText] = useState(null)
        const handleSearch = async(value) => {
            let res = [];
            if(value !== null && value !== '') {
                const records = await getClientsMinPromise(0, 10, [category], value, null, null)
                res = records.data.records
            }
            setResult(res);
            //setText(value)
        }
        const onSelect = (data, obj) => {
            onSelectCallback(data)
            //setText(obj.props.children)
        };
        return (
            asignedToMe ?
            <Input disabled={true} size="small" placeholder={placeholder}/>
            :

            <AutoComplete
                style={{width: '100%'}}
                onSearch={handleSearch}
                placeholder={placeholder}
                onSelect={onSelect}
                size="small"
                disabled={category===null}
                loading={result}
            >
                {result.map(record => (
                    <Option value={record.id}>
                        {record.nombre}
                    </Option>
                ))}
            </AutoComplete>
        );
    };

    return (
        <div className='tasks-creator-section'>
            <div className="title-section">
                <Row>
                    <Col span={19}>
                        <h3>Solicitud de Tareas</h3>
                    </Col>
                    <Col span={5}>
                        <div style={{textAlign: 'right'}}>
                            <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                                {seeMore ?
                                    <>Ver menos <Icon type="minus"/></>
                                    :
                                    <>Ver más <Icon type="plus"/></>
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
            <AnimateHeight
                duration={ 600 }
                height={ seeMore }
                animateOpacity={true}
            >
                <div className='tasks-creator-body'>
                    <Row style={{height: 0}}>
                        { changes > 0 && alert!==null && alert.status !== "CLOSED" && <Button type="primary" size="small" icon="save" className="save-button" style={{float: 'right'}} onClick={ handleSave }>Guardar cambios</Button> }
                    </Row>
                    <div className='tasks-creator-content-wrapper'>
                        { alerts.map((alert, index) =>
                                <li key={index} list-style="none">
                                    <div className="tasks-creator-task-wrapper">
                                        <div className='task-wrapper-header'>
                                            <Row>
                                                <Col span={17} offset={1} style={{ padding: '  0 5px'}}>
                                                    { t('messages.aml.task') } #{ index + 1 }
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{float: 'right'}}>
                                                        Fecha de creación: {moment(handleRenderCurrentDateTime()).format('DD/MM/YYYY')}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{float: 'right'}}>
                                                        { index > 0 && <Button icon="delete" size="small" onClick={ () => handleRemoveAlert(index) }></Button> }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='task-wrapper-body'>
                                            <Row gutter={[0, 20]}>
                                                <Col span={12}>
                                                    Seleccione un título a la tarea
                                                </Col>
                                                <Col span={12}>
                                                    <Select style={{ width: '100%' }} size="small" value={alert.tarea} onChange={ (value) => handleTaskSelect(value, index) }>
                                                        {
                                                            tasks.map((task) => <Select.Option value={task}>{task}</Select.Option>)
                                                        }
                                                    </Select>
                                                </Col>
                                                <Col span={12}>
                                                    Asigne un SLA
                                                </Col>
                                                <Col span={12}>
                                                    <Input size="small" onChange={ e => handleTimeInDaysSelect(e.target.value, index) } placeholder="Número de días"/>
                                                </Col>
                                                <Col span={24}>
                                                    <Input.TextArea placeholder={ t('messages.aml.comments') } onChange={ e => handleCommentsChange(e.target.value, index) }></Input.TextArea>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </li>
                            )
                        }
                        <div className='panel-form-tasks-creator-wrapper'>
                            <div className='panel-form-tasks'>
                                <Row gutter={[20, 20]}>
                                    <Col span={18}>
                                        Agregar una nueva tarea
                                    </Col>
                                    <Col span={6}>
                                        <Button icon="plus" type="ghost" onClick={handleAddAlert} size="small" style={{width:'100%'}}>
                                            { t('messages.aml.newTask') }
                                        </Button>
                                    </Col>
                                    <Col span={18}>
                                        Si la tarea va a ser resuelta por usted, marque la casilla.
                                    </Col>
                                    <Col span={6}>
                                        <div style={{textAlign: 'right'}}>
                                            <Checkbox checked={asignedToMe} style={{display: 'initial'}} onChange={changeAsignedToMeHandler} />
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        Si la tarea va a ser resuelta por un tercero, indique la categoría y el usuario al que va dirigido.
                                    </Col>
                                    <Col span={4}>
                                        <Select style={{ width: '100%' }} size="small" value={asignedToMe ? null : category} onChange={changeCategoryHandler} disabled={asignedToMe} allowClear={true}>
                                            { categories.map((item) =>
                                                <Select.Option value={item}>
                                                    { t('messages.aml.category.' + item)}
                                                </Select.Option>
                                            )}
                                        </Select>
                                    </Col>
                                    <Col span={8}>
                                        { DestinationComplete(t('messages.aml.inControl'), onSelectDest) }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimateHeight>
            { showNewTask &&
                <Modal
                    title="Tarea"
                    className="modal-new-tarea"
                    visible={ true }
                    width={ 600 }
                    onOk={() => {
                        let inputValue = newTask.trim()
                        setAlerts(oldCollection => {
                            const arr = JSON.parse(JSON.stringify(oldCollection))
                            arr[indexTask].tarea = inputValue
                            return arr
                        })
                        setIndexTask(-1)
                        setShowNewTask(false)
                    }}
                    onCancel={() => {
                        setIndexTask(-1)
                        setShowNewTask(false)
                        setAlerts(oldCollection => {
                            const arr = JSON.parse(JSON.stringify(oldCollection))
                            delete arr[indexTask].tarea
                            return arr
                        })
                    }}
                >
                    <Input onChange={(e) => setNewTask(e.target.value) }/>
                </Modal>
            }
        </div>
    );
};

export default TasksCreator;