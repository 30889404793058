import './SideBarMenu.scss'
import { Icon, Menu, Spin, Table } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import React, { useEffect, useState } from 'react'
import { getSearchesPromise } from '../../../AdminSearch/promises'
import { camelizerHelper } from '../../../../helpers'
import { withRouter } from 'react-router'
import moment from 'moment'

const SideBarMenu = ({currentUser, collapsed, handleQuery, changeStatusSidebar=true}) => {

  const [historyData, setHistoryData] = useState(null);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);

  useEffect(() => {
    console.log(collapsed)
    getHistoryData()
  }, []);

  const getHistoryData = () => {
    setIsHistoryLoading(true)
    const anio = new Date().getFullYear();
    getSearchesPromise(0, 20, {userId: currentUser.id}).then(result => {
      var fecha = null;
      let records = []
      result.records.forEach(rec => {
        const y = new Date(rec.fecha).getFullYear();
        const m = moment(rec.fecha);
        let f
        if(anio === y) {
          f = m.format("dddd DD [de] MMMM")
        }else {
          f = m.format("dddd DD [de] MMMM [de] YYYY")
        }
        if(fecha !== f) {
          records.push({date: f})
          fecha = f
        }
        records.push({rut: rec.rut, name: rec.nombre})
      });
      setHistoryData(records)
      setIsHistoryLoading(false)
    })
  }

  const columns = [
    {
      title: 'Rut',
      dataIndex: 'rut',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if(row.date) {
          obj.props.className = 'fecha'
          obj.props.colSpan = 2;
          obj.children = row.date
        }
        return obj
      }
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: (value, row, index) => {
        const obj = {
          children: camelizerHelper(value),
          props: {},
        };
        if(row.date) {
          obj.props.className = 'fechaName'
        }
        return obj
      }
    },
    
  ];

  const handleSearch = (text) => {
    handleQuery(text)
  }

  return (
    <div className={"sidebar-search-history collapsed-" + (changeStatusSidebar ? collapsed : 'null')} style={{display: collapsed===null ? 'none' : 'block', right: collapsed ? 0 : -300}}>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
      >
        <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="history" />
              <span>Consultas recientes</span>
            </span>
          }
        >
          {isHistoryLoading ?
            <Spin/>
            :
            <Table 
              dataSource={historyData} 
              columns={columns} 
              pagination={historyData?.length > 10}
              size="small"
              onRow={(record) => {
                if(!record.date) {
                  return {onClick: () => { 
                    handleSearch(record.rut ?? record.nombre)
                  }}
                }
              }}
            />
          }
        </SubMenu>
      </Menu>
    </div>
  )
}

export default withRouter(SideBarMenu);