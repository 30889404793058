import React, {useEffect, useState} from 'react';
import Plot from 'react-plotly.js';
import {getNumberMonitoredPersonPromise} from "./promises/getPeopleMonitoredQuantityPromise";
import {Spin} from "antd";
import {useTranslation} from "react-i18next";

export const PeopleMonitoredQuantity = ({chartColor}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();


    useEffect(() => {
        initFunc();
    }, []);

    const initFunc = () => {

        setLoading(true);
        getNumberMonitoredPersonPromise().then((response) => {

            let monitoredPerson = response.data;

            const categorias = [...new Set(monitoredPerson.map(item => item.categoria))];

            const datosPorCategoria = {};

            categorias.forEach(categoria => {
                datosPorCategoria[categoria] = monitoredPerson.filter(item => item.categoria === categoria);
            });

            const traces = categorias.map((categoria, index)=> {
                const cantidades = datosPorCategoria[categoria].map(item => item.totalRegistros);
                const anios = datosPorCategoria[categoria].map(item => item.anio);
                const color = chartColor[index % chartColor.length].color;
                const marker = {color}

                return {
                    x: anios,
                    y: cantidades.map(Number),
                    type: 'bar',
                    name: t(`messages.aml.category.${categoria}`),
                    marker: marker,
                };
            });

            setData(traces);
        }).then(() => setLoading(false))
    }

    return (
        <Spin spinning={loading}>

            <div className='inside-compliance-amount-people-stack'>
                <Plot
                    data={data}
                    layout={{
                        barmode: 'stack',
                        paper_bgcolor: 'rgba(0,0,0,0)',
                        plot_bgcolor: 'rgba(0,0,0,0)',
                        autoscale: false,
                        showlegend: true,
                        height: 280,
                        margin: {
                            l: 70,
                            r: 0,
                            b: 25,
                            t: 21,
                        },

                        xaxis: {
                            tickmode: 'linear',
                            tick0: 2010,
                            dtick: 1
                        },
                        yaxis: {
                            titlefont: {
                                family: 'Arial, sans-serif',
                                size: 12,
                                color: 'rgb(103 103 103)'
                            },
                            showgrid: true,
                            gridcolor: 'rgb(187 187 187)',
                        }
                    }}
                    config={{
                        displayModeBar: false,
                    }}
                    useResizeHandler={true}
                    style={{width: '100'}}
                />
            </div>
        </Spin>
    );
};