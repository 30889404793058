import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, DatePicker, Progress, Row, Select} from "antd";
import './ManagedAlertCard.scss'

import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import getResumeAlertsByCategory from "../../../../promises/getResumeAlertsByCategory";
import moment from "moment";


const ManagedAlertCard = ({handleTabAlertas}) => {

    const {t} = useTranslation()

    const [countAlerts, setCountAlerts] = useState([])
    const [activeAlertType, setActiveAlertType] = useState(null);
    const [activeDateRange, setActiveDateRange] = useState(null);


    let filterResume = {}

    const alertType = ["PERSONA", "DEMOGRAFICA", "TRANSACCIONAL"]

    useEffect(() => {
        handleSearchResume(filterResume);
    }, [activeAlertType, activeDateRange]);

    const handleSearchResume = (filterResume) => {

        filterResume.dateRange = activeDateRange
        filterResume.alertType = activeAlertType

        getResumeAlertsByCategory(filterResume).then(response => {
            setCountAlerts(response.data)
        })
    }

    const handleSetActiveAlertType = (alertType) => {
        setActiveAlertType(alertType);
    };

    const handleSetActiveDateRange = (dateRange) => {
        setActiveDateRange(dateRange)
    }

    const getLegend = () => {
        return activeDateRange ?
            (t('messages.aml.monitor.managed.alerts.by.date')) : (t('messages.aml.monitor.managed.alerts'))
    }

    const getFilters = (category, status) => {
        let filters = {categories: [category]}
        if(activeAlertType) filters.types = [activeAlertType]
        if(activeDateRange && activeDateRange.length === 2) filters.fechaAlerta = activeDateRange
        if(status) filters.states = [status]

        return filters
    }

    const ContentAlertManager = () => {

        return (
            <>
                <Row>
                    {countAlerts.length !== 0 ?
                        <div className={"resume-alert-info"}>
                            {countAlerts.map(cont =>
                                <Col span={6}>
                                    {cont.category &&

                                        (<div style={{textAlign: 'center'}}>
                                            <div className={"alert-progress"}>
                                                <Progress
                                                    type="circle"
                                                    percent={(cont?.alertsClosed * 100 / cont?.totalCount).toFixed(0)}
                                                    format={percent => `${percent}%`}
                                                />
                                            </div>

                                            <div className={"managed-alert-resume"}>
                                                <a onClick={() => handleTabAlertas(getFilters(cont.category))}>
                                                    <div className={"alert-title"}>{t('messages.aml.category.' +cont.category)} </div>
                                                </a>
                                                <a onClick={() => handleTabAlertas(getFilters(cont.category, 'OPEN'))}>
                                                    <div className={"alert-detail"}> {t('messages.aml.monitor.status.OPEN')} : {cont.alertsOpen}</div>
                                                </a>
                                                <a onClick={() => handleTabAlertas(getFilters(cont.category, 'CLOSED'))}>
                                                    <div className={"alert-detail"}> {t('messages.aml.monitor.status.CLOSED')} : {cont.alertsClosed}</div>
                                                </a>
                                            </div>
                                        </div>)
                                    }
                                </Col>
                            )}
                        </div>
                        :
                        <div className={"label-no-info"}>
                            <Row>
                                <Col offset={7}>
                                    {t('messages.aml.monitor.no.info')}
                                </Col>
                            </Row>
                        </div>
                    }


                </Row>
                <br/>
                <Row>
                    <Col span={7}>
                        <DatePicker.RangePicker
                            placeholder={[t('messages.aml.from'), t('messages.aml.to')]}
                            style={{width: "100%"}}
                            value={
                                activeDateRange
                                ? [
                                    moment(activeDateRange[0]),
                                    moment(activeDateRange[1]),
                                    ]
                                : null
                            }
                            onChange={momentData => {

                                handleSetActiveDateRange(
                                    momentData !== null && momentData.length > 0 ?
                                        [
                                            moment(momentData[0]).valueOf(),
                                            moment(momentData[1]).valueOf(),
                                        ]
                                        : null)
                            }}
                        />
                    </Col>
                    <Col span={7} offset={1}>
                        <Select
                            style={{width: "100%"}}
                            value={activeAlertType}
                            placeholder={"Tipo de alerta"}
                            onChange={value => handleSetActiveAlertType(value)}
                        >
                            <Select.Option value={null}>Tipo de alerta</Select.Option>
                            {alertType.map((type, index) => (
                                <Select.Option key={index} value={type}>
                                    {t('messages.aml.compliance.' + type)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </>


        )
    }

    return (

        <>
            <div className="managed-alert-card">
                <LargeCardDashboard
                    title={t("Alertas Gestionadas")}
                    legend={getLegend()}
                    content={ContentAlertManager()}
                />
            </div>

        </>

    )
}

export default ManagedAlertCard;