import { InsideSectionTitle } from "./InsideSectionTitle/InsideSectionTitle";
import { AlertAreaChart } from "./AlertAreaChart/AlertAreaChart";
import { AlertStackedChart } from "./AlertStackedChart/AlertStackedChart";

import {MonitoringSource} from "./MonitoringSourceSumary/MonitoringSource";
import {MonitoringRules} from "./MonitoringRulesSumary/MonitoringRules";
import { AlertManagement } from "./AlertManagement/AlertManagement";
import { RelationsCdi } from "./RelationsCdi/RelationsCdi";
import { PendingStatements } from "./PendingStatements/PendingStatements";
import { PeopleMonitoredQuantity } from './AmountOfPeople/PeopleMonitoredQuantity';
import {CdiDeclarationChart} from './CdiDeclarationChart/CdiDeclarationChart'

export const AlertAreaChartComponent = AlertAreaChart;
export const AlertStackedChartComponent = AlertStackedChart;
export const InsideSectionTitleComponent = InsideSectionTitle;
export const MonitoringSourceSumaryComponent = MonitoringSource;
export const MonitoringRulesSumaryComponent = MonitoringRules;
export const AlertManagementComponent = AlertManagement;
export const CdiDeclarationChartComponent = CdiDeclarationChart;
export const PeopleMonitoredQuantityComponent = PeopleMonitoredQuantity;
export const PendingStatementsComponent = PendingStatements;
export const RelationsCdiComponent = RelationsCdi;
