import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export const getTransactionalActivityService = ({dateValue}) => {
	return apiRequestorHelper({
		url: apiConfig.url + '/transaction/inside/getTransactionalActivity',
		method: 'post',
    body: {
			dateRange: dateValue
		}
	})
}