function formTypeValidator (currentUser, categories, addAllOpt) {
  //Recibe currentUser (Global Context var)
  //Recibe categories[]

  const FORM_VALIDATOR = {
    'CDI': currentUser.modules.includes('CDI-FORM-GD') && currentUser.cliente.modules.includes('CDI-FORM-CDI') && (categories === null || categories === undefined || categories?.includes('COLABORADOR') || categories?.includes('PROVEEDOR')),
    'REL': currentUser.modules.includes('CDI-FORM-GREL') && currentUser.cliente.modules.includes('CDI-FORM-REL') && (categories === null || categories === undefined || categories?.includes('COLABORADOR')),
    'DIR': currentUser.modules.includes('CDI-FORM-GDIR') && currentUser.cliente.modules.includes('CDI-FORM-DIR') && (categories === null || categories === undefined || categories?.includes('DIRECTOR')),
    'PATR': currentUser.modules.includes('CDI-FORM-GPATR') && currentUser.cliente.modules.includes('CDI-FORM-PATR') && (categories === null || categories === undefined || categories?.includes('CLIENTE')),
    'GIFT': currentUser.modules.includes('CDI-FORM-GF-G') && currentUser.cliente.modules.includes('CDI-FORM-G') && (categories === null || categories === undefined || categories?.includes('COLABORADOR')),
    'TRAVEL': currentUser.modules.includes('CDI-FORM-GF-T') && currentUser.cliente.modules.includes('CDI-FORM-T') && (categories === null || categories === undefined || categories?.includes('COLABORADOR')),
    'FP': currentUser.modules.includes('CDI-FORM-GF-F') && currentUser.cliente.modules.includes('CDI-FORM-F') && (categories === null || categories === undefined || categories?.includes('COLABORADOR')),
    'SOC': currentUser.modules.includes('CDI-FORM-GF-S') && currentUser.cliente.modules.includes('CDI-FORM-S') && (categories === null || categories === undefined || categories?.includes('COLABORADOR')),
    'PEP': currentUser.modules.includes('FORM-PEP') && currentUser.cliente.modules.includes('FORM-PEP')
  }

    let validatedTypes = []

    for (let type in FORM_VALIDATOR){
      if(FORM_VALIDATOR[type]){
        validatedTypes.push(type)
      }
    }
    if(addAllOpt){
      validatedTypes.unshift('ALL')
    }

    return validatedTypes;
  }
export default formTypeValidator;