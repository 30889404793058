import {FileManagerService} from "../services";

export default () => {
        return new Promise((resolve) => {
            FileManagerService.getFileList()
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => console.log(err))
        })

}