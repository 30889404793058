import BasicInfoAlert from './BasicInfoAlert/BasicInfoAlert'
import AlertRules from './AlertRules/AlertRules'
import AlertAssignment from './AlertAssignment/AlertAssignment';
import AlertClose from './AlertClose/AlertClose';
import TasksCreator from './TasksCreator/TasksCreator';
import TaskRevision from './TaskRevision/TaskRevision';
import SuspiciousOperation from './SuspiciousOperation/SuspiciousOperation';
import HistoryTask from './HistoryTask/HistoryTask';

export const BasicInfoAlertSection = BasicInfoAlert;
export const AlertRulesSection = AlertRules;
export const AlertAssignmentSection = AlertAssignment;
export const AlertCloseSection = AlertClose;
export const TasksCreatorSection = TasksCreator;
export const TaskRevisionSection = TaskRevision;
export const SuspiciousOperationSection = SuspiciousOperation;
export const HistoryTaskSection = HistoryTask;