import './Sidebar.scss'
import React, { useState } from 'react'
import { Row, Col, Icon, Collapse, Switch } from 'antd'
import AnimateHeight from 'react-animate-height'
import { useTranslation } from 'react-i18next';

const Sidebar = ({ defaultFilters, activeTab, onTabChange, categories, showTabForms, handleChangeShowTabForms }) => {
	const {t} = useTranslation()
	const  [filters, setFilters] = useState(defaultFilters)

	const {Panel} = Collapse;

	const FILTER_CATEGORIES = 'categories'

	const onFilterManager = (checked, key, value) => {
        if (checked) {
            if (filters[key] != null) {

                setFilters(data => {

                    let prevData = data[key]

                    if (prevData.find(val => val === value) === undefined) {
                        prevData.push(value)
                    }

                    data[key] = prevData
                    return data
                })
            }else {
                setFilters(data => {
                    data[key] = [value]
                    return data
                })
            }
        } else if (filters[key] != null) {
            setFilters(data => {
                let prevData = data[key]

                if (prevData.find(val => val === value) !== undefined) {
                    prevData = prevData.filter(element => element !== value)
                }

                (prevData.length === 0) ? delete data[key] : data[key] = prevData
                if (data.length === 0) {
                    data = {}
                }

                return data
            })
        }
        onTabChange('tab-forms', filters)
    }

    const localTabChange = (tab) => {
        if(tab === 'tab-forms') {
            if(!showTabForms) {
                if(activeTab !== 'tab-forms') {
                    onTabChange('tab-forms', filters)
                }
                handleChangeShowTabForms(true)
            }else {
                if(activeTab === 'tab-forms') {
                    handleChangeShowTabForms(false)
                }else {
                    onTabChange('tab-forms', filters)
                }
            }
        }else {
            onTabChange(tab)
        }
    }

	return (
		<Col className="sidebar" span={ 5 }>
			<div className="sidebar-inner">
				<div className="menu-block">
					<ul>
						<li className={ activeTab === 'tab-dashboard' ? 'active' : '' } onClick={ () => localTabChange('tab-dashboard') }>

							<div className="menu-item-inner">
								<Icon type="dashboard" className="option"/>Dashboard
								<Icon type="check" />
							</div>
						</li>
						<li className={ activeTab === 'tab-solicitudes' ? 'active' : '' } onClick={ () => localTabChange('tab-solicitudes') }>
							<div className="menu-item-inner">
								<Icon type="mail" className="option"/>Solicitudes
								<Icon type="check" />
							</div>
						</li>
						{ /* Formularios */ }
						<li className={ activeTab === 'tab-forms' ? 'active' : '' } onClick={ () => localTabChange('tab-forms') }>
							<div className="menu-item-inner">
								<Icon type="form" className="option"/>Gestión de Formularios
								<Icon type="check" />
							</div>
						</li>
						<AnimateHeight
                            duration={300}
                            height={showTabForms ? 'auto' : 0}
                        >
                            <Collapse
                                bordered={false}
                                defaultActiveKey={["1"]}
                                accordion={ false}
                                expandIconPosition="right"
                            >
								<Panel header={t('messages.aml.category')} key="1">
                                    {categories.map((categoria) =>
                                        <Row className="filter-item">
                                            {t('messages.aml.category.' + categoria)}
                                            <Switch size="small"
                                                    checked={filters[FILTER_CATEGORIES]?.includes(categoria)}
                                                    onChange={(checked) => {
                                                        onFilterManager(checked, FILTER_CATEGORIES, categoria);
                                                    }}/>
                                        </Row>
                                    )}
                                </Panel>
							</Collapse>
                        </AnimateHeight>
						<li className={ activeTab === 'tab-reporting' ? 'active' : '' } onClick={ () => localTabChange('tab-reporting') }>
							<div className="menu-item-inner">
								<Icon type="file-excel" className="option"/>Reportes
								<Icon type="check" />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</Col>
	)
}

export default Sidebar
