import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export default {
  getCdi: (registerId) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/cdi/getCDIRegistroDetail/'+registerId,
      method: 'post',
    })
  }
}