import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const onBoarding = {
  newSendRequest: (bdParams) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/onb/newSendRequest',
      method: 'post',
      body: bdParams
    })
  },
  declarations: (fromDate, toDate) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/declarations/D',
			method: 'post',
      body: {type:"KYC", fromDate, toDate, optDates: 'RANGE'}
		})
	}
}

export default onBoarding