import './HistoryTask.scss'
import React, { useEffect, useState } from 'react';
import { Button, Col, Icon, Row, Table } from 'antd';
import AnimateHeight from 'react-animate-height';
import { ModalComments, ModalFiles } from './components';
import { camelizerHelper } from '../../../../helpers';
import moment from 'moment';
import { getHistorialPromise } from '../../promises';
import { useTranslation } from 'react-i18next';

const HistoryTask = ({ alert, currentUserId, origin }) => {
    const [seeMore, setSeeMore] = useState(0);
    const { t } = useTranslation();
    const [historyItems, setHistoryItems] = useState([]);
    const [modalComment, setModalComment] = useState("");
    const [modalFiles, setModalFiles] = useState([]);

    const [isModalCommentsVisble, setIsModalCommentsVisible] = useState(false);
    const [isModalFilesVisble, setIsModalFilesVisible] = useState(false);

    useEffect(() => {
        if (alert !== null) {
            getHistoryItems();
        }
    }, [alert]);

    async function getHistoryItems() {
        getHistorialPromise(alert.id, currentUserId).then((response) => {
        setHistoryItems(response);
        });
    }

    const handleOnModalComments = (record) => {
        setModalComment(record.comments);
        setIsModalCommentsVisible(true);
    };

    const handleOnModalFiles = (record) => {
        setModalFiles(record.files);
        setIsModalFilesVisible(true);
    };

    const handleModalCommentsCancel = () => {
        setIsModalCommentsVisible(false);
    };

    const handleModalFilesCancel = () => {
        setIsModalFilesVisible(false);
    };

    const historyColumns = [
        {
            title: t("messages.aml.user"),
            dataIndex: "user",
            width: "190px",
            render: (text, record) => (
                <div>{camelizerHelper(record.user)}</div>
            ),
        },
        {
            title: "Asignado a",
            dataIndex: "user",
            width: "190px",
            render: (text, record) => (
                record.asignedTo ?
                <div>{camelizerHelper(record.asignedTo)}</div>
                :
                <div>N/A</div>
            ),
        },
        {
            title: t("Evento"),
            dataIndex: "action",
            width: "150px",
            render: (text) =>
                text !== null ? t("messages.aml.request." + text) : "",
        },
        {
            title: t("Descripción"),
            dataIndex: "request",
            render: (text, record) => <div>{text ? text : "-"}</div>,
        },
        // {
        //     title: t("messages.aml.status"),
        //     width: "100px",
        //     dataIndex: "status",
        //     render: (text) => (text ? t("messages.aml.request." + text) : "-"),
        // },
        {
            title: () => (
                <>
                <div>Fecha</div>
                <div>Evento</div>
                </>
            ),
            width: "200px",
            dataIndex: "creationDate",
            render: (text) => <div>{moment(text).utc(false).format("DD/MM/YYYY HH:mm")}</div>,
        },
        // {
        //     title: () => (
        //         <>
        //         <div>Duración</div>
        //         <div>Evento</div>
        //         </>
        // ),
        //     width: "80px",
        //     dataIndex: "duration",
        //     render: (text, record) => <div>{text !== null ? text : "-"}</div>,
        // },
        // {
        //     title: () => (
        //         <>
        //         <div>Tiempo</div>
        //         <div>Asignado</div>
        //         </>
        // ),
        //     width: "80px",
        //     dataIndex: "days",
        //     render: (text) => <div> {text ? text + " dias" : "-"}</div>,
        // },
        {
            title: t("messages.aml.view"),
            width: "70px",
            render: (text, record) => {
                return (
                <>
                    {record.files !== null && (
                    <div
                        className="comments-files"
                        onClick={(e) => handleOnModalFiles(record)}
                    >
                        <Icon type="folder-open" />
                    </div>
                    )}
                </>
                );
            },
        },
    ];
    return (
        <main className='task-history-main-section'>
            <header>
                <section className="title-section">
                    <Row>
                        <Col span={19}>
                            <h3>Historial</h3>
                        </Col>
                        <Col span={5}>
                            <div style={{textAlign: 'right'}}>
                                <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                                    {seeMore ?
                                        <>Ver menos <Icon type="minus"/></>
                                        :
                                        <>Ver más <Icon type="plus"/></>
                                    }
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                </section>
            </header>
            <AnimateHeight
                duration={ 600 }
                height={ seeMore }
                animateOpacity={true}
            >
                <section className="task-history-wrapper">
                    <div className="task-history-table-wrapper">
                        <Table
                            size="small"
                            dataSource={historyItems}
                            columns={historyColumns}
                            pagination={false}
                            scroll={{ y: 500 }}
                            rowClassName={record => {
                                    if (!(record.comments)){
                                        return "no-show-icon-class"
                                    }
                                }
                            }
                            expandedRowRender={(record) => <p style={{ margin: 0 }}>Comentario: {record?.comments ?? "N/A"}</p>}
                        />
                    </div>

                    {isModalCommentsVisble && (
                        <ModalComments
                            onCancel={handleModalCommentsCancel}
                            comment={modalComment}
                        />
                    )}
                    {isModalFilesVisble && (
                        <ModalFiles
                            onCancel={handleModalFilesCancel}
                            files={modalFiles}
                            alertId = {alert.id}
                            origin = {origin}
                            userId = {currentUserId}
                        />
                    )}
                </section>
            </AnimateHeight>
        </main>
    );
};

export default HistoryTask;