import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const items = {
	sendReminders: (type, filters, items) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/sendReminders/' + type,
			method: 'post',
			body: { ...filters, items }
		})
	},
	deleteDeclarations: (type, filters, items) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/deleteDeclarations/' + type,
			method: 'post',
			body: { ...filters, items }
		})
	},
	updateRisk: (formData) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/changeRiskRecord',
			method: 'post',
			headers: {
			'Content-Type': 'multipart/form-data'
			},
			body: formData
		})
	},
	saveComments: (formData) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/saveRecordHistComments',
			method: 'post',
			body: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	},
	saveRegState: (id, statusRec, statusRecComments) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/changeStatusRecClCliente',
			method: 'post',
		body: { id, statusRec, statusRecComments}
		})
	},
	getUbosCtrlByRut: (rut, country) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/ubo/getUbosControllersByRut',
			method: 'post',
			body: { rut, country }
		})
	},
	savePeriodicity: (clClientId, tipo, periodicity) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/savePeriodicity',
			method: 'post',
			body: { clClientId, tipo, periodicity }
		})
	}
}
export default items;