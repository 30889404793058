import React, { useState, useEffect } from 'react';
import './ParticipacionEmpresaSection.scss';
import { Button, Col, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, relationshipOptions, tipoDocOptions } from '../../optList';

export default function ParticipacionEmpresaSection({ form, sectionName, empresasGrupo, apiForm, clientName, format, registerData, docValidator }) {

  const [showTipoPropiedad, setShowTipoPropiedad] = useState(false);
  const [showEmpresaIndirectaFields, setShowEmpresaIndirectaFields] = useState(false);

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    toDescriptionsPdf,
    handleDeleteRow,
    handleSaveOnChangeField
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []

  useEffect(() => {
    setShowTipoPropiedad(rowData.cargo === "Accionista");
    setShowEmpresaIndirectaFields(rowData.cargo === "Accionista" && rowData.tipoPropiedad === "Indirecta");
  }, [rowData.cargo, rowData.tipoPropiedad]);

  useEffect(() => {
    if (rowData.parentesco === 'Mi Persona') {
      rowData.nombre = registerData.nombre
      rowData.rut = registerData.rut
      rowData.group = registerData.grupo
      rowData.tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
      form.setFieldsValue({
        pempName: registerData.nombre,
        pempRut: registerData.rut,
        pempGroup: registerData.grupo,
        pempTipoDoc: registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
      });
  }
  }, [rowData.parentesco]);

  const fieldsParams = [
    {
      type: 'divider',
      dividerText: 'Datos de la persona que posee relación con ' + clientName
    },
    {  
      type: 'select',
      jsonKeyName: "parentesco",
      label: "Parentesco",
      id: "pempParentesco",
      options: relationshipOptions
    },
    {
      type: 'input', 
      jsonKeyName: "nombre",
      label: "Nombre",
      id: "pempName",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDoc",
      id: "pempTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rut",
      id: "pempRut",
      customValidator: docValidator(form.getFieldValue('pempTipoDoc'))
    },
    {
      type: 'select',
      label: "Empresa del Grupo",
      jsonKeyName: "group",
      id: "pempGroup",
      options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })) ?? [{val: "Sin Empresa", text: "Sin Empresa"}],
    },
    {
      type: 'divider',
      dividerText: `Cargo ocupado por: ${rowData["nombre"] || ''}`
    },
    {
      type: 'select',
      label: "Cargo",
      jsonKeyName: "cargo",
      id: "pempcargo",
      options: cargosOptions,
    },
    showTipoPropiedad && {
      type: 'select',
      label: "Tipo de Propiedad",
      jsonKeyName: "tipoPropiedad",
      id: "pempTipoPropiedad",
      options: [{val: "Directa", text: "Directa"}, {val: "Indirecta", text: "Indirecta"}],
    },
    showTipoPropiedad && {
      type: 'input',
      label: "Porcentaje de Participación",
      jsonKeyName: "porcentajeParticipacion",
      id: "pempporcentajeParticipacion",
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Nombre de Empresa Propiedad Indirecta",
      jsonKeyName: "nombreEmpresaIndirecta",
      id: "pempnombreEmpresaIndirecta",
    },
    showEmpresaIndirectaFields && {
      type: 'select',
      label: "Tipo de documento Empresa Propiedad Indirecta",
      jsonKeyName: "tipoDocEmpresaIndirecta",
      id: "pemptipoDocEmpresaIndirecta", 
      options: tipoDocOptions
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Documento Empresa Propiedad Indirecta",
      jsonKeyName: "rutEmpresaIndirecta",
      id: "pemprutEmpresaIndirecta", 
      options: tipoDocOptions
    }
  ].filter(Boolean);

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }

  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>PARTICIPACIÓN EN LA PROPIEDAD DE LA EMPRESA </Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que, hasta donde tengo conocimiento, participo, o lo hace mi cónyuge y/o parientes 
            hasta el tercer grado de consanguinidad o afinidad inclusive, en sociedades, en forma 
            directa o a través de otras personas naturales o jurídicas, con un 10% o más de su capital, 
            o bien mi cónyuge y/o parientes hasta el tercer grado de consanguinidad o afinidad inclusive 
            ocupa el cargo de director, gerente, administrador, ejecutivo principal u otro equivalente 
            en {clientName}, o alguna de sus empresas relacionadas.
          </p>
        </Col>
        <Col span={2} push={1}>
        <Form.Item>
          {form.getFieldDecorator("hasPemp", {
            rules: [
              {required: true, message: t("messages.aml.dontForgetSelect")},
              { 
                validator: (rule, value, callback) => {
                  if (value === true && records.length === 0) {
                    callback(true);
                  } else {
                    callback(); // Pasar la validación
                  }
                }
              }
            ],
            initialValue: apiForm?.json?.pemp?.hasPemp
          })(
            <Radio.Group
              onChange={({ target }) =>
                handleSaveOnChangeField(form, target.value, sectionName, "hasPemp")
              }
            >
              <Radio className="radio-switch" value={true}>
                Sí
              </Radio>
              <Radio className="radio-switch" value={false}>
                No
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>
        </Col>
      </Row>
      {obj.json.pemp?.hasPemp && (
        <>
          { format === 'html' &&
          <>
            <Row gutter={[20, 10]}>
              {fieldsParams.map(item => (
                <>
                  {item.type === 'divider' &&
                    <Divider orientation={item.orientation ?? 'left'}>
                      {item?.dividerText ?? ''}
                    </Divider>
                  }
                  {(item.type === 'input' || item.type === 'select' || item.type === 'radio') &&
                    <Col span={8} key={item.id}>
                      <Form.Item label={item.label}>
                        {form.getFieldDecorator(item.id, {
                          rules: [
                            { required: true, message: t("messages.aml.requiredData") },
                            { validator: item.customValidator }
                          ],
                        })(
                          <div>
                            {item.type === 'input' && (
                              <Input
                                onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                                value={rowData[item.jsonKeyName] ?? ""}
                              />
                            )}
                            
                            {item.type === 'select' && (
                              <Select
                                allowClear
                                onChange={(value) => {
                                  handleOnChangeTableElement(value, item.jsonKeyName);
                                  form.setFieldsValue({ [item.id]: value });
                                }}
                                style={{ width: '100%' }}
                                value={rowData[item.jsonKeyName] ?? ""}
                              >
                                {item.options.map(option => (
                                  <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                                ))}
                              </Select>
                            )}
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  }
                </>
              ))}
            </Row>
            <Row className="button-row">
              {records.length === 0 &&
                <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                  {t("messages.aml.registersRequired")}
                </Col>
              }
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" onClick={() => {
                  handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
                }} icon="plus"> Añadir </Button>
              </Col>
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
              </Col>
            </Row>
          </>
          }
          <Row>
            {records.length > 0 && format === 'html' ?
              <Table columns={getColumns()} dataSource={records || []} size="middle" pagination={false}/>
              :
              toDescriptionsPdf(sectionName, fieldsParams)
            }
          </Row>
          
        </>
      )}
    </div>
  );
}
