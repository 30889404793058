import React from 'react';
import {Card, Col, Row, Select} from 'antd';
import './LargeCardDashobard.scss'
import {useTranslation} from "react-i18next";


const LargeCardDashboard = ({title, legend, content, categories, activeCategory, handleSetActiveCategory}) => {

    const {t} = useTranslation()

    return (

        <div className="large-card">
            <Card>

                <Row className={"card-header"}>
                    <Col span={18}>
                        <p className={"card-title"}> {title}</p>
                    </Col>

                    {categories ?
                        (
                        <Col span={6}>
                            <div className="col-inner">
                                <div className="value">

                                    <Select
                                        style={{width: "100%"}}
                                        value={activeCategory}
                                        placeholder={t('messages.aml.category')}
                                        onChange={(value) =>
                                            handleSetActiveCategory(value)
                                        }
                                    >
                                        <Select.Option key={Math.random()} value={null}>
                                            Todas las Categorías
                                        </Select.Option>
                                        {categories.map((category) => (
                                            <Select.Option key={Math.random()} value={category} >
                                                {t('messages.aml.category.' + category)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </Col>
                        ) : <></>
                    }
                </Row>

                <p> {legend}</p>
                <Row>
                    <div>{content}</div>
                </Row>

            </Card>
        </div>

    )
}


export default LargeCardDashboard;




  