import './QueryRisk.scss'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { ReportService, SessionStorageService } from '../../services'
import { Page, PageBottomBar, PageContent, PageFooter, PageHeader, PageToolbar, PageTopBar, PdfModal } from '../../layouts/Private/components'
import { Button, Col, Form, notification, Pagination, Row, Spin, Modal } from 'antd'
import { ResultsPerPageSelector, SearchBox2, PersonCard, AdvancedSearchToggleButton, AdvancedSearchFilters } from './components'
import { animatePageContentPromise, getQueryFirstPagePromise } from './promises'
import sadSmileyImage from './no-results-bg.png'
import rutFormatter from '../../helpers/formatRut'
import { getSearchesPromise } from './../AdminSearch/promises'
import moment from 'moment'

class QueryRisk extends Component {
  state = {
    breadcrumbs: this.getBreadcrumbs(),
    isAdvancedSearchFiltersVisible: false,
    isLoadingFirst: false,
    isLoadingOthers: false,
    query: '',
    queryId: '',
    queryResults: [],
    queryFiltersPersonType: '',
    queryFiltersSearchScope: 'near',
    queryFiltersRegion: '',
    historyResults: [],
    historyResultsCurrentPage: 1,
    historyResultsFromNum: 0,
    historyResultsPerPage: 10,
    historyResultsTotalNum: -1,
    showModalPdf: false,
    pdfSource: '',
    loadingPdf: false,
    rutSearch: ''
  }

  async componentDidMount() {
    this.handlePaginationChange(1)
  }

  getBreadcrumbs() {
    const { t } = this.props

    const breadcrumbs = [
      { title: t('messages.aml.query'), icon: 'file-search', link: '/consulta2' }
    ]

    return breadcrumbs
  }

  async handleChange(e) {
    const { historyResultsTotalNum } = this.state
    const newValue = e.target.value

    await this.setState({ query: newValue })
    if (newValue === '') {
      await this.setState({
        query: '',
        queryId: '',
        queryResults: [],
        queryResultsCurrentPage: 1,
        queryResultsTotalNum: -1
      })

      await SessionStorageService.delete('latest_Query')
      await SessionStorageService.delete('latest_QueryId')
      await SessionStorageService.delete('latest_QueryResultsCurrentPage')
      await SessionStorageService.delete('latest_QueryResultsFromNum')
      await SessionStorageService.delete('latest_QueryResultsTotalNum')

      animatePageContentPromise.shrink()
    }
  }

  async handleQuery(text) {
    if (text !== '') {
      const { queryFiltersPersonType, queryFiltersSearchScope, queryFiltersRegion, queryResultsPerPage, subclientId, historyResultsPerPage } = this.state

      // const isSubclientSelected = this.validateSubclientSelection()

      await this.setState({ isLoadingFirst: true, isLoadingOthers: true, queryResults: [], query: text })

      // if (isSubclientSelected) {
      const queryFirstPage = await getQueryFirstPagePromise(text, subclientId, queryResultsPerPage, queryFiltersPersonType, queryFiltersSearchScope, queryFiltersRegion)

      let results = queryFirstPage.results
      if(queryFirstPage.size != 1) {
        results = [{ name: text, hasPdf: queryFirstPage.size === 0 }]
      }
      await this.setState({
        queryId: queryFirstPage.searchId,
        queryResults: results,
        queryResultsCurrentPage: 1,
        isLoadingFirst: false,
        totalResults: queryFirstPage.size,
        isSearch: true
      })
      if (queryFirstPage.size > 1) {
        Modal.warning({
          title: 'Resultados',
          content: 'El nombre buscado tiene más de una coincidencia. Por favor comunique el caso al área de Cumplimiento'
        })
      }else if (queryFirstPage.size === 1) {
        //Mostrar pdf
        this.downloadPdf(queryFirstPage.results[0]);
      } else if (queryFirstPage.size === 0) {
        this.handleDownloadCertificate()
      }

      this.handlePaginationChange(1, historyResultsPerPage, true)

      this.setState({ isLoadingOthers: false })
    }
  }

  async handleSearch() {
    const { query } = this.state

    if (query) {
      //abrir automaticamente el pdf segun lo que hay en el primer item sino el que no hay resultado
      this.handleQuery(query)
    } else {
      notification['error']({
        message: 'Error',
        description: 'Rut no Valido'
      })
      return
    }
  }

  async handlePaginationChange(from, size, isSearch = false) {
    const { historyResultsPerPage, queryResultsPerPage } = this.state
    const fromNum = (from - 1) * queryResultsPerPage

    await animatePageContentPromise.grow(queryResultsPerPage)

    this.setState({ isLoadingOthers: true, historyResultsFromNum: fromNum, isSearch })

    let today = moment().format('DD/MM/YYYY');;
    let todayLessMonth = moment().subtract(1, 'month').format('DD/MM/YYYY');

    getSearchesPromise(fromNum, from === 1 && isSearch ? queryResultsPerPage - 1 : queryResultsPerPage, { modo: 'ONLINE', optDates: 'RANGE', fromDate: todayLessMonth, toDate: today }, this.props.currentUser.id).then(queryResults => {
      this.setState({
        historyResults: queryResults.records.map((item) => {
          return {...item, 
            formatRut: item.certificados?.length > 0 && item.certificados[0].rut ? item.certificados[0].rut : item.formatRut && item.rut && item.formatRut.length > item.rut.length ? item.formatRut : item.rut,
            name: item.certificados?.length > 0 && item.certificados[0].nombre ? item.certificados[0].nombre : item.nombre,
            compliance: item.certificados?.length > 0 ? item.certificados[0].compliance : null,
            type: item.certificados?.length > 0 ? item.certificados[0].type : 'Person',
          }
        }),
        historyResultsCurrentPage: from,
        historyResultsTotalNum: queryResults.total,
        isLoadingOthers: false
      })
    })
  }

  handlePersonMouseOver(e) {
    const person = e.target

    person.className = 'person-card mouseover'

    window.setTimeout(() => {
      person.className = 'person-card'
    }, 500)
  }

  handlePersonMouseOut(e) {
    const person = e.target

    person.className = 'person-card mouseout'

    window.setTimeout(() => {
      person.className = 'person-card'
    }, 500)
  }

  closeHandlerPdfModal() {
    this.setState({
      showModalPdf: false
    })
  }

  async handleDownloadCertificate() {
    const { queryId } = this.state;
    //window.open(apiConfig.urlAml + '/pdfUNIQUE.pdf?bId=' + queryId)

    this.setState({
      loadingPdf: true
    })
    //Llamar endpoint y obtener base64
    ReportService.pdfToBase64('pdfSearchBase64', queryId).then(base64 => {
      this.setState({
        showModalPdf: true,
        pdfSource: base64.data,
        loadingPdf: false,
        isPdfObj: false
      })
    })
  }

  handleOnChangeSubclient(subclientId) {
    this.setState({ subclientId })
  }

  onChangeRegionHandler(pais) {
    this.setState({ queryFiltersRegion: pais })
  }

  handleQueryTypeChange(e) {
    this.setState({ queryFiltersPersonType: e.target.value })
  }

  handleSearchChange(e) {
    this.setState({ queryFiltersSearchScope: e.target.value })
  }

  async handleAdvancedSearchButtonClick() {
    await this.setState(prevState => {
      return { isAdvancedSearchFiltersVisible: !prevState.isAdvancedSearchFiltersVisible }
    })
  }

  handleScrollToTop(duration) {
    const scrollStep = -window.scrollY / (duration / 15)
    const scrollInterval = setInterval(function () {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep)
      } else clearInterval(scrollInterval)
    }, 15)
  }

  downloadPdf(person) {
    if(person?.id) {
      this.setState({
        loadingPdf: true
      })
      ReportService.pdfToBase64('pdfComplianceBase64', person.id).then(base64 => {
        this.setState({
          showModalPdf: true,
          pdfSource: base64.data,
          loadingPdf: false,
          isPdfObj: false
        })
      })
    }
  }


  async downloadPdfHis(record) {
    if (record.certificados !== null && record.certificados.length > 0) {
      this.handlePDFReport(record.id, record.certificados[0].pdf)
    }
  }

  async handleOnChangeResultsNum(e) {
    await this.setState({ queryResultsPerPage: e.target.value })
    //await LocalStorageService.create('queryResultsPerPage', e.target.value)

    this.handlePaginationChange(1)
  }

  async handlePDFReport(searchId, pdf) {
    const obj = await ReportService.pdfToBase64Obj('/certificate', { searchId, pdf }, null, 'report.pdf')
    this.setState({
      showModalPdf: true,
      pdfSource: obj.data,
      isPdfObj: true
    })
  }

  formatRut(rut) {
    let rutVal = rutFormatter.format(rut);
    let rutFull

    rut = rutVal;
    if (rutVal && rutVal.length > 0) {
      var isValid = rutFormatter.validate(rutVal);
      if (isValid) {
        rutFull = rutFormatter.clean(rutVal);
      }
    }

    return {
      rut,
      rutFull
    };
  }

  render() {
    const { currentUser, t } = this.props
    const { getFieldDecorator } = this.props.form
    const { breadcrumbs,
      isAdvancedSearchFiltersVisible,
      isLoadingFirst,
      isLoadingOthers,
      query,
      queryResults,
      historyResults,
      historyResultsCurrentPage,
      historyResultsPerPage,
      historyResultsTotalNum,
      queryResultsPerPage,
      showModalPdf,
      pdfSource,
      isPdfObj,
      loadingPdf,
      totalResults,
      isSearch,
      queryFiltersPersonType,
      queryFiltersSearchScope,
      queryFiltersRegion,
    } = this.state

    return (
      <div id="query-risk" style={{ position: 'relative' }}>
        <PageTopBar breadcrumbs={breadcrumbs} />
        <Page>
          <PageHeader
            title={t('messages.aml.query')}
            description={t('messages.aml.queryPageDescription')}
            icon="file-search"
          >
          </PageHeader>
          <PageToolbar additionalClassName={ isAdvancedSearchFiltersVisible ? 'is-advanced-search' : '' }>
            <Form.Item>
              {getFieldDecorator('query')(<SearchBox2 onChange={this.handleChange.bind(this)} onSearch={this.handleSearch.bind(this)} currentQuery={query} loading={loadingPdf} />)}
            </Form.Item>
            <AdvancedSearchToggleButton defaultValue={isAdvancedSearchFiltersVisible} onChangeHandler={this.handleAdvancedSearchButtonClick.bind(this)} />
            <AdvancedSearchFilters 
              defaultValues={{ queryFiltersPersonType, queryFiltersSearchScope, queryFiltersRegion }} 
              onChangeTypeHandler={this.handleQueryTypeChange.bind(this)} 
              onChangeSearchHandler={this.handleSearchChange.bind(this)} 
              onChangeRegionHandler={this.onChangeRegionHandler.bind(this)} 
              currentUser={currentUser}
            />
          </PageToolbar>
          <PageContent>
            <div className="page-content-inner" style={{ marginBottom: 20 }}>
              <div style={{marginBottom: 10}}>
                {/* <Row type='flex' justify='center'>
                  <span style={{fontSize: 12}}>
                    Resultados por página
                  </span>
                </Row> */}
                <Row type='flex' justify='center' style={{padding:5}}>
                  <ResultsPerPageSelector value={ queryResultsPerPage } onChange={ this.handleOnChangeResultsNum.bind(this) } />
                  {/* <Radio.Group value={ queryResultsPerPage } size="small" onChange={this.handleOnChangeResultsNum.bind(this)}>
                    <Radio.Button value={5}>5</Radio.Button>
                    <Radio.Button value={10}>10</Radio.Button>
                    <Radio.Button value={15}>15</Radio.Button>
                  </Radio.Group> */}
                </Row>
              </div>
              <div className="table-headers">
                <Row>
                  <Col xs={6}>
                    {t('messages.aml.personalIdentification')}
                  </Col>
                  <Col xs={6}>
                    Información del Nivel de Riesgo
                  </Col>
                  <Col xs={6}>
                    Fecha de consulta
                  </Col>
                  <Col xs={6}>
                    Ver Certificado
                  </Col>
                </Row>
              </div>
              {isLoadingFirst ?
                  <Spin size={'large'} />
                  :
                  queryResults.map((person, index) => <PersonCard key={index} person={person} currentUser={currentUser} onClick={totalResults === 0 ? this.handleDownloadCertificate.bind(this) : this.downloadPdf.bind(this)} loading={loadingPdf} />)
              }
              {isLoadingOthers ?
                <Spin size={'large'} />
                :
                historyResults.map((person, index) => <PersonCard key={index} person={person} currentUser={currentUser} onClick={this.downloadPdfHis.bind(this)} />)
              }
              { totalResults === 0 && historyResultsTotalNum === 0 && query.length &&
                  <div className="zero-results">
                    <br />
                    <div align="center" style={{ height: '200px' }}><img src={sadSmileyImage} alt="" /></div>
                    <br /><br /><br /><br /><br /><br />
                    <div align="center" style={{ fontSize: '1.8em' }}>{t('messages.aml.queryNoResult')}.</div>
                    <div align="center">{t('messages.aml.queryNoResultDownloadPDFPhrase')} :</div>
                    <br />
                    <div>
                      <Button disabled={loadingPdf} type="primary" size="large" icon={loadingPdf ? "loading" : "file-pdf"} onClick={this.handleDownloadCertificate.bind(this)}>
                        {t('messages.aml.viewPdf')}
                      </Button>
                    </div>
                  </div>
              }

            </div>
          </PageContent>
          <PageFooter>
            {historyResultsTotalNum > 0 &&
              <Pagination
                key={Math.floor(Math.random() * 100000000)}
                current={historyResultsCurrentPage}
                onChange={this.handlePaginationChange.bind(this)}
                pageSize={historyResultsPerPage}
                total={historyResultsTotalNum}
              />
            }
          </PageFooter>
          {showModalPdf && <PdfModal closeHandler={this.closeHandlerPdfModal.bind(this)} pdfSource={pdfSource} embeded={true} isObj={isPdfObj} />}
        </Page>
        <PageBottomBar breadcrumbs={breadcrumbs} />
        {/* { isSelectedPersonVisible && ReactDOM.createPortal(<ProfilePage currentPerson={ selectedPerson } currentUser={ currentUser } isTransition />, document.body) } */}
      </div>
    )
  }
}

const WrappedTimeRelatedForm = Form.create({ name: 'query' })(QueryRisk)

export default withRouter(withTranslation()(WrappedTimeRelatedForm))
