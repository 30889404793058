import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Icon, Input, message, Spin, Upload, Tooltip} from 'antd';
import { HistoryModal } from '../../../../../../layouts/Private/components'
import {saveCommentsPromise} from '../../../../promises'
import moment from 'moment';

const Comentarios = ({item, saveComments}) => {

    const [itemTable, setItemTable] = useState(item);
    const [seeMore, setSeeMore] = useState(item.id ? false : true);
    const [loadingSaveComment, setLoadingSaveComment] = useState(false)
    const [title, setTitle] = useState("");
    const [comments, setComments] = useState("");
    const [lastCommentDate, setLastCommentDate] = useState(null);
    const [isHistoryVisible, setIsHistoryVisible] = useState(false);
    const [fileList, setFileList] = useState(null);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        if(itemTable.histComments && itemTable.histComments.length > 0){
            setLastCommentDate(moment(itemTable.histComments[0].creationDate).format('DD/MM/YYYY HH:mm'));
        }
    }, []);

    useEffect(() => {
        const fd = new FormData()
        fd.append('title', title)
        fd.append('comments', comments)
        if(fileList && fileList.length > 0) {
            fd.append('file', fileList[0])
        }
        if(itemTable.id) {
            fd.append('formId', itemTable.id)
        }
        setFormData(fd)
        saveComments(fd)
    }, [title, comments, fileList])

    const handleComments = (comment) => {
        setComments(comment);
    }

    const handleCloseModals = () => {
        setIsHistoryVisible(false);
    }

    const handleTitle = (title) => {
        setTitle(title);
    }

    const handleSaveComment = () => {
        if(title) {
            if(itemTable.id) {
                setLoadingSaveComment(true)
                
                saveCommentsPromise(formData).then((response) => {
                    if(response.success === true){
                        message.success("Se ha guardado el comentario")
                        let record = response.data
                        const item = { ...itemTable, histComments: record.histComments}
                        setItemTable(item)
                        setLastCommentDate(moment(response.data.histComments[0].creationDate).format('DD/MM/YYYY HH:mm'));
                        setComments(null);
                        setTitle(null);
                        setFileList(null)
                        setLoadingSaveComment(false)
                    }else{
                        message.error("Ha ocurrido un error al guardar el comentario")
                    }
                })
            }
        }else{
            message.error("Debe ingresar un título")
        }
    }

    const onRemove = (file) => {
        setFileList(prevFileList => {
            const index = prevFileList.indexOf(file);
            const newFileList = [...prevFileList];
            newFileList.splice(index, 1);
            return newFileList;
        });
    };

    const beforeUpload = (file) => {
        if ((fileList === null || fileList.length === 0) && file) {
            setFileList([file]);
        }
        return false;
    };

    const uploadProps = {
        accept: ".pdf, .txt, .png, .jpg, .jpeg",
        onRemove: onRemove,
        beforeUpload: beforeUpload,
        multiple: false,
        fileList: fileList,
    };

    const commentSchema = [
        {
            title: 'Fecha',
            dataIndex: 'creationDate',
            cols: 3
        },
        {
            title: 'Nombre',
            dataIndex: 'userName',
            cols: 6
        },
        {
            title: 'Titulo',
            dataIndex: 'title',
            cols: 10
        },
        {
            title: 'Archivo',
            dataIndex: 'fileNameComment',
            cols: 5
        },
    ];


    return (
        <div>
            <Row>
                <Col span={22}>
                    <h3>Comentarios</h3>
                </Col>
                { item.id &&
                    <Col span={2} style={{textAlign:'right'}}>
                        <Button type="link" onClick={() => setSeeMore(!seeMore)}>
                            {seeMore ?
                            <>Ver menos <Icon type="minus"/></>
                            :
                            <>Ver más <Icon type="plus"/></>
                            }
                        </Button>
                    </Col>
                }
            </Row>
            <hr/>
            {seeMore &&
                <div className="div-more-info" style={{padding: 20}}>
                    <Row>
                        <Col span={5} >
                            <Row>
                                <Col span={24}>
                                    Título
                                    <Input disabled={loadingSaveComment} onChange={(e) => {handleTitle(e.target.value)}} value={title}/>
                                </Col>
                                <Col span={24}>
                                {fileList === null || fileList.length === 0 ?
                                    <Upload {...uploadProps}>
                                        <Tooltip title=".pdf, .png, .jpg o .txt">
                                            <Button style={{marginTop:18}}>
                                                Adjuntar Archivo
                                            </Button>
                                        </Tooltip>
                                    </Upload>
                                    :
                                    <Row style={{marginTop:10}}>
                                        <Col span={23}>
                                            {fileList[0].name}
                                        </Col>
                                        <Col span={1}>
                                            <Icon type="close" onClick={() => setFileList(null)}/>
                                        </Col>
                                    </Row>
                                }
                                </Col>
                            </Row>
                        </Col>
                        <Col span={18} push={1} style={{paddingBottom: 30}}>
                            Comentario
                            <Input.TextArea disabled={loadingSaveComment} onChange={(e) => {handleComments(e.target.value)}} value={comments}/>
                        </Col>
                    </Row>
                    { item.id &&
                        <Row>
                            <Col span={6}>
                                {lastCommentDate &&
                                    <Button onClick={() => setIsHistoryVisible(true)} style={{padding:'unset'}}type="link">Ver comentarios anteriores</Button>
                                }
                            </Col>
                            <Col span={6}>
                                <div>
                                    Último comentario: {lastCommentDate ? lastCommentDate : "Sin comentarios"}
                                </div>
                            </Col>
                            <Col span={12}>
                                {loadingSaveComment ? <Spin style={{float:'right', padding:'unset', marginTop: 5}}/> : <Button onClick={() => handleSaveComment()} style={{float:'right', padding:'unset'}} type="link">Guardar</Button>}
                            </Col>
                        </Row>
                    }
                </div>
            }
            {isHistoryVisible &&
                <HistoryModal schema={commentSchema} data={itemTable.histComments} closeHandler={handleCloseModals}/>
            }
        </div>
    );
};

export default Comentarios;