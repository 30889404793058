import React from 'react';
import {Row, Col, Input } from 'antd'

const BasicInfoAdicional = ({infoBasicAdicional={}, actual, record}) => {
    return (
        <div>
            <Row gutter={[32, 12]}>
                {record.type === 'Person' ?
                <>
                    { infoBasicAdicional.residencia &&
                        <Col span={6}>
                            ¿Residencia permanente en otro país?
                            <Input readOnly value={infoBasicAdicional.residencia} className={actual && infoBasicAdicional.residencia !== actual.residencia && "difference"}/>
                        </Col>
                    }
                    { infoBasicAdicional.oficio &&
                        <Col span={6}>
                            Ocupación u Oficio
                            <Input readOnly value={infoBasicAdicional.oficio} className={actual && infoBasicAdicional.oficio !== actual.oficio && "difference"}/>
                        </Col>
                    }
                </>
                :
                <>
                    {infoBasicAdicional.nombreFantasia &&
                        <Col span={6}>
                            Nombre de Fantasía
                            <Input readOnly value={infoBasicAdicional.nombreFantasia} className={actual && infoBasicAdicional.nombreFantasia !== actual.nombreFantasia && "difference"}/>
                        </Col>
                    }
                    {infoBasicAdicional.tipoEmpresa &&
                        <Col span={6}>
                            Tipo de Empresa
                            <Input readOnly value={infoBasicAdicional.tipoEmpresa} className={actual && infoBasicAdicional.tipoEmpresa !== actual.tipoEmpresa && "difference"}/>
                        </Col>
                    }
                    {infoBasicAdicional.tipoSociedad &&
                        <Col span={6}>
                            Tipo de Sociedad
                            <Input readOnly value={infoBasicAdicional.tipoSociedad} className={actual && infoBasicAdicional.tipoSociedad !== actual.tipoSociedad && "difference"}/>
                        </Col>
                    }
                    {infoBasicAdicional.tipoSociedadOtra &&
                        <Col span={6}>
                            Tipo de Sociedad (Otra)
                            <Input readOnly value={infoBasicAdicional.tipoSociedadOtra} className={actual && infoBasicAdicional.tipoSociedadOtra !== actual.tipoSociedadOtra && "difference"}/>
                        </Col>
                    }
                    {infoBasicAdicional.tamEmpresa &&
                        <Col span={6}>
                            Tamaño de Empresa
                            <Input readOnly value={infoBasicAdicional.tamEmpresa} className={actual && infoBasicAdicional.tamEmpresa !== actual.tamEmpresa && "difference"}/>
                        </Col>
                    }
                    { infoBasicAdicional.matriz &&
                        <Col span={6}>
                            ¿Tiene Casa Matriz?
                            <Input readOnly value={infoBasicAdicional.matriz} className={actual && infoBasicAdicional.matriz !== actual.matriz && "difference"}/>
                        </Col>
                    }
                    {infoBasicAdicional.web &&
                        <Col span={6}>
                            Página Web
                            <Input readOnly value={infoBasicAdicional.web} className={actual && infoBasicAdicional.web !== actual.web && "difference"}/>
                        </Col>
                    }
                </>
                }
            </Row>
        </div>
    );
};

export default BasicInfoAdicional;