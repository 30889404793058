import AdminLists from './AdminLists/AdminLists'
import AdminConsultas from './AdminConsultas/AdminConsultas'
import AdminRisk from './AdminParams/AdminRisk'
import AdminGroups from './AdminParams/AdminGroups'
import Batch from './Batch/Batch'
import InterestConflicts from './InterestConflicts/InterestConflicts'
import AdminSearch from './AdminSearch/AdminSearch'
import AdminAuthIps from './AdminAuthIps/AdminAuthIps'
import AdminTransFile from './AdminTransFile/AdminTransFile'
import NewRegister from './Register/NewRegister'
import Home from './Home/Home'
import Mfa from './Mfa/Mfa'
import Login from './Login/Login'
import LoginOauth from './Login/LoginOauth'
import LoginGoogle from './Login/LoginGoogle'
import AuthLogin from './Login/AuthLogin'
import GoogleLogin from './Login/GoogleLogin'
import NotFound from './NotFound/NotFound'
import NotAuthorized from './NotAuthorized/NotAuthorized'
import Query from './Query/Query'
import Profile from './Profile/Profile'
import OnBoarding from './OnBoarding/OnBoarding'
import Admin from './Admin/Admin'
import AdminUsers from './AdminUsers/AdminUsers'
import AdminAudit from './AdminAudit/AdminAudit'
import Monitoring from './Monitoring/Monitoring'
import Quiebras from './Quiebras/Quiebras'
import Stakeholder from './Stakeholder/Stakeholder'
import StakePublicComponent from './Stakeholder/Public'
import StakePrivateComponent from './Stakeholder/Private'
import StakeholderPortalComponent from './Stakeholder/StakeholderPortal'
import PublicPortalComponent from './Stakeholder/PublicPortal'
import AdminLogBases from './AdminLogBases/AdminLogBases'
import Query2 from './Query/Query2'
import ModalAlertCard from './ModalAlertCard/ModalAlertCard'
import InterestConflicts_v2 from './InterestConflicts_v2/InterestConflicts'
import CdiInvestigacion_v2 from './CdiInvestigacion/CdiRelaciones'
import ReportHistory from './ReportHistory/ReportHistory'
import QueryRisk from './QueryRisk/QueryRisk'
import Inside from './Inside/Inside'


export const LoginPage = Login
export const LoginOauthPage = LoginOauth
export const LoginGooglePage = LoginGoogle
export const AuthLoginPage = AuthLogin
export const GoogleLoginPage = GoogleLogin
export const NotFoundPage = NotFound
export const NotAuthorizedPage = NotAuthorized
export const HomePage = Home
export const MfaPage = Mfa
export const NewRegisterPage = NewRegister
export const QueryPage = Query
export const ProfilePage = Profile
export const OnBoardingPage = OnBoarding
export const AdminPage = Admin
export const AdminUsersPage = AdminUsers
export const AdminAuditPage = AdminAudit
export const AdminAuthIpsPage = AdminAuthIps
export const AdminTransFilePage = AdminTransFile
export const AdminSearchPage = AdminSearch
export const MonitoringPage = Monitoring
export const Query2Page = Query2
export const AdminListsPage = AdminLists
export const AdminConsultasPage = AdminConsultas
export const AdminRiskPage = AdminRisk
export const AdminGroupsPage = AdminGroups
export const BatchPage = Batch
export const InterestConflictsPage = InterestConflicts
export const QuiebrasPage = Quiebras
export const StakeholderPage = Stakeholder
export const StakePublic = StakePublicComponent
export const StakePrivate = StakePrivateComponent
export const StakeholderPortalPage = StakeholderPortalComponent
export const PublicPortal = PublicPortalComponent
export const AdminLogBasesPage = AdminLogBases
export const ModalAlertCardPage = ModalAlertCard;
export const InterestConFlictsPage_v2 = InterestConflicts_v2
export const CdiInvestigacionPage_v2 = CdiInvestigacion_v2
export const ReportHistoryPage = ReportHistory
export const QueryRiskPage = QueryRisk
export const InsidePage = Inside
