import './Sidebar.scss'
import React, { useEffect, useState } from 'react'
import {Col, Collapse, Icon, Row, Switch} from 'antd'
import {useTranslation} from 'react-i18next';
import AnimateHeight from 'react-animate-height'

const Sidebar = ({currentUser, activeTab, onTabChange, categories, defaultFilters={}, showTabAlertas, handleChangeShowTabAlertas}) => {
    const {t} = useTranslation()
    const {Panel} = Collapse;

    const FILTER_STATUS = 'states'
    const FILTER_CATEGORIES = 'categories'
    const FILTER_TYPE = 'types'
    const FILTER_RISK = 'risks'

    const condicion = ['OPEN', 'CLOSED']
    const alertas = ["PERSONA", "DEMOGRAFICA", "TRANSACCIONAL"]
    const riesgos = [4, 3, 2, 1]

    let [filters, setFilters] = useState(defaultFilters);

    useEffect(() => {
    }, [])

    const onFilterManager = (checked, key, value) => {

        if (checked) {
            if (filters[key] != null) {

                setFilters(data => {

                    let prevData = data[key]

                    if (prevData.find(val => val === value) === undefined) {
                        prevData.push(value)
                    }

                    data[key] = prevData
                    return data
                })
            }else {
                setFilters(data => {
                    data[key] = [value]
                    return data
                })
            }

        } else if (filters[key] != null) {

            setFilters(data => {
                let prevData = data[key]

                if (prevData.find(val => val === value) !== undefined) {
                    prevData = prevData.filter(element => element !== value)
                }

                (prevData.length === 0) ? delete data[key] : data[key] = prevData
                if (data.length === 0) {
                    data = {}
                }

                return data
            })
        }
        onTabChange('tab-alerts', filters)
    }

    const localTabChange = (tab) => {
        if(tab === 'tab-alerts') {
            if(!showTabAlertas) {
                if(activeTab !== 'tab-alerts') {
                    onTabChange('tab-alerts', filters)
                }
                handleChangeShowTabAlertas(true)
            }else {
                if(activeTab === 'tab-alerts') {
                    handleChangeShowTabAlertas(false)
                }else {
                    onTabChange('tab-alerts', filters)
                }
            }
        }else {
            onTabChange(tab)
        }
    }

    return (
        <Col className="sidebar" span={ 5 }>
            <div className="sidebar-inner">
                <div className="menu-block">
                    <ul>
                        {/*dashboard*/}
                        <li className={ activeTab === 'tab-dashboard' ? 'active' : '' } onClick={ () => localTabChange('tab-dashboard') }>

							<div className="menu-item-inner">
								<Icon type="dashboard" className="option"/>
                                {t("messages.aml.monitor.dashboard")}
								<Icon type="check" />
							</div>
						</li>

                        {/*Alert*/}
                        <li className={ activeTab === 'tab-alerts' ? 'active' : '' } onClick={ () => localTabChange('tab-alerts') }>

							<div className="menu-item-inner">
								<Icon type="alert" className="option"/>
                                {t("messages.aml.monitor.alertas")}
								<Icon type="check" />
                            </div>
                        </li>
                        <AnimateHeight
                            duration={300}
                            height={showTabAlertas ? 'auto' : 0}
                        >
                            <Collapse
                                bordered={false}
                                defaultActiveKey={["2", "3", "4", "5"]}
                                accordion={ false}
                                expandIconPosition="right"
                            >
                                <Panel header={t('messages.aml.condition')} key="2">
                                    {condicion.map((cond) =>
                                        <Row className="filter-item">
                                            {t('messages.aml.monitor.status.' + cond)}
                                            <Switch
                                                size="small"
                                                checked={filters[FILTER_STATUS]?.includes(cond)}
                                                onChange={
                                                    (checked) => onFilterManager(checked, FILTER_STATUS, cond)
                                                }
                                            />
                                        </Row>
                                    )}
                                </Panel>

                                <Panel header={t('messages.aml.category')} key="3">
                                    {categories.map((categoria) =>
                                        <Row className="filter-item">
                                            {t('messages.aml.category.' + categoria)}
                                            <Switch size="small"
                                                    checked={filters[FILTER_CATEGORIES]?.includes(categoria)}
                                                    onChange={(checked) => {
                                                        onFilterManager(checked, FILTER_CATEGORIES, categoria);
                                                    }}/>
                                        </Row>
                                    )}
                                </Panel>


                                {currentUser.cliente.modules.includes("MONITOR-T") &&
                                    <Panel header={t('messages.aml.alert')} key="4">
                                        {alertas.map((alerta) =>
                                            <Row className="filter-item">
                                                {t('messages.aml.monitor.alertas.' + alerta)}
                                                <Switch size="small"
                                                        checked={filters[FILTER_TYPE]?.includes(alerta)}
                                                        onChange={(checked) => {
                                                            onFilterManager(checked, FILTER_TYPE, alerta);
                                                        }}/>
                                            </Row>
                                        )}
                                    </Panel>
                                }

                                <Panel header={t('messages.aml.risk')} key="5">
                                    {riesgos.map((riesgo) =>
                                        <Row className="filter-item">
                                            {t('messages.aml.indicators.sunburst.risk.' + riesgo)}
                                            <Switch size="small"
                                                    checked={filters[FILTER_RISK]?.includes(riesgo)}
                                                    onChange={(checked) => {
                                                        onFilterManager(checked, FILTER_RISK, riesgo);
                                                    }}/>
                                        </Row>
                                    )}
                                </Panel>
                            </Collapse>
                        </AnimateHeight>

                        {/*Biblioteca de reglas*/}
                        {(currentUser.type === 'SADMIN' ||  (currentUser.cliente.oficialCto && currentUser.cliente.oficialCto.id === currentUser.id)) &&
                            <li className={ activeTab === 'tab-rules' ? 'active' : '' } onClick={ () => localTabChange('tab-rules') }>
                                <div className="menu-item-inner">
                                    <Icon type="fund" className="option"/>
                                    {t("messages.aml.RulesLibrary")}
                                    <Icon type="check" />
                                </div>
                            </li>
                        }

                        {/*Simulador*/}
                        {/*currentUser.cliente.modules.includes("MONITOR-T") && (currentUser.type === 'SADMIN' || (currentUser.cliente.oficialCto && currentUser.cliente.oficialCto.id === currentUser.id)) &&
                            <li className={ activeTab === 'tab-simulator' ? 'active' : '' } onClick={ () => localTabChange('tab-simulator') }>
                                <div className="menu-item-inner">
                                    <Icon type="file-done" className="option"/>
                                    {t("messages.aml.Simulator")}
                                    <Icon type="check" />
                                </div>
                            </li>
                        */}

                        {/*Reportes*/}
                        <li className={ activeTab === 'tab-reports' ? 'active' : '' } onClick={ () => localTabChange('tab-reports') }>
                            <div className="menu-item-inner">
                                <Icon type="line-chart" className="option"/>
                                {t("messages.aml.monitor.report")}
                                <Icon type="check" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Col>
    )

}
export default Sidebar
