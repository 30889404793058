import React, {useState} from 'react';
import './AlertClose.scss';
import {Row, Col, Button, Icon, Input, Radio, Upload, message, notification} from 'antd';
import AnimateHeight from 'react-animate-height';
import { alertClosePromise } from './promises'
import { useTranslation } from 'react-i18next'

const AlertClose = ({alert, refreshHandler, falsosPositivos}) => {
    const [seeMore, setSeeMore] = useState(0);
    const [hasComments, setHasComments] = useState(null);
    const [comms, setComms] = useState(null);
    const [title, setTitle] = useState(null);
    const [changes, setChanges] = useState(0);
    const [tmpFilesList, setTmpFilesList] = useState([]);
    const { t } = useTranslation()

    const handleTitleChange = (e) => {
        setTitle(e)
        handleAddChange()
    }

    const handleCommentsChange = (e) => {
        setComms(e)
        handleAddChange()
    }

    const handleAddChange = () => {
        setChanges(oldChanges => {
            const newNumber = oldChanges + 1
            return newNumber
        })
    }

    const handleActionSave = () => {
        if(alert.estadoFP === 'PENDIENTE' && falsosPositivos.length > 0) {
                notification.warn({
                message: 'Se deben resolver las coincidencias por Nombre'
            })
        }else if(title === null || title ===''){
                notification['error']({
                message: t('messages.aml.requestedField'),
                description: "El campo 'Descripción corta' es obligatorio"
            })
        }else if (tmpFilesList.indexOf > 3){
                notification['error']({
                message: t('messages.aml.requestedField'),
                description: "Debe adjuntar al menos 3 archivos"
            })
        }else {
            const formData = new FormData()
            tmpFilesList.forEach(file => {
                formData.append('file', file)
            })
            formData.append('action', "CLOSE")
            formData.append('shortDescription', title)
            if(comms) formData.append('comments', comms)
            alertClosePromise(alert.id, formData)
                .then(response => {
                    notification['success']({
                        "message": "Operación Exitosa",
                        "description": "La asignación ha sido guardada exitosamente"
                })
                setChanges(0)
                refreshHandler()
            })
        }
    }

    const propsUpload = {
    //accept: ".pdf, .png",
    onRemove: file => {
        setTmpFilesList(oldTmpFilesList => {
            const index = oldTmpFilesList.indexOf(file)
            const newTmpFilesList = oldTmpFilesList.slice()
            newTmpFilesList.splice(index, 1)
            return newTmpFilesList
        })
    },
        beforeUpload: file => {
            if (file.size > (5 * 1024*1024)) {
                    notification['error']({
                    message: "Máx. tamaño permitido",
                    description: "No se pueden cargar archivos de mas de 5MB"
                })
            }else if(tmpFilesList.length > 2 ) {
                    notification['error']({
                    message: "Máx. Archivos alcanzado",
                    description: "No se pueden cargar más de 3 archivos"
                })
            }else {
                setTmpFilesList(oldTmpFilesList => [...oldTmpFilesList, file])
            }
        return false
    },
    multiple: false,
    listType: 'picture',
    fileList: tmpFilesList,
    className: 'upload-list-inline'
  }

    return (
        <div className='alert-close-section'>
            <div className="title-section">
                <Row>
                    <Col span={19}>
                        <h3>Cierre Autorizado</h3>
                    </Col>
                    <Col span={5}>
                        <div style={{textAlign: 'right'}}>
                        <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                            {seeMore ?
                                <>Ver menos <Icon type="minus"/></>
                                :
                                <>Ver más <Icon type="plus"/></>
                            }
                        </Button>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
            <AnimateHeight
                duration={ 600 }
                height={ seeMore }
                animateOpacity={true}
            >
                <div className='alert-close-body'>
                    <Row style={{height: 0}}>
                        { changes > 0 && alert!==null && alert.status !== "CLOSED" && <Button type="primary" size="small" icon="save" className="save-button" style={{float: 'right'}} onClick={ handleActionSave }>Guardar cambios</Button> }
                    </Row>
                    <div className='alert-close-content-wrapper'>
                        <Row gutter={[0, 20]}>
                            <Col span={18} style={{float: 'left'}}>
                                Ingrese un título o descripción corta
                            </Col>
                            <Col span={6}>
                                <Input onChange={(e) => handleTitleChange(e.target.value)}/>
                            </Col>
                            <Col span={18} style={{float: 'left'}}>
                                ¿Requiere asociar algún comentario?
                            </Col>
                            <Col span={6}>
                                <div style={{float: 'right'}}>
                                    <Radio.Group  defaultValue={comms ? true : false} buttonStyle="solid" onChange={(e) => setHasComments(e.target.value)}>
                                        <Radio.Button value={true}>Si</Radio.Button>
                                        <Radio.Button value={false}>No</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Col>
                            {/* <AnimateHeight id='123' duration={500} height={seeComments} animateOpacity={true}> */}
                            {hasComments &&
                                <Col span={24}>
                                    <Input.TextArea rows={4} value={comms} placeholder="Comentarios" onChange={(e) => {handleCommentsChange(e.target.value)}}/>
                                </Col>
                            }
                            {/* </AnimateHeight> */}

                            <Col span={24}>
                                <div style={{float: 'right'}}>
                                    <Upload {...propsUpload}>
                                        <Button>
                                            <Icon type="upload" /> Cargar Archivos
                                        </Button>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </AnimateHeight>
        </div>
    );
};

export default AlertClose;