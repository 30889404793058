import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

export default {
  create: (username, captchaResponse) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/auth/forgotPwd',
      method: 'post',
      body: {
        username,
        captchaResponse
      }
    })
  }
}
