import './Mfa.scss'
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import {Col, Row, Input, Button, message} from 'antd'
import {Page, PageBottomBar, PageContent, PageFooter, PageHeader, PageTopBar} from '../../layouts/Private/components'
import { globalContext } from '../../contexts'
import { UserService } from '../../services'
import { logoutPromise } from '../../promises'
import { sessionStorageCleanerHelper } from '../../helpers'

class Mfa extends Component {
    static contextType = globalContext;

    state = {
      isValidaMfaAuthenticated: false,
      qrImageUrl: null,
      qrCode: null
    }

    async componentDidMount() {
        const {validaMfaAuthenticated, currentUser} = this.context;
        if(!currentUser.hasMfaKey) {
            UserService.getQrMfaImage().then(r => {
              const blob = new Blob([r.data], { type: "image/png" });
              const imageUrl = URL.createObjectURL(blob);
              this.setState({
                qrImageUrl: imageUrl
              })
            })
        }else {
          const valid = validaMfaAuthenticated()
          this.setState({
            isValidaMfaAuthenticated: valid
          })
        }
    }

    handleChangeText (e) {
      this.setState({
        qrCode: e.target.value
      })
    }

    genMfa(action) {
      const {qrCode} = this.state

      UserService.genMfa(action, qrCode).then(r => {
        if(r.data === 'ERROR') {
          message.error("Código MFA no es válido");
          logoutPromise()
          sessionStorageCleanerHelper()
        }
        document.location.href = '/'
      })
    }

    validaMfa() {
      const {qrCode} = this.state
      UserService.validaMfa(qrCode).then(r => {
        if(r.data === 'ERROR') {
          message.error("Código MFA no es válido");
          logoutPromise()
          sessionStorageCleanerHelper()
        }
        document.location.href = '/'
      })
    }

    handleClickCancel() {
      document.location.href = '/'
    }

    render() {
      const {isValidaMfaAuthenticated, qrImageUrl, qrCode} = this.state
      const { currentUser, userParams} = this.context;

      return (
        <div className="home">
          <PageTopBar breadcrumbs={ [] } />
            <Page>
              <PageHeader title="Autenticación multifactor (MFA)" description="Utilice MFA para aumentar la seguridad del entorno de AMLupdate." icon="key" />
              <PageContent>
                <Row>
                  <Col>
                    { !currentUser.hasMfaKey ?
                      <>
                        <h3>Para iniciar sesión con MFA, se requiere un c&oacute;digo de autenticaci&oacute;n de un dispositivo MFA.</h3>
                        <Row>
                          <img src={qrImageUrl} alt="Código QR MFA" width={250} />
                        </Row>
                        <Row style={{paddingTop: 15}}>
                          Código <Input size="small" style={{width: 200}} maxLength={6} onChange={this.handleChangeText.bind(this) }/>
                        </Row>
                        <Row style={{paddingTop: 15, width:250, textAlign: 'right'}}>
                          { userParams?.system?.mfa === 'opcional' && !currentUser.cliente.modules?.includes('MFAREQ') &&
                            <Button type="ghost" style={{width: 100}} onClick={this.handleClickCancel.bind(this)}>Cancelar</Button>
                          }
                          <Button style={{width: 100, marginLeft: 10}} disabled={qrCode == null || qrCode===''} onClick={() => this.genMfa('guardar')}>Validar</Button>
                        </Row>
                      </>
                    : !isValidaMfaAuthenticated ?
                      <div>
                        <p><span style={{fontSize:"larger"}}>Ingrese un código MFA para completar el inicio de sesión.</span></p>
                        <Row style={{width:250}}>
                          <Col style={{paddingTop: 15}}>
                            <b>Código MFA:</b>
                          </Col>
                          <Col>
                            <Input size="small" maxLength={6} onChange={this.handleChangeText.bind(this) } />
                          </Col>
                          <Col style={{paddingTop: 15, width:250, textAlign: 'right'}}>
                            <Button type="ghost" style={{width: 100}} onClick={this.handleClickCancel.bind(this)}>Cancelar</Button>
                            <Button style={{width: 100, marginLeft: 10}} disabled={qrCode == null || qrCode===''} onClick={() => this.validaMfa()}>Validar</Button>
                          </Col>
                        </Row>
                      </div>
                    :
                    <div>
          	       		<span style={{fontSize:"larger"}}>Confirme si desea eliminar el código de autenticación MFA.</span>
                      <Row style={{width:250}}>
                        <Col style={{paddingTop: 15}}>
                          <b>Código MFA:</b>
                        </Col>
                        <Col>
                          <Input size="small" maxLength={6} onChange={this.handleChangeText.bind(this) } />
                        </Col>
                        <Col style={{paddingTop: 15, width:250, textAlign: 'right'}}>
                          <Button type="ghost" style={{width: 100}} onClick={this.handleClickCancel.bind(this)}>Cancelar</Button>
                          <Button style={{width: 100, marginLeft: 10}} disabled={qrCode == null || qrCode===''} onClick={() => this.genMfa('eliminar')}>Validar</Button>
                        </Col>
                      </Row>
                    </div>
                    }
                  </Col>
                </Row>
              </PageContent>
              <PageFooter />
            </Page>
          <PageBottomBar breadcrumbs={ [] } />
        </div>
      )
    }
}

export default withTranslation()(withRouter(Mfa))
