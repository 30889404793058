import { itemsService } from '../services'

export default (formData) => {
	return new Promise((resolve) => {
		itemsService.saveComments(formData)
			.then(response => {
				resolve({ success: true, data: response.data })
			})
	})
}
