import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const Clients = {
  read: (from, size, filters) => {
    const config = {
      url: apiConfig.url + '/getClientes',
      method: 'post',
      body: {
        from,
        size
      }
    }

    for (let key in filters) {
      config.body[key] = filters[key]
    }

    return apiRequestorHelper(config)
  },
  getFiltersCLClientes: (filters) => {
    const config = {
      url: apiConfig.url + '/getFiltersCLClientes',
      method: 'post',
      body: filters
    }

    return apiRequestorHelper(config)
  },
  getRiskCLClientes: (filters) => {
    const config = {
      url: apiConfig.url + '/getRiskCLClientes',
      method: 'post',
      body: filters
    }

    return apiRequestorHelper(config)
  },
  detail: (id) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/clientDetail/'+id,
      method: 'post'
    })
  },
  tree: (id) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/clientTree/'+id,
      method: 'post'
    })
  },
  validateForm: (id) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/ddaValidate/'+id,
      method: 'post'
    })
  },
  saveDDAValidated: (id, updateMalla) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/saveDDAValidated/'+id+'/'+updateMalla,
      method: 'post'
    })
  },
  sendFormC57: (id, obsCli) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/sendFormC57',
      method: 'post',
      body: {
        obsCli,
        clCliente: {
          id
        }
      }
    })
  },
  historyMonitoreo: (id) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getMonitoreoRegistroByClient',
      method: 'post',
      body: {
        id
      }
    })
  },
  monitoreoHisClient: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getMonitoreoHistCliente',
      method: 'post'
    })
  },
  areas: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/getAreasClient',
			method: 'post'
		})
	},
  grupos: (category) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/getGroupsClientesByUser/' + category,
			method: 'post'
		})
	},
  categories: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/getCategoriesClient',
			method: 'post'
		})
	},
  sendBatchAction: (action, typeForm, period, ids, filters, isSendAll) => {
    return apiRequestorHelper({
			url: apiConfig.url + '/sendBatchAction',
			method: 'post',
      body: { action, typeForm, period, ids, filters, isSendAll}
		})
  },
  getRulesDetail: (id) => {
    return apiRequestorHelper({
			url: apiConfig.url + '/getDetailRulesRecord',
			method: 'post',
      body: { id }
		})
  },
  sendNewRequestFile: (fileData) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/uploadNewRequest',
      method: 'post',
      body: fileData,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    })
  },
  newSendRequest: (bdParams, type, filters, isSendAll) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/newSendRequest/' +type,
      method: 'post',
      body: { bdParams, filters, isSendAll }
    })
  }
}
export default Clients