import React, {useState, useEffect} from 'react';
import {Row, Col, Input, Button, Spin, Popconfirm, notification, Icon} from 'antd'
import PageTopBar from '../../layouts/Private/components/PageTopBar/PageTopBar';
import './ModalAlertCard.scss'
import {BasicInfoAlertSection, 
        AlertRulesSection, 
        AlertAssignmentSection,
        AlertCloseSection,
        TasksCreatorSection,
        SuspiciousOperationSection,
        HistoryTaskSection
      } from './components'
import { closeAlertPromise, getAlertPromise } from "./promises";
import { getFalsosPositivosPromise } from './promises'
import TaskRevision from './components/TaskRevision/TaskRevision';
import { useTranslation } from 'react-i18next';


const ModalAlertCard = ({ item, currentPage, handlePrevItem, handleNextItem, closeOverlayAlerta, currentUser }) => {

  const [alert, setAlert] = useState(null);
  const [falsosPositivos, setFalsosPositivos] = useState(null);
  const {t} = useTranslation();
  const [isAlertCloseLoading, setIsAlertCloseLoading] = useState(false);

  useEffect(() => {
    tmpFuncForAlert()
  }, []);

  const tmpFuncForAlert = async () => {
    let alertx = await getAlertPromise(item.id);
    let tmpFalsesPos = await getFalsosPositivosPromise(alertx.id)
    setAlert(alertx)
    setFalsosPositivos(tmpFalsesPos)
  }

  const handleCloseAlert = async () => {
    setIsAlertCloseLoading(true)
    if(alert.estadoFP === 'PENDIENTE' && falsosPositivos.size > 0) {
      notification.warn({
        message: 'Se deben resolver las coincidencias por Nombre'
      })
    }else {
      const alertClose = await closeAlertPromise(alert.id);
      notification["success"]({
        message: t("messages.aml.request.CERRADA"),
      });
      setAlert(alertClose)
      setIsAlertCloseLoading(false)
    }

    handleRefreshTasks()
  }

  const handleRefreshTasks = async () => {
    setAlert(null)
    const alertObj = await getAlertPromise(item.id);
    setAlert(alertObj)
  }

  return (
    <div class='content'>
      <div style={{backgroundColor: '#ebebeb', borderRadius: 5}}>
          <PageTopBar breadcrumbs={null}>
              <div className="title">
                <h3>Alerta de {t("messages.aml.category."+item.category)}</h3>
              </div>
              <div className="top-buttons">
                {handlePrevItem && <Button size="small" disabled={!(handlePrevItem)} onClick={handlePrevItem} icon="arrow-left" />}
                {handleNextItem && <Button size="small" disabled={!(handleNextItem)} onClick={handleNextItem} icon="arrow-right" />}
                <Button size="small" onClick={closeOverlayAlerta} icon="close" />
              </div>
          </PageTopBar>
        </div>

        {alert ?
          <div style={{padding: 10}}>
            {alert?.status === "READY" &&
              ((currentUser.cliente.oficialCto && currentUser.id === currentUser.cliente.oficialCto.id) ||
                (currentUser.modules && (currentUser.modules.includes("OFCTO") || currentUser.modules.includes("ANCTO")))) && (
                <div className='closing-alert-notification'>
                  <Row>
                    <div style={{textAlign: 'center'}}>
                      <Col span={24}>
                        <strong>
                          Se completaron todas las tareas, desea cerrar la Alerta?
                        </strong>
                      </Col>
                      <Col span={24}>
                        {isAlertCloseLoading ?
                          <Icon type="loading"/>
                          :
                          <div style={{marginTop: 10}}>
                            <Popconfirm
                              title={["Confirma cierre de alerta"]}
                              onConfirm={handleCloseAlert}
                              okText="Sí"
                              cancelText="No"
                            >
                              <Button
                                className="close-button"
                                type="primary"
                                icon="save"
                                size="small"
                              >
                                Cerrar Alerta
                              </Button>
                            </Popconfirm>
                          </div>
                        }
                      </Col>
                    </div>
                  </Row>
              </div>
            )}

            {/* EMPIEZA EL CONTENT */}
            <BasicInfoAlertSection alert={alert}/>
            <AlertRulesSection alert={alert} refreshHandler={handleRefreshTasks} setFalsosPositivos={setFalsosPositivos} currentUser={currentUser}/>

            {((currentUser.cliente.oficialCto && currentUser.id === currentUser.cliente.oficialCto.id) ||
                (currentUser.modules && currentUser.modules.includes("OFCTO"))
              ) &&
              <AlertAssignmentSection alert={alert} refreshHandler={handleRefreshTasks}/>
            }

            {alert?.status !== "CLOSED"
              &&((currentUser.cliente.oficialCto && currentUser.id === currentUser.cliente.oficialCto.id) ||
                  (currentUser.modules && currentUser.modules.includes("OFCTO"))
                ) &&
              <AlertCloseSection alert={alert} refreshHandler={handleRefreshTasks} falsosPositivos={falsosPositivos}/>
            }

            {alert?.status !== "CLOSED" &&
              <TasksCreatorSection alert={alert} refreshHandler={handleRefreshTasks}/>
            }
            {alert !== null && alert.tasks !== null && alert.tasks.length > 0 &&
              <TaskRevision alert={alert} currentUser={currentUser} origin="aml" refreshHandler={handleRefreshTasks}/>
            }

            {alert?.status === "CLOSED"
              &&((currentUser.cliente.oficialCto && currentUser.id === currentUser.cliente.oficialCto.id) ||
                  (currentUser.modules && currentUser.modules.includes("OFCTO"))
                ) &&
              <SuspiciousOperationSection alert={alert} currentUser={currentUser} refreshHandler={handleRefreshTasks}/>
            }

            <HistoryTaskSection alert={alert} origin="aml"/>
          </div>
          :
          <div style={{textAlign: 'center', padding: 100}}>
            <Spin/>
          </div>
        }
    </div>
  );
};

export default ModalAlertCard;