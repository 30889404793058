import React, {useState} from 'react';
import {Row, Col, Button, Icon, Select, Input, Modal, message} from 'antd'
import { useTranslation } from 'react-i18next';
import {saveRegStatePromise} from '../../../../promises'

const CambioEstado = ({currentUser, itemTable}) => {
    const [seeMore, setSeeMore] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [statusComments, setStatusComments] = useState();

    const { t } = useTranslation();

    const varStateOptions = (actualState) => {
        var options = [];
        if (actualState === null)  {
            options.push({val: "AUTORIZADA", label: "Autorizado"}, {val: "RECHAZADA", label: "Rechazado"})
        }
        if (actualState === 'AUTORIZADA'){
            options.push({val: "RECHAZADA", label: "Rechazado"});
        }else if(actualState === 'RECHAZADA'){
            options.push({val: "AUTORIZADA", label: "Autorizado"});
        }
        return options
    }

    const handleSaveStatus = () => {
        saveRegStatePromise(itemTable.id, selectedStatus, statusComments).then((response) => {
            if(response){
                message.success("Se ha guardado correctamente el estado del registro");
                setSelectedStatus(null);
            }else{
                message.error("Ha ocurrido un error al guardar el estado del registro")
                setSelectedStatus(null);
            }
        })
    }


    return (
        <div>
            <Row>
                <Col span={22}>
                    <h3>Cambio de estado</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                    <Button type="link" onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ?
                        <>Ver menos <Icon type="minus"/></>
                        :
                        <>Ver más <Icon type="plus"/></>
                    }
                    </Button>
                </Col>
            </Row>
            <hr/>
            {seeMore &&
            <div className="status-box div-more-info" style={{padding: 20}}>
                <Row gutter={[68, 0]}>
                    <Col span={6} >
                        <h3>Modifique el estado</h3>
                    </Col>
                    <Col span={8} push={10}>
                        <Select
                            style={{width: '100%'}}
                            placeholder="Seleccionar Estado"
                            defaultValue={itemTable.statusRec}
                            onChange={(e) => setSelectedStatus(e)}
                            // disabled={item.statusDecl === "EVALUACION" ? false : true} (se activa disabled si se cambia el estado)
                            >
                                {varStateOptions(itemTable.statusRec).map(option =>
                                    <Select.Option value={option.val}>{option.label}</Select.Option>
                                )
                                }
                        </Select>
                    </Col>
                </Row>
                {selectedStatus &&
                <Modal
                    title="Cambio de estado"
                    centered
                    width={700}
                    visible="true"
                    onCancel={() => setSelectedStatus(null)}
                    footer={[
                    <Button key="save" onClick={() => handleSaveStatus()}>Guardar</Button>
                    ]}
                    >
                    <Row>
                        <Col span={24}>
                        Ha solicitado cambiar el estado del formulario a {t("messages.aml.formKycStatus."+selectedStatus)}. <br/>
                        Agregue comentarios para dejar registro de este evento los cuales se
                        guardarán automáticamente en el historial de
                        comentarios asociado a este formulario.
                        </Col>
                    </Row>
                    <Row gutter={[0, 32]}>
                        <Col span={24}>
                        <Input.TextArea placeholder="Agregue comentarios. Máximo 1000 caractéres" onChange={(e) => setStatusComments(e.target.value)} />
                        </Col>
                    </Row>
                </Modal>
                }
            </div>
            }
        </div>
    );
};

export default CambioEstado;