import './TopBar.scss'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Row, Col, message, Tooltip } from 'antd'
import { QuerySearchBox } from '../../components'

  const TopBar = ({ addRegister, onSearch, onClickAddToClient, onClickReport, isLoadingReport, activeTab, personCompliance, personUbosControllers, collapsed, toggleCollapsed }) => {
  const { t } = useTranslation();

  const copyTable = () => {
    let elTable = null;
    if(activeTab === 'nav-item-4'){
      elTable = document.getElementById('tableUbos') ?? document.getElementById('tablePsoc') ?? document.getElementById('tableDir')
    }else if (activeTab === 'nav-item-5'){
      elTable = document.getElementById('finBenTable')
    }

    let range, sel;
    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
      document.execCommand('copy');
    }
    sel.removeAllRanges();
    message.success("Tabla copiada correctamente")
  }

  const isVisibleBtnCopy = () => {
    if(activeTab === 'nav-item-4') {
      if(personCompliance.type === 'Entity') {
        if(personUbosControllers?.ubos?.filter(u => u.ubo).length > 0) {
          return true
        }
      }else {
        if(personUbosControllers?.psoc?.length > 0) {
          return true
        }
      }
      if(personUbosControllers?.directores?.length > 0) {
        return true
      }
    } else if(activeTab === 'nav-item-5') {
      if(personUbosControllers.ubos && personUbosControllers.ubos.length > 0) {
        return true
      }
    }
    return false
  }

  return (
    <div className="topbar">
      <Row>
        <Col span={2} >
          <Button disabled={isLoadingReport} type="primary" onClick={ onClickReport } >
            <Icon type={ isLoadingReport ? 'loading': 'file-pdf' }/>
            { t('messages.aml.viewPdf') }
          </Button>
        </Col>
        { addRegister === true &&
          <Col span={4} offset={1}>
            <Button type="primary" onClick={ onClickAddToClient }><Icon type="plus" /> { t('messages.aml.addToClientsList') }</Button>
          </Col>
        }
        <Col span={4} offset={1}>
          { personUbosControllers.status === "OK" && isVisibleBtnCopy() &&
            <Button  type="primary" icon='copy' onClick={copyTable}>{ t('messages.aml.copyTable') }</Button>
          }
        </Col>
        <Col span={4} offset={addRegister ? 6 : 11}>
          <QuerySearchBox  onSearch={ onSearch } />
        </Col>
        <Col span={2}>
          <div className='btn-collapsed-menu-wrapper'>
            <Tooltip title="Consultas recientes">
              <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
                <Icon type={collapsed ? 'arrow-right' : 'arrow-left'} />
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TopBar