import { negativeListService } from '../services'

export default (recordList) => {
    return new Promise(async (resolve) => {
        await negativeListService.updateNegativeRecord(recordList)
            .then(response => {
                resolve(response.data)
            })
    })
}
