import { AuthProvider } from 'react-oidc-context';
import { LoginOauthPage } from '..';

export default function AuthLogin({successHandler, config}) {

    const oidcConfig = {
        authority: config.authority,
        client_id: config.clientId,
        redirect_uri: document.location.href,
        response_type: 'code',
        scope: 'openid profile email'
    };

    return (
        <AuthProvider {...oidcConfig}>
            <LoginOauthPage successHandler={successHandler} />
        </AuthProvider>
    )
}