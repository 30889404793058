import './SearchBox2.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'antd'


export default ({ currentQuery, onChange, onSearch, loading }) => {
  const { t } = useTranslation()
  const { Search } = Input;

  return (
    <div id="searchbox">
      <div class="inputSearch">
        <Search 
          placeholder={t('messages.aml.enterNameOrRut')} 
          onChange={onChange} 
          value={currentQuery} 
          onSearch={onSearch} 
          allowClear
          loading={loading}
          enterButton  
          style={{width: 500}}/>
      </div>
    </div>
  )
}

