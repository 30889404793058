import { notification } from 'antd'
import { getAlertByRiskService } from '../services/getAlertByRiskService'

export const getAlertResumeByRisk = (dateRange) => {
	return new Promise(resolve => {
		getAlertByRiskService(dateRange)
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "transactional activity: "+error.message})})
	})
}

