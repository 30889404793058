import './ReportHistory.scss'
import { Button, Col, Icon, Input, Progress, Row, Spin, Table, message } from 'antd';
import React, { useRef } from 'react'
import { Page, PageContent, PageHeader, PageTopBar } from '../../layouts/Private/components'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getReportHistoryDataPromise } from './promises';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import getUsersByClient from '../../promises/getUsersByClient';
import { ReportService } from '../../services';
const ReportHistory = () => {
    const {t} = useTranslation();
    const breadcrumbs = [{ title: t('messages.aml.reportHistory'), icon: 'database', link: '/historial-de-reportes' }];
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [reportData, setReportData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [users, setUsers] = useState(null);
    

    useEffect(() => {
        getUsersByClient().then(response => {
            setUsers(response)
        }).finally(() => setIsLoadingUsers(false))
    }, []);

    useEffect(() => {
        getReportHistoryDataPromise().then(response => {
            const tmpArray = updatedHistoryReport(response);
            setReportData(tmpArray)
        }).finally(() => setIsLoadingHistory(false))


        const interval = setInterval(() => {
            getReportHistoryDataPromise().then(response => {
            const tmpArray = updatedHistoryReport(response);
            setReportData(tmpArray)
            })
        }, 2400)

        return () => {
            clearInterval(interval)
        }
    }, [users]);
    
    const updatedHistoryReport = (response) => {
        let acum = response.map(report => {
            const user = users?.find(user => user.id === report.userId);
                if (user) {
                    return { ...report, name: user.name };
                }
                return report; // Si no se encuentra el usuario, se mantiene igual
            });
        return acum;
    }

    const typeColumnFilters = [
        {
            text: t("messages.aml.report.type.MALLAS"),
            value: "MALLAS"
        },
        {
            text: t("messages.aml.report.type.UBOS"),
            value: "UBOS"
        },
        {
            text: t("messages.aml.report.type.NEG"),
            value: "NEG"
        },
        {
            text: t("messages.aml.report.type.RECORDS"),
            value: "RECORDS"
        },
        {
            text: t("messages.aml.report.type.RISK"),
            value: "RISK"
        },
        {
            text: t("messages.aml.report.type.PDFS"),
            value: "PDFS"
        },
        {
            text: t("messages.aml.report.type.FORMS"),
            value: "FORMS"
        },
        {
            text: t("messages.aml.report.type.ALERT"),
            value: "ALERT"
        },
        {
            text: 't("messages.aml.report.type.DECL")',
            value: "DECL"
        },
    ]

    const checkStatusReport = async (report) => {
        let fileName = ''
        if(report.type === 'RECORDS') fileName = 'registros.xlsx'
        else if(report.type === 'MALLAS') fileName = 'propietarios.csv'
        else if(report.type === 'UBOS') fileName = 'Beneficiarios_Finales.csv'
        else if(report.type === 'RISK') fileName = 'risks.xlsx'
        else if(report.type === 'PDFS') fileName = 'certificados.zip'
        else if(report.type === 'FORMS') fileName = 'formularios.zip'
        else if(report.type === 'ALERT') fileName = 'monitoreo.xlsx'
        else if(report.type === 'NEG') fileName = 'negatividad.txt'
        else if(report.type === 'DECL') fileName = 'Declaraciones.xlsx'
        else if(report.type === 'ONB') fileName = 'Declaraciones.xlsx'
        else if(report.type === 'DECL_REC') fileName = 'Registros.xlsx'

        message.loading({ content: 'Descargando reporte...', duration: 0, key: 'msg-report' })
        await ReportService.read('/getFileReporte', {id: report.id}, null, fileName, {onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total)*100);
        if(progressEvent.total > 0) {
            message.loading({ content: 'Descargando reporte...' + percentCompleted + '%', duration: 0, key: 'msg-report' })
        }
        }})
        message.destroy()
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
      const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
      };

      const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder="Buscar Usuario"
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Row gutter={[12]}>
                <Col span={12}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{
                        width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                        width: 90,
                        }}
                    >
                        Borrar
                    </Button>
                </Col>
            </Row>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon type="search"
            style={{
              color: filtered ? '#1677ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });

    const reportHistoryColumns = [
        {
            key: "name",
            dataIndex: "name",
            title: "Usuario",
            align: "center",
            width: 180,
            ...getColumnSearchProps("name")
        },
        {
            key: "type",
            title: "Tipo de reporte",
            align: "center",
            dataIndex: "type",
            width: 180,
            filters: typeColumnFilters,
            onFilter: (value, record) => record.type.includes(value),
            sorter: (a, b) => a.type.localeCompare(b.type),
            sortDirections: ['descend'],
            // sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
            render: (type) => <span>{t("messages.aml.report.type."+type)}</span>
        },
        {
            key: "creationDate",
            title: "Fecha",
            align: "center",
            width: 100,
            dataIndex: "creationDate",
            render: (creationDate) => {
                return <span>{moment(creationDate).format('DD/MM/YYYY')}</span>
            }
        },
        {
            key: "lines",
            title: "Total de Filas",
            align: "center",
            width: 100,
            dataIndex: "lines"
        },
        {
            key: "progressBar",
            title: "Progreso",
            width: 150,
            align: "center",
            render: (row) => {
                return <Progress status={row.status === "PROCESSING" ? "active" : ""} percent={Math.floor((row.processed / row.lines) * 100)} size="small" />
            }
        },
        {
            key: "estimatedTime",
            title: "Tiempo estimado de descarga",
            width: 120,
            align: "center",
            render: (record) => {
                if (record.processed > 0 && record.lines > 0) {
                    const linesPerSecond = record.processed / ((Date.now() - record.creationDate) / 1000);
                    const remainingLines = record.lines - record.processed;
                    const remainingTimeInSeconds = remainingLines / linesPerSecond;

                    if(remainingTimeInSeconds < 3600 && remainingTimeInSeconds > 60){
                        return <span>{Math.floor(remainingTimeInSeconds / 60)} min.</span>;
                    }else if (remainingTimeInSeconds > 3600){
                        const formatoHoras = Math.floor(remainingTimeInSeconds / 3600);
                        const formatoMinutos = Math.floor((remainingTimeInSeconds % 3600) / 60);
                        return <span>{formatoHoras+" hrs. "+formatoMinutos+" min."}</span>
                    }else if(remainingTimeInSeconds === 0){
                        return <span>Finalizado</span>
                    }else{
                        return <span>{Math.floor(remainingTimeInSeconds)} seg.</span>;
                    }
                }else{
                    return "Calculando..."
                }
            }

        },
        {
            key: "downloads",
            title: "Descarga",
            width: 120,
            align: "center",
            render: (record) => {
                if(record.status === 'FINISHED' || record.status === 'DOWNLOADED') {
                return <Icon style={{fontSize: 20}} type="download" onClick={() => checkStatusReport(record)}/>
                }
            }
        },

    ]

    
    
  return (
    <div className='report-history'>
        <PageTopBar breadcrumbs={ breadcrumbs } />
        <Page>
            <PageHeader
                title={ t('messages.aml.reportHistory') }
                description={ t('messages.aml.administracion.headerPageDescription')}
                icon="database"
            />
            <PageContent>
                {(isLoadingUsers && isLoadingHistory) ?
                    <div className='spin-loading-wrapper'>
                        <Spin size="large"/>
                    </div>
                    :
                    <Table 
                        columns={reportHistoryColumns} 
                        dataSource={reportData}
                        bordered
                        // pagination={false}
                        // scroll={{y: 700}}
                    />
                }
            </PageContent>
        </Page>
        
    </div>
  )
}

export default ReportHistory;
