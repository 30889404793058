import './Monitoring.scss'
import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Content, Sidebar, Wrapper} from './layout'
import {Page, PageBottomBar, PageContent, PageHeader, PageTopBar} from '../../layouts/Private/components'
import {TabAlerts, TabRules, TabSimulator} from './components'
import {TabReports} from './tabs'
import {getCategoriesClientPromise} from './promises'
import {withTranslation} from "react-i18next";
import TabDashboard from "./tabs/TabDashboard/TabDashboard";

class Monitoring extends Component {
    state = {
        activeTab: 'tab-dashboard',
        breadcrumbs: this.getBreadcrumbs(),
        categories: [],
        loadCategories: false,
        randomKey: 0,
        defaultFilters: { states: ['OPEN']},
        keySidebar: 1,
        showTabAlertas: false,
        fromDashboard: false,
        filters: {},
    }

    componentDidMount() {

        const {match} = this.props;

        getCategoriesClientPromise().then((categories) => {
            this.setState({categories, loadCategories: true})
        })

        if (match.url.startsWith('/monitoreo/alertas') && match.params.category !== undefined && match.params.category !== null) {
            this.setState({activeTab: 'tab-alerts-' + match.params.category})
        } else if (match.url.startsWith('/monitoreo/eventos')) {
            this.setState({activeTab: 'tab-custom-alerts'})
        } else {
            this.setState({activeTab: 'tab-dashboard'})
        }

        let { filters } = this.state;
        if (match.params && match.params.params) {
            const p = JSON.parse(atob(match.params.params));
            filters = {...filters, ...p};
            this.setState({
                defaultFilters: filters,
            });
            this.setState({activeTab: filters.activeTab})
        }else {
            this.setState({activeTab: 'tab-alerts'})
        }
    }

    getBreadcrumbs() {
        const {t} = this.props

        const breadcrumbs = [
            {title: 'Monitoreo', icon: 'branches', link: '/monitoreo'}
        ]

        return breadcrumbs
    }

    handleTabChange(activeTab, filters) {
        if(this.state.activeTab !== activeTab && activeTab === 'tab-alerts' && this.state.fromDashboard) {
            this.setState({fromDashboard: false, defaultFilters: { states: ['OPEN']}, keySidebar: Math.random()})
        }
        this.setState({activeTab, filters, randomKey: Math.random()})
    }

    handleTabAlertas(filters, module='alerts') {
        window.scrollTo(0, 0)
        if(module === 'alerts') {
            this.setState({activeTab: 'tab-alerts', fromDashboard: true, showTabAlertas: true, filters, defaultFilters: filters, keySidebar: Math.random()})
        }else if(module === 'records') {
            const { history } = this.props
            const params = btoa(JSON.stringify(filters))
            history.push("/registro/" + params)
        }
    }

    handleChangeShowTabAlertas(show) {
        this.setState({showTabAlertas: show})
    }

    render() {
        const {activeTab, breadcrumbs, categories, loadCategories, defaultFilters, keySidebar, showTabAlertas} = this.state
        const {currentUser, t} = this.props

        return (
            <div className="monitoring">
                <PageTopBar breadcrumbs={breadcrumbs}/>
                <Page>
                    <PageHeader
                        title={t("messages.aml.monitoring")}
                        description={t("messages.aml.monitoringDescription")}
                        icon="branches" />

                    <PageContent>
                        <Wrapper>
                            <Sidebar key={keySidebar}
                                     currentUser={currentUser}
                                     activeTab={activeTab}
                                     onTabChange={this.handleTabChange.bind(this)}
                                     categories={categories}
                                     defaultFilters={defaultFilters}
                                     showTabAlertas={showTabAlertas}
                                     handleChangeShowTabAlertas={this.handleChangeShowTabAlertas.bind(this)}
                                     />

                            {loadCategories &&
                                <Content>
                                    {activeTab === 'tab-dashboard' && <TabDashboard currentUser={currentUser} categories={categories} handleTabAlertas={this.handleTabAlertas.bind(this)}/>}

                                    {activeTab === 'tab-reports' && <TabReports categories={categories}/>}

                                    {activeTab === 'tab-alerts' && <TabAlerts key={this.state.randomKey} type='N' currentUser={currentUser} filtersSideBar={defaultFilters}/>}

                                    {activeTab === 'tab-rules' && <TabRules currentUser={currentUser}/>}

                                    {activeTab === 'tab-custom-alerts' && <TabAlerts key={activeTab} type="EVENTO" currentUser={currentUser} categories={categories}/>}

                                    {activeTab === 'tab-simulator' && <TabSimulator type="EVENTO" currentUser={currentUser}/>}
                                </Content>
                            }
                        </Wrapper>
                    </PageContent>
                </Page>
                <PageBottomBar breadcrumbs={breadcrumbs}/>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Monitoring))
