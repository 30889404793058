import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

const Clients = {
  tree: (id) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/clientTree/'+id,
      method: 'post'
    })
  },
  detail: (id) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/clientDetail/'+id,
      method: 'post'
    })
  },
}
export default Clients