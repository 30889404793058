import Clients from './Clients'
import DetailFalsoPositivo from './DetailFalsoPositivo'
import RegisterClient from './RegisterClient'
import UpdateFalsoPositivo from './UpdateFalsoPositivo'
import items from './items'

export const ClientsService = Clients
export const DetailFalsoPositivoService = DetailFalsoPositivo
export const RegisterClientService = RegisterClient
export const UpdateFalsoPositivoService = UpdateFalsoPositivo
export const itemsService = items
