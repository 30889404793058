import ContactoComercialComponent from './ContactoComercial/ContactoComercial.js';
import InfoBasicComponent from './InfoBasic/InfoBasic';
import InfoTribComponent from './InfoTrib/InfoTrib';
import MonedaExtComponent from './MonedaExt/MonedaExt';
import OrigenFondosComponent from './OrigenFondos/OrigenFondos';
import DomicilioComponent from './Domicilio/Domicilio';
import CasaMatrizComponent from './CasaMatriz/CasaMatriz';
import ActividadEcoComponent from './ActividadEco/ActividadEco';
import RepLegalComponent from './RepLegal/RepLegal'
import InfoFinancieraComponent from './InfoFinanciera/InfoFinanciera'
import InfoPepComponent from './InfoPep/InfoPep'
import BenFinalesComponent from './BenFinales/BenFinales'
import InfoComplianceComponent from './InfoCompliance/InfoCompliance.js';
import ParticipacionSocComponent from './ParticipacionSoc/ParticipacionSoc.js';
import ContactoFacturacionComponent from './ContactoFacturacion/ContactoFacturacion.js';
import BasicInfoAdicionalComponent from './BasicInfoAdicional/BasicInfoAdicional.js';
import ActivityModalComponent from './ActivityModal/ActivityModal.jsx'

export const ContactoComercial = ContactoComercialComponent;
export const InfoBasic = InfoBasicComponent;
export const InfoTrib = InfoTribComponent;
export const MonedaExt = MonedaExtComponent;
export const OrigenFondos = OrigenFondosComponent;
export const Domicilio = DomicilioComponent;
export const CasaMatriz = CasaMatrizComponent;
export const ActividadEco = ActividadEcoComponent;
export const RepLegal = RepLegalComponent;
export const InfoFinanciera = InfoFinancieraComponent;
export const InfoPep = InfoPepComponent;
export const BenFinales = BenFinalesComponent;
export const InfoCompliance = InfoComplianceComponent;
export const ParticipacionSoc = ParticipacionSocComponent;
export const ContactoFacturacion = ContactoFacturacionComponent;
export const BasicInfoAdicional = BasicInfoAdicionalComponent;
export const ActivityModal = ActivityModalComponent;