import React, {useState, useEffect} from 'react'
import {Row, Col, Table, Modal} from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ModalAlertCardPage } from '../../../../../'
import {getItemsPromise} from '../../../../../Monitoring/components/TabAlerts/promises'

const AlertItems = ({currentUser, selectedAlertItem, recordId}) => {
    const { t } = useTranslation()
    const [alertRow, setAlertRow] = useState(null);
    const [alertItems, setAlertItems] = useState(null);

    useEffect(() => {
        getItemsPromise('N' ,{ruleId: selectedAlertItem.ruleId, recordId, size: 1000}).then(response => {
            setAlertItems(response.data.records)
        })
    }, []);

    const alertTableColumns = [
        {
            title: "Tipo",
            dataIndex: "type",
            render: (type) => {
            return <div>
                {t("messages.aml.risk."+type)}
            </div>
            }
        },
        {
            title: "Riesgo",
            dataIndex: "score"
        },
        {
            title: "Folio",
            dataIndex: "folio"
        },
        {
            title: "Recepción",
            dataIndex: "creationDate",
            render: (e) => {
            return <div>
                {moment(e ?? "Sin fecha").format('DD/MM/YYYY')}
            </div>
            }
        },
        {
            title: "Estado",
            dataIndex: "status",
            render: (status) => {
            return <div>
                {t("messages.aml.alert.status."+status)}
            </div>
            }
        },
        {
            title: "Fecha de Estado",
            dataIndex: "dateStatus",
            render: (text, obj) => {
            return <div>
                {moment(text ?? obj.creationDate ?? "Sin fecha").format('DD/MM/YYYY')}
            </div>
            }
        },
        {
            title: "Tareas",
            dataIndex: "nroTareas"
        },
    ]

        const closeModalAlertHandler = () =>{ 
            setAlertRow(null)
        }

    return (
        <div>
            <Row gutter={[0, 20]}>
                <Col span={24}>
                A continuación se presentan las alertas en las cuales gatillo la regla {selectedAlertItem.rule}
                </Col>
                <Col span={24}>
                <Table
                    dataSource={alertItems}
                    loading={!alertItems}
                    columns={alertTableColumns}
                    footer={null}
                    pagination={alertItems && alertItems.length > 5 ? { pageSize: 5} : false }
                    rowClassName="selected-alert-row-risk"
                    onRow={(record) => {
                    return {
                        onClick: event => {setAlertRow(record)}
                    };
                    }}
                />
                </Col>
            </Row>
            {alertRow &&
                <Modal
                    className="modal-alert-card"
                    visible={ true }
                    header={ null }
                    footer={ null }
                    onCancel={ closeModalAlertHandler }
                    style={{top: '20px'}}
				    bodyStyle={{padding: '0px 0px 0px 0px'}}
                    width={'95vw'}
                >
                    <ModalAlertCardPage
                        // key={"alert-detail-" + clickedItem.id}
                        // currentPage={currentPage}
                        item={alertRow} 
                        currentUser={currentUser}
                        closeOverlayAlerta={closeModalAlertHandler}
                        // handlePrevItem={getHandlePrevItem()}
                        // handleNextItem={getHandleNextItem()}
                    />
                </Modal>
            }
        </div>
    )
}

export default AlertItems