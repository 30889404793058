import './Header.scss'
import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import {Layout} from 'antd'
import {CurrentUser, Navigation} from '../'
import Logo from '../../../commonComponent/components/Logo/Logo'
import { globalContext } from '../../../../contexts'

const {Header} = Layout

const HeaderComp = ({ logoutHandler }) => {
    const { currentUser, userParams } = useContext(globalContext)
    return (
        <>
        <Header id="header" theme="dark">

            <Logo currentUser={{userId:currentUser.id, subclienteId: (currentUser.subcliente ? currentUser.subcliente.id : 0)}} isForm={false}/>

            {currentUser.cliente.modules.includes('AML2') &&
                <>
                    <Navigation currentUser={currentUser} />
                    <CurrentUser
                        userParams={userParams}
                        currentUser={currentUser}
                        logoutHandler={logoutHandler}
                    />
                </>
            }
        </Header>

    </>
    )
}
export default withRouter(HeaderComp)