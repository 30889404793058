import "./TabDashboard.scss";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import NotificationCard from "./Components/NotificationCard/NotificationCard";
import CategoryRiskCard from "./Components/CategoryRiskCard/CategoryRiskCard";
import ListCategoryRiskCard from "./Components/ListCategoryRiskCard/ListCategoryRiskCard";
import PrincipalRulesCard from "./Components/PrincipalRulesCard/PrincipalRulesCard";
import AlertRiskCard from "./Components/AlertRiskCard/AlertRiskCard";
import ManagedAlertCard from "./Components/ManagedAlertCard/ManagedAlertCard";
import AsignedAlertCard from "./Components/AssignedAlertCard/AsignedAlertCard";
import PrincipalRulesByCategoryCard from "./Components/PrincipalRulesByCategoryCard/PrincipalRulesByCategoryCard";
import PrincipalRegisterByRulesCard from "./Components/PrincipalRegisterByRulesCard/PrincipalRegisterByRulesCard";
import AlertsGeneratedByRegisterCard from "./Components/AlertsGeneratedByRegisterCard/AlertsGeneratedByRegisterCard";

const TabDashboard = ({currentUser, categories, handleTabAlertas }) => {

  const { t } = useTranslation();

  const [generalActiveCategory, setGeneralActiveCategory] = useState(null)

  useEffect(() => {

  }, []);



  const getLevelLabel =(level, textLabel ) => {
    return (
        <>
          <div className="level-indicator">
            <div className={"text-indicator"}>
              {t('messages.aml.monitor.dashboard.level')} {level}: {textLabel}
            </div>
          </div>
        </>
    )
  }
  return (
    <>
      <div className='content-dashboard'>

        {/* Notificaciones*/}
        <div className="section-notifications">
          <NotificationCard currentUser={currentUser} categories={categories} handleTabAlertas={handleTabAlertas} handleSetActiveCategory={setGeneralActiveCategory} />
        </div>

        {/* Etiqueta nivel 1*/}
        <div className="section-level">
          {getLevelLabel(1, t('messages.aml.records'))}
        </div>

        {/* Riesgo */}
        <div className="section-risk">
          <CategoryRiskCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas} />
        </div>
        {/* Listado últimos registros */}
        <div className="section-last-risk">
           <ListCategoryRiskCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas} />
        </div>

        {/* Info activacion principales reglas */}
        <div className="principal-rules">
          <PrincipalRulesCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas}/>
        </div>

        {/* Etiqueta nivel 2*/}
        <div className="section-level">
          {getLevelLabel(2, t('messages.aml.monitor.alertas'))}
        </div>

        {/* Notificaciones*/}
        <div className="section-notifications">
          <AlertRiskCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas} />
        </div>

        {/* Alertas Gestionadas*/}
        <div className="section-managed-alerts">
          <ManagedAlertCard handleTabAlertas={handleTabAlertas} />
        </div>

        {/* Alertas por Registro*/}
        <div className="section-managed-alerts">
          <AlertsGeneratedByRegisterCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas} />
        </div>

        {/* Alertas asignadas*/}
        <div className="section-managed-alerts">
          <AsignedAlertCard handleTabAlertas={handleTabAlertas} />
        </div>


        {/* Etiqueta nivel 3*/}
        <div className="section-rules">
          {getLevelLabel(3, t('messages.aml.monitor.dashboard.level.3'))}
        </div>

        {/* reglas principales por categoría */}
        <div className="section-managed-alerts">
          <PrincipalRulesByCategoryCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas} />
        </div>

        {/* principales categorías por regla */}
        <div className="section-managed-alerts">
          <PrincipalRegisterByRulesCard key={generalActiveCategory} categories={categories} globalActiveCategory={generalActiveCategory} handleTabAlertas={handleTabAlertas} />
        </div>


      </div>
    </>
  );
};

export default TabDashboard
