import React, { useEffect, useState } from 'react';
import {Row, Table } from 'antd';
import getActivityRecordPromise from './promises/getActivityRecord';
import moment from 'moment';
const ActivityModal = ({recordId}) => {

    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    getActivityRecords()
  }, []);

  const getActivityRecords = () => {
    getActivityRecordPromise(recordId).then((response) => {
        setActivities(response)
        setIsLoading(false)
    })
  }

    const activityColumns = [
        {
            title: 'Fecha',
            dataIndex:'dateShortAsString'
        },
        {
            title: 'Usuario',
            dataIndex:'userName'
        },
        {
            title: 'Actividad',
            dataIndex:'text'
        },
        {
            title: 'Comentario',
            dataIndex:'comment'
        },
    ]
    return (
        <Row gutter={[32, 12]}>
            <Table
                size='small'
                columns={activityColumns}
                dataSource={activities}
                loading={isLoading}
            />
        </Row>
    );
};

export default ActivityModal;