import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

export default {
	getCaptchaPublicKey: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getCaptchaPublicKey',
			method: 'post'
		})
	  },
	getEstadoTareasUser: (userId) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getEstadoTareasUser/' + userId,
			method: 'post'
		})
	},
	getUsersRespTareas: (userId) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/portal/getUsersRespTareas/' + userId,
			method: 'post'
		})
	},
	
}