import React, {useEffect, useState} from 'react';
import Plot from 'react-plotly.js';
import {getCdiDeclarationSumaryPromise} from "./promises/getCdiDeclarationSumaryPromise";
import {Col, Divider, Row, Spin} from "antd";
import './CdiDeclarationChart.scss'
import {useTranslation} from "react-i18next";

export const CdiDeclarationChart = ({dateValue, chartColor, handleTabRedirection}) => {

    const [loading, setLoading] = useState(false);
    const [cdiDeclarationData, setCdiDeclarationData] = useState([]);
    const {t} = useTranslation();


    useEffect(() => {
        setLoading(true)
        getCdiDeclarationSumaryPromise({dateValue}).then((response) => {
            setCdiDeclarationData(response.data);
        }).then(() => setLoading(false))

    }, [dateValue]);

    const getColorByName = (colorName) => chartColor.find(color => color.name === colorName)?.color;

    const getPendientes = (data) => { return  data.reduce((total, item) => total + (item.total - item.completadas), 0);}

    const getVigentes = (data) => {return data.reduce((total, item) => total + item.completadas, 0);}

    const categorias = (cdiDeclarationData.map(item => t(`messages.aml.category.${item.categoria}`)));
    const verde = (cdiDeclarationData.map(item => item.total - (item.total - item.completadas)));
    const naranjo = (cdiDeclarationData.map(item => item.total - item.completadas));

    const calculateTickInterval = () => {
        const allQuantities = [...verde, ...naranjo];
        const min = Math.min(...allQuantities);
        const max = Math.max(...allQuantities);

        let range = max - min;
        let interval = Math.round(range / 6);

        if (interval < 1) {
            interval = 1;
        }

        return interval;
    };

    const handleClick = (e) => {
        const categoria = e.points[0].label.toUpperCase();
        const status = e.points[0].data.name.toUpperCase();

        let statusSearch = status === "PENDIENTES"? "ENVIADO" : "COMPLETADO"
        handleTabRedirection(getFiltersToRender(categoria, statusSearch), 'daclaration')

    };

    const getFiltersToRender = (categoria, status) => {
        let filters = {
            tab: 'tab-forms',
            status:status,
            categories:[categoria]
        }
        if(dateValue && dateValue.length === 2) {
            filters.sendDate = dateValue;
        }
        return filters;

    };

    return (
        <Spin spinning={loading}>
            <div className='inside-cdi-declaration-bar-chart'>
                <Row type='flex' justify='center' align='middle'>

                    <Col span={18}>
                        { cdiDeclarationData.length === 0 ? (
                        <div className="text-no-content">No hay datos disponibles</div>
                        ) : (
                        <Plot className="rounded-bar-chart"  data={
                            [
                                {
                                    width: 0.6,
                                    x: verde,
                                    y: categorias,
                                    type: 'bar',
                                    orientation: 'h',
                                    name: 'Vigentes',
                                    text: verde,
                                    textposition: 'inside',
                                    textfont: {size: 12, color: '#ffffff'},
                                    textangle: 0,
                                    bargap: 0.1,
                                    showlegend: false,
                                    hovertemplate: '%{x}',
                                    marker: {
                                        color: getColorByName('GREEN'),
                                        width: 0.1,
                                        line: {
                                            width: 0.3,
                                            color: 'black',
                                            shape: 'spline'

                                        },
                                    }
                                },
                                {
                                    width: 0.6,
                                    x: naranjo,
                                    y: categorias,
                                    type: 'bar',
                                    orientation: 'h',
                                    name: 'Pendientes',
                                    text: (naranjo.map(value => value !== 0 ? value : null)),
                                    textposition: 'inside',
                                    textfont: {size: 11, color: '#ffffff'},
                                    textangle: 0,
                                    bargap: 0.9,
                                    showlegend: false,
                                    shape: 'spline',
                                    hovertemplate: '%{x}',
                                    marker: {
                                        color: getColorByName('ORANGE'),
                                        width: 0.1,
                                        line: {
                                            width: 0.3,
                                            color: 'black',
                                            shape: 'spline'
                                        },
                                    },

                                }
                            ]}
                              layout={{
                                  showlegend: false,
                                  hovermode: 'closest',
                                  barmode: 'stack',
                                  paper_bgcolor: 'rgba(0,0,0,0)',
                                  plot_bgcolor: 'rgba(0,0,0,0)',
                                  automargin: false,
                                  gridcolor: 'rgb(187 187 187)',
                                  height: 280,
                                  margin: {
                                      l: 70,
                                      r: 0,
                                      b: 10,
                                      t: 20,
                                  },

                                  xaxis: {
                                      hoverinfo: 'text',
                                      automargin: true,
                                      showgrid: true,
                                      gridcolor: 'rgb(187 187 187)',
                                      tickfont: {
                                          size: 12,
                                          family: 'Arial, sans-serif',
                                          color: 'rgb(103 103 103)',
                                          weight: 'bold'
                                      },
                                      tickmode: 'linear',
                                      tick0: 0,
                                      dtick: calculateTickInterval(),
                                  },

                                  yaxis: {
                                      hoverinfo: 'text',
                                      autoscale: false,
                                      height: 280,
                                      ticklen: 5, //separación entre texto y eje x
                                      tickwidth: 0,
                                      lineTickWidth: 0,
                                      tickcolor: 'rgba(190,4,4,0)',

                                      tickfont: {
                                          size: 9,
                                          family: 'Arial, sans-serif',
                                          color: 'rgb(103 103 103)',
                                          weight: 'bold'
                                      },
                                      showgrid: false,
                                      gridcolor: 'rgb(187 187 187)',
                                  }
                              }}

                              config={{displayModeBar: false}}
                              useResizeHandler={true}
                              style={{width: "100%"}}
                              onClick= {handleClick}

                        />)}
                    </Col>

                    <Col span={6}>
                        <Col span={24}>
                            <div className='big-number-inside'>
                                {getVigentes(cdiDeclarationData)}
                            </div>
                            <Divider>
                                Vigentes
                            </Divider>
                        </Col>
                        <Col span={24}>
                            <div className='big-number-inside' style={{color: '#ee220c'}}>
                                {getPendientes(cdiDeclarationData)}
                            </div>
                            <Divider>
                                Pendientes
                            </Divider>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Spin>

    )
}
