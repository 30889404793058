import './ModalClientCard.scss'
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Button, Col, Icon, Modal, notification, Row, Spin, Table} from 'antd'
import {ModalPdfViewer} from '../../../InterestConflicts/components'
import {getC57FormHistoryPromise, getParamsPromise} from '../../../AdminParams/promises'
import {getClientDetailPromise, saveRegisterClientPromise, saveCommentsPromise} from '../../promises'
import {getRegionComunaPromise} from '../../../../promises'
import {getCountriesCodePromise} from '../../../OnBoarding/promises'
import {
  AnalisisRiesgo,
  CambioEstado,
  Comentarios,
  FichaCliente,
  InfoCdi,
  InvestigacionCdi,
  MonitoreoQuiebras,
  Relacionados,
  Servicios,
  UboFinder,
  Acciones
} from './components'

const ModalClientCard = ({item, handleCancel, currentUser, areas, users, parametersClient}) => {
  const { t } = useTranslation()
  const [itemTable, setItemTable] = useState({status: 'ACTIVE', type: 'Person', category: 'CLIENTE', formularioInterno: true, subcliente: currentUser.subcliente, dateBirth: null, hasMonitoring: true});
  const [seeMore, setSeeMore] = useState(false);
  const [record, setRecord] = useState(null);
  const [formId, setFormId] = useState(null);
  const [mode, setMode] = useState('new');
  const [countries, setCountries] = useState([]);
  const [repLegales, setRepLegales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [uboFinderParams, setUboFinderParams] = useState({});
  const [groups, setGroups] = useState([]);
  const [riskLocalStatus, setRiskLocalStatus] = useState(null);
  const [formDataComments, setFormDataComments] = useState(null);

  useEffect(() => {
    loadInit()
  }, [])


  const loadInit = async () => {
    const paises = await getCountriesCodePromise()
    setCountries(paises)
    let client = item;
    let mode = 'edit' // view para modo lectura
    if(client.id === undefined) {
      client = { ...itemTable, ...client }
      if(item.type === undefined) {
        mode = 'new'
      }else {
        mode = 'newFilled'
        if(client.extId && client.rut) {
          if ((currentUser.cliente.pais === 'CHI' && client.pais !== 'CHI')
            ||
            (currentUser.cliente.pais === 'PER' && client.pais !== 'PER')
            ||
            (currentUser.cliente.pais === 'CR' && client.pais !== 'CR')
            ||
            (currentUser.cliente.pais === 'COL' && client.pais !== 'COL')
      ) {
            client.params = client.pais + '-' + client.rut
            client.rut = 'EXTRANJERO'
          }
        }
        if(client.iso3) {
          const p = paises.filter(p => p.iso3 === client.iso3);
          if(p && p.length === 1) {
            client.pais = p[0].country
          }
        }
      }
      client.userAsig = {id: currentUser.id}

      setRepLegales([{}]);
      setItemTable(client)
    }else{
      await loadClient(paises)
    }
    setMode(mode)

    if(!parametersClient) {
      const params = await getParamsPromise()
      setUboFinderParams(params.ubofinder)
      setGroups(params.gruposNames)
    }else {
      setUboFinderParams(parametersClient.ubofinder)
      setGroups(parametersClient.gruposNames)
    }
    setIsLoading(false)
  }


  const handleSaveClient = ()  => {
    let rp = repLegales.filter(rec => rec.isValid)
    let itemRecord = {...itemTable,repLegales: rp }
    if(riskLocalStatus) {
      itemRecord = {...itemRecord, ...riskLocalStatus}
    }
    saveRegisterClientPromise(itemRecord).then(response => {
      if(response.status === 'OK') {
        notification.success({
          message: t('messages.aml.successfulOperation'),
          description: t('messages.aml.dataSavedSuccessfully')
        })
        if((mode === 'new' || mode === 'newFilled') && formDataComments) {
          formDataComments.append('formId', response.id)

          saveCommentsPromise(formDataComments).then(r => {
            handleCancel();
          });
        }else {
          handleCancel();
        }
      }else {
        notification.error({
          message: t('messages.aml.notifications.anErrorOcurred'),
          description: response.message
        })
      }
    })
  }

  const handleEditClient = () => {
    setMode('edit')
  }

  const loadClient = async (paises) => {
    let record = await getClientDetailPromise(item.id)
    setItemTable(record.clCliente)
    setRecord(record)

    let client = record.clCliente
    if(client.repLegales) {
        client.repLegales.map((rl) => {
        rl.isValid=true 
        rl.isNew=false
      })
      setRepLegales(client.repLegales)
    }

    if(!paises) paises = countries
    if(client.paisDir) {
      let pais = paises.filter(p => p.country === client.paisDir)
      if(pais && pais.length === 1) {
        pais = pais[0].iso2
        getRegionComunaPromise(pais).then((response) => {
          setRegiones(response)
          if(client.region) {
            let comunas = response.filter(e => e.name === client.region)
            if (comunas && comunas.length > 0){
              setComunas(comunas[0].comunas)
            }else{
              setComunas([])
            }
          }
        })
        }
    }
  }

  const handleRefreshItem = () => {
      loadClient()
  }

  const handlePdfViewer = (id) => {
    setFormId(id);
  }

  const onCancelPdfViewer = () => {
		setFormId(null);
  }

  const formsColumns = [
      {
        title: "Folio",
        dataIndex: 'folio'
      },
      {
        title: "Solicitud",
        dataIndex: 'creationDate',
        sorter: (a, b) => a.creationDate - b.creationDate,
        render: (date) => {
          return moment(date).format('DD/MM/YYYY')
        }
      },
      {
        title: "Estado",
        dataIndex: 'statusDecl',
        sorter: (a, b) => a.statusDecl < b.statusDecl,
        render: (status) => {
          return t("messages.aml.formKycStatus." + status)
        }
      },
      {
        title: "Fecha estado",
        dataIndex: 'statusDate',
        sorter: (a, b) => a.statusDate - b.statusDate,
        render: (date) => {
          return moment(date).format('DD/MM/YYYY')
        }
      },
      {
        title: "Recordatorio",
        dataIndex: 'lastReminder',
        render: (date, record) => {
          return date ? record.nroReminders+" - "+ moment(date).format('DD/MM/YYYY') : "Sin Recordatorio"
        }
      },
      {
        title: "Ver",
        dataIndex: 'id',
        render: (id, record) => {
          return record.statusDecl !== 'PENDIENTE' && <Icon type="file-pdf" onClick={ () => handlePdfViewer(id) } style={{fontSize: '20px'}}/>
        }
      }
    ]


  const handleOnChange = (key, value, item) => {
    if (key === 'type' && itemTable.id === undefined) {
      itemTable.processUF = false
      itemTable.formularioInterno = true
    }else if (key === 'paisDir') {
      if (item && item.props && item.props.code) {
        getRegionComunaPromise(item.props.code).then((response) => {
          setRegiones(response)
        })
      }else {
        setRegiones([])
      }
      setComunas([])
    }else if (key === 'region'){
      if (value && regiones){
        let comunas = regiones.filter(region => region.name === value)
        if (comunas && comunas.length > 0){
          setComunas(comunas[0].comunas)
        }else{
          setComunas([])
        }
      }
    }

    setItemTable({...itemTable, [key]: value})
  }

  const saveComments = (formData) => {
    setFormDataComments(formData)
  }

    return (
      <div className="modClientCard">
        { isLoading ? 
          <Row style={{textAlign:'center', padding:700}}>
            <Spin size="large"/>
          </Row>
          :
          <>
            <FichaCliente
              currentUser={currentUser}
              item={itemTable}
              handleSaveClient={handleSaveClient}
              handleEditClient = {handleEditClient}
              handleOnChange={handleOnChange}
              countries = {countries}
              mode = {mode}
              groups= {groups}
              regiones={regiones}
              comunas={comunas}
              areas={areas}
              users={users}
            />
            { itemTable?.type === 'Entity' &&
              <Relacionados
                currentUser={currentUser}
                repLegales={repLegales}
                setRepLegales={setRepLegales}
                mode={mode}
                countries={countries}
              />
            }
            { mode !== 'view' && (currentUser.cliente.modules.includes('QUIEBRA') || currentUser.cliente.modules.includes('ONBOARDING') || (itemTable.type === 'Entity' && currentUser.cliente.modules.includes('ADMCLI'))) &&
              <Servicios
                item={itemTable}
                currentUser={currentUser}
                handleOnChange={handleOnChange}
                uboFinderParams={uboFinderParams}
                loadClient = {loadClient}
                key={Math.random()}
              />
            }
            { itemTable.category && !itemTable.id && (mode === 'new' || mode === 'newFilled') && (currentUser.cliente.modules.includes("CDI-FORM") || currentUser.cliente.modules.includes("ONBOARDING")) &&
              <Acciones
                  item={itemTable}
                  currentUser={currentUser}
                  handleOnChange={handleOnChange}
                  uboFinderParams={uboFinderParams}
                  loadClient = {loadClient}
                  key={Math.random()}
              />
            }
            {(mode === 'view' || mode === 'edit') &&
              <AnalisisRiesgo
                record={record}
                currentUser={currentUser}
                handleRefreshItem = {handleRefreshItem}
                setRiskLocalStatus = {setRiskLocalStatus}
              />
            }
            { (mode === 'view' || mode === 'edit') && (currentUser.cliente.modules.includes('ONBOARDING') || currentUser.cliente.modules.includes('CDI-FORM')) && currentUser.cliente.modules.includes('OFCTO') &&
              <CambioEstado
                currentUser={currentUser}
                itemTable={itemTable}
              />
            }
            <Comentarios
              item={itemTable}
              saveComments={saveComments}
            />
            { (mode === 'view' || mode === 'edit') && currentUser.cliente.modules.includes('CDI-MATCH') && currentUser.modules.includes('CDI-MATCH') &&
              <InvestigacionCdi itemTable={itemTable}/>
            }
            {mode=== 'edit' && currentUser.cliente.modules.includes('CDI-FORM') && currentUser.modules.includes('CDI-FORM') &&
              <InfoCdi
                item={itemTable}
              />
            }
            {/* {(mode === 'view' || mode === 'edit') && currentUser.cliente.modules.includes('MONITOR') && currentUser.modules.includes('MONITOR') &&
              <Monitoreo
                itemTable={itemTable}
                currentUser={currentUser}
              />
            } */}
            {(mode === 'view' || mode === 'edit') && currentUser.cliente.modules.includes('QUIEBRA') && currentUser.modules.includes('QUIEBRA') && itemTable.hasQuiebras &&
              <MonitoreoQuiebras
                itemTable={itemTable}
              />
            }
            {itemTable?.type === 'Entity' && currentUser.cliente.modules.includes('ADMCLI')
              && uboFinderParams
              && ((itemTable.processUF  && !uboFinderParams.noInvestigaMalla)
                ||
                (!itemTable.formularioInterno && !uboFinderParams.sinFormulario))
              && (mode === 'view' || mode === 'edit') &&
              <UboFinder
                itemRecord={record}
                currentUser={currentUser}
                parametersClient={parametersClient}
              />
            }
            
            {(mode === 'view' || mode === 'edit') && itemTable.ficha && currentUser.cliente.modules.includes('ONBOARDING') && currentUser.modules.includes('ONBOARDING') &&
            <>
              <Row>
                <Col span={22}>
                  <h3>Formularios de Onboarding</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                  <Button type="link" onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ?
                      <>Ver menos <Icon type="minus"/></>
                      :
                      <>Ver más <Icon type="plus"/></>
                    }
                  </Button>
                </Col>
              </Row>
              <hr/>

              {seeMore &&
                <div className="div-more-info" style={{padding: 10}}>
                  <Row className="field-section-row">
                    <Col span={3}>
                      <div className="formCard-onb-modal">
                        Solicitudes <br/>
                        {itemTable.ficha?.forms.length}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className="formCard-onb-modal">
                        Pendientes <br/>
                        {itemTable.ficha?.forms.filter(form => form.statusDecl === 'PENDIENTE').length}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className="formCard-onb-modal">
                        Realizadas <br/>
                        {itemTable.ficha?.forms.filter(form => form.statusDecl === 'EVALUACION').length}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className="formCard-onb-modal">
                        Autorizadas <br/>
                        {itemTable.ficha?.forms.filter(form => form.statusDecl === 'AUTORIZADA').length}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div className="formCard-onb-modal">
                        Rechazadas <br/>
                        {itemTable.ficha?.forms.filter(form => form.statusDecl === 'RECHAZADA').length}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Table
                        columns={formsColumns}
                        dataSource={itemTable.ficha?.forms}
                        pagination={itemTable.ficha?.forms.length > 5 ? { pageSize: 5, size:"small" } : false}
                      />
                      {formId &&
                        <Modal
                          className="modal-pdf-viewer"
                          title="Formulario"
                          centered={true}
                          width={1000}
                          header={null}
                          footer={false}
                          onCancel={onCancelPdfViewer}
                          visible={true}
                        >
                            <ModalPdfViewer
                            pdfId={formId}
                            />
                        </Modal>
                      }
                    </Col>
                  </Row>
                </div>
              }
            </>
            }
          </>
        }
      </div>
    );
}
export default ModalClientCard;