import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ShortCardDashobard from "../CardDashboard/ShortCardDashobard/ShortCardDashobard";
import {Col, DatePicker, Row, Select, Spin} from "antd";
import './AlertRiskCard.scss'
import { getResumeAlertsPromise, getResumeAlertsByCategoryPromise } from "../../../../promises";
import moment from "moment";


const AlertRiskCard = ({categories, globalActiveCategory, handleTabAlertas}) => {

    const {t} = useTranslation()

    const [alertResume, setAlertResume] = useState([]);
    const [activeCategory, setActiveCategory] = useState(globalActiveCategory)
    const [countAlerts, setCountAlerts] = useState([])
    const [activeAlertType, setActiveAlertType] = useState(null);
    const [activeDateRange, setActiveDateRange] = useState(null);


    let filter = {}

    let filterResume = {
        dateRange: null
    }

    const alertType = ["PERSONA", "DEMOGRAFICA", "TRANSACCIONAL"]

    useEffect(() => {
        handleSearch();
    }, [activeCategory]);

    useEffect(() => {
        handleSearchResume(filterResume);
    }, [activeAlertType, activeDateRange]);

    /* left card */
    const handleSearch = async () => {
        activeCategory ? filter.category = activeCategory : delete filter.category
        getResumeAlertsPromise(filter).then(response => {
            setAlertResume(response.data);
        })
    };

    const getFilters = (risk) => {
        let filters = {risks: [risk]}
        if(activeCategory) filters.categories = [activeCategory]
        return filters
    }

    const getFiltersCategory = (category) => {
        let filters = {categories: [category]}
        if(activeAlertType) filters.types = [activeAlertType]
        if(activeDateRange && activeDateRange.length === 2) filters.fechaAlerta = activeDateRange
        return filters
    }

    const contentAlertRisk = () => {

        let countCriticalRisk = 0
        alertResume.map(resume => {
            if (resume.risk === 4) {
                countCriticalRisk += resume.alertCount
            }
        })

        let countHighRisk = 0
        alertResume.map(resume => {
            if (resume.risk === 3) {
                countHighRisk += resume.alertCount
            }
        })

        return (<>
            <div>
                <Row>
                    <Col span={10}>
                        <Select
                            style={{width: "100%"}}
                            value={activeCategory}
                            onChange={(value) => setActiveCategory(value)}
                        >
                            <Select.Option value={null}> {t('messages.aml.category')} </Select.Option>
                            {categories.map((category, index) =>
                                <Select.Option value={category}>
                                    {t('messages.aml.category.' + category)}
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                </Row>
                <br/>
            </div>

            <div className={"alert-resume-text"}>
                <div>A la fecha existen alertas pendientes de gestionar:</div>
                &nbsp;
                <div>
                { countCriticalRisk > 0 ?
                    <a onClick={() => handleTabAlertas(getFilters(4))}><span className={"notification-count"}>{countCriticalRisk}</span></a>
                :
                    <span className={"notification-count"}>{countCriticalRisk}</span>
                } de riesgo crítico.
                </div>
                <div>
                { countHighRisk > 0 ?
                    <a onClick={() => handleTabAlertas(getFilters(3))}><span className={"notification-count"}>{countHighRisk}</span></a>
                    :
                    <span className={"notification-count"}>{countHighRisk}</span>
                } de riesgo alto.
                </div>
            </div>

        </>)
    }

    /*right card*/
    const handleSearchResume = (filterResume) => {

        filterResume.dateRange = activeDateRange
        filterResume.alertType = activeAlertType

        getResumeAlertsByCategoryPromise(filterResume).then(response => {
            setCountAlerts(response.data)
        })
    }

    const handleSetActiveAlertType = (alertType) => {
        setActiveAlertType(alertType);
    };

    const handleSetActiveDateRange = (dateRange) => {
        setActiveDateRange(dateRange)
    }

    const contentPendingAlerts = () => {

        return (
            <>
                <div>A continuación se presentan las alertas pendientes de gestionar por categoría
                { activeDateRange ? ' en el período.'
                    : ' en el último mes.'
                }
                </div>
                <br/>
                <Row>
                    <div className={"resume-alert-info"}>
                        {
                            countAlerts.map((cont) =>
                                <Col span={12}>
                                    {cont.category && 
                                        <a onClick={() => handleTabAlertas(getFiltersCategory(cont.category))}>
                                            <div className={"count-info"}>
                                                {t('messages.aml.category.' + cont.category)} : {cont?.totalCount}
                                            </div>
                                        </a>
                                    }
                                </Col>
                            )}

                        {countAlerts?.length === 0 &&
                            (<div>Sin información. </div>)
                        }
                    </div>
                </Row>
                <br/>
                <Row>
                    <Col span={12}>
                        <DatePicker.RangePicker
                            placeholder={[t('messages.aml.from'), t('messages.aml.to')]}
                            style={{width: "100%"}}
                            value={
                                activeDateRange
                                ? [
                                    moment(activeDateRange[0]),
                                    moment(activeDateRange[1]),
                                    ]
                                : null
                            }
                            onChange={momentData => {
                                handleSetActiveDateRange(
                                    momentData !== null && momentData.length > 0 ?
                                        [
                                            moment(momentData[0]).valueOf(),
                                            moment(momentData[1]).valueOf(),
                                        ]
                                        : null)
                            }}
                        />
                    </Col>

                    <Col span={8} offset={3}>
                        <Select
                            style={{width: "100%"}}
                            value={activeAlertType}
                            placeholder={"Tipo de alerta"}
                            onChange={value => handleSetActiveAlertType(value)}
                        >
                            <Select.Option value={null}>Tipo de alerta</Select.Option>
                            {alertType.map((type, index) => (
                                <Select.Option value={type}>
                                    {t('messages.aml.compliance.' + type)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>

                </Row>
            </>)
    }

    return (
        <>
            <div className='alert-info-card'>
                <Row gutter={16} type="flex">

                    <Col span={12}>
                        <div className="left-card">
                            {!alertResume ?
                                <div className="cake-is-loading">
                                    <Spin spinning={true}/>
                                </div> :
                                <ShortCardDashobard
                                    title={t('Alertas de riesgo Crítico y Alto')}
                                    content={contentAlertRisk()}
                                />}
                        </div>
                    </Col>

                    <Col span={12}>
                        <div className="right-card">
                            {!alertResume ?
                                <div className="cake-is-loading">
                                    <Spin spinning={true}/>
                                </div>
                                :
                                <ShortCardDashobard
                                    title={t('Alertas pendientes por categoría')}
                                    content={contentPendingAlerts()}
                                />}
                        </div>
                    </Col>
                </Row>
            </div>

        </>

    )
}

export default AlertRiskCard;