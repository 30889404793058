import './Content.scss'
import React from 'react'

const Content = ({ children }) => {
  return (
    <div id="register-content" className="content">
      { children }
    </div>
  )
}

export default Content
