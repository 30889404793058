import './PersonCard.scss'
import React from 'react'
import entityIcon from './entity-icon-2.png'
import personIcon from './person-icon-2.png'
import { camelizerHelper } from '../../../../helpers'
import { Col, Icon, Row, Button } from 'antd'
import moment from 'moment'

const PersonCard = ({ currentUser, onClick, person }) => {
  return (
    <div className={ 'person-card-query-risk ' + ((person.certificados?.length > 0 || person.hasPdf === true || person.compliance) ? 'has-click' : '') } onClick={ () => onClick(person, person.id, person.name, person.formatRut, person.type) }>
      <Row>
        <Col xs={ currentUser.cliente.pais === 'CHI' || currentUser.cliente.pais === 'PER' ? 6 : 8 }>
          <div className="col-inner person-info">
            <div className="person-info-wrapper">
              <div className="person-avatar">
                { person.type === 'Entity' && <div className="icon-wrapper"><img className="person-avatar-icon" src={ entityIcon } alt="" /></div> }
                { person.type === 'Person' && <div className="icon-wrapper"><img className="person-avatar-icon" src={ personIcon } alt="" /></div> }
              </div>
              <div className="person-name-rut">
                <strong>{ camelizerHelper(person.name) }</strong>
                <span className="person-rut">{ person.formatRut }</span>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={ currentUser.cliente.pais === 'CHI' || currentUser.cliente.pais === 'PER' ? 6 : 8 }>
          <div className="col-inner compliance">
            <div className="compliance-wrapper">
              <ul>
                <li>
                  <div className="compliance-group-title">Listas<br />Obligatorias</div>
                  <div className={ person.compliance?.PEPSAN?.bases === null ? 'risk-' + person.compliance?.PEPSAN?.color + ' compliance-group-circle disabled' : 'risk-' + person.compliance?.PEPSAN?.color + ' compliance-group-circle' }>
                    { person.compliance?.PEPSAN?.bases !== null ? person.compliance?.PEPSAN?.bases.length : <Icon type="lock" /> }
                  </div>
                </li>
                <li>
                  <div className="compliance-group-title">KYC<br/>&nbsp;</div>
                  <div className={ person?.compliance?.KYCAME?.bases === null ? 'risk-' + person?.compliance?.KYCAME?.color + ' compliance-group-circle disabled' : 'risk-' + person?.compliance?.KYCAME?.color + ' compliance-group-circle' }>
                    { person.compliance?.KYCAME?.bases !== null ? person.compliance?.KYCAME?.bases.length : <Icon type="lock" /> }
                  </div>
                </li>
                <li>
                  <div className="compliance-group-title">UBO & <br />Companies</div>
                  <div className={ person?.compliance?.UBOCOM?.bases === null ? 'risk-' + person?.compliance?.UBOCOM?.color + ' compliance-group-circle disabled' : 'risk-' + person?.compliance?.UBOCOM?.color + ' compliance-group-circle' }>{ person?.compliance?.UBOCOM?.bases !== null ? person?.compliance?.UBOCOM?.bases.length : <Icon type="lock" /> }</div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xs={ 6 }>
          <div className="col-inner partners-controllers">
            <h3>
              {moment(person?.creationDate).format('DD/MM/YYYY HH:mm')}
            </h3>
          </div>
        </Col>
        <Col span={6}>
          <div className="col-inner relevance">
            { (person.certificados?.length > 0 || person.hasPdf === true || person.compliance) &&
              <Button type='link'>
                Ver Certificado
              </Button>
            }            
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PersonCard;