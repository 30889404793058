import React, {useEffect, useState} from "react"
import './Step3.scss'
import {Form, Row, Col, Select, Button, message, Transfer, Spin} from 'antd'
import {getAreasPromise} from '../../../../promises'
import { getTransferDataPromise } from '../../../../promises'
import { getGruposPromise } from '../../../../../Register/promises'

const Step3 = ({sendFormS3, currentUser, setRecieversGroup2, form, prev}) => {

    const [area, setArea] = useState([])
    const { getFieldDecorator, validateFields, getFieldsError, setFieldsValue } = form;
    const [grupos, setGrupos] = useState([]);
    const [category, setCategory] = useState("CLIENTE");
    const [typePerson, setTypePerson] = useState("Person");
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState();
    const [segment, setSegment] = useState(null);
    // const [company, setCompany] = useState(null);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [transferIsLoading, setTransferIsLoading] = useState(true);

    useEffect(() => {
        setRecieversGroup2([])
    handleOnChange()
    }, [category, typePerson, selectedCompany, selectedArea, segment])

    useEffect(() => {
        getAreasPromise().then(response => {
            setArea(response);
        })
        getGruposPromise('ALL').then(response => {
            setGrupos(response);
        })
    }, []);

    const handleOnChange = () => {
        setTransferIsLoading(true)
        getTransferDataPromise(0, 7000, [category], null, selectedArea, typePerson, segment).then((response) => {
            getFilteredClients(response?.data?.records)
            setTransferIsLoading(false)
        })
    }

      const handleChangeTransfer = (selectedIds) => {
        setSelectedIds(selectedIds);
        let objs = selectedIds.map((item) => {
            return {record: {id: item}}
        })
        setRecieversGroup2({category, recipients: objs})
      };

      const getFilteredClients = (clients) => {
        const resRec = [];
        clients?.map(rec => {
          const data = {
            key: rec.id,
            title: rec.nombre,
          };
          resRec.push(data);
        });
        setFilteredClients(resRec);
      }


    return (
        <div className="step2bd-content">
            <div className="step2bd-title">
                Paso 3.1: Identifique el tipo de destinatario a través de filtros
            </div>
            <div>
                <Form>
                    <Row className="step2bd-field-select" gutter={[30,0]}>
                        <Col span={5}>
                            <Form.Item label="Indique la categoría">
                                { getFieldDecorator('category', {
                                    rules: [{
                                        required: true,
                                        message: 'Indique la Categoría'
                                    }],
                                    initialValue: "CLIENTE"
                                })(
                                    <Select onChange={(value) => setCategory(value)}>
                                        <Select.Option value="CLIENTE">Cliente</Select.Option>
                                        <Select.Option value="COLABORADOR">Colaborador</Select.Option>
                                        <Select.Option value="PROVEEDOR">Proveedor</Select.Option>
                                        <Select.Option value="DIRECTOR">Director</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Tipo de Persona">
                                { getFieldDecorator('typePerson', {
                                    rules: [{
                                        required: true,
                                        message: 'Indique Tipo de persona'
                                    }],
                                    initialValue: "Person"
                                })(
                                    <Select onChange={(value) => setTypePerson(value)}>
                                        <Select.Option value="Person">Persona Natural</Select.Option>
                                        <Select.Option value="Entity">Persona Jurídica</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        { currentUser.cliente.outsourcer && currentUser.cliente.clientes && currentUser.cliente.clientes.length > 0 &&
                          <Col span={5}>
                              <Form.Item label="Empresa">
                                  { getFieldDecorator('company', {
                                      rules: [{
                                          required: false,
                                          message: 'Empresa'
                                      }]
                                  })(
                                      <Select onChange={(value) => setSelectedCompany(value)}>
                                           {(currentUser.empresas !== null && currentUser.empresas.length > 0) ?
                                              currentUser.empresas.map( (c) => <Select.Option value={c.id}>{c.name}</Select.Option>)
                                           :
                                           <Select.Option value="N/A">No tiene empresas</Select.Option>
                                           }
                                      </Select>
                                  )}
                              </Form.Item>
                          </Col>
                        }
                        <Col span={5}>
                            <Form.Item label="Área">
                            { getFieldDecorator('area', {
                                rules: [{
                                    required: false,
                                    message: 'Área'
                                }]
                            })(
                                <Select allowClear onChange={(value) => setSelectedArea(value)}>
                                     {(area.length > 0) ?
                                        area.map( (c) => <Select.Option value={c}>{c}</Select.Option>)
                                     :
                                        <Select.Option value="N/A">No tiene área</Select.Option>
                                     }
                                </Select>
                            )}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Grupo">
                            { getFieldDecorator('segment', {
                                rules: [{
                                    required: false,
                                    message: 'Segmento'
                                }]
                            })(
                                <Select allowClear onChange={(value) => setSegment(value)}>
                                  { grupos.map(g =>
                                    <Select.Option value={g.grupo}>{g.grupo}</Select.Option>
                                  )}
                                </Select>
                            )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="step2bd-title">
                Paso 3.2: Seleccione personas a revisar
            </div>
            {transferIsLoading ? 
                <Spin/>
                :
                <Row>
                    <div className="step3-transfer">
                        <Transfer
                            dataSource={filteredClients}
                            lazy={{ height: 32, offset: 10 }}
                            showSearch
                            filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            listStyle={{
                                width: 380,
                                height: 370,
                            }}
                            operations={['Agregar', 'Eliminar']}
                            targetKeys={selectedIds}
                            onChange={handleChangeTransfer}
                            render={item => item.title}
                        />
                    </div>
                </Row>
            }
            <div className="steps-buttons">
                <Button  onClick={() => prev()}>
                    Atrás
                </Button>
                <Button style={{ marginLeft: 8 }} type="primary" onClick={() => sendFormS3()}>
                    Enviar
                </Button>
            </div>
        </div>
    )
}
export default Form.create()(Step3);
