import FichaClienteComponent from './FichaCliente/FichaCliente';
import AlertItemsComponent from './AlertItems/AlertItems';
import AnalisisRiesgoComponent from './AnalisisRiesgo/AnalisisRiesgo';
import RelacionadosComponent from './Relacionados/Relacionados';
import ServiciosComponent from './Servicios/Servicios';
import CambioEstadoComponent from './CambioEstado/CambioEstado';
import ComentariosComponent from './Comentarios/Comentarios';
import MonitoreoComponent from './Monitoreo/Monitoreo'
import InfoCdiComponent from './InfoCdi/InfoCdi';
import MonitoreoQuiebrasComponent from './MonitoreoQuiebras/MonitoreoQuiebras'
import UboFinderComponent from './UboFinder/UboFinder'
import InvestigacionCdiComponent from './InvestigacionCdi/InvestigacionCdi'
import AccionesComponent from './Acciones/Acciones'

export const FichaCliente = FichaClienteComponent;
export const AlertItems = AlertItemsComponent;
export const AnalisisRiesgo = AnalisisRiesgoComponent;
export const Relacionados = RelacionadosComponent;
export const Servicios = ServiciosComponent;
export const CambioEstado = CambioEstadoComponent;
export const Comentarios = ComentariosComponent;
export const Monitoreo = MonitoreoComponent;
export const InfoCdi = InfoCdiComponent;
export const MonitoreoQuiebras = MonitoreoQuiebrasComponent;
export const UboFinder = UboFinderComponent;
export const InvestigacionCdi = InvestigacionCdiComponent;
export const Acciones = AccionesComponent;
