import { ParamsService } from '../services'

const importSelected = (formData) => {
    return new Promise(async (resolve, reject) => {
        await ParamsService.importSelected(formData)
            .then(response => resolve(response !== undefined ? response.data : []))
            .then(err => reject({ error: true }))
    })
}
export default importSelected;
