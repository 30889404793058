import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { getPendingDeclarationsPromise } from "./promises/getPendingDeclarationsPromise";
import { Col, Row, Spin } from "antd";

export const PendingStatements = ({dateValue, handleTabRedirection}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initFunc();
    }, [dateValue]);

    const initFunc = () => {
        setLoading(true);
        getPendingDeclarationsPromise({ dateValue }).then((response) => {
            const filteredData = response.data.filter(item => item.dias < 25);
            setData(filteredData);
            //setData(response.data);
        }).finally(() => setLoading(false));
    }

    const getTotalPending=(data) => {
        return data.reduce((acc, item) => acc + item.dias, 0);
    }

    const calculateTickInterval = (data) => {
        const values = data.map(item => item.cantidad);
        const min = Math.min(...values);
        const max = Math.max(...values);
        const range = max - min;

        let interval = Math.round(range / 6);
        if (interval < 1) {
            interval = 1;
        }

        return interval;
    };

    const handleClick = (event) => {
        const daysPending = event.points[0].label;
        handleTabRedirection(getFiltersToRender(daysPending), 'daclaration')
    };

    const getFiltersToRender = (days) => {
        let filters = {
            tab: 'tab-forms',
            statusDecl: "PENDIENTE",
            pendingDays: days
        }
        if(dateValue && dateValue.length === 2) {
            filters.sendDate = dateValue;
        }
        return filters;
    };

    return (
        <Spin spinning={loading}>
            <div className='inside-compliance-Pending-declaration-stack'>
                <Row type='flex' justify='left' align='middle'>

                    <Col span={16}>
                        {data.length === 0 ? (
                            <div className="text-no-content">No hay datos disponibles</div>
                        ) : (
                            <Plot
                                data={[{
                                    x: data.map(item => item.dias),
                                    y: data.map(item => item.cantidad),
                                    name:'',
                                    type: 'bar',
                                    textposition: 'inside',
                                    textfont: { size: 12, color: '#ffffff' },
                                    textangle: 0,
                                    bargap: 0.9,
                                    showlegend: false,
                                    hovertemplate: '%{x} Días, %{y} Pendientes',


                                }]}
                                layout={{
                                    hovermode: 'closest',
                                    barmode: 'stack',
                                    paper_bgcolor: 'rgba(0,0,0,0)',
                                    plot_bgcolor: 'rgba(0,0,0,0)',
                                    autoscale: false,
                                    showlegend: true,
                                    height: 360,
                                    margin: {
                                        l: 70,
                                        r: 0,
                                        b: 70,
                                        t: 21,
                                    },
                                    xaxis: {
                                        title: 'Días',
                                        hoverinfo: 'text',
                                        titlefont: {
                                            family: 'Arial, sans-serif',
                                            size: 12,
                                            color: 'rgb(103, 103, 103)'
                                        },
                                        showgrid: true,
                                        gridcolor: 'rgb(187, 187, 187)',
                                        tickmode: 'linear',
                                        tick0: 0,
                                        dtick: calculateTickInterval(data),
                                    },

                                    yaxis: {
                                        title: 'Cantidad declaraciones',
                                        hoverinfo: 'text',
                                        titlefont: {
                                            family: 'Arial, sans-serif',
                                            size: 12,
                                            color: 'rgb(103, 103, 103)'
                                        },
                                        showgrid: false,
                                        gridcolor: 'rgb(187, 187, 187)',
                                    }
                                }}
                                config={{
                                    displayModeBar: false,
                                }}
                                useResizeHandler={true}
                                style={{ width: '100%' }}
                                onClick= {handleClick}

                            />
                        )}
                    </Col>
                    <Col span={8}>
                        <div className='big-number-inside' style={{color: '#ee220c'}}>
                            {getTotalPending(data)}
                        </div>
                        <div className='small-text-relations-cdi'>
                            Pendientes
                        </div>
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

