import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const Params = {
  read: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getParametrosCliente',
      method: 'post'
    })
  },

  save: (params) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/saveParametrosCliente',
      method: 'post',
      body: params
    })
  },

  getCountries: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getCountryFilter',
      method: 'post'
    })
  },
  getNewsLetter: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getNewsLetter',
      method: 'post'
    })
  },
  getNewsGesintel: (currentUser) => {
    let idCategory = 9
    if(currentUser.cliente.pais === 'PER') {
      idCategory = 10
    }else if(currentUser.cliente.pais === 'CR') {
      idCategory = 49
    }
    return apiRequestorHelper({
      url: 'https://www.gesintel.cl/wp-json/wp/v2/posts?_embed&categories='+idCategory+'&per_page=10',
      method: 'get'
    }, false)
  },
  getOptions: (option) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/getOptionsDemoRisk',
      method: 'post',
      body: {option}
    })
  },
  importSelected: (formData) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/uploadOptionsDemoRisk',
      method: 'post',
      body: formData
    })
  },
  getC57FormHistory:(id) => {
    return apiRequestorHelper(
        {
          url: apiConfig.url + '/c57FormHistory/' + id,
          method: 'get'
        }
    )
  }
}
export default Params