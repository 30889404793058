import './ModalNewRequest.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row, Icon, Steps, message, notification } from 'antd'
import {Step1Page, Step2FilePage, Step2BdPage, Step3Page} from './components'
import { sendRequestMatchesPromise } from '../../promises'
import { requestMatchFilePromise } from '../../promises'


const ModalNewRequest = ({closeModalRequest, currentUser}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedOption, setSelectedOption] = useState();
    const [recieversGroup1, setRecieversGroup1] = useState([]);
    const [recieversGroup2, setRecieversGroup2] = useState([]);


    const sendFormFile = (category1, category2, fileGroup1, fileGroup2) => {
        const formData = new FormData()
        formData.append('categoriaGrupo1', category1)
        formData.append("categoriaGrupo2", category2)
        formData.append("archivoGrupo1", fileGroup1)
        formData.append("archivoGrupo2", fileGroup2)


        requestMatchFilePromise(formData)
            .then(results => {
            if(results === 'OK') {
                notification.success({
                    message: 'OK',
                    description: "Se han enviado los archivos correctamente"
                })
                closeModalRequest();
            }else {
                notification.error({
                    message: 'Error',
                    description: results
                })
            }
        })
    }

    const sendFormS3 = () => {
        if (recieversGroup1?.recipients?.length > 0 && recieversGroup2?.recipients?.length > 0){
            let recipients=[recieversGroup1, recieversGroup2]
            let body = {
                id: null,
                levelId: 1,
                levels: [],
                recipients,
                subject: ""
            }
            sendRequestMatchesPromise(body).then(results => {
                    if(results.status === 200 && results.data !== "ERROR: null") {
                        message.success('Solicitud enviada con éxito')
                        closeModalRequest();
                    }else{
                        notification.error({
                            message: 'Error',
                            description: "No se han enviado los registros"
                        })
                    }
                }
            )
        }else{
            notification.error({
                    message: 'Error',
                    description: "Debe agregar al menos un registro del segundo grupo"
                })
        }
    }

    const next2Bd = () => {
        let ra = recieversGroup1
        if (recieversGroup1?.recipients?.length > 0){
            next();
        }else{
            notification.error({
                message: 'Error',
                description: "Debe agregar al menos un registro del primer grupo"
                })
        }
        
    }

    const next = () => {
        const current = currentStep + 1;
        setCurrentStep(current);
    }

    const prev = () => {
        const current = currentStep - 1;
        setCurrentStep(current);
    }

    const fileOrBd = (option) => {
        setSelectedOption(option);
        next();
    }



    return(
        <div className="modal-newrequest-onb">
            <div className="topBar-nr-onb">
                    <Row>
                        <Col span={6}>
                            <div className="topBar-nr-onb-title">
                                Envío del formulario On Boarding
                            </div>
                        </Col>
                        <Col span={1} push={17}>
                            <div className="button-close-ob">
                                <a href="#" onClick={closeModalRequest}><Icon type="close" style={{color:'black', fontSize:'20px'}} /></a>
                            </div>
                        </Col>

                    </Row>
            </div>
            <div className="nr-onb-content-steps">
                <div className="nr-onb-steps">
                    <hr />
                            <Steps current={currentStep}>

                                <Steps.Step title="Seleccione la base de datos" />

                                {currentStep === 1 && selectedOption === "file" &&
                                    <Steps.Step title="Suba el archivo externo" />
                                }
                                {currentStep > 0 && selectedOption === "bd" &&
                                    <Steps.Step title="Seleccione el primer grupo" />
                                }
                                {currentStep > 0 && selectedOption === "bd" &&
                                    <Steps.Step title="Seleccione el segundo grupo" />
                                }
                            </Steps>
                    <hr />
                </div>
                <div className="steps-content">
                    {currentStep === 0 &&
                        <Step1Page fileOrBd={fileOrBd}/>
                    }
                    { currentStep === 1 && selectedOption === "file" &&
                        <Step2FilePage sendFormFile={sendFormFile} prev={prev} />
                    }
                    { currentStep === 1 && selectedOption === "bd" &&
                        <Step2BdPage next2Bd={next2Bd} prev={prev} currentUser = {currentUser} setRecieversGroup1={setRecieversGroup1}/>
                    }
                    {currentStep === 2 &&
                        <Step3Page prev={prev} sendFormS3={sendFormS3} currentUser = {currentUser} setRecieversGroup2 ={setRecieversGroup2}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default ModalNewRequest
