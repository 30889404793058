import { Col, DatePicker, Form, Input, Row } from 'antd'
import React from 'react'
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import moment from 'moment';


export default function Introduccion ({apiForm, sectionName, form, clientName}) {

  const {
    handleSaveOnChangeField
  } = useFormItems(apiForm);

  return (
    <div>
      <Row
        className="lineamiento subheader"
        style={{ marginTop: "0px" }}
      >
        <Col xl={24}>INTRODUCCIÓN</Col>
      </Row>
      <Row className="summary">
        <Col xl={24}>
          <p>
            Yo,{" "}
              <strong>{apiForm.recipient.record.nombre}</strong>
              ,{" "} cédula de indentidad nacional número {" "}
              <strong>
                {apiForm.recipient.record.rut}
              </strong> {" "}
              persona trabajadora perteneciente a la Gerencia{" "}
              <Form.Item className='introduction-item'>
                {form.getFieldDecorator("introduccionGerencia", {
                  initialValue: apiForm?.json?.basicInformation?.gerencia,
                  rules: [{ required: true, message: t("messages.aml.requiredData") }],
                })(
                  <Input
                    onChange={(e) => handleSaveOnChangeField(form, e.target.value,sectionName, "gerencia", "introduccionGerencia")}
                    style={{width: 200}}
                  />
                )}
              </Form.Item>
              {" "}
              , en mi calidad de{" "}
              <Form.Item className='introduction-item'>
                {form.getFieldDecorator("introduccionCargo", {
                  initialValue: apiForm?.json?.basicInformation?.cargo,
                  rules: [{ required: true, message: t("messages.aml.requiredData") }],
                })(
                  <Input
                    onChange={(e) => handleSaveOnChangeField(form, e.target.value,sectionName, "cargo", "introduccionCargo")}
                    style={{width: 200}}
                  />
                )}
              </Form.Item>
              {" "},
              función que realizó desde {" "}
              
              <Form.Item className='introduction-item'>
                {form.getFieldDecorator("introduccionHireDate", {
                  initialValue: apiForm?.json?.basicInformation?.hireDate ? moment(apiForm?.json?.basicInformation?.hireDate) : null,
                  rules: [{ required: true, message: t("messages.aml.requiredData") }],
                })(
                  <DatePicker
                  placeholder=''
                    format="DD/MM/YYYY"
                    onChange={(momentObj) => handleSaveOnChangeField(form, momentObj ? moment(momentObj).format("YYYY/MM/DD") : null, sectionName,"hireDate", "introduccionHireDate")}
                  />
                )} 
              </Form.Item>{" "}
              declaro bajo juramento
              que toda la información proporcionada en la presente Declaración Jurada, es del todo exacta,
              fehaciente y verdadera hasta donde tengo conocimiento y que no he omitido ningún tipo de
              información que pudiese ser relevante o pudiese producir un Conflicto de Interés para con <strong>
              {clientName}
              </strong> o sus empresas relacionadas, asumiendo desde ya, las
              responsabilidades tanto civiles como penales que me corresponderían en caso de falsedad o
              inexactitud de esta Declaración Jurada.
            </p>
            <p>
              Asimismo, declaro conocer, aceptar y haber leído, el Código de Ética, el Reglamento Interno
              de Orden, Higiene y Seguridad, y el Manual de Prevención del Delito de <strong>
              {clientName}
              </strong>, en adelante "La Empresa".
            </p>
            <p>
              También reconozco saber que tengo el deber de informar sobre cualquier situación que pueda
              dar origen a un potencial conflicto de interés en el desempeño de mis funciones
              encomendadas.
            </p>
            <p>
              Por todo lo anterior informo y declaro (marque el casillero correspondiente):
            </p>
          </Col>
      </Row>
    </div>
  )
}
