import './BillingAlert.scss'
import React from 'react'
import { Alert } from 'antd'

const BillingAlert = ({ currentUser }) => {
    return (
        <>
            <Alert className="billingMessage" message={currentUser.cliente.billingNoticeMessage} type="info"/>
        </>
    )
}

export default BillingAlert
