import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const cdi = {
	declarations: (fromDate, toDate) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/declarations/D',
			method: 'post',
			body: {type:"CDI", fromDate, toDate, optDates: 'RANGE'}
		})
	},
	barGraph: (categoryBarGraphFilter) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/cdi/getRegistroMensual',
			method: 'post',
			body: {category: categoryBarGraphFilter}
		})
	}

}

export default cdi
