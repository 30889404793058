import './AnalisisRiesgo.scss'
import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Input, Upload, Button, Icon, notification, Progress, Radio, Table, List, Modal, message} from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { HistoryModal } from '../../../../../../layouts/Private/components'
import {getUbosCtrlPromise} from '../../../../promises'
import apiConfig from '../../../../../../config/api'
import { camelizerHelper } from '../../../../../../helpers';
import { updateRiskPromise, getRulesDetailPromise } from '../../../../promises';
import { FalsesPositivesBlock } from '../../../../../AlertManager/components';
import {AlertItems} from '../'

const AnalisisRiesgo = ({record, currentUser, handleRefreshItem, setRiskLocalStatus}) => {

    const [itemTable, setItemTable] = useState(record.clCliente);
    const [seeMore, setseeMore] = useState(true);
    const [ubos, setUbos] = useState(null);
    const [related, setRelated] = useState(null);
    const [isRiskVisible, setIsRiskVisible] = useState(false);
    const [tmpFilesList, setTmpFilesList] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [riskComment, setRiskComment] = useState(null);
    const [confirmLoading, setConfirmLoading] = useState(null)
    const [userFileName, setUserFileName] = useState("");
    const [lastCommentRisk, setLastCommentRisk] = useState(itemTable.commentRisk);
    const [lastRiskDate, setLastRiskDate] = useState(null);
    const [detailsRisk, setDetailsRisk] = useState(null);
    const [falsosPositivos, setFalsosPositivos] = useState(null);
    const [selectedAlertItem, setSelectedAlertItem] = useState();
    const [selectedRisk, setSelectedRisk] = useState(record.clCliente.asignedRisk);
    const [isModalRiskVisible, setIsModalRiskVisible] = useState(false);
    const { t } = useTranslation()

    useEffect(() => {
        getUbosCtrlPromise(itemTable.rut, currentUser.cliente.pais).then(ubos => {
            if(ubos.success) {
                setUbos(ubos.data)
            }else{
                message.error("Ha ocurrido un error al obtener propietarios/sociedades")
            }
        });

        if(itemTable.histRisks.length > 0){
            setLastRiskDate(moment(itemTable.histRisks[0].creationDate).format('DD/MM/YYYY HH:mm'));
        }

        if (currentUser.cliente.modules.includes('MONITOR')){
            getRulesDetailPromise(itemTable.id).then(rules => {
                setDetailsRisk(rules)
            })
        }else{
            setDetailsRisk(getRecordsRiskDetail(itemTable.compliance))
        }

        if(itemTable?.alert?.estadoFP === 'PENDIENTE' || itemTable?.alert?.estadoFP === 'TRATADO') {
            setFalsosPositivos(itemTable.alert)
        }else if(itemTable?.estadoFP === 'PENDIENTE' || itemTable?.estadoFP === 'TRATADO') {
            let rec = {record: itemTable, estadoFP: itemTable.estadoFP}
            setFalsosPositivos(rec)
        }
        if (record.entity && record.entity.propietarios) {
            setRelated(getRiskRelated(record))
        }
    }, []);

    const handleCloseRiskModal = () =>{
        setIsRiskVisible(false);
    }

    const handleOnChangeRisk = (value) => {
        setSelectedRisk(value)
        setIsModalRiskVisible(true)
    }

    const downloadReportRisk = (pdfFile) => {
        window.open(apiConfig.url + '/ubo/getPDFUboFinder?path=' + pdfFile)
    }

    const handleSaveRisk = () => {
        const formData = new FormData()
        if(tmpFilesList !== null && tmpFilesList.length > 0){
                tmpFilesList.forEach((file, index) => {
                formData.append('file', file)
                formData.append('fileName', fileNames[index])
            })
        }
        formData.append('risk', selectedRisk)
        formData.append('commentRisk', riskComment)
        formData.append('id', itemTable.id);

        setConfirmLoading(true)
        updateRiskPromise(formData).then(res => {
            if(res.success){
                message.success("Riesgo actualizado correctamente");
                const tmpItem = { ...itemTable, histRisks: res.data.histRisks, risk: res.data.risk, asignedRisk: res.data.asignedRisk, commentRisk: res.data.commentRisk}
                setItemTable(tmpItem)
                setSelectedRisk(res.data.asignedRisk)
                setLastRiskDate(moment(res.data.histRisks[0].creationDate).format('DD/MM/YYYY HH:mm'));
                setLastCommentRisk(res.data.commentRisk)
                setRiskComment(null)
                setIsModalRiskVisible(false)
                setTmpFilesList([])

                setRiskLocalStatus({risk: res.data.risk, asignedRisk: res.data.asignedRisk, origRisk: res.data.origRisk, fecRisk: res.data.fecRisk, commentRisk: res.data.commentRisk})
            }else{
                setSelectedRisk(itemTable.asignedRisk)
                message.error("Error al actualizar el riesgo");
            }
            setConfirmLoading(false)
        })
    }

    const handleListDeleted = (index) =>{
        setFileNames(oldFileNames => {
            const newFileNames = oldFileNames.slice();
            newFileNames.splice(index, 1)

            return newFileNames
        })

        setTmpFilesList(oldFileList => {
            const newFileList = oldFileList.slice();
            newFileList.splice(index, 1)

            return newFileList
        })
    }

    const ITEM_DESCRIPTION = {
        "PEPVTO": { list: t('messages.aml.holderObl'), db: t('messages.aml.activePEPS') },
        "PEPVTN": { list: t('messages.aml.holderNoObl'), db: t('messages.aml.activePEPS') },
        "PEPVRO": { list: t('messages.aml.relatedObl'), db: t('messages.aml.activePEPS') },
        "PEPVRN": { list: t('messages.aml.relatedNoObl'), db: t('messages.aml.activePEPS') },

        "FP": { list: t('messages.aml.civilServant'), db: t('messages.aml.civilServant') },

        "PEPI": { list: "PEP", db: "WatchList" },
        "RCAI": { list: t('messages.aml.related'), db: "WatchList" },
        "WLSI": { list: t('messages.aml.specialInterest'), db: "WatchList" },
        "WLSAN": { list: t('messages.aml.sanctioned'), db: "WatchList" },
        "WLOOL": { list: t('messages.aml.otherLists'), db: "WatchList" },
        "UAF": { list: t('messages.aml.uafLists'), db: "WatchList" },
        "MAM": { list: t('messages.aml.mam.title'), db: t('messages.aml.mam.title') },

        "NEG": { list: t('messages.aml.ownLists'), db: t('messages.aml.ownLists') },

        "PEPHTO": { list: t('messages.aml.holderObl'), db: t('messages.aml.historicalPEPS') },
        "PEPHTN": { list: t('messages.aml.holderNoObl'), db: t('messages.aml.historicalPEPS') },
        "PEPHRO": { list: t('messages.aml.relatedObl'), db: t('messages.aml.historicalPEPS') },
        "PEPHRN": { list: t('messages.aml.relatedNoObl'), db: t('messages.aml.historicalPEPS') },

        "PEPC": { list: t('messages.aml.holder'), db: t('messages.aml.candidates') },
        "RCAC": { list: t('messages.aml.related'), db: t('messages.aml.candidates') },

        "AMESI": { list: t('messages.aml.specialInterest'), db: "Adverse Media" },
        "AMESAN": { list: t('messages.aml.sanctioned'), db: "Adverse Media" },
        "AMEOOL": { list: t('messages.aml.otherLists'), db: "Adverse Media" },

        "BPI": { list: t('messages.aml.personsOfInterest'), db: t('messages.aml.personsOfInterest') },

        "VIP": { list: "VIP", db: "VIP" },

        "PJUD-C": { list: t('messages.aml.civilCauses'), db: t('messages.aml.powerOfAttorney') },
        "PJUD-P": { list: t('messages.aml.criminalCauses'), db: t('messages.aml.powerOfAttorney') },
        "PJUD-LAB": { list: t('messages.aml.laborCauses'), db: t('messages.aml.powerOfAttorney') },
        "PJUD-COB": { list: t('messages.aml.collectionCauses'), db: t('messages.aml.powerOfAttorney') },
        "PJUD-APE": { list: t('messages.aml.courtAppeals'), db: t('messages.aml.powerOfAttorney') },
        "PJUD-SUP": { list: t('messages.aml.supremeCourt'), db: t('messages.aml.powerOfAttorney') },

        "SOCBRD": { list: "Board Member", db: "State Owned Companies" },
        "SOCSOC": { list: "State Owned Companies", db: "State Owned Companies" },

        "ONB-NAT": { list: t('messages.aml.titles.ONB-NAT'), db: "Onboarding" },
        "ONB-EXP": { list: t('messages.aml.titles.ONB-EXP'), db: "Onboarding" },
        "ONB-CONS": { list: t('messages.aml.titles.ONB-CONS'), db: "Onboarding" },
        "ONB-MAT": { list: t('messages.aml.titles.ONB-MAT'), db: "Onboarding" },
        "ONB-OCTO": { list: t('messages.aml.titles.ONB-OCTO'), db: "Onboarding" },
        "ONB-ACTE": { list: t('messages.aml.titles.ONB-ACTE'), db: "Onboarding" },
        "ONB-TSA": { list: t('messages.aml.titles.ONB-TSA'), db: "Onboarding" },
        "ONB-TSM": { list: t('messages.aml.titles.ONB-TSM'), db: "Onboarding" },
        "ONB-TSB": { list: t('messages.aml.titles.ONB-TSB'), db: "Onboarding" }
    }

    const getRecordsRiskDetail = (compliance) => {
        let records = []
        if(compliance) {
            if(compliance?.PEPSAN?.riskBases) {
                Object.keys(compliance.PEPSAN.riskBases).map(list =>
                    records.push({type: 'Listas Obligatorias', list: ITEM_DESCRIPTION[list].list, db: ITEM_DESCRIPTION[list].db, risk: compliance.PEPSAN.riskBases[list]})
                )
            }
            if(compliance?.KYCAME?.riskBases) {
                Object.keys(compliance.KYCAME.riskBases).map(list =>
                    records.push({type: 'KYC (Conoce a tu cliente)', list: ITEM_DESCRIPTION[list].list, db: ITEM_DESCRIPTION[list].db, risk: compliance.KYCAME.riskBases[list]})
                )
            }
            if(compliance?.UBOCOM?.riskBases) {
                Object.keys(compliance.UBOCOM.riskBases).map(list =>
                    records.push({type: 'UBO & Companies', list: ITEM_DESCRIPTION[list].list, db: ITEM_DESCRIPTION[list].db, risk: compliance.UBOCOM.riskBases[list]})
                )
            }
            if(compliance?.ONB?.riskBases) {
                Object.keys(compliance.ONB.riskBases).map(list =>
                    records.push({type: 'Onboarding', list: ITEM_DESCRIPTION[list].list, db: ITEM_DESCRIPTION[list].db, risk: compliance.ONB.riskBases[list]})
                )
            }
        }
        return records
    }

    const localeObj = {
        emptyText: 'No hay datos',
    }

    const riskSchema = [
        {
            title: 'Fecha',
            dataIndex: 'creationDate',
            cols: 4
        },
        {
            title: 'Nombre',
            dataIndex: 'userName',
            cols: 8
        },
        {
            title: 'Riesgo',
            dataIndex: 'risk',
            cols: 12
        },
    ];

    const RISK_DESCRIPTION={
        BLACK: "Crítico",
        RED: "Alto",
        ORANGE: "Medio",
        YELLOW: "Bajo",
        GREEN: "No tiene",
        GRAY: "No procesado"
    }

    const ubosColumns = [
        {
            key:1,
            dataIndex: itemTable.type === 'Person' || (ubos && ubos.ubos && ubos.ubos.length === 0) ? 'participacionDirecta' : 'participacion',
            width: '20%',
            render: (porcent) => {
            return <div className="porcent-onb-modal">{porcent ? (Math.round(porcent * 100)/100)+'%' : 'N/A' }</div>
            }
        },
        {
            key:2,
            dataIndex: 'name',
            width: '15%',
            render: () => {
            return <div className="prop-onb-modal">p</div>
            }
        },
        {
            key:3,
            dataIndex: 'name',
            ellipsis: true,
        },
    ]

    const getRiskRelated = (data) => {
        let rows = []
        if (data.entity) {
          for (let i = 0; i < data.entity.propietarios.length; i++) {
            if (data.entity.propietarios[i].amlStatus && data.entity.propietarios[i].amlStatus !== 'GREEN') {
              rows.push(data.entity.propietarios[i])
            }
          }
        }
        console.log(rows)
        return rows
    }

    const propsUpload = {
        accept: ".pdf, .png, .docx, .xlsx",
        onRemove: file => {
            const index = tmpFilesList.indexOf(file);
            handleListDeleted(index);
        },
        beforeUpload: file => {
            const validFiles = file.type === 'application/pdf' || file.type === 'image/png'|| file.name.endsWith('.docx') || file.name.endsWith('.xlsx');
            if (!validFiles) {
            message.error('Formato no permitido.');
            }else{
            const validSize = file.size / 1024 / 1024 < 2;
            if (!validSize) {
                message.error('Tamaño máximo 2MB!');
            }else{
                if(userFileName === "" || userFileName === null){
                message.error("Debe ingresar un nombre de archivo")
                }else{
                setTmpFilesList(oldTmpFilesList => [...oldTmpFilesList, file]);
                setFileNames(oldFileNames => [...oldFileNames, userFileName]);
                }
            }
            setUserFileName(null)
            }
            return false
        },
        multiple: false,
        showUploadList: false
    }

    const getDetailsColumns = () => {
        let columns = []
        if (currentUser.cliente.modules.includes('MONITOR')){
            columns.push(
            {
                key: 1,
                title: "Tipo",
                dataIndex: 'type',
                render: (type) => {
                return t("messages.aml.compliance."+type)
                }
            },
            {
                key: 2,
                title: "Regla",
                dataIndex: 'rule'
            },
            {
                key: 3,
                title: "Nro. de Alertas",
                className: "codeColumn",
                dataIndex: 'alerts',
                onCell: (record) => {
                return {
                    onClick: () => {
                        setSelectedAlertItem(record)
                    },
                }
                }
            },
            {
                key: 4,
                title: "Riesgo",
                dataIndex: 'risk',
                render: (riesgo) => (
                <div className={"risk-"+riesgo} style={{textAlign:"center", borderRadius:8, border: '1px solid rgb(158, 158, 158)'}}>
                    {RISK_DESCRIPTION[riesgo]}
                </div>
                )
            }
            )
        }else{
            columns.push(
            {
                key: 5,
                title: "Tipo",
                dataIndex: 'type'
            },
            {
                key: 6,
                title: "Base de Datos",
                dataIndex: 'db'
            },
            {
                key: 7,
                title: "Lista en la que se efectuo el cruce",
                dataIndex: 'list'
            },
            {
                key: 8,
                title: "Riesgo",
                dataIndex: 'risk',
                render: (riesgo) => (
                <div className={"risk-"+riesgo} style={{textAlign:"center", borderRadius:8, border: '1px solid rgb(158, 158, 158)'}}>
                    {RISK_DESCRIPTION[riesgo]}
                </div>
                )
            }
            )
        }
        return columns;
    }

    const getTypeRelated = (type) => {
        if(type === "REPLEGAL") return t('messages.aml.repLegal')
        else if(type === "DIRECTOR") return "Director"
        else if(type === "GERENTE_GENERAL") return "Gerente"
        else if(type === "EJ_PRINCIPAL") return "Ejecutivo Principal"
        else if(type === "AVAL") return "Aval o Coaval"
        else if(type === "APODERADO") return "Apoderado"
        else return type
    }

    const riskTableColumns = [
        {
            key: 1,
            title: t('messages.aml.type'),
            dataIndex: 'type',
            width: 100,
            render: (text, record) => {
                if (text === 'Entity') {
                return t('messages.aml.entity')
                } else if(record.origenMallaPropiedad === 'CLIENTE'){
                return "UBO"
                }else {
                return getTypeRelated(record.origenMallaPropiedad)
                }
            }
        },
        {
            key: 2,
            title: t('messages.aml.rut'),
            dataIndex: 'rut',
            width: 100,
            render: (text, record) => {
                if (record.pais === 'NACIONAL') {
                return text
                }
            }
        },
        {
            key: 3,
            title: t('messages.aml.name'),
            dataIndex: 'name',
            width: 220,
            render: (text) => camelizerHelper(text)
        },
        {
            key: 4,
            title: t('messages.aml.risk'),
            dataIndex: 'amlStatus',
            width: 70,
            render: (text) => <div className={'risk risk-' + text} />
        },
        {
            key: 5,
            title: t('messages.aml.module'),
            dataIndex: 'baseAml',
            width: 150
        },
        {
            key: 6,
            title: t('messages.aml.download'),
            dataIndex: 'pdfFile',
            width: 80,
            render: (text) => <Button type="primary" icon="file-pdf" size="small" onClick={(e) => downloadReportRisk(text)} />
        }
      ]

    return (
        <div className="ficha-analisis-riesgo">
            <Row className="field-section-row">
                <Col span={22}>
                    <h3>Análisis de riesgo</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                    <Button type="link" onClick={() => {setseeMore(!seeMore)}}>
                    {seeMore ?
                        <>Ver menos <Icon type="minus"/></>
                        :
                        <>Ver más <Icon type="plus"/></>
                    }
                    </Button>
                </Col>
            </Row>
            <hr/>
            {seeMore &&
                <div className="div-more-info" style={{padding: 10}}>
                    <div className="riesgo-fields-section-wrapper">
                        <Row>
                        { currentUser.cliente.modules.includes('MONITOR-AML') &&
                            <Col span={5}>
                            <Row>
                                <Col>
                                <strong>Riesgo AMLupdate</strong>
                                </Col>
                                <Col style={{marginTop:15, marginBottom:10}}>
                                <div className= "radioBtn-risk-aml">
                                    <Radio.Group
                                    className={"radioGroupOnbRisk-"+(itemTable && itemTable.amlStatus !== null ? itemTable.amlStatus : 'GRAY')}
                                    size="large"
                                    value={itemTable.amlStatus !== null && itemTable.amlStatus}
                                    name="radioRiskObn"
                                    buttonStyle="solid"
                                    >
                                    <Radio.Button value={itemTable.amlStatus} style={{cursor: 'default'}}>
                                        { itemTable.amlStatus === null ? "N/A"
                                        :
                                        itemTable.amlStatus === 'GREEN' ? "No posee"
                                        :
                                        itemTable.amlStatus === 'YELLOW' ? "Bajo"
                                        :
                                        itemTable.amlStatus === 'ORANGE' ? "Medio"
                                        :
                                        itemTable.amlStatus === 'RED' ? "Alto"
                                        :
                                        itemTable.amlStatus === 'BLACK' && "Crítico"
                                        }
                                    </Radio.Button>
                                    </Radio.Group>
                                </div>
                                </Col>
                            </Row>
                            <Row style={{marginRight:30, marginTop:20}}>
                                El Riesgo AML es entregado por AMLupdate resultado del cruce de información con las bases de datos.
                            </Row>
                            </Col>
                        }
                        <Col span={currentUser.cliente.modules.includes('MONITOR-AML') ? 19 : 24}>
                            <Row>
                                <Col>
                                    <strong>Riesgo Asignado</strong>
                                </Col>
                                <Col style={{marginTop:15, marginBottom:10}}>
                                    <div className= "radioBtn-wrapper-onb">
                                    <Radio.Group
                                        className={"radioGroupOnbRisk-"+selectedRisk}
                                        size="large"
                                        value={selectedRisk ?? itemTable.asignedRisk }
                                        name="radioRiskObn"
                                        buttonStyle="solid"
                                        onChange={(e) => handleOnChangeRisk(e.target.value)}
                                    >
                                        <Radio.Button value="GREEN">No Posee</Radio.Button>
                                        <Radio.Button value="YELLOW">Bajo</Radio.Button>
                                        <Radio.Button value="ORANGE">Medio</Radio.Button>
                                        <Radio.Button value="RED">Alto</Radio.Button>
                                        <Radio.Button value="BLACK">Crítico</Radio.Button>
                                    </Radio.Group>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    Comentario
                                    <Input.TextArea
                                    // onChange={(e) => {setRiskComment(e.target.value)}} 
                                    readOnly
                                    value={(!lastCommentRisk || lastCommentRisk === "null") ? "No hay comentarios" : lastCommentRisk}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <div>
                                    Último comentario: {lastRiskDate ? lastRiskDate : "Sin comentarios"}
                                    </div>
                                </Col>
                                <Col span={6} offset={12} style={{textAlign: 'right'}}>
                                    {lastRiskDate &&
                                    <Button onClick={() => setIsRiskVisible(true)} style={{padding:'unset'}}type="link">Ver comentarios anteriores</Button>
                                    }
                                </Col>
                            </Row>
                            {isRiskVisible &&
                                <HistoryModal schema={riskSchema} data={itemTable.histRisks} closeHandler={handleCloseRiskModal} recordId={itemTable.id}/>
                            }
                        </Col>
                        </Row>
                    </div>
                    {isModalRiskVisible &&
                        <Modal
                        title="Modificar el Riesgo"
                        // className="modal-area-cliente"
                        visible={ true }
                        width={ 600 }
                        confirmLoading={confirmLoading}
                        onOk={() => {
                            if (riskComment){
                            if (tmpFilesList.length === 0 && userFileName !== ""){
                                notification.error({message: "Debe seleccionar un archivo"})
                            }else{
                                handleSaveRisk()
                            }
                            }else{
                            notification.error({message: "Debe ingresar comentario"})
                            }
                        }}
                        onCancel={() => {
                            setSelectedRisk(itemTable.asignedRisk)
                            setIsModalRiskVisible(false);
                        }}
                        >
                        <>
                            <Row>
                            Estimado {currentUser.name} usted asignara un nivel de riesgo a <strong>{itemTable.nombre}</strong>. <br/>
                            Sugerimos agregar un comentario para dejar el sustento correspondiente.
                            </Row>
                            <Row style={{marginTop:20}}>
                            <Input.TextArea
                                onChange={(e) => {setRiskComment(e.target.value)}} 
                                maxLength={4000}
                                placeholder="Máximo de 4000 caracteres."
                            />
                            </Row>
                            <Row style={{marginTop:25}}>
                            Si requiere agregar documentación de sustento, lo puede realizar a continuación.
                            </Row>
                            <Row style={{marginTop: 15}}>
                                <Col span={16}>
                                    <Form.Item>
                                        <Input
                                            placeholder="Ingrese nombre de documento"
                                            autoComplete="off"
                                            onChange={(e) => setUserFileName(e.target.value) }
                                            value={userFileName}
                                        />
                                    </Form.Item>
                                </Col>
                            <Col span={7} offset={1}>
                                <Upload {...propsUpload} >
                                <Button disabled={tmpFilesList.length > 9} style={{marginTop:4}} >
                                    <Icon type="upload" /> Subir Archivo
                                </Button>
                                </Upload>
                            </Col>
                            </Row>
                            {tmpFilesList.length > 0 &&
                            <Row>
                                <List
                                size="small"
                                header="Documentos adjuntos"
                                itemLayout="horizontal"
                                dataSource={tmpFilesList.map((file, index)=> {return {fileName: file.name, docName: fileNames[index], index: index}})}
                                renderItem={item => (
                                    <List.Item
                                    actions={[<Button type="link" onClick={() => handleListDeleted(item.index)}>Eliminar</Button>]}
                                    >
                                    <List.Item.Meta
                                        description={item.docName}
                                    />
                                    <List.Item.Meta
                                        description={item.fileName}
                                    />
                                    </List.Item>
                                )}
                                />
                            </Row>
                            }
                        </>
                        </Modal>
                    }
                    <div className="riesgo-fields-section-wrapper" style={{marginTop:10}}>
                        {currentUser.cliente.modules.includes('MONITOR-AML') && itemTable.compliance && itemTable.compliance.total > 0 &&
                        <Row style={{padding:'0px 0px 10px 2px'}}>Se han identificado {itemTable.compliance.total} coincidencia{itemTable.compliance.total > 1 && 's'}. A continuación se muestra la de mayor riesgo.</Row>
                        }
                        <Row type="flex" gutter={[6,0]}>
                        { currentUser.cliente.modules.includes('MONITOR-AML') &&
                            <Col span={9} style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
                            <div className="field-section-row-wrapper">
                                <Row>
                                    <div className="header-card-onb-form">
                                        Cumplimiento
                                    </div>
                                </Row>
                                <Row>
                                    <Col span={itemTable.compliance?.ONB ? 6 : 8}>
                                        <div className="title-card-onb-form">
                                        Listas Obligatorias
                                        </div>
                                    </Col>
                                    <Col span={itemTable.compliance?.ONB ? 6 : 8}>
                                        <div className="title-card-onb-form">
                                        KYC
                                        </div>
                                    </Col>
                                    <Col span={itemTable.compliance?.ONB ? 6 : 8}>
                                        <div className="title-card-onb-form">
                                        UBO & Companies
                                        </div>
                                    </Col>
                                    { itemTable.compliance?.ONB &&
                                        <Col span={6}>
                                        <div className="title-card-onb-form">
                                            Onboarding
                                        </div>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col span={itemTable.compliance?.ONB ? 6 : 8}>
                                        <div className="item-risk-align">
                                        <div className={ 'child-risk-onb-circle risk-' + (itemTable.compliance && itemTable.compliance.PEPSAN && itemTable.compliance.PEPSAN.color ? itemTable.compliance.PEPSAN.color : "GRAY") }>
                                            {itemTable.compliance && itemTable.compliance.PEPSAN && itemTable.compliance.PEPSAN.bases && itemTable.compliance.PEPSAN.bases.length}
                                        </div>
                                        </div>
                                    </Col>
                                    <Col span={itemTable.compliance?.ONB ? 6 : 8}>
                                        <div className="item-risk-align">
                                        <div className={ 'child-risk-onb-circle risk-' + (itemTable.compliance && itemTable.compliance.KYCAME && itemTable.compliance.KYCAME.color ? itemTable.compliance.KYCAME.color : "GRAY") }>
                                            {itemTable.compliance && itemTable.compliance.KYCAME && itemTable.compliance.KYCAME.bases && itemTable.compliance.KYCAME.bases.length}
                                        </div>
                                        </div>
                                    </Col>
                                    <Col span={itemTable.compliance?.ONB ? 6 : 8}>
                                        <div className="item-risk-align">
                                        <div className={ 'child-risk-onb-circle risk-' + (itemTable.compliance && itemTable.compliance.UBOCOM && itemTable.compliance.UBOCOM.color ? itemTable.compliance.UBOCOM.color : "GRAY") }>
                                            {itemTable.compliance && itemTable.compliance.UBOCOM && itemTable.compliance.UBOCOM.bases && itemTable.compliance.UBOCOM.bases.length}
                                        </div>
                                        </div>
                                    </Col>
                                    { itemTable.compliance?.ONB &&
                                        <Col span={6}>
                                        <div className="item-risk-align">
                                            <div className={ 'child-risk-onb-circle risk-' + (itemTable.compliance && itemTable.compliance.ONB && itemTable.compliance.ONB.color ? itemTable.compliance.ONB.color : "GRAY") }>
                                            {itemTable.compliance && itemTable.compliance.ONB && itemTable.compliance.ONB.bases && itemTable.compliance.ONB.bases.length}
                                            </div>
                                        </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            </Col>
                        }
                        <Col span={currentUser.cliente.modules.includes('MONITOR-AML') ? 9 : 24}>
                            <div className="field-section-row-wrapper">
                                <Row>
                                    <div className="header-card-onb-form">
                                    Sociedades / Propietarios
                                    </div>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="table-col-wrapper-onb">
                                            <Table
                                                dataSource={ubos === null ? null : (itemTable.type === 'Person' || (ubos?.ubos?.length === 0)) ? ubos.psoc : ubos.ubos}
                                                columns={ubosColumns}
                                                showHeader={false}
                                                tableLayout="fixed"
                                                scroll={{y: (ubos?.ubos?.length > 3 ?? ubos?.psoc?.length > 3) ? 120 : false}}
                                                style={{overflowX: 'hidden'}}
                                                pagination={false}
                                                loading = {ubos === null}
                                                locale = {localeObj}
                                            />
                                        </div>
                                    </Col>
                                    {/* <Col span={6}>
                                    <div className="text-5mas-wrapper">
                                        y 5 más...
                                    </div>
                                    </Col> */}
                                </Row>
                            </div>
                        </Col>
                        { currentUser.cliente.modules.includes('MONITOR-AML') &&
                            <Col span={6}>
                                <div className="field-section-row-wrapper">
                                    <Row>
                                        <div className="header-card-onb-form">
                                            Relevancia
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="progress-flat-wrapper">
                                            <Progress
                                            // type="circle"
                                            percent={itemTable.compliance && itemTable.compliance.percent}
                                            status="normal"
                                            width={80}
                                            />
                                        </div>
                                    </Row>
                                    {itemTable.pdfFile &&
                                    <Row>
                                        <div className="progress-button-wrapper">
                                        <Button style={{marginBottom: 10}} onClick={() =>{downloadReportRisk(itemTable.pdfFile)}}>Ver Certificado</Button>
                                        </div>
                                    </Row>
                                    }
                                </div>
                            </Col>
                        }
                        </Row>
                    </div>
                    {currentUser.cliente.modules.includes('MONITOR-AML') && itemTable.compliance && itemTable.amlStatus && itemTable.amlStatus !== 'GREEN' &&
                        <div className="riesgo-fields-section-wrapper" style={{marginTop:10, marginBottom: 15}}>
                            <Row>
                                <Col span={24}>
                                    <div className="title-section">
                                        <Row>
                                            <Col span={22}>
                                                <h4>Detalle</h4>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col span={15} style={{marginTop:10}}>
                                    <Table
                                        loading={detailsRisk === null}
                                        dataSource={detailsRisk}
                                        columns={getDetailsColumns()}
                                        pagination={false}
                                        bordered
                                    />
                                </Col>
                            </Row>
                            { related && related.length > 0 &&
                                <Row style={{marginTop:15}}>
                                    <Col span={24}>
                                        <div className="title-section">
                                            <Row>
                                                <Col span={22}>
                                                    <h4>Detalle de Relacionados</h4>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={15} style={{marginTop:10}}>
                                        <Table
                                            dataSource={related}
                                            columns={riskTableColumns}
                                            bordered
                                            size="small"
                                            pagination={related.length > 10}
                                        />
                                    </Col>
                                </Row>
                            }
                        </div>
                    }
                    {selectedAlertItem &&
                        <Modal
                        wrapClassName="modal-detail-risk-alerts"
                        visible={true}
                        onCancel={() => setSelectedAlertItem(null)}
                        width={1000}
                        footer={[
                            <Button key="back" onClick={() => setSelectedAlertItem(null)}>
                                Cerrar
                            </Button>
                        ]}
                        >
                            <AlertItems currentUser={currentUser} selectedAlertItem={selectedAlertItem} recordId={itemTable.id}/>
                        </Modal>
                    }
                    {falsosPositivos &&
                        <FalsesPositivesBlock alert={falsosPositivos} refreshHandler={handleRefreshItem} view="new" />
                    }
                </div>
            }
        </div>
    );
};

export default AnalisisRiesgo;