import React, { useEffect, useState } from 'react'
import './AlertAreaChart.scss'
import Plot from 'react-plotly.js'

import {getTransactionalActivityPromise} from './promises/getTransactionalActivityPromise'
import { Spin } from 'antd'


export const AlertAreaChart = ({ dateValue, chartColor, handleTabRedirection }) => {

  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {

    setIsLoading(true)
    getTransactionalActivityPromise({ dateValue }).then((response) => {
      setActivity(response.data)
    }).then( () => setIsLoading(false))
  }, [dateValue]);

  const getColorByName = (colorName) => chartColor.find(color => color.name === colorName)?.color;

  const handleClick = (data) => {
    //seleccionados solo CERRADOS
    const alertDate = data.points[1].x;
    const unix = new Date(alertDate);
    const unixDate = unix.getTime();

    let filters = {
      activeTab: 'tab-alerts',
      estado: 'CLOSED',
      fechaAlerta: [unixDate,unixDate]
    }

    handleTabRedirection(filters, 'monitoring')

  };

  return (

  <div className='inside-compliance-alert-area-chart'>
    <Spin spinning={isLoading}>
      {(activity.length !== 0 && activity[0]?.total !== 0) ? (
          <Plot
              data={
                [
              {
                name: "Total",
                x: activity.map(el => el.date),
                y: activity.map(el => el.total),
                type: 'scatter',
                line: {
                  shape: 'spline',
                  smoothing: '0.3',
                  width: 4,
                  color:  getColorByName('BLUE') ,
                },
                mode: 'lines',
              },
              {
                name: "Cerrados",
                x: activity.map(el => el.date),
                y: activity.map(el => el.closed),
                type: 'scatter',
                line: {
                  shape: 'spline',
                  smoothing: '0.3',
                  width: 6,
                  color: getColorByName('GREEN'),
                },
                mode: 'lines',
              },
            ]
          }
          layout={{
            showlegend: true,
            height: 300,
            margin: {
              l: 30,
              r: 0,
              b: 25,
              t: 20,
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            autoscale: false,

            xaxis: {
              hoverinfo: 'text',
              showgrid: true,
              showticklabels: true,
              tickfont: {
                family: 'Arial, sans-serif',
                size: 10,
                color: 'rgb(103 103 103)',
              },
              //tickformat: '%d %b, %y'
              tickformat: '%d %b, %y'
            },
            yaxis: {
              //title: 'Cantidad Actividades',
              hoverinfo: 'text',
              titlefont: {
                family: 'Arial, sans-serif',
                size: 10,
                color: 'rgb(103 103 103)'
              },
              showticklabels: true,
              tickfont: {
                family: 'Arial, sans-serif',
                size: 10,
                color: 'rgb(103 103 103)'
              },
              showgrid: true,
              gridcolor: 'rgb(187 187 187)',
            }
          }}

          config={{
            displayModeBar: false,
            locale: 'de-CH'
          }}
          useResizeHandler={true}
          style={{width: '100'}}
          onClick={(data) => { handleClick(data) }}
        />
        ) : (
            <div className="text-no-content">No hay datos disponibles</div>
        )}
      </Spin>
    </div>
  )
}
