import { Button, Col, Icon, Row, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import {getCdiDataPromise} from './promises'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { camelizerHelper } from '../../../../../../helpers';
import { ModalPdfViewer } from '../../../../../InterestConflicts/components';
import { ModalIndexCardPage } from '../../../../../InterestConflicts_v2/components/FormManager/components';

const InfoCdi = ({item}) => {

    const [seeMore, setSeeMore] = useState(false);
    const [formIdView, setFormIdView] = useState(null);
    const [cdiDataSource, setCdiDataSource] = useState(null);
    const [formId, setFormId] = useState(false);
    const { t } = useTranslation()

    useEffect(() => {
        getCdiDataPromise(item.id).then((data) => {
            setCdiDataSource(data)
        })
    }, []);

    const declarationsColumns = [
        {
            title: "Tipo",
            dataIndex: 'type',
            render: text => t("messages.aml.type."+text)
        },
        {
            title: "Folio",
            dataIndex: 'folio'
        },
        {
            title: "Solicitud",
            dataIndex: "sendDate",
            render: text => moment(text).format('DD/MM/YYYY')
        },
        {
            title: "Estado",
            dataIndex: "status",
            render: text => t("messages.aml.cdi.decl.status."+text)
        },
        {
            title: "Fecha Estado",
            dataIndex: 'sendDate',
            render: (text, record) => {
                if(record.status === 'SENT') return moment(record.receiveDate).format('DD/MM/YYYY')
                else return moment(text).format('DD/MM/YYYY')
            }
        },
        {
            title: "Vínculos / Riesgo",
            align: 'center',
            dataIndex: 'hasPositives',
            render: ((text, record) => {
                return <>
                    { text ? 'Sí' : 'No'} / {record.risk !== null ? t('messages.aml.risk.' + record.risk) : record.status === 'SENT' ? 'No asignado' : '-'}
                </>
            })
        },
        {
            title: "Recordatorio",
            render: (record) => {
                return record.nroReminders ? record.nroReminders+" "+moment(record.lastReminder).format('DD/MM/YYYY') : "Sin recordatorio"
            }
        },
        {
            title: "Ver",
            align: 'center',
            dataIndex: "status",
            render: (status, record) => {
                if(status === 'SENT') {
                    return <Button size='small' icon='file-pdf' onClick={() => onClickPdfView(record.id)}/>
                }
            }
        },
        {
            title: "Detalle",
            align: 'center',
            render: (item) => 
                <Button size="small" icon='file' onClick={() => setFormId(item)}/>
        }
    ]

    const handleCloseModalFicha = () => {
        setFormId(null)
    }

    const onClickPdfView = (id) => {
        setFormIdView(id);
    }

    const onCancelPdfViewer = () => {
        setFormIdView(null);
    }
    
    const vinculosColumns = [
        {
            title: "Tipo de Persona",
            dataIndex: 'type',
            render: (text) => text === 'Person' ? "Persona" : "Empresa"
        },
        {
            title: "Nombre",
            dataIndex: 'nombre',
            render: text => camelizerHelper(text)
        },
        {
            title: "Nro. de Documento",
            dataIndex: 'rut'
        },
        {
            title: "Categoría",
            dataIndex: 'category',
            render: text => camelizerHelper(text)
        },
    ]
  return (
    <div className='info-cdi-component'>
        <Row>
            <Col span={22}>
                <h3>Gestión de Declaraciones</h3>
            </Col>
            <Col span={2} style={{textAlign:'right'}}>
                <Button type="link" onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ?
                    <>Ver menos <Icon type="minus"/></>
                    :
                    <>Ver más <Icon type="plus"/></>
                    }
                </Button>
            </Col>
        </Row>
        <hr/>
        {seeMore &&
            <div className="div-more-info" style={{padding: 20}}>
                <Row gutter={[0, 20]}>
                    <Col span={24}>
                        <div style={{fontSize: 16}}>Solicitud de declaraciones</div>
                        <hr/>
                    </Col>
                    <Row className="field-section-row">
                        <Col span={3}>
                            <div className="formCard-onb-modal">
                                Solicitudes <br/>
                                {cdiDataSource?.forms?.length}
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="formCard-onb-modal">
                                Pendientes <br/>
                                {cdiDataSource?.forms?.filter(form => form.status !== 'SENT').length}
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="formCard-onb-modal">
                                Realizadas <br/>
                                {cdiDataSource?.forms?.filter(form => form.status === 'SENT').length}
                            </div>
                        </Col>
                    </Row>
                    <Col span={24} >
                        <Table dataSource={cdiDataSource?.forms} 
                            columns={declarationsColumns}
                            loading={cdiDataSource === null}
                            pagination={cdiDataSource?.forms?.length > 10}/>
                    </Col>
                    <Col span={24}>
                        <div style={{fontSize: 16}}>Vínculos</div>
                        <hr/>
                    </Col>
                    <Col span={24} >
                        <Table dataSource={cdiDataSource?.related} 
                            columns={vinculosColumns}
                            loading={cdiDataSource === null}
                            pagination={cdiDataSource?.related?.length > 10}/>
                    </Col>
                </Row>
            </div>
        }

        {formId &&
            <Modal
                wrapClassName="modal-fichaCliente-register"
                style={{top:'10px'}}
                title="Registro"
                visible={true}
                onCancel={handleCloseModalFicha}
                cancelText="Cerrar"
                footer={null}
                width={1200}
            >
                <ModalIndexCardPage item={formId} />
            </Modal>
        }

        {formIdView &&
            <Modal
                className="modal-pdf-viewer"
                title="Formulario"
                centered={true}
                width={1000}
                header={null}
                footer={[<Button key="back" onClick={onCancelPdfViewer}>Cerrar</Button>]}
                onCancel={onCancelPdfViewer}
                visible="true"
            >
                <ModalPdfViewer
                    pdfId={formIdView}
                />
            </Modal>
        }
    </div>
  )
}

export default InfoCdi
