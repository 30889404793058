import GenFormTrab from './FormTrab/FormTrab'
import GenFormDir from './FormDir/FormDir'
import GenFormProv from './FormProv/FormProv'
import GenFormFp from './FormFP/FormFP'
import GenFormGift from './FormGift/FormGift'
import GenFormPep from './FormPep/FormPep'
import GenFormSoc from './FormSoc/FormSoc'
import GenFormTravel from './FormTravel/FormTravel'
import GenFormValues from './FormValues/FormValues'
import GenFormColab from './FormColab/FormColab'

export const GenFormTrabPage = GenFormTrab
export const GenFormDirPage = GenFormDir
export const GenFormProvPage = GenFormProv
export const GenFormFpPage = GenFormFp
export const GenFormGiftPage = GenFormGift
export const GenFormPepPage = GenFormPep
export const GenFormSocPage = GenFormSoc
export const GenFormTravelPage = GenFormTravel
export const GenFormValuesPage = GenFormValues
export const GenFormColabPage = GenFormColab