import './MonitoreoQuiebras.scss'
import React, { useState, useEffect } from 'react'
import { Descriptions, Button, Table, Icon, Tabs, Row, Col, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from "moment";
import { getHistoryQuiebrasPromise, getUbosQuiebrasPromise } from './promises'
import { camelizerHelper } from '../../../../../../helpers'


const MonitoreoQuiebras = ({ itemTable }) => {
    const { t } = useTranslation()
  
    const [quiebra, setQuiebra] = useState(null)
    const [ubos, setUbos] = useState(null)
    const [seeMore, setSeeMore] = useState(false);
  
    useEffect(() => {
      if(itemTable?.id) {
        if(itemTable?.risk) {
          let _ubos = []
          if(itemTable?.risk !== 'GREEN') {
            _ubos.push({rut: itemTable.rut, nombre: itemTable.nombre, relacion: 'TITULAR', riesgo: itemTable?.risk})
          }
          getUbosQuiebrasPromise(itemTable.id)
            .then(response => {
              for(let i=0;i<response.length;i++) {
                if(response[i].riesgo !== null && response[i].riesgo !== 'GREEN') {
                  _ubos.push(response[i])
                }
              }
              if(itemTable?.risk !== 'GREEN' && _ubos.length === 1) {
                getHistoryQuiebrasPromise(itemTable.rut)
                  .then(response => {
                    setQuiebra(response)
                  })
              }else {
                setUbos(_ubos)
              }
            })
        }else {
          getHistoryQuiebrasPromise(itemTable.rut)
            .then(response => {
              setQuiebra(response)
            })
        }
      }
    }, [])
  
    const riskTableColumns = [
      {
        title: t('messages.aml.type'),
        dataIndex: 'relacion',
        width: 100,
        render: (text) => camelizerHelper(text)
      },
      {
        title: t('messages.aml.rut'),
        dataIndex: 'rut',
        width: 100
      },
      {
        title: t('messages.aml.name'),
        dataIndex: 'nombre',
        width: 220,
        render: (text) => camelizerHelper(text)
      },
      {
        title: t('messages.aml.risk'),
        dataIndex: 'riesgo',
        width: 70,
        render: (text) => {
            if(text !==null) return <div className={ 'onboarding risk-' + text } />
            else return 'N/A'
        }
      }
    ]
  
    const quiebrasColumn = [
      {
        title: 'Tipo Procedimiento',
        dataIndex: 'tipoProc',
        width: 200
      },
      {
        title: t('messages.aml.name'),
        dataIndex: 'nombrePub',
        width: 200
      },
      {
        title: t('messages.aml.date'),
        dataIndex: 'fecha',
        width: 100,
        render: (text, record) => moment(text, 'DD/MM/YYYY').format("DD-MM-YYYY")
      },
      {
        title: 'Martillero',
        dataIndex: 'martillero',
        width: 300,
        render: (text) => camelizerHelper(text)
      }
    ]
  
    return (
        <div className='monitoreo-quiebras'>
            <Row>
                <Col span={22}>
                    <h3>Monitoreo de Quiebras</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                    <Button type="link" onClick={() => {setSeeMore(!seeMore)}}>
                        {seeMore ?
                        <>Ver menos <Icon type="minus"/></>
                        :
                        <>Ver más <Icon type="plus"/></>
                        }
                    </Button>
                </Col>
            </Row>
            <hr/>
            {seeMore &&
                <div className="modal-risk-main-wrapper">
                    <Row gutter={[0,20]}>
                        <Col span={8}>
                            <Descriptions bordered>
                                <Descriptions.Item label="Tipo de Riesgo">
                                    <Row>
                                        <Col span={12} offset={4}>
                                          {quiebra?.risk ? t("messages.aml.risk."+quiebra?.risk): "N/A"}
                                        </Col>
                                        <Col span={8}>
                                            <div className={ 'risk-wrapper risk-'+ quiebra?.risk } />
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={[30, 30]}>
                        <Col span={6}>
                            Rol
                            <Input value={ quiebra !== null && quiebra.rol !== null ? quiebra.rol : 'N/A' } readOnly/>
                        </Col>
                        <Col span={6}>
                            { t('messages.aml.stage') }
                            <Input value={ quiebra !== null && quiebra.etapa !== null ? quiebra.etapa : 'N/A' } readOnly/>
                        </Col>
                        <Col span={6}>
                            { t('messages.aml.court') }
                            <Input value={ quiebra !== null && quiebra.tribunal !== null ? quiebra.tribunal : 'N/A' } readOnly/>
                        </Col>
                        <Col span={6}>
                            Martillero
                            <Input value={ quiebra !== null && quiebra.martillero !== null ? camelizerHelper(quiebra.martillero) : 'N/A'} readOnly/>
                        </Col>
                        <Col span={24}>
                            { quiebra !== null ?
                                <Descriptions.Item label="Historial">
                                    { quiebra.history !== null &&
                                    <Table dataSource={ quiebra.history } columns={ quiebrasColumn } size='small' />
                                    }
                                </Descriptions.Item>
                                :
                                <Descriptions.Item label={t('messages.aml.finalBeneficiaries')}>
                                    { ubos !== null &&
                                        <Table dataSource={ ubos } columns={ riskTableColumns } pagination={ false } size='small' />
                                    }
                                </Descriptions.Item>
                            }
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
  }

export default MonitoreoQuiebras;