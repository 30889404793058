import './Stakeholder.scss'
import React, { useState } from 'react'
import { withRouter, Link } from "react-router-dom"
import { Layout, Spin, Icon, Menu, Dropdown, Button, Col, Row, message, Select, Tooltip } from 'antd'
import { useTranslation } from "react-i18next"
import { PublicPortal, StakePrivate } from '../'
import { ModalChangePassword } from '../../layouts/Private/components'
import { changePasswordUserPortalPromise } from './promises'
import { SessionStorageService } from '../../services'

import logoAml from '../../layouts/Private/components/Footer/logo-aml.png'
import ImageResources from "../../layouts/commonComponent/components/ExternalResources/ImageResources";

const { Header } = Layout

const StakeholderPortal = ({ countryCode }) => {
	const { t } = useTranslation();
	const [clientes, setClientes] = useState(null)
	const [user, setUser] = useState(null)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] = useState(false)
	const [selectedClient, setSelectedClient] = useState(null);
	const [showPrivate, setShowPrivate] = useState(false);
	const [loginRut, setLoginRut] = useState(null);

	document.title = "Portal de Usuarios"
	const { Option } = Select;

	const handleLogin = async (record, code) => {
		if (code === 'x') {
			record.activeUser = true
		} else if (code === '200' || code === '202') {
			record.activeUser = (code === '200')
			SessionStorageService.create('portal-user-id', record.id)
			SessionStorageService.create('portal-user-status', code)
			if (code === '202') {
				message.warn({ content: 'Se recomienda cambiar la contraseña' })
				// setIsModalChangePasswordVisible(true)
			}
		} else {
			record.activeUser = false
		}
		setUser(record)
		setClientes(record.detalleCuenta)
		if (record.detalleCuenta.length === 1) {
			setSelectedClient(record.detalleCuenta[0])
			setShowPrivate(true)
		}
		setIsLoggedIn(true)
	}

	const handleLogout = async (e) => {
		setIsLoggedIn(false)
		setUser(null)
		setShowPrivate(false)
		SessionStorageService.delete('portal-user-id')
		SessionStorageService.delete('portal-user-status')
	}

	const handleOpenModalChangePassword = () => {
		setIsModalChangePasswordVisible(true)
	}

	const handleCloseModalChangePassword = () => {
		setIsModalChangePasswordVisible(false)
	}

	const handleSaveModalChangePassword = (passwordCurrent, passwordNew) => {
		changePasswordUserPortalPromise(loginRut, passwordCurrent, passwordNew, countryCode).then(response => {
			if (response) {
				setIsModalChangePasswordVisible(false)
				if (!user.activeUser) {
					let _u = { ...user, activeUser: true }
					SessionStorageService.create('portal-user-status', '200')
					setUser(_u)
				}
			}
		})
	}

	const dropdownMenu = (
		<Menu>
			<Menu.Item>
				<Link to={'#'} onClick={handleOpenModalChangePassword}>
					<Icon type="lock" /> &nbsp;{t('messages.aml.changePwd')}
				</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to={'#'} onClick={handleLogout}>
					<Icon type="logout" /> {t('messages.aml.logout')}
				</Link>
			</Menu.Item>
		</Menu>
	)

	const handleSelectEntity = (id) => {
		let cliente = clientes.filter(c => c.clCliente.id === id)[0]
		setSelectedClient(cliente)
	}

	const handlePrivatePortalOnClick = () => {
		if (selectedClient) {
			setShowPrivate(true)
		} else {
			message.error("Debe seleccionar una empresa")
		}
	}

	const getUrlPerson = (id) => {
		return ('/getImageClient?clientId=' + id)
	}

	const handleLoginRut = (rut) => {
		setLoginRut(rut)
	}

	const handlePageBack = () => {
		setShowPrivate(false)
		setSelectedClient(null)
	}

	return (
		<div className="stakeholder">
			<>
				<Header className="header">
					{/* {!isLoggedIn &&
						<div className="register">
							<Button type="link" onClick={null}>Registrarse</Button>
						</div>
					} */}
					<div className="logo">
						{selectedClient && showPrivate &&
							<ImageResources
								url={getUrlPerson(selectedClient.cliente.id)}
								key={Math.random()}
							/>
						}
					</div>
					{isLoggedIn && user !== null &&
						<div className="current-user">
							<Dropdown overlay={dropdownMenu}>
								<Button key={user.nombre}>
									<Icon type="user" /> {user.nombre} <Icon type="caret-down" />
								</Button>
							</Dropdown>
						</div>
					}
				</Header>
				{isLoggedIn ?
					!showPrivate ?
						clientes?.length > 0 ?
							<>
								<div className="stakeholder-public">
									<div className="login">
										<div className="login-content">
											<Row>
												<Col xs={20} offset={2} className="login-container">
													<div className="login-box" style={{width:600}}>
														<Row gutter={[0, 30]}>
															<Col span={24}>
																Seleccione la Empresa
															</Col>
															<Col>
																<Select style={{ width: '100%' }} placeholder="Seleccionar empresa" onChange={handleSelectEntity}>
																	{clientes.map(item =>
																		<Option value={item.clCliente.id}>
																			{item.clCliente.rut} - {item.clCliente.nombre} - {item.cliente.name}
																		</Option>
																	)
																	}
																</Select>
															</Col>
															<Col span={24}>
																<Button type='primary' onClick={() => handlePrivatePortalOnClick()}>
																	Siguiente
																</Button>
															</Col>
														</Row>
													</div>
												</Col>
											</Row>
										</div>
									</div>
								</div>
							</>
							:
							<div align="center" style={{ padding: 200 }}>
								<h1>Usuario no tiene Empresas asociadas</h1>
								<br />
								<Button type="primary" onClick={handleLogout}>Volver</Button>
							</div>
						:
						<>
							{clientes && clientes.length > 1 &&
								<Tooltip title="Regresar a selección de Empresa" placement="right">
									<Icon type="arrow-left" style={{ fontSize: 30, marginLeft: 10, marginTop: 10 }} onClick={() => handlePageBack()} />
								</Tooltip>
							}
							<StakePrivate client={selectedClient.cliente} currentUser={selectedClient.clCliente} />
						</>
					:
					<PublicPortal countryCode={countryCode} successHandler={handleLogin} handleLoginRut={handleLoginRut} />
				}
				<div id="footer">
					<Row className="footer-inner">
						<Col xs={7}>
							<img className="logo" src={logoAml} alt="" />
							<div className="powered">
								<span>Powered by <a href="https://www.gesintel.cl" target="_blank">Gesintel Compliance S.A.</a></span>
							</div>
						</Col>
					</Row>
				</div>

				{isModalChangePasswordVisible &&
					<ModalChangePassword
						visible={true}
						isForced={user?.activeUser === false}
						onOk={handleSaveModalChangePassword}
						onCancel={handleCloseModalChangePassword}
					/>
				}
			</>
		</div>
	)
}

export default withRouter(StakeholderPortal)
