import "./PortalAlertManager.scss";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Icon } from "antd";
import {
  Page,
  PageBottomBar,
  PageContent,
  PageHeader,
  PageTopBar,
} from "../../../../layouts/Private/components";
import { withTranslation } from "react-i18next";

import { getAlertPromise } from "./promises";
import moment from "moment";
import { TaskRevisionSection, BasicInfoAlertSection, AlertRulesSection } from "../../../ModalAlertCard/components";

class PortalAlertManager extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: this.getBreadcrumbs(),
      alert: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    this.handleRefreshTasks()
  }

  async handleRefreshTasks() {
    this.setState({isLoading: true });
    let alertx = await getAlertPromise(this.props.alertId);
    this.setState({
      alert: alertx,
      isLoading: false
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clickPortal() {
    this.props.clickReturnPortal();
  }

  getBreadcrumbs() {
    const { t } = this.props;
    const { match } = this.props;

    const breadcrumbs = [
      {
        title: "Portal",
        icon: "branches",
        link: match.url,
        onClick: this.clickPortal.bind(this),
      },
      { title: "Alerta", icon: "branches", link: match.url },
    ];

    return breadcrumbs;
  }

  render() {
    const { breadcrumbs, alert, isLoading } = this.state;
    const { t, alertId } = this.props;

    return (
      <div className="portal-alert-manager">
        <PageTopBar breadcrumbs={breadcrumbs} />
          <Page>
            <PageHeader
                title={t("messages.aml.task")}
                onBack={() => null}
                description={
                  alert !== null && (
                    <small>
                      Fecha de alerta :{" "}
                      {moment(alert.creationDate).format("DD/MM/YYYY")}
                    </small>
                  )
                }
                icon="branches"
              >
            </PageHeader>
            {alert !==null && (
              <PageContent>
                {/* { (alert.record || alert.contratante) && <UserDetailsBlock alert={alert} /> }
                <AlertDetailsBlock alert={alert} /> */}
                <BasicInfoAlertSection alert={alert}/>
                <AlertRulesSection className="modal-alert-card" alert={alert} origin="portal"/>
                <TaskRevisionSection
                  className="modal-alert-card"
                  alert={alert}
                  origin="portal"
                  currentUser={this.props.currentUser}
                  taskId={this.props.taskId}
                  refreshHandler={this.handleRefreshTasks.bind(this)}
                />
              </PageContent>
            )}
          </Page>
        <PageBottomBar breadcrumbs={breadcrumbs} />
      </div>
    );
  }
}

export default withTranslation()(withRouter(PortalAlertManager));
