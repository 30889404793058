import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, DatePicker, Row, Select, Tooltip} from "antd";

import './AssignedAlertCard.scss'
import LargeCardDashboard from "../CardDashboard/LargeCardDashobard/LargeCardDashobard";
import {camelizerHelper} from "../../../../../../helpers";
import moment from "moment";
import getResumeAlertsByUserAssign from "../../../../promises/getResumeAlertsByUserAssign";


const AssignedAlertCard = ({handleTabAlertas}) => {

    const {t} = useTranslation()


    const [allUsersAssigned, setAllUsersAssigned] = useState([])
    const [slicedUserAssigned, setSlicedUserAssigned] = useState([])
    const [activeDateRange, setActiveDateRange] = useState(null);
    const [activeUserAssigned, setActiveUserAssigned] = useState(null);

    let filterResume = {
        dateRange: null,
        userAssignId: null
    };

    const percent = [0, 20, 40, 60, 80, 100]

    useEffect(() => {
        handleSearch(filterResume)
    }, [activeDateRange, activeUserAssigned]);


    const handleSearch = (filter) => {

        filter.dateRange = activeDateRange
        filter.userAssignId = activeUserAssigned

        getResumeAlertsByUserAssign(filter).then((response) => {
            let data = response.data
            if (!filter.userAssignId && !filter.dateRange) {
                setAllUsersAssigned(data)
            }
            setSlicedUserAssigned([...data].slice(0, 4))
        })
    }

    //Assigned alert card
    const getLegend = () => {
        return activeDateRange ? (t('messages.aml.monitor.managed.alerts.by.date')) : (t('messages.aml.monitor.managed.alerts'))
    }

    const handleSetActiveDateRange = (dateRange) => {
        setActiveDateRange(dateRange)
    }

    const handleSetActiveUserAssigned = (alertAssigned) => {
        setActiveUserAssigned(alertAssigned);
    };

    const getFilters = (userAsig, status) => {
        let filters = {usuarioAsignado: userAsig}
        if(status) filters.states = [status]
        if(activeDateRange) filters.fechaAlerta = activeDateRange
        return filters
    }

    const getContent = (alertsByUser) => {

        return (
            <>
                {alertsByUser.length !== 0 ?

                    <>
                        {alertsByUser.map((alert, index) =>
                            <Row className={"row-principal-content"}>
                                <Col span={6} offset={1}>
                                    <div className={"alert-asigned-name"}>
                                        <a onClick={() => handleTabAlertas(getFilters(alert.userAssignId))}>
                                            <span className={"asigned-name"}>{camelizerHelper(alert.userAssignName)} </span>
                                        </a>
                                    </div>
                                </Col>


                                <Col span={14} offset={1}>
                                    <div className={"full-advance-assign"}>
                                        <div className={"level-open-alerts"}
                                            onClick={() => handleTabAlertas(getFilters(alert.userAssignId, 'OPEN'))}>
                                            {alert.alertsOpen > 0 && alert.alertsOpen}
                                        </div>
                                        {alert.alertsClosed > 0 &&
                                            <div className={"level-advance-indicator"} style={{width: getWidth(alert) + '%'}}
                                                onClick={() => handleTabAlertas(getFilters(alert.userAssignId, 'CLOSED'))}
                                                >
                                                <div className={"level-closed-alerts"}>{alert.alertsClosed} </div>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>)}

                        <Row className={"row-percent-content"}>
                            <Col span={16} offset={7}>
                                <div>
                                    {percent.map(scale =>
                                            <Col span={4} className={"col-space"}> {scale}% </Col>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row className={"row-info-color-label"}>
                            <Col span={13} offset={9}>

                                <Row>
                                    <Col span={8}>
                                        <div className={"label-legend closed"}></div>
                                        <div
                                            className={"label-content"}>&nbsp;{t("messages.aml.monitor.status.CLOSED")}</div>
                                    </Col>

                                    <Col span={8} offset={1}>
                                        <div className={"label-legend open"}></div>
                                        &nbsp;&nbsp;
                                        <div
                                            className={"label-content"}> &nbsp;{t("messages.aml.monitor.status.OPEN")}</div>
                                    </Col>
                                    <br/>
                                </Row>
                            </Col>
                        </Row>
                    </>

                    :

                    <div className={"label-no-info"}>
                        <Row>
                            <Col offset={7}>
                                {t('messages.aml.monitor.no.info')}
                            </Col>
                        </Row>
                    </div>
                }

                <br/>
                <Row>
                    <Col span={7}>
                        <DatePicker.RangePicker
                            placeholder={[t('messages.aml.from'), t('messages.aml.to')]}
                            style={{width: "100%"}}
                            onChange={momentData => {

                                handleSetActiveDateRange(
                                    momentData !== null && momentData.length > 0 ?
                                        [
                                            moment(momentData[0]).valueOf(),
                                            moment(momentData[1]).valueOf(),
                                        ]
                                        : null)
                            }}
                        />
                    </Col>
                    <Col span={7} offset={1}>
                        <Select
                            style={{width: "100%"}}
                            value={activeUserAssigned}
                            placeholder={"Persona asignada"}
                            onChange={value => handleSetActiveUserAssigned(value)}
                        >
                            <Select.Option value={null}>Persona asignada</Select.Option>
                            {allUsersAssigned.map((user) => (
                                <Select.Option value={user.userAssignId}>
                                    {camelizerHelper(user.userAssignName)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </>
        )
    }


    function getWidth(alertData) {
        return alertData.alertsClosed * 100 / alertData.totalCount
    }


    return (
        <>
            <div className='Alerts-by-user-asigned'>

                <LargeCardDashboard
                    title={t('messages.aml.monitor.assigned.alert.title')}
                    legend={getLegend(null)}
                    content={getContent(slicedUserAssigned)}
                />

            </div>

        </>
    )

}

export default AssignedAlertCard;