
import React,{useEffect, useState} from 'react'
import moment from 'moment'
import { Row, Col, Button, Select, message, Spin, Modal, Divider, notification, Icon, Table} from 'antd'
import { sendBatchActionPromise } from '../../promises'
import { sendBdRequestPromise } from '../../promises'
import { useTranslation } from 'react-i18next'
import formTypeValidator from '../../../../helpers/formTypeValidator'
import { getBeneficiariesMatchesPromise } from './promises/getBeneficiariesMatches'

const ModalRequestOpt = ({type, recipients, handleCancel, currentUser, isSendAll, totalCurrentUser, filterCurrentUser}) => {
  const { t } = useTranslation()
  const [period, setPeriod] = useState(null)
  const [selectedFormType, setSelectedFormType] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [ cdiRecipients, setCdiRecipients ] = useState(recipients)
  const Option = Select;
  const { confirm } = Modal;
  const today = moment().format('DD/MM/YYYY')
  const opciones = { style: 'decimal', minimumFractionDigits: 0 };
  const [findUbosMatches, setFindUbosMatches] = useState([]);
  const [isUbosMatchesLoading, setIsUbosMatchesLoading] = useState(true);

  useEffect(() => {
    if (type === 'beneficiariesMatchs'){
      let idsForMatch = []
      cdiRecipients.map(item => 
        idsForMatch.push(item.id)
      )
      getBeneficiariesMatchesPromise(idsForMatch).then(response => {
        setFindUbosMatches(response.data)
        setIsUbosMatchesLoading(false)
      })
    }
  }, []);

  const showConfirm = () => {
    switch (type) {
      case ('cronForm'):
        if(period){
          confirm({
            title: 'Confirmación',
            cancelText: 'Cancelar',
            content: "¿Quiere programar el envío "+t('messages.aml.onboarding.period.'+period)+" a estas personas?",
            onOk() {
              handleSendRequest()
            },
            onCancel() {
            },
          });
        }else {
          message.info('Seleccione un periodo')
        }
      break;
      case "newRequest":
        confirm({
          title: 'Confirmación',
          cancelText: 'Cancelar',
          content: "¿Quiere enviar el formulario Onboarding a estas personas?",
          onOk() {
            handleSendRequest()
          },
          onCancel() {
          },
        });
      break;
      case "brokeRegister":
        confirm({
          title: 'Confirmación',
          cancelText: 'Cancelar',
          content: "¿Quiere activar el registro de quiebras para estas personas?",
          onOk() {
            handleSendRequest()
          },
          onCancel() {
          },
        });
      break;
      case "uboFinder":
        confirm({
          title: 'Confirmación',
          cancelText: 'Cancelar',
          content: "¿Quiere activar la investigación UBO a estas personas?",
          onOk() {
            handleSendRequest()
          },
          onCancel() {
          },
        });
      break;
      case "form57":
        confirm({
          title: 'Confirmación',
          cancelText: 'Cancelar',
          content:  t('messages.aml.confirm.send.formc57') ,
          onOk() {
            handleSendRequest()
          },
          onCancel() {
          },
        });
      break;
      case ('cronCdiForm'):
        if(cdiRecipients.length === 0) {
          message.info('No hay registros destinatarios')
        }else {
          if(period){
            confirm({
              title: 'Confirmación',
              cancelText: 'Cancelar',
              content: "¿Quiere programar el envío "+t('messages.aml.onboarding.period.'+period)+" a estas personas?",
              onOk() {
                handleSendRequest()
              },
              onCancel() {
              },
            });
          }else {
            message.info('Seleccione un periodo')
          }
        }
      break;
      case "newCdiRequest":
        if(cdiRecipients.length === 0) {
          message.info('No hay registros destinatarios')
        }else {
          confirm({
            title: 'Confirmación',
            cancelText: 'Cancelar',
            content: "¿Quiere enviar el formulario de Conflicto de Interés a estas personas?",
            onOk() {
              handleSendRequest()
            },
            onCancel() {
            },
          })
        }
      break;
      default:
      break;
    }
  }

  const copyTable = (tableId) => {
    let elTable = null;
      elTable = document.getElementById(tableId)
  

    let range, sel;
    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
      document.execCommand('copy');
    }
    sel.removeAllRanges();
    message.success("Tabla copiada correctamente")
  }

  const handleSendRequest = () => {
    setIsLoading(true)
    if (type === 'newRequest') {
      sendBdRequestPromise({recipients: recipients.map((recipient) => recipient.id)}, "KYC", filterCurrentUser, isSendAll).then(results => {
        setIsLoading(false)
        if(results === 'Solicitud eviada correctamente') {
            message.success('Solicitud enviada con éxito')
            handleCancel();
        }else {
            notification.error({
            message: 'Error'
          })
        }
      })
    }else if (type === 'newCdiRequest'){
      sendBdRequestPromise({recipients: cdiRecipients.map((recipient) => recipient.id)}, selectedFormType, filterCurrentUser, isSendAll).then(results => {
        setIsLoading(false)
        if(results === 'Solicitud eviada correctamente') {
            message.success('Solicitud enviada con éxito')
            handleCancel();
        }else {
            notification.error({
            message: 'Error',
          })
        }
      })
    }else if (type === 'cronForm' || type === 'brokeRegister' || type === 'uboFinder' || type === 'form57' || type === 'cronCdiForm') {
      let rec = recipients
      if (type === 'cronCdiForm') rec = cdiRecipients
 
      sendBatchAction(type === 'cronCdiForm' ? 'cronForm' : type, type === 'cronForm' ? 'KYC' : selectedFormType, period === 'N' ? null : period, rec.map((recipient) => recipient.id), filterCurrentUser, isSendAll)
    }
  }

  const sendBatchAction = (action, typeForm, period, ids, filters, isSendAll) => {
    sendBatchActionPromise(action, typeForm, period, ids, filters, isSendAll).then(results => {
      setIsLoading(false)
      if(results === 'OK') {
          message.success('Solicitud enviada con éxito')
          handleCancel();
      }else {
          notification.error({
            message: 'Error',
            description: 'Ha ocurrido un error'
          })
      }
    })
  }

  const validaTypeForm = (type, record) => {
    if(type === 'CDI' && currentUser.modules.includes('CDI-FORM-GD') && currentUser.cliente.modules.includes('CDI-FORM-CDI') && (!record || record.category === 'COLABORADOR' || record.category === 'PROVEEDOR')) {
      return true
    }else if(type === 'REL' && currentUser.modules.includes('CDI-FORM-GREL') && currentUser.cliente.modules.includes('CDI-FORM-REL') && (!record || record.category === 'COLABORADOR')) {
      return true
    }else if(type === 'DIR' && currentUser.modules.includes('CDI-FORM-GDIR') && currentUser.cliente.modules.includes('CDI-FORM-DIR') && (!record || record.category === 'DIRECTOR')) {
      return true
    }else if(type === 'PATR' && currentUser.modules.includes('CDI-FORM-GPATR') && currentUser.cliente.modules.includes('CDI-FORM-PATR') && (!record || record.category === 'CLIENTE')) {
      return true
    }else if(type === 'GIFT' && currentUser.modules.includes('CDI-FORM-GF-G') && currentUser.cliente.modules.includes('CDI-FORM-G') && (!record || record.category === 'COLABORADOR')) {
      return true
    }else if(type === 'TRAVEL' && currentUser.modules.includes('CDI-FORM-GF-T') && currentUser.cliente.modules.includes('CDI-FORM-T') && (!record || record.category === 'COLABORADOR')) {
      return true
    }else if(type === 'FP' && currentUser.modules.includes('CDI-FORM-GF-F') && currentUser.cliente.modules.includes('CDI-FORM-F') && (!record || record.category === 'COLABORADOR')) {
      return true
    }else if(type === 'SOC' && currentUser.modules.includes('CDI-FORM-GF-S') && currentUser.cliente.modules.includes('CDI-FORM-S') && (!record || record.category === 'COLABORADOR')) {
      return true
    }else if(type === 'PEP' && currentUser.modules.includes('FORM-PEP') && currentUser.cliente.modules.includes('FORM-PEP')) {
      return true
    }
    return false
  }

  const changeSelectedFormType = (value) => {
    setSelectedFormType(value)
    if(!isSendAll){
      let rec = []
      recipients.map(recipient => {
        if(validaTypeForm(value, recipient)) {
          rec.push(recipient)
        }
      })
      setCdiRecipients(rec)
    }
  }

  const ubosMatchsColumns = [
    {
      title: "Nombre",
      dataIndex: "name"
    },
    {
      title: "Rut",
      dataIndex: "rut"
    }
  ]

  return (
  isLoading ? 
    <Row>
      <Col span={24} style={{justifyContent: 'center', display: 'flex'}}>
        <Spin size="large" />
      </Col>
    </Row>
  :
    <div className="modal-remind-opt">
      {type === 'newRequest' ?
          <>
            <Row gutter={[0, 64]}>
              <Col>
                El sistema enviará automáticamente una nueva solicitud del formulario de Onboarding a las siguientes personas:
              </Col>
            </Row>
            <Row gutter={[0, 48]}>
              <Col>
                <ul>
                  {recipients.map((recipient) => <li>{recipient.name}</li>)}
                </ul>
              </Col>
            </Row>
          </>
        : type === 'cronForm' ?
        <>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              Registros seleccionados:
            </Col>
              <Col span={24}>
                <ul>
                  {recipients.map(e => <li>{e.name}</li>)}
                </ul>
              </Col>
              <Divider/>
            <Col>
              A continuación seleccione el periodo de tiempo en el que se enviará el formulario de OnBoarding:
            </Col>
          </Row>
          <Row gutter={[0, 24]}>
            <Col span={10} push={7}>
              <Select style={{width: '100%'}} onChange={(e) => setPeriod(e)}>
                <Option value="N">{t('messages.aml.onboarding.period.N')}</Option>
                <Option value="MONTHLY">{t('messages.aml.onboarding.period.MONTHLY')}</Option>
                <Option value="QUARTERLY">{t('messages.aml.onboarding.period.QUARTERLY')}</Option>
                <Option value="BIANNUAL">{t('messages.aml.onboarding.period.BIANNUAL')}</Option>
                <Option value="ANNUAL">{t('messages.aml.onboarding.period.ANNUAL')}</Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              {"AMLupdate enviará automáticamente una solicitud vía correo electrónico, a partir de la fecha de hoy ("+today+"). Si requiere desactivar el envío periodico sólo debe ingresar a la ficha de cliente e inactivar el botón de periodicidad"}
            </Col>
          </Row>
        </>
        : type === 'brokeRegister' ?
          <>
              <Row gutter={[0, 64]}>
                <Col>
                  El sistema activará automáticamente el seguimiento de quiebras para las siguientes personas:
                </Col>
              </Row>
              <Row gutter={[0, 48]}>
                <Col>
                  <ul>
                    {recipients.map((recipient) => <li>{recipient.name}</li>)}
                  </ul>
                </Col>
              </Row>
            </>
        : type === 'uboFinder' ?
          <>
            <Row gutter={[0, 64]}>
              <Col>
                El sistema activará automáticamente la investigación de UBOs para las siguientes empresas:
              </Col>
            </Row>
            <Row gutter={[0, 48]}>
              <Col>
                <ul>
                  {recipients.map((recipient) => <li>{recipient.name}</li>)}
                </ul>
              </Col>
            </Row>
          </>
        : type === 'form57' ?
          <>
            <Row gutter={[0, 64]}>
              <Col>
                {t('messages.aml.system.c57.information')}
              </Col>
            </Row>
            <Row gutter={[0, 48]}>
              <Col>
                <ul>
                {recipients.map((recipient) => <li>{recipient.name}</li>)}
                </ul>
              </Col>
            </Row>
          </>
        : type === 'newCdiRequest' ?
          <>
            <Row type='flex' justify='center' gutter={[0, 20]}>
              <Col span={24}>Seleccione la declaración</Col>
              <Col span={24}>
                <Select placeholder="Seleccione el tipo de comunicado" onChange={ changeSelectedFormType } style={{width: '100%'}}>
                  {formTypeValidator(currentUser, recipients.length > 1 ? null : recipients[0].category).map(type => {
                    return <Select.Option value={type}>{t('messages.aml.type.'+type)}</Select.Option>
                  })}
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 25]} style={{marginTop: 20}}>
              <Col span={24}>
                El sistema enviará automáticamente una solicitud de la declaración a las siguientes personas:
              </Col>
              <Col span={24}>
                <ul>
                  {cdiRecipients.map((recipient) => <li>{recipient.name}</li>)}
                </ul>
              </Col>
            </Row>
          </>
        : type === 'beneficiariesMatchs' ?
          <>
            <Row gutter={[0, 25]}>
              <Col span={24}>
                El sistema buscará coincidencias de UBOS (Beneficiarios Finales) entre las siguientes empresas:
              </Col>
              <Col span={24}>
                <ul>
                  {cdiRecipients.map((recipient) => <li>{recipient.name}</li>)}
                </ul>
              </Col>
            </Row>
            <Divider>
              Resultados
            </Divider>
            <Row>
              <Col span={24}>
                {isUbosMatchesLoading ?
                    <div style={{textAlign:"center"}}>
                      <Spin tip="Cargando matches..."
                        indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
                      />
                    </div>
                  : 
                    <Row>
                   
                      <div style={{textAlign:"center"}}>
                        { findUbosMatches.length > 0 ?
                          <Table columns={ubosMatchsColumns} dataSource={findUbosMatches} id='beneficiariesMatchsTableInModal' pagination={false}/>
                        :
                          <h3>
                            No se encontraron coincidencias.
                          </h3>
                        }
                      </div>
                    </Row>
                }
              </Col>
            </Row>
          </>
        : type === 'cronCdiForm' ?
          <>
            <Row>
              <Col span={10}>Seleccione la declaración</Col>
              <Col span={14}>
                <Select placeholder="Seleccione el tipo de comunicado ..." onChange={ changeSelectedFormType } style={{width: '100%'}}>
                  {formTypeValidator(currentUser, recipients.length > 1 ? null : recipients[0].category).map(type => {
                    return <Select.Option value={type}>{t('messages.aml.type.'+type)}</Select.Option>
                  })}
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                Registros seleccionados:
              </Col>
              <Col span={24}>
                <ul>
                  {cdiRecipients.map(e => <li>{e.name}</li>)}
                </ul>
              </Col>
              <Divider/>
              <Col span={24}>
                A continuación seleccione el periodo de tiempo en el que se enviará el formulario de Conflicto de Interés:
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={10} push={7}>
                <Select style={{width: '100%'}} onChange={(e) => setPeriod(e)}>
                  <Option value="MONTHLY">{t('messages.aml.onboarding.period.MONTHLY')}</Option>
                  <Option value="QUARTERLY">{t('messages.aml.onboarding.period.QUARTERLY')}</Option>
                  <Option value="BIANNUAL">{t('messages.aml.onboarding.period.BIANNUAL')}</Option>
                  <Option value="ANNUAL">{t('messages.aml.onboarding.period.ANNUAL')}</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                {"AMLupdate enviará automáticamente una solicitud vía correo electrónico, a partir de la fecha de hoy ("+today+"). Si requiere desactivar el envío periodico sólo debe ingresar a la ficha de cliente e inactivar el botón de periodicidad"}
              </Col>
            </Row>
          </>
        : null 
      }
      { type !== 'beneficiariesMatchs' ?
          <Row>
            <Divider/>
            { isSendAll ? 
              <span>Esta acción afectará a {totalCurrentUser.toLocaleString('es-ES', opciones)} Registros,<br/>Estás seguro de que quieres continuar?</span> : "" }        
            <Button style={{float: 'right'}} onClick={showConfirm} disabled={(type === 'newCdiRequest' || type === 'cronCdiForm') && selectedFormType === null}>
              Aceptar
            </Button>
          </Row>
        : findUbosMatches.length > 0 &&
          <Row type='flex' justify='center'>
            <Button style={{textAlign: 'center', marginTop: 20}} type="primary" onClick={() => copyTable('beneficiariesMatchsTableInModal')} disabled={(type === 'newCdiRequest' || type === 'cronCdiForm') && selectedFormType === null}>
              Copiar Tabla
            </Button>
          </Row>
      }
    </div>
  )
}

export default ModalRequestOpt
