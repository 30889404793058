import { notification } from 'antd'
import {getMonitoringRulesService} from '../services/getMonitoringRulesService'

export const getMonitoringRulesPromise= () => {
	return new Promise(resolve => {
		getMonitoringRulesService()
			.then(response => resolve({ success: true, data: response.data }))
			.catch(error => {notification.error({message: "Alert Resume : "+error.message})})
	})
}

