import {Col, Row, Spin} from 'antd';
import React, {useEffect, useState} from 'react'
import Plot from 'react-plotly.js';
import './RelationsCdi.scss'
import {getRelationCdiResumePromise} from "./promises/getRelationCdiResumePromise";

export const RelationsCdi = ({chartColor, handleTabRedirection }) => {

    const [loading, setLoading] = useState(false);
    const [cdiDeclarationData, setCdiDeclarationData] = useState([]);

    useEffect(() => {
        initFunc();
    }, []);

    const initFunc = () => {
        setLoading(true)
        getRelationCdiResumePromise().then(response => {
            setCdiDeclarationData(response.data)
        }).then(() => setLoading(false))
    };

    const resultFormater = (numero) => {
        return (!isNaN(parseFloat(numero)) && isFinite(numero)) ?
         numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):0
    }

    const handleClick = (event) => {
        const labelSelected = event.points[0].label;
        let vinculos = labelSelected === 'Conflicto existente' ? "SI" : "NO";
        handleTabRedirection(getFiltersToRender(vinculos), 'records')
    };

    const getFiltersToRender = (vinculos) => {
        let filters = {
            advanced: {
                cdi: {
                    vinculos: vinculos
                },
            }
        };
        return filters;
    };

    return (
        <Spin spinning={loading}>
            <div className='inside-relations-cdi-pie-chart'>
                <Row type='flex' justify='center' align='middle'>
                    <Col span={16}>
                        {(cdiDeclarationData[0]?.total !== 0)? (
                        <Plot
                            data={[
                                {
                                values: [(cdiDeclarationData[0]?.total - cdiDeclarationData[0]?.conVinculos), cdiDeclarationData[0]?.conVinculos],
                                labels: ['Sin vínculo', 'Conflicto existente' ],
                                marker: { color: [chartColor[0]?.color, chartColor[1]?.color] },
                                type: 'pie',
                                hole: 0.5,
                                }]
                        }
                            layout={{
                                paper_bgcolor: 'rgba(0,0,0,0)',
                                plot_bgcolor: 'rgba(0,0,0,0)',
                                height: 300,
                                margin: {
                                    t: 15,
                                    b: 0,
                                    l: 0,
                                    r: 30
                                },
                            }}
                            useResizeHandler={true}
                            style={{width: '100%'}}
                            config={{
                                displayModeBar: false,
                            }}
                            onClick={handleClick}

                        />
                        ) : (
                            <div className="text-no-content">No hay datos disponibles</div>
                        )}
                    </Col>


                    <Col span={8}>
                        <Col span={24}>
                            <div className='big-number-inside'>
                                {resultFormater(cdiDeclarationData[0]?.total - cdiDeclarationData[0]?.conVinculos)}
                            </div>
                            <div className='small-text-relations-cdi'>
                                Sin vínculo
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='big-number-inside' style={{color: '#ee220c'}}>
                                {resultFormater( cdiDeclarationData[0]?.conVinculos)}
                            </div>
                            <div className='small-text-relations-cdi'>
                                Conflicto existente
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Spin>
    )
}
