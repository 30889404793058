import "./formPep.scss";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row, Spin, DatePicker, Select, notification, Checkbox, InputNumber, Modal } from "antd";
import { FormLayout } from "../../../../../layouts";
import { withRouter } from "react-router-dom";
import { getFormPromise, sendFormPromise, saveFormPromise } from "../../../promises";
import moment from "moment";
import Logo from '../../../../../layouts/commonComponent/components/Logo/Logo'
import { getCountriesCodePromise } from "../../../../OnBoarding/promises";
import apiConfig from '../../../../../config/api'

const FormPep = ({ form, match }) => {
  const { t } = useTranslation();
  const { getFieldDecorator, validateFields, getFieldsError } = form;
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [paises, setPaises] = useState(null);
  const [date, setDate] = useState(0);
  const [user, setUser] = useState({});
  const [apiForm, _setApiForm] = useState(null);
  const [colLogo, setColLogo] = useState(4);
  const [format, setFormat] = useState("html");
  const [openSigner, setOpenSigner] = useState(false);
  const hasSign = false
  const [clientName, setClientName] = useState("");
  const [sentErrors, setSentErrors] = useState(0);
  const [signData, setSignData] = useState(null);
  const apiFormRef = useRef(apiForm);

  let registersStop = {};

  const setApiForm = data => {
    apiFormRef.current = data;
    _setApiForm(data);
  };

  const infoPepIS= {
    infoPepIsPep: { key: "isPep", val: "",size: "small", title: "", maxLength:200, id: "infoPepIsPep", required: true, type: 'select'},
    infoPepNationality: { key: "nationality", val: "",size: "small", title: "", maxLength:200, id: "infoPepNationality", required: true, type: 'select'},
  };
  const [infoPep, setInfoPep]=useState(infoPepIS);

 // END INIT OBJ

  const validateLengthFieldWithInnerLength = (section)=>{
    return {
      max: section.maxLength,
      message: "Debe tener un máximo de "+ section.maxLength  + " caracteres"
    }
  }

  const customState=(sectionId)=>{
    if (sectionId.startsWith('infoPep')) {
      return([infoPep,setInfoPep])
    }
  }

  const entriesToObj = (obj) => {
    const objAttr = {}
    Object.entries(obj).map(([key, value]) => {
      if(value.section) {
        if(!objAttr[value.section]) objAttr[value.section] = {}
        objAttr[value.section][value.key] = value.val
      }else {
        objAttr[value.key] = value.val
      }
    })
    return objAttr
  }

  const handlerOnChangeObjAttr = async (sectionId, value, formObj) => {
    handleOnChangeField(sectionId, value)
    const [stateObject,stateObjectSetter] = customState(sectionId)
    stateObject[sectionId].val = value

    const iPep = entriesToObj(infoPep)


    if(!formObj) formObj = apiForm
    const json = { infoPep: iPep }
    let formToUpdate = { ...formObj, json };
    let ret = await saveFormOnbPromiseLocal(formToUpdate);
    if(!ret.success) {
    //  setFieldsValue({[field]: ret.form[field]})
    }
  }

  const handleOnChangeField = (sectionId, value) => {
    const [stateObject, stateObjectSetter] = customState(sectionId)
    stateObjectSetter({ ...stateObject, [sectionId]: { ...stateObject[sectionId], val: value }});
  };

  const objToVariable = (variable, obj) => {
    let varAttr = { ...variable }
    for(var key in obj) {
      for(var prop in variable) {
        if(variable[prop].key === key && varAttr[prop]) {
          varAttr[prop].val = obj[key]
        }else if(typeof obj[key] === "object" && variable[prop].section === key) {
          for(var attr in obj[key]) {
            if(variable[prop].key === attr && varAttr[prop]) {
              varAttr[prop].val = obj[key][attr]
            }
          }
        }
      }
    }
    return varAttr
  }


  useEffect(() => {
    if (match.params.view === "pdf") {
      setColLogo(5);
      setFormat("pdf");
    }

    getCountriesCodePromise().then((response) => {
      setPaises(response)
    })
    setIsloading(true);
    getFormPromise(match.params.id).then((response) => {
      if (response?.data?.status ) {
        setApiForm(response.data);
        initVariablesFromObject(response.data, response.data.recipient.record)
        setUser(response.data.recipient.request.createUser);
        setClientName(response.data.recipient.request.createUser.cliente.name);

        if(response.data.status === "SENT") {
          setDate(moment(response.data.receiveDate).format("DD-MM-YYYY"));
        }else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
    });

    const handleMessage = (message) => {
      if(message.data && typeof message.data === 'string' && message.data.startsWith('DOCUSIGN') && apiConfig.url.startsWith(message.origin)) {
        const data = message.data.split('|')
        if(data.length === 4) {
          if(data[1] === "signing_complete") {
            const reqId = data[2] + "|" + data[3]
            sendDocument(reqId, apiFormRef.current)
          }else {
            closeHandlerSigner();
            notification["warning"]({
              message: "Firma cancelada",
            });
          }
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const closeHandlerSigner = () => {
    setSignData(null)
    setOpenSigner(false)
  }

  const initVariablesFromObject = (obj) => {
    if(obj.json) {
      if(obj.json.infoPep) {
        let iPep = objToVariable(infoPep, obj.json.infoPep)
        setInfoPep(iPep)
      }
    
    }
  }

  const saveFormOnbPromiseLocal = async (form) => {
    let response = await saveFormPromise(form);
    if(response.code !== 'OK' && response.form !== undefined && response.form !== null) {
      setApiForm(response.form)
      initVariablesFromObject(response.form)

      notification["error"]({
        message: t("messages.aml.notifications.anErrorOcurred"),
      });

      return { success: false, form: response.form}
    }else {
      setApiForm(form)
      return { success: true }
    }
  }

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  const sendDocument = (requestId = "", formObj = apiForm) => {
    sendFormPromise(match.params.id, requestId).then((response) => {
      if (response.code === "OK") {
        let formSend = { ...formObj, status: "SENT" };
        setApiForm(formSend);
      } else {
        let errores = sentErrors+1
        setSentErrors(errores)

        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: errores === 1 ? 'Error Interno: Actualice y reintente.' : 'Comuníquese con soporte.aml@gesintel.cl'
        });
        setApiForm(response.form);
      }
    });
  }

  const handleReadOnly = (field,readOnly,sectionId=null)=>{
    if (sectionId===null){
      return null
    }else{
      settingStateObj(sectionId,readOnly)
    }
  }

  const settingStateObj=(sectionId,readOnly)=>{
    const [stateObject,stateObjectSetter] = customState(sectionId)
    stateObjectSetter({...stateObject,[sectionId]:{...stateObject[sectionId],readOnly:readOnly}})
  }

  const handleSubmit = async (e) => {
    //VALIDACIONES
    e.preventDefault();
    setIsValidate(true);
    validateFields([infoPep.infoPepIsPep.id, infoPep.infoPepNationality.id])

    if (
      hasErrors(getFieldsError()) ||
      Object.values(registersStop).find((value) => value === true) !== undefined
    ) {
      notification["warning"]({
        message: t("messages.aml.missingRequiredField"),
      });
    } else {
      //FUNCION DE PROMISE Y GESTION DE RESPONSE
      sendDocument()
    }
  };

  const renderFormItem = (formItem) => {
    return (
        <Col
          className={formItem.colClassName}
          span={formItem.wrapperCols}
          offset={formItem.offset}
        >
          <Form.Item
            className={formItem.itemClassName}
            label={formItem.label}
            labelCol={formItem.labelCol > 0 ? { span: formItem.labelCol } : {}}
            wrapperCol={
              formItem.labelCol > 0 ? { span: formItem.wrapperCol } : {}
            }
          >
            {getFieldDecorator(formItem.name, {
              rules: formItem.rules,
              initialValue: formItem.initialValue,
              validateTrigger: "onChange",
            })(formItem.item)}
          </Form.Item>
        </Col>
    );
  };

  const renderFormItemTable = ({ section, cols=format === 'html' ? 6 : 8, offset, labelCol=0, wrapperCol=0, options=[], validator=null, customRule=null, handlerOnChangeObj=handleOnChangeField, customTitle, colsOption }) => {
    const type = section.type ? section.type : 'input'
    const required = "required" in section ? section.required : true
    const disabled = "disabled" in section ? section.disabled : false
    const initialValue = section.val && section.val !== '' && format === 'html' && type === 'date' ? moment(section.val, "DD/MM/YYYY") : section.val
    return renderFormItem({
      label: customTitle ? customTitle : section.title,
      name: section.id,
      initialValue: initialValue,
      colClassName: "topLabel",
      labelCol: labelCol,
      wrapperCol: wrapperCol,
      rules:
      [
        { required: required, message: t( "messages.aml.requestedField")},
        ...validator ? [{ validator: validator }]:[],
        ...type==='input' ? [validateLengthFieldWithInnerLength(section)]:[],
        ...customRule ? [customRule]:[]
      ],
      wrapperCols: cols,
      offset: offset,
      item: (
        format === 'html' || type === 'check' ?
          type === 'input' ?
            <Input
              placeholder="Ingrese texto"
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
              readOnly = {section.readOnly}
              onChange={(e) => handlerOnChangeObj( section.id, e.target.value ) }
              disabled={disabled}
            />
          : type === 'number' ?
            <InputNumber
              placeholder="Ingrese un número"
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
              min= {section.min}
              max={section.max}
              formatter = { section.isCurrency ? (value => `$ ${value}`)  : section.isPercentage ? (value => `${value}%`) : null}
              readOnly = {section.readOnly}
              onChange={(value) => {
              handlerOnChangeObj( section.id, value )
              }}
              disabled={disabled}
            />
          : type === 'textarea' ?
            <Input.TextArea
              placeholder="Ingrese texto"
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
              readOnly = {section.readOnly}
              onChange={(e) => handlerOnChangeObj( section.id, e.target.value ) }
              disabled={disabled}
              style = {{width: '100%'}}
            />
          : type === 'select' ?
            <Select
              allowClear
              showSearch
              size={section.size}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={section.multiple ? "Seleccione una o más opciones" : "Seleccione una opción"}
              onChange={(value, codigo) => handlerOnChangeObj(section.id, value, null, codigo)}
              disabled={disabled}
              style={{ width: '100%' }}
              mode = {section.multiple ? 'multiple' : ''}
            >
              { options.map((option) => (<Select.Option value={option.val} key={option.key} code={option.code}>{ option.text }</Select.Option>)) }
            </Select>
          : type === 'date' ?
            <DatePicker
              onFocus= {(e)=>handleReadOnly(e.currentTarget.id,false,section.id)}
              onBlur= {(e)=>handleReadOnly(e.currentTarget.id,true,section.id)}
              readOnly = {section.readOnly}
              format={"DD/MM/YYYY"}
              placeholder={"Ingrese la fecha"}
              style = {{width: '100%'}}
              disabled={disabled}
              mode={type}
              onChange={(momentObj, str) => {
                handlerOnChangeObj( section.id, momentObj ? moment(momentObj).format( "DD/MM/YYYY" ) : null )
              }}
            />
          : type === 'check' ?
            <Checkbox.Group
              style={{width: '100%'}}
              className={section.title && section.title !== '' ? 'has-title' : ''}
              onChange={(value) => handlerOnChangeObj(section.id, value) }
              >
                <hr/>
              {options.map((option) => (
                <Col span={colsOption ? colsOption : section.colsOption}>
                  <Checkbox value={option.val}>
                    { option.text }
                  </Checkbox>
                </Col>
              ))}
            </Checkbox.Group>
          :<Input type="small"/>
        :
          <Input size="small"/>
      ),
    })
  }

  const renderFormItemObj = (params) => {
    return renderFormItemTable({...params, handlerOnChangeObj: handlerOnChangeObjAttr})
  }

  return (
    <FormLayout view={match.params.view} >
      <div style={{ position: "relative" }}>
        {isLoading ? (
          <div className="form-header">
            <Row>
              <Col xs={9}></Col>
              <Col xs={6}>
                <div className="form-wrapper" style={{ textAlign: "center" }}>
                  <Spin style={{ fontColor: "#fff" }} size={"large"} />
                </div>
              </Col>
              <Col xs={9}></Col>
            </Row>
          </div>
        ) : (
          <>
            {apiForm !== null ? (
              <div
                className={
                  "form-content-kyc " + format + " " +(!isValidate ? " form-validate-messages" : "")
                }
              >
                <Form onSubmit={handleSubmit} className="form-form">
                  <Row
                    className="title-logo"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col
                      xs={24 - colLogo}
                      sm={24 - colLogo}
                      md={24 - colLogo}
                      lg={24 - colLogo}
                      xl={24 - colLogo}
                    >
                      <h3>DECLARACIÓN DE VÍNCULO CON PERSONAS EXPUESTAS POLÍTICAMENTE (PEP)</h3>
                    </Col>
                    <Col
                      className="logo-col"
                      xs={colLogo}
                      sm={colLogo}
                      md={colLogo}
                      lg={colLogo}
                      xl={colLogo}
                    >
                      <Logo currentUser={{ userId: user.id, subclienteId: '0' }} isForm={true} />
                    </Col>
                  </Row>
                  <Row
                    className="date"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col span={2} style={{ textAlign: "left" }}>
                      Fecha:
                    </Col>
                    <Col span={4}>
                      <div
                        className="formDate"
                        style={{
                          marginLeft: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {date}
                      </div>
                    </Col>
                    <Col span={13} style={{ textAlign: "right" }}>
                      Folio:
                    </Col>
                    <Col span={5}>
                      <div
                        className="formDate"
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "8px",
                        }}
                      >
                        {apiForm.folio !== null ? apiForm.folio : ""}
                      </div>
                    </Col>
                  </Row>
                  {apiForm.status === "SENT" &&
                  (format === "html") ? (
                    <>
                      <br />
                      <h3 style={{ textAlign: "center" }}>
                        Estimado(a){/* {apiForm.recipient.record.nombre} */}
                        {apiForm.recipient.record.name}, le informamos que su declaración fue correctamente
                        completada, agradecemos su tiempo y disposición.
                        <br />
                        Hemos enviado una copia de la declaración realizada al
                        correo electrónico registrado:<br/><br/>
                        {apiForm.recipient.record.email}
                      </h3>
                    </>
                  ) : (
                    <div className="form-pep-container-text">
                      <Row>
                        <Col span={format === 'html' ? 13 : null}>
                          Yo, <strong>{apiForm.recipient.record.nombre}</strong>, cédula nacional de 
                          identidad/pasaporte Nº <strong>{apiForm.recipient.record.rut}</strong>, 
                          de nacionalidad 
                        </Col>
                        <Col span={4}>
                          <div>
                            {renderFormItemObj({
                                section: infoPep.infoPepNationality,
                                cols: null,
                                initialValue: infoPep.infoPepNationality.val,
                                options:
                                  paises.map(item => {
                                    return {val: item.country, text: item.country}
                                })
                              })
                            }
                          </div> 
                        </Col>
                        <Col span={2}>
                          , declaro
                        </Col>
                        <Col span={3}>
                          <div>
                            {renderFormItemObj({
                                section: infoPep.infoPepIsPep,
                                cols: null,
                                options:[
                                  {val: "Ser", text:"Ser"},
                                  {val: "No Ser", text:"No Ser"}
                                ]
                              })
                            }
                          </div> 
                        </Col>
                        cónyuge o pariente hasta el segundo grado de consanguinidad 
                        (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)), 
                        ni haber celebrado pacto de actuación conjunta mediante el cual tengan poder 
                        de voto suficiente para influir en sociedades constituidas en Chile, con 
                        ninguna de las Personas Políticamente Expuestas que a continuación se indican, 
                        sea que actualmente desempeñen o hayan desempeñado uno o más de los 
                        siguientes cargos:
                      </Row>
                      

                      <br/><br/><br/>

                      <ol>
                        <li>Presidente de la República.</li>
                        <li>Senadores, Diputados y Alcaldes.</li>
                        <li>Ministros de la Corte Suprema y Cortes de Apelaciones.</li>
                        <li>Ministros de Estado, Subsecretarios, Intendentes, Gobernadores, Secretarios Regionales Ministeriales, Embajadores, Jefes Superiores de Servicio, tanto centralizados como descentralizados y el directivo superior inmediato que deba subrogar a cada uno de ellos.</li>
                        <li>Comandantes en Jefe de las Fuerzas Armadas, Director General Carabineros, Director General de Investigaciones, y el oficial superior inmediato que deba subrogar a cada uno de ellos.</li>
                        <li>Fiscal Nacional del Ministerio Público y Fiscales Regionales.</li>
                        <li>Contralor General de la República.</li>
                        <li>Consejeros del Banco Central de Chile.</li>
                        <li>Consejeros del Consejo de Defensa del Estado.</li>
                        <li>Ministros del Tribunal Constitucional.</li>
                        <li>Ministros del Tribunal de la Libre Competencia.</li>
                        <li>Integrantes titulares y suplentes del Tribunal de Contratación Pública.</li>
                        <li>Consejeros del Consejo de Alta Dirección Pública.</li>
                        <li>Los directores y ejecutivos principales de empresas públicas, según lo definido por la Ley Nº 18.045.</li>
                        <li>Directores de sociedades anónimas nombrados por el Estado o sus organismos.</li>
                        <li>Miembros de las directivas de los partidos políticos.</li>
                    </ol>
                    </div>
                  )}
                  {apiForm.status !== "SENT" &&
                    <Row className="button-row">
                      <Col className="submitTrabajador" xl={24}>
                        <Button type="primary" onClick={handleSubmit} icon='file-protect'>
                          { hasSign ? 'Firmar' : 'Enviar' }
                        </Button>
                      </Col>
                    </Row>
                  }
                </Form>
              </div>
            ) : (
              <h2 style={{ textAlign: "center" }}>Formulario no encontrado</h2>
            )}
          </>
        )}
      </div>
    </FormLayout>
  );
};

export default withRouter(Form.create()(FormPep));
