import "./Sidebar.scss";
import React, { useEffect } from "react";
import { Col, Icon, Badge } from "antd";
import { useTranslation } from 'react-i18next';

const Sidebar = ({ client, currentUser, activeTab, onTabChange, edoTasks }) => {
  const {t} = useTranslation()
  return (
    <Col className="sidebar" span={5}>
      <div className="sidebar-inner">
        <div className="menu-block">
          <ul>
            {client?.modules.includes("CDI-FORM") && (
              <>
                <h3>Conflicto de Interés</h3>
                <li
                  className={
                    activeTab === "tab-newdeclaraciones" ? "active" : ""
                  }
                  onClick={() => onTabChange("tab-newdeclaraciones")}
                >
                  <div className="menu-item-inner">
                    Declaraciones
                    <Icon type="check" />
                  </div>
                </li>
              </>
            )}

            {client?.modules.includes("ADMCLI") && currentUser.type === 'Entity' &&
              (!currentUser.formularioInterno) &&
              <>
                <h3>Beneficiarios Finales</h3>
                <li
                  className={
                    activeTab === "tab-beneficiarios-finales" ? "active" : ""
                  }
                  onClick={() => onTabChange("tab-beneficiarios-finales")}
                >
                  <div className="menu-item-inner">
                    Formularios Circular 57
                    <Icon type="check" />
                  </div>
                </li>
              </>
            }

            {client?.modules.includes("MONITOR") && edoTasks !== null && (
              <>
                <h3>Monitoreo</h3>
                {Object.entries(edoTasks).map(([key, val]) => (
                  <li key={val}
                    className={activeTab === "tab-tasks-" + key ? "active" : val === 0 ? "disabled-tab":""}
                    onClick={val > 0 ?(() => onTabChange("tab-tasks-" + key)) : null}
                  >
                    <div className="menu-item-inner">
                      <span>{t("messages.aml.tasks."+key)}</span>
                      <Badge count={val} size="small" showZero/>
                      <Icon type="check" />
                    </div>
                  </li>
                ))}
              </>
            )}

          {/* INICIO TABS DE ONBOARDING */}
            {client?.modules.includes("ONBOARDING") && (
              <>
                <h3>OnBoarding</h3>
                <li
                  className={ activeTab === "tab-onboarding" ? "active" : "" }
                  onClick={() => onTabChange("tab-onboarding")}
                >
                  <div className="menu-item-inner">
                    <Icon type="form" className="option"/>
                    Formularios
                    <Icon type="check" />
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default Sidebar;
