import "./ModalViewRules.scss";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Select, Switch, Input, Table,Icon } from "antd";
import { camelizerHelper } from "../../../../helpers/";
import moment from "moment";

const ModalViewRules = ({
  modalItem,
  onCancel,
  handleChangeModal,
  historyTable,
}) => {
  const [itemCopy, setItemCopy] = useState({});
 

  const [changesCounter, setChangesCounter] = useState(0);

  useEffect(() => {
    if (modalItem !== undefined) {
      setItemCopy(JSON.parse(JSON.stringify(modalItem)));
    }
  }, []);

  const handleEditItemCopy = (parameterIndex, parameter, value) => {
    const parametersCollection = itemCopy.params;
    parametersCollection[parameterIndex].value = value;
    setItemCopy({ ...itemCopy, params: parametersCollection });
    setChangesCounter(changesCounter + 1);
  };

  const handleChangeField = (field, value) => {
    setItemCopy({ ...itemCopy, [field]: value });
    setChangesCounter(changesCounter + 1);
  };

  const tableColumns = [
    {
      title: "Fecha",
      dataIndex: "fecha",
      render: (text) => {
        return text !== null ? moment(text).format("DD/MM/YYYY HH:mm") : "-";
      },
    },
    {
      title: "Usuario",
      dataIndex: "username",
    },
    // {
    //   title: t("messages.aml.view"),
    //   width: "70px",
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {record.description !== null && record.description !== '' && (
    //           <div
    //             className="comments-files"
    //             onClick={(e) => {
    //               handleOnModalComments(record)

    //             }}
    //           >
    //             <Icon type="folder-open" />
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <Modal
      className="modal-view-rules"
      footer={null}
      header={null}
      title={"Regla "+camelizerHelper(modalItem.type)}
      onCancel={onCancel}
      visible={true}
      width="800px"
    >
      <>
      <Row>
        <Col span={12}>
          <h2>{modalItem.name}</h2>
        </Col>
        <Col span={4} offset={5}>
          <div style={{marginTop: 4}}>
            Estado <Switch 
                      defaultChecked={modalItem.status === "ACTIVE"}
                      onChange={(value) => {
                        handleChangeField("status", value ? "ACTIVE" : "INACTIVE")
                      }}                    
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />} />
          </div>
        </Col>
        <Col span={2}>
          <div style={{textAlign: 'center'}}>
            <Button
              type="ghost"
              onClick={() => handleChangeModal(itemCopy)}
              disabled={changesCounter === 0}
            >
              Guardar
            </Button>
          </div>
        </Col>
      </Row>
      <hr/>
        <div className="section-modal-view-rules-wrapper">
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <label>Descripción</label>
              <Input.TextArea
                defaultValue={null}
                autoSize={{ minRows: 2, maxRows: 10 }}
                style={{ width: "100%" }}
                disableds
                value={modalItem.description}
              />
            </Col>
            {(modalItem.type === "DEMOGRAFICA" || modalItem.type === "TRANSACCIONAL") && (
            <Col span={24}>
              <label>Script Regla</label>
              <Input.TextArea
                defaultValue={modalItem.ruleScript}
                onChange={(e) => handleChangeField("ruleScript", e.target.value)}
                autoSize={{ minRows: 2, maxRows: 10 }}
                style={{ width: "100%" }}
              />
            </Col>
            )}
            <Col span={8}>
              <label>Riesgo</label>
              <Select
                // size="small"
                defaultValue={modalItem.score}
                style={{ width: "100%" }}
                onChange={(value) => handleChangeField("score", value)}
              >
                <Select.Option key={1} value={1}>Bajo</Select.Option>
                <Select.Option key={2} value={2}>
                  Medio
                </Select.Option>
                <Select.Option key={3} value={3}>
                  Alto
                </Select.Option>
                <Select.Option key={4} value={4}>
                  Crítico
                </Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <label>Código de Regla</label>
              <Input
                disabled
                defaultValue={modalItem.code}
              />
            </Col>
          </Row>
        </div>
        {itemCopy.params !== undefined && itemCopy.params.length > 0 && (
          <Row className="field-section-row">
            <Col span={24}>
                <div className="title-section">
                    <Row>
                        <Col span={22}>
                          <h4>Parámetros</h4>
                        </Col>
                    </Row>
                </div>
            </Col>
            <div className="section-modal-view-rules-wrapper">
              <Row gutter={[20, 20]}>
                {itemCopy.params.map((parameter, index) => (
                  <Col
                    span={itemCopy.params.length === 1 ? 24 : 12}
                    key={index}
                  >
                    <label>{parameter.name}</label>
                    <Input
                      defaultValue={parameter.value}
                      onChange={({ target }) => {
                        handleEditItemCopy(index, parameter, target.value);
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Row>
        )}
        <Row className="field-section-row" gutter={[20,20]}>
          <Col span={24}>
              <div className="title-section">
                  <Row>
                      <Col span={22}>
                        <h4>Historial de Cambios</h4>
                      </Col>
                  </Row>
              </div>
          </Col>
          <div className="section-modal-view-rules-wrapper">
            <Col xs={24}>
              <Table
                pagination={{ pageSize: 5 }}
                className="table-data"
                dataSource={historyTable}
                columns={tableColumns}
                size="small"
                expandedRowRender={record => {
                  return <pre>{record.descripcion}</pre>}
                }
              />
            </Col>
          </div>
        </Row>
      </>
      {/* {isModalCommentsVisble && (
        <ModalComments
          onCancel={handleModalCommentsCancel}
          comment={modalComment}
        />
      )} */}
    </Modal>
  );
};

export default ModalViewRules;
