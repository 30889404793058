import getCategories from './getCategories'
import Form from './Form'
import getClientsMin from './getClientsMin'
import onBoarding from './onBoarding'
import items from './items'
import sendRequestMatches from './sendRequestMatches'

export const getClientsMinService = getClientsMin
export const getCategoriesService = getCategories
export const FormService = Form
export const onBoardingService = onBoarding
export const itemsService = items
export const sendRequestMatchesService = sendRequestMatches;
