import React, {useEffect, useState} from 'react'
import './AlertManagement.scss'
import {Progress, Spin, Table} from 'antd'
import {getAlertResumeByRisk} from './promises/getAlertByRiskPromise'
import {t} from 'i18next'

export const AlertManagement = ({dateValue, handleTabRedirection}) => {

    const [responseAlertData, setResponseAlertData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        initFunc()
    }, [dateValue]);

    const initFunc = () => {
        setIsLoading(true)

        getAlertResumeByRisk(dateValue).then((response) => {

            const totalAlertManaged = response.data.reduce((acc, curr) => acc + curr.alertCount, 0) //suma total alertCount
            const totalClosed = response.data.reduce((acc, curr) => acc + (curr.closed), 0)// suma total registerCount

            const totalRow = {
                risk: 'total',
                alertCount: totalAlertManaged,
                closed: totalClosed
            };
            setResponseAlertData([...response.data, totalRow])

        }).catch((error) => console.error("alert management section: ", error))
            .finally(() => setIsLoading(false))
    }

    const defaultRiskInfo = {
        1: t('messages.aml.low'),
        2: t('messages.aml.medium'),
        3: t('messages.aml.high'),
        4: t('messages.aml.critical'),
        "total": <strong>Total</strong>
    };

    const getStrokeColorByIndex = {
        4: '#80806f',
        3: '#f6906f',
        2: '#fec964',
        1: '#fcf07d'
    }

    const handleClick = (event) => {
        const record = event.record;
        const selection = event.selected;

        handleTabRedirection(getFiltersToRender(selection, record), 'monitoring')
    };

    const getFiltersToRender = (selection, record) => {
        const states = (selection ===  'closed')? ["CLOSED"] : ["OPEN", "READY"]

        let risk =(record.risk === "total") ? null : record.risk;

            let filters = {
            states: states,
            risks: [risk],
            activeTab: 'tab-alerts',
        }

        if(dateValue && dateValue.length === 2) {
            filters.fechaAlerta = dateValue;
        }
        return filters;
    };

    const dataColumns = [
        {
            dataIndex: 'risk',
            render: (text) => {
                return <span>{defaultRiskInfo[text]}</span>
            }
        },
        {
            title: 'Gesitonadas',
            dataIndex: 'closed',
            align: 'center',
            render: (text, record) => {

                return (record.risk !== 'total')?
                (<a onClick={() => handleClick({selected: 'closed', record: record})}>
                    {record.closed}
                </a>)
                :
                (<span> {record.closed}</span>)
            }
        },
        {
            title: 'Pendientes',
            render: (text, record) => {


                return (record.risk !== 'total')?
                (<a onClick={() => handleClick({selected: 'pending', record: record}) }>
                    {record.alertCount - record.closed}
                </a >)
                :
                (<span> {record.alertCount - record.closed}</span >)
            },
            align: 'center'
        },
        {
            title: 'Progreso',
            align: 'center',
            width: '40%',
            render: (text, record) => {
                if (record.risk !== 'total') {
                    return (
                        <div className={'progress-container-inside-compliance-' + record.risk}>
                            <Progress
                                percent={100}
                                successPercent={(record.closed * 100 / record.alertCount)}
                                showInfo={false}
                                strokeColor={getStrokeColorByIndex[record.risk]}
                            />
                        </div>
                    )
                }
            },
        },
        {
            align: 'center',
            render: (text, record) => {
                if (record.risk !== 'total') {
                const percentage = Math.round(record.closed * 100 / record.alertCount);
                return <span>{isNaN(percentage) ? '0%' : percentage + '%'}</span>;
            } else {
                return <strong>{record.alertCount}</strong>;
            }
            }
        },
    ]

    return (
        <div className='inside-alert-management-section'>
            <Spin spinning={isLoading}>
                {responseAlertData.every(item => item.alertCount === 0 && item.registerCount === 0) ? (
                    <div className="text-no-content">No hay datos disponibles</div>
                ) : (
                    <Table
                        pagination={false}
                        columns={dataColumns}
                        dataSource={responseAlertData}
                        loading={isLoading}
                        style={{width: '100%'}}
                        size='small'
                    />
                )}
            </Spin>
        </div>
    )
}
