import './Sidebar.scss'
import React from 'react'
import { Col, Icon } from 'antd'

const Sidebar = ({ activeTab, onTabChange }) => {

	return (
		<Col className="sidebar" xs={7} s={7} md={5} lg={5} xl={5} xxl={3}>
			<div className="sidebar-inner">
				<div className="menu-block">
					<ul>
						<li className={ activeTab === 'tab-dashboard' ? 'active' : '' } onClick={ () => onTabChange('tab-dashboard') }>
							<div className="menu-item-inner">
								<Icon type="mail" className="option"/>Dashboard
								<Icon type="check" />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</Col>
	)
}

export default Sidebar
