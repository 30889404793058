import React, {useState, useEffect} from 'react';
import './AlertAssignment.scss'
import {Row, Col, Button, Icon, Select, Input, Radio, notification, Spin} from 'antd'
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { alertAssignPromise } from './promises'
import { getUsersByClientPromise } from '../../../../promises'

const AlertAssignment = ({alert, refreshHandler}) => {
    const [seeMore, setSeeMore] = useState(0);
    const [hasComments, setHasComments] = useState(false);
    const [timeInDays, setTimeInDays] = useState(null)
    const [comms, setComms] = useState(null)
    const [changes, setChanges] = useState(0)
    const [user, setUser] = useState();
    const [users, setUsers] = useState();
    const { t } = useTranslation()

    useEffect(() => {
        handleGetUsers();
    }, []);

    useEffect(() => {
        if (alert?.assign !== null) {
            handleGetInitialData()
        }
    }, [alert])

    const handleGetInitialData = () => {
        setUser(alert?.assign?.userId)
        setComms(alert?.assign?.comments)
        setTimeInDays(alert?.assign?.days)
    }

    const handleGetUsers = async () => {
        const usrs = await getUsersByClientPromise()
        const filtered = usrs.filter(u => (u.type === 'ADMIN' || u.type === 'SADMIN' || u.type === 'USUARIO') && u.status === 'ACTIVE' && u.modules && u.modules.includes('ANCTO'));
        setUsers(filtered)
    }

    const handleUserChange = async (value) => {
        await setUser(value)
        handleAddChange()
    }

    const handleTimeInDaysChange = async (value) => {
        await setTimeInDays(value)
        handleAddChange()
    }

    const handleCommentsChange = async (value) => {
        await setComms(value)
        handleAddChange()
    }

    const handleAddChange = () => {
        setChanges(oldChanges => {
            const newNumber = oldChanges + 1
            return newNumber
        })
    }

    const handleSave = () => {
        if (user){
            alertAssignPromise(alert.id, user, timeInDays, comms)
            .then(response => {
                notification['success']({
                    "message": "Operación Exitosa",
                    "description": "La asignación ha sido guardada exitosamente"
                })
                setChanges(0)
                refreshHandler()
            })
        }else{
            notification['error']({
                "message": "Error",
                "description": "Debe asignar un usuario"
            })
        }
    }
    return (
        <div className="alert-assignment-section">
            <div className="title-section">
                <Row>
                    <Col span={19}>
                        <h3>Asignación de Alertas</h3>
                    </Col>
                    <Col span={5}>
                        <div style={{textAlign: 'right'}}>
                        <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                            {seeMore ?
                                <>Ver menos <Icon type="minus"/></>
                                :
                                <>Ver más <Icon type="plus"/></>
                            }
                        </Button>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
            <AnimateHeight 
                duration={ 600 } 
                height={ seeMore } 
                animateOpacity={true}
            >
                <div className='alert-assignment-body'>
                        <Row style={{height: 0}}>
                            { changes > 0 && alert!==null && alert.status !== "CLOSED" && <Button type="primary" size="small" icon="save" className="save-button" style={{float: 'right'}} onClick={ handleSave }>Guardar cambios</Button> }
                        </Row>
                    <div className='alert-assignment-content-wrapper'>
                        <Row gutter={[0, 20]}>
                            <Col span={18} style={{float: 'left'}}>
                                Seleccione el usuario a quien asignará la alerta
                            </Col>
                            {users &&
                                <Col span={6}>
                                    <Select value={ user } placeholder={ t('messages.aml.select') } disabled={alert!==null && alert?.status === "CLOSED" ? true : false} style={{ width: '100%' }} onChange={ handleUserChange }>
                                        {
                                            users.map(user => <Select.Option value={user.id}>{user.name}</Select.Option>)
                                        }
                                    </Select>
                                </Col>
                            }
                            <Col span={18} style={{float: 'left'}}>
                                Asigne un SLA
                            </Col>
                            <Col span={6}>
                                <Input placeholder='Número de días' value={timeInDays} onChange={(e) => handleTimeInDaysChange(e.target.value)}/>
                            </Col>
                            <Col span={18} style={{float: 'left'}}>
                                ¿Requiere asociar algún comentario?
                            </Col>
                            <Col span={6}>
                                <div style={{float: 'right'}}>
                                    <Radio.Group  defaultValue={comms ? true : false} buttonStyle="solid" onChange={(e) => setHasComments(e.target.value)}>
                                        <Radio.Button value={true}>Si</Radio.Button>
                                        <Radio.Button value={false}>No</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Col>
                            {/* <AnimateHeight id='123' duration={500} height={seeComments} animateOpacity={true}> */}
                            {hasComments &&
                                <Col span={24}>
                                    <Input.TextArea rows={4} value={comms} placeholder="Comentarios" onChange={(e) => {handleCommentsChange(e.target.value)}}/>
                                </Col>
                            }
                            {/* </AnimateHeight> */}
                        </Row>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    );
};

export default AlertAssignment;