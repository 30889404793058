import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

const sendForm57Service = {
  sendFormC57: (id, sendFormComment) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/sendFormC57',
      method: 'post',
      body: {
        sendFormComment,
        clCliente: {
          id
        }
      }
    })
  }
}
export default sendForm57Service